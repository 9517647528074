// import { Tab, Tabs } from "@material-ui/core";
import { Tabs } from "antd";
import React, { useEffect, useMemo } from "react";
import { useMenu } from "../MenuContext";
import { useTabScroll } from "./useTabScroll";

const { TabPane } = Tabs;

export function SubTab() {
  const {
    onChangePosition,
    state: { position, menuPosition, menuKeys },
    getCurrentMenu,
  } = useMenu();

  const sub = useMemo(() => {
    if (!Array.isArray(menuKeys) || menuKeys.length === 0) return [];
    const menu = getCurrentMenu();
    const subs = [];
    if (menu && menu.subCats) {
      for (const key in menu.subCats) {
        subs.push(menu.subCats[key]);
      }
    }
    return subs;
    // eslint-disable-next-line
  }, [menuPosition, menuKeys]);

  const ref = useTabScroll(position, [sub]);

  const tabs = useMemo(() => {
    return sub.map((subCats) => {
      const name = subCats.subcatName;
      return (
        <TabPane
          key={subCats.index}
          tab={
            <span
              data-component-name="SubCategoryTab"
              data-subcategory-tab={name}
              className="tabs"
              ref={(el) => (ref.current[subCats.index] = el)}
            >
              {name}
            </span>
          }
        />
      );
    });
  }, [sub, ref]);

  useEffect(() => {
    if (!Array.isArray(menuKeys) || menuKeys.length === 0) return;
    const menu = getCurrentMenu();
    if (menu) {
      if (position < menu.startIndex || position > menu.endIndex) {
        onChangePosition(`${menu.startIndex}`);
      }
    }
    // eslint-disable-next-line
  }, [menuPosition]);

  return (
    <div className="sub_cats">
      <Tabs
        className="subMenu"
        activeKey={`${position}`}
        onChange={onChangePosition}
      >
        {tabs}
      </Tabs>
      {!(sub.length - 2 === position) && <div className="shadowRight"></div>}
    </div>
  );
}
