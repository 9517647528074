import moment from "jalali-moment";
const ShortMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export function convertToJalali(date, showTime = false, onlyHourAndMin) {
  if (onlyHourAndMin) return moment(date).locale("en").format("HH:mm");
  if (showTime)
    return moment(date).locale("en").format("YYYY/MMMM/DD, HH:mm:ss"); //add  HH:mm:ss for time functinality

  return moment(date).locale("en").format("YYYY/MM/DD"); //add  HH:mm:ss for time functinality
}

export function getDateWithMonthName(date) {
  const dateArr = moment(date).locale("en").format("YYYY/M/DD").split("/");
  return `${dateArr[2]} ${ShortMonths[parseInt(dateArr[1] - 1)]} ${dateArr[0]}`;
}
export function getTime(date) {
  return moment(date).locale("en").format("HH:mm");
}
