import React from "react";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  InputWrapper: {
    backgroundColor: "#f9f9f9",
    margin: "1.3rem 0",
    borderRadius: "0.375rem",
    maxHeight: "3.35rem",
  },
  errorMessage: {
    color: "#FF0800",
    fontSize: "0.9rem",
  },
});

export interface ISquareCardError {
  type: string;
  message: string;
  field: string;
}

export interface ISquareCardInputs {
  errors: ISquareCardError[];
}

const SquareCardInputs = (props: ISquareCardInputs) => {
  const renderError = (field: string) => {
    const error = props.errors.find(
      (error: ISquareCardError) => error.field === field
    );
    if (!error) return null;
    return <span className={classes.errorMessage}>{error.message}</span>;
  };
  const classes = useStyles();
  return (
    <>
      <div className={classes.InputWrapper}>
        {renderError("cardNumber")}
        <div id="sq-card-number"></div>
      </div>
      <div className={classes.InputWrapper}>
        {renderError("expirationDate")}
        <div id="sq-expiration-date"></div>
      </div>
      <div className={classes.InputWrapper}>
        {renderError("cvv")}
        <div id="sq-cvv"></div>
      </div>
      <div className={classes.InputWrapper}>
        {renderError("postalCode")}
        <div id="sq-postal-code"></div>
      </div>
    </>
  );
};

export default SquareCardInputs;
