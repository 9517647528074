import { useEffect, useState } from "react";
import Axios from "axios";
import { v4 as uuidv4 } from "uuid";
import T from "i18n-react";

import { generatePay360Url } from "../generatePay360Url";
import { useSelector, useDispatch } from "react-redux";
import { _updateCartID } from "../../../../../redux/actions/index";

import {
  setIsPaymentDrawerOpen,
  setIsPaymentProcessingDrawerOpen,
  setSelectedPaymentMethod,
} from "../../../reducer/PaymentActions";
import useCompleteOrder from "../../../PaymentHooks/useCompleteOrder";
import useOnSuccessfulOrder from "../../../PaymentHooks/useOnSuccessfulOrder";
import { usePaymentState } from "../../../PaymentStore";

import { useSnackbarMessages } from "../../../../../yoello-lib/modules/components/snackbar/SnackbarContext";
import { useSentry } from "../../../../../customHooks/useSentry";
import { useCheckCartItems } from "../../../../../customHooks/useCheckCartItems";

const baseUrl = generatePay360Url();

const useConfirmPay360Order = () => {
  const Venue = useSelector((state: any) => state.Venue);
  const { completeOrder } = useCompleteOrder();
  const { retrieveToken, paymentDetails, dispatch } = usePaymentState();
  const rdxDispatch = useDispatch();
  const { sendSnackbarMessage } = useSnackbarMessages()!;
  const { captureException } = useSentry();
  const { checkCart } = useCheckCartItems();

  const [threedsUrl, setThreedsUrl] = useState<string>();
  const [threedsTransactionId, setThreedsTransactionId] = useState<string>();
  const [threedsType, setThreedsType] = useState<string>();
  const [orderId, setOrderId] = useState<string>();
  const [orderLongId, setOrderLongId] = useState<string>();
  const [cvv, setCVV] = useState<string>();
  const [shouldResumePaymentV1, setShouldResumePaymentV1] = useState(false);
  const [shouldResumePaymentV2, setShouldResumePaymentV2] = useState(false);
  const [PaRes, setPaRes] = useState<string>();
  const [transactionId, setTransactionId] = useState<string>();
  const [shouldSaveCard, setShouldSaveCard] = useState<boolean>();
  const [billingAddress, setBillingAddress] = useState<any>();

  const { onSuccessfulOrder } = useOnSuccessfulOrder();

  useEffect(() => {
    if (paymentDetails) {
      setShouldSaveCard(paymentDetails.save_card);
      setBillingAddress({
        line1: paymentDetails.address_1,
        city: paymentDetails.city,
        postcode: paymentDetails.post_code,
        country_code: paymentDetails.country_code,
      });
    }
  }, [paymentDetails]);

  useEffect(() => {
    if (
      transactionId &&
      orderId &&
      billingAddress &&
      (shouldResumePaymentV2 || (PaRes && shouldResumePaymentV1))
    ) {
      resumePayment();
    }
    //eslint-disable-next-line
  }, [
    transactionId,
    orderId,
    billingAddress,
    shouldResumePaymentV2,
    shouldResumePaymentV1,
  ]);

  const resetPaymentProcess = () => {
    setShouldResumePaymentV1(false);
    setShouldResumePaymentV2(false);
    setOrderId(undefined);
    setCVV(undefined);
    setPaRes(undefined);
    setTransactionId(undefined);
    setThreedsTransactionId(undefined);
    setThreedsType(undefined);
    setThreedsUrl(undefined);
  };

  const onOrderFailure = (message?: string) => {
    resetPaymentProcess();
    dispatch(setIsPaymentDrawerOpen(true));
    dispatch(setIsPaymentProcessingDrawerOpen(false));
    dispatch(setSelectedPaymentMethod(null));
    rdxDispatch(_updateCartID(uuidv4()));
    if (message) {
      sendSnackbarMessage(T.translate(message) as string, "error");
    }
  };

  const onBeginPaymentProcessing = () => {
    dispatch(setIsPaymentProcessingDrawerOpen(true));
  };

  const confirmPay360Order = async () => {
    let userToken;
    let order;
    let payment;
    const isUsingSavedDetails = !!paymentDetails.id;
    const paymentDetailOptions = isUsingSavedDetails
      ? {
          cvv,
          billing_address: {
            line1: paymentDetails.address_1,
            city: paymentDetails.city,
            postcode: paymentDetails.post_code,
            country_code: paymentDetails.country_code,
          },
        }
      : {
          expiry_date: paymentDetails.expiry_date,
          billing_address: paymentDetails.billing_address,
        };

    dispatch(setIsPaymentProcessingDrawerOpen(true));
    onBeginPaymentProcessing();

    if (isUsingSavedDetails && (!cvv || cvv.length < 3)) {
      onOrderFailure("Payment.Errors.CVV_FAILURE");
      return;
    }

    try {
      userToken = await retrieveToken();
    } catch (e) {
      onOrderFailure("Payment.Errors.AUTH_FAILURE");
      return;
    }

    if (!paymentDetails.card_token) {
      onOrderFailure("Payment.Errors.INVALID_CARD");
      return;
    }

    try {
      order = await completeOrder(true);
    } catch (e) {
      onOrderFailure("Payment.Errors.CompleteOrderFailed");
      return;
    }

    if (order.data.data.order_reference_number) {
      setOrderId(order.data.data.order_reference_number);
      setOrderLongId(order.data.data.order_id);
    } else {
      onOrderFailure("Payment.Errors.CreateOrderError");
      return;
    }

    //Check if cart is still valid
    const cartSuccessful = await checkCart();
    if (!cartSuccessful) {
      onOrderFailure();
    }

    //@TODO - api handler
    try {
      payment = await Axios.post(
        `${baseUrl}/payments`,
        {
          placed_order_hash_id: order.data.data.order_reference_number,
          name_on_card: paymentDetails.name_on_card,
          card_token: paymentDetails.card_token,
          save_card: !!paymentDetails.save_card,
          ...paymentDetailOptions,
        },
        {
          params: { venue_id: Venue.id },
          headers: { Authorization: `Bearer ${userToken}` },
        }
      );
    } catch (e) {
      captureException(e, { feature: "pay360-payments" });
      onOrderFailure("Payment.Errors.PaymentAuthenticationError");
      return;
    }

    if (payment.status === 201) {
      onSuccessfulOrder(
        order.data.data.order_reference_number,
        order.data.data.order_id
      );
      return;
    }

    if (payment.data.data.threeds_type) {
      setTransactionId(payment.data.data.transaction_id);
      setThreedsUrl(payment.data.data.redirect_url);
      setThreedsTransactionId(payment.data.data.redirect_id);
      setThreedsType(payment.data.data.threeds_type);
    } else {
      onOrderFailure("Payment.Errors.GENERIC_CARD_FAILURE");
      return;
    }
  };

  const resumePayment = async () => {
    let userToken;
    let resume;

    const billingAddress = paymentDetails.billing_address || {
      line1: paymentDetails.address_1,
      city: paymentDetails.city,
      postcode: paymentDetails.post_code,
      country_code: paymentDetails.country_code,
    };

    onBeginPaymentProcessing();

    try {
      userToken = await retrieveToken();
    } catch (e) {
      onOrderFailure("Payment.Errors.AUTH_FAILURE");
      return;
    }

    try {
      resume = await Axios.post(
        `${baseUrl}/payments/resume`,
        {
          transaction_id: transactionId,
          placed_order_hash_id: orderId,
          billing_address: billingAddress,
          save_card: !!shouldSaveCard,
          pares: PaRes,
        },
        {
          params: { venue_id: Venue.id },
          headers: { Authorization: `Bearer ${userToken}` },
        }
      );
    } catch (e) {
      captureException(e, { feature: "pay360-payments-resume" });
      onOrderFailure("Payment.Errors.PaymentAuthenticationError");
      return;
    }

    if (resume.status === 201) {
      onSuccessfulOrder(orderId, orderLongId);
    } else {
      onOrderFailure("Payment.Errors.AUTH_FAILURE");
      return;
    }
  };

  const onVerificationComplete = async (ev: any) => {
    if (ev.origin !== document.location.origin) return;
    if (ev.data.name === "payment-verification-redirect") {
      if (ev.data.payload.PaRes) {
        const decodedPaRes = decodeURIComponent(ev.data.payload.PaRes);
        setPaRes(decodedPaRes);
        setShouldResumePaymentV1(true);
        return;
      }
      switch (ev.data.payload.overallStatus) {
        case "00":
          setShouldResumePaymentV2(true);
          break;
        default:
          onOrderFailure("Payment.Errors.PaymentAuthenticationError");
      }
    }
  };

  return {
    confirmPay360Order,
    onVerificationComplete,
    threedsUrl,
    threedsType,
    setThreedsUrl,
    threedsTransactionId,
    setCVV,
  };
};

export default useConfirmPay360Order;
