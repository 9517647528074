import React, { ComponentType, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ConsumerUpdatePreferences, getConsumer } from "../../Api.js";
import { LOGIN } from "../../constants/URL.const";
import { useAuth0 } from "../../yoello-lib/modules/auth/Auth0";
import IConsumerUser from "../../yoello-lib/modules/auth/IConsumerUser";
import PageLoading from "../Page/PageLoading";
import { useDispatch, useSelector } from "react-redux";
import { profileData } from "../LoginV2/ProfileView/useSubmitProfile.js";
import { setLanguage } from "../../yoello-lib/modules/redux/actions/localization";

/**
 * Wrapper component to handle the authentication of components
 */
function WithConsumerAuth(Component: ComponentType) {
  function WrappedComponent(props: any) {
    const {
      isAuthenticated,
      retrieveUser,
      setUpAuthedUser,
      user,
      setRedirectURLs,
      retrieveToken,
      logout,
    } = useAuth0()!;
    const history = useHistory();
    // const is_safari =
    //   navigator.userAgent.indexOf("Safari") > -1 &&
    //   navigator.userAgent.indexOf("Chrome") === -1;
    const [isConsumerLoading, setIsConsumerLoading] = useState(
      isTokenValid() ? true : false
    );
    const dispatch = useDispatch();
    const appLanguage = useSelector(
      (state: any) => state.localization.language
    );
    useEffect(() => {
      if (!isAuthenticated && isTokenValid()) {
        if (isConsumerLoading === false) {
          setIsConsumerLoading(true);
        }
        checkTokenGetConsumer()
          .then((isToken) => {
            if (!isToken) {
              retrieveUser(LOGIN).catch(() => {
                localStorage.removeItem(`user_token_${window.location.host}`);
                setIsConsumerLoading(false);
              });
            }
            // setIsConsumerLoading(false);
          })
          .finally(() => setIsConsumerLoading(false));
      }
      // eslint-disable-next-line
    }, []);
    useEffect(() => {
      if (!history.location.pathname.includes(LOGIN)) {
        setRedirectURLs(history.location.pathname);
      }
      //eslint-disable-next-line
    }, [history.location]);
    useEffect(() => {
      const userObj = user as IConsumerUser;
      /**Checks if the user object is the consumer obj */
      if (
        isAuthenticated &&
        userObj &&
        userObj.user_details_set === undefined
      ) {
        getAndSaveConsumer();
      }
      // eslint-disable-next-line
    }, [isAuthenticated]);
    async function getAndSaveConsumer(tokenString?: string) {
      try {
        const token = await retrieveToken();
        const consumer = await getConsumer(token);
        const tokenObj =
          tokenString !== undefined
            ? JSON.parse(
                localStorage.getItem(`user_token_${window.location.host}`)!
              )
            : undefined;
        setUpAuthedUser(consumer.data.data, tokenObj);
        handleConsumerLanguageSettings(consumer.data.data, token);
      } catch (error) {
        localStorage.removeItem(`user_token_${window.location.host}`);
      }
    }
    async function checkTokenGetConsumer() {
      const token = localStorage.getItem(`user_token_${window.location.host}`)!;
      if (token !== null) {
        const tokenObj = JSON.parse(token);
        await getAndSaveConsumer(tokenObj.token);
        return true;
      }
      return false;
    }
    async function handleConsumerLanguageSettings(
      ConsumerData: profileData,
      token: string
    ) {
      if (ConsumerData?.consumer_preferences?.language?.length) {
        dispatch(
          setLanguage(
            ConsumerData.consumer_preferences?.language?.toUpperCase()
          )
        );
      } else {
        await ConsumerUpdatePreferences(
          {
            language: appLanguage?.toLowerCase(),
          },
          token
        );
      }
    }
    function isTokenValid() {
      const token = localStorage.getItem(`user_token_${window.location.host}`);
      if (token !== null) {
        try {
          const tokenObj = JSON.parse(token);
          if (tokenObj.token !== undefined) {
            return true;
          }
        } catch (error) {
          return false;
        }
      }
      return false;
    }
    async function getTokenOrLogout() {
      try {
        const token = await retrieveToken();
        if (token === undefined) {
          history.push(LOGIN);
        } else {
          return token;
        }
      } catch (error) {
        console.error("Something went wrong whilst getting auth token.", error);

        logout();
      }
    }
    /**Handles loading if you have a valid token */
    if (isAuthenticated === false && isConsumerLoading === true) {
      return (
        <>
          <PageLoading />
        </>
      );
    }
    return (
      <Component
        {...props}
        retrieveToken={getTokenOrLogout}
        User={user}
        isAuthenticated={isAuthenticated}
      />
    );
  }
  return WrappedComponent;
}

export default WithConsumerAuth;
