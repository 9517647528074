import React from "react";
import useSocialLogins from "./useSocialLogins";

import FacebookButton from "./Buttons/FacebookButton";
import GoogleButton from "./Buttons/GoogleButton";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {},
});

const SocialLoginButtons = () => {
  const classes = useStyles();
  const { loginWithGoogle, loginWithFacebook } = useSocialLogins();
  return (
    <div className={classes.root}>
      <GoogleButton onClick={loginWithGoogle} />
      <FacebookButton onClick={loginWithFacebook} />
    </div>
  );
};

export default SocialLoginButtons;
