import React from "react";
import { ReactComponent as MinusSVG } from "../../../imges/Minus-r.svg";
import { ReactComponent as AddSVG } from "../../../imges/Add-r.svg";
import { ReactComponent as DeleteSVG } from "../../../imges/delete.svg";
import Styles from "./styles.module.scss";
interface ICountComponentProps {
  count: number;
  disableMinus?: boolean;
  onMinusClick?: Function;
  onPlusClick?: Function;
  noDelete?: boolean;
  disablePlus?: boolean;
  choiceBox: boolean;
}

function CountComponent({
  count,
  disableMinus,
  onMinusClick,
  onPlusClick,
  noDelete,
  disablePlus,
  choiceBox,
}: ICountComponentProps) {
  const renderIcon = () => {
    if (count > 1 || (noDelete && !choiceBox)) {
      return (
        <MinusSVG
          width="16px"
          onClick={() => {
            if (!disableMinus && onMinusClick) {
              onMinusClick();
            }
          }}
          data-component-name="MinusItem"
        />
      );
    }
    return (
      <DeleteSVG
        width="16px"
        onClick={() => {
          if (!disableMinus && onMinusClick) {
            onMinusClick();
          }
        }}
        data-component-name="DeleteItem"
      />
    );
  };
  return (
    <div
      className={`${Styles.CountDiv} ${
        !choiceBox ? Styles.CountChangebleDiv : ""
      }`}
    >
      {!choiceBox && renderIcon()}
      {!disablePlus && (
        <p className={Styles.CountText} data-component-name="ItemCount">
          {count}
        </p>
      )}
      {!disablePlus && !choiceBox && (
        <AddSVG
          width="16px"
          onClick={() => {
            if (onPlusClick) {
              onPlusClick();
            }
          }}
          data-component-name="PlusItem"
        />
      )}
    </div>
  );
}

export default CountComponent;
