import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Styles from "./styles.module.scss";
import { ReactComponent as PlaceholderSVG } from "../../imges/placeholder.svg";

export function SideMenuHeader() {
  const Group = useSelector((state: any) => state.Group);
  const Venue = useSelector((state: any) => state.Venue);
  const { pathname } = useLocation();

  function renderContent() {
    if (pathname.split("/")[1] === "g" && !pathname.split[3] && Group) {
      return <p>{Group.group_name_public}</p>;
    } else {
      return (
        <>
          {Venue.url_logo ? (
            <img src={Venue.url_logo} alt="" />
          ) : (
            <PlaceholderSVG />
          )}
          <p>{Venue.venue_name}</p>
        </>
      );
    }
  }
  return <div className={Styles.VenueHeader}>{renderContent()}</div>;
}
