import { AlertProps, Alert, AlertTitle } from "@material-ui/lab";
import React from "react";

interface IAlertStatusCard {
    alertProps: AlertProps;
    alertTitle?: React.ReactNode;
    alertMessage: React.ReactNode;
}

export function AlertStatusCard({
    alertProps,
    alertTitle,
    alertMessage
}: IAlertStatusCard) {
    return <Alert {...alertProps}>
        {alertTitle && <AlertTitle>{alertTitle}</AlertTitle>}
        {alertMessage}
    </Alert>
}