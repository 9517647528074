import React, { useMemo } from "react";
import ProductCard from "../../Widgets/ProductCard";
import { useMenu } from "../MenuContext";
import { IElementItems } from "../MenuLists";
import { CategoryDescription } from "./CategoryDescription";

interface IProps extends IElementItems {}

export function ProductItems({ data, position, name, description }: IProps) {
  const {
    handleItemSelect,
    state: { listHeight, position: currentPosition },
    formatCurrency,
  } = useMenu();

  const productList = useMemo(() => {
    return data.map((items, index) => {
      return (
        <ProductCard
          key={`${position}-${items.menu_item_id}-${index}`}
          productInfo={items as any}
          onClick={() => handleItemSelect(items)}
          formatCurrency={formatCurrency}
        />
      );
    });
  }, [formatCurrency, data, handleItemSelect, position]);

  return (
    <div
      key={`menu-list${position}${name}`}
      style={{
        overflow: "auto",
        height: listHeight,
        transition:
          position === currentPosition ? "height 0.15s linear" : undefined,
      }}
    >
      <CategoryDescription position={position} description={description} />
      {productList}
      <div
        style={{
          paddingBottom: "3rem",
        }}
      ></div>
    </div>
  );
}
