import Styles from "./styles.module.scss";
import React from "react";
import { SideMenuHeader } from "./SideMenuHeader";
import Button from "../Widgets/Button";
import { SideMenuFooter } from "./SideMenuFooter";
import { SideMenuContent } from "./SideMenuContent";

export function SideMenu(props) {
  return (
    <div
      data-component-name="SideMenuContainer"
      className={Styles.SideMenu}
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div className={Styles.Header}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <SideMenuHeader />
          <Button
            onClick={props.closeSideDrawer}
            dataComponentName="ExitButton"
            iconClassName={"consumer-close"}
            style={{
              color: "#FFFFFF",
              fontSize: "1.75rem",
              background: "rgba(255, 255, 255, 0.15)",
              borderRadius: "0.75rem",
              top: "1.875rem",
              right: "1.875rem",
            }}
            isHeaderBtn
          />
        </div>
      </div>
      <SideMenuContent {...props} />
      <SideMenuFooter {...props} />
    </div>
  );
}
