import React, { useState } from "react";
import { AxiosResponse } from "axios";
import ConfirmPayment from "../../../Widgets/ConfirmPayment/ConfirmCardPayment";
import PaymentDrawer from "../PaymentDrawer";
import StripePayNowButton from "../../paynowButton/StripePayNowButton";
import {
  setIsCardDetailsDrawerOpen,
  setIsPaymentDrawerOpen,
} from "../../reducer/PaymentActions";
import { IPlaceOrder } from "../../../../typings/IPayment";

interface IPaymentComponentProps {
  dispatch: React.Dispatch<any>;
  dispatcher: React.Dispatch<any>;
  paymentDetails: any;
  paymentFunction: (
    isUsingPaymentRequest: boolean
  ) => Promise<AxiosResponse<IPlaceOrder>>;
  totalPrice: number;
  checkPaymentProceed: () => Promise<boolean>;
  successfulTransaction: (orderId: string, orderLongID: string) => void;
  errorMessenger: (message: string) => void;
  cartItemTaxAmount: number | null;
  serviceTaxAmount: number | null;
}

/**Handles the confirm payment swipeable drawer */
function PaymentComponent({
  dispatch,
  paymentDetails,
  paymentFunction,
  totalPrice,
  checkPaymentProceed,
  successfulTransaction,
  errorMessenger,
}: IPaymentComponentProps) {
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);

  return (
    <PaymentDrawer
      digitalWalletComponent={
        <StripePayNowButton
          totalCost={totalPrice}
          errorMessenger={errorMessenger}
          successfulTransaction={successfulTransaction}
          createOrder={paymentFunction}
          checkPaymentProceed={checkPaymentProceed}
        />
      }
      confirmPaymentComponent={
        <ConfirmPayment
          paymentFunction={paymentFunction}
          totalCost={totalPrice}
          paymentDetails={paymentDetails}
          errorMessenger={errorMessenger}
          onSuccessfulTransaction={successfulTransaction}
          checkPaymentProceed={checkPaymentProceed}
          changeCard={() => {
            dispatch(setIsPaymentDrawerOpen(false));
            dispatch(setIsCardDetailsDrawerOpen(true));
          }}
          setIsPaymentLoading={setIsPaymentLoading}
          dispatch={dispatch}
        />
      }
      isPaymentLoading={isPaymentLoading}
    />
  );
}

export default PaymentComponent;
