import { useDirtyForm } from "./useDirtyForm";
import React from "react"



interface IDirtyFormContainerProps {
    control: any
    formData: any;
    hasDataChanged: any;
}

export function DirtyFormContainer({
    formData,
    control,
    hasDataChanged
}: IDirtyFormContainerProps) {
    useDirtyForm(formData, control, hasDataChanged);

    return <></>;
}