import React from "react";
import { ReactComponent as TwitterSVG } from "../../../imges/twitter_new.svg";
import { ReactComponent as FacebookSVG } from "../../../imges/facebook_new.svg";
import { ReactComponent as InstagramSVG } from "../../../imges/instagram_new.svg";
import { ReactComponent as BrowserSVG } from "../../../imges/website_new.svg";
import { ReactComponent as PhoneSVG } from "../../../imges/phone_new.svg";
import Styles from "../styles.module.scss";
import ShareButton from "../../Widgets/ShareButton/ShareButton";

export function VenueSocials({ Venue }) {
  const Socials = [
    {
      href: Venue.contact_phone ? "tel:" + Venue.contact_phone : null,
      icon: PhoneSVG,
    },
    {
      href: Venue.url_website ? Venue.url_website : null,
      icon: BrowserSVG,
    },
    {
      href: Venue.url_instagram ? Venue.url_instagram : null,
      icon: InstagramSVG,
    },
    {
      href: Venue.url_facebook ? Venue.url_facebook : null,
      icon: FacebookSVG,
    },
    {
      href: Venue.url_twitter ? Venue.url_twitter : null,
      icon: TwitterSVG,
    },
  ];
  return (
    <div className={Styles.shareButtons}>
      {Socials.map((item) => {
        return item.href ? (
          <ShareButton href={item.href} Icon={item.icon} />
        ) : (
          ""
        );
      })}
    </div>
  );
}
