import {
  PaymentStages,
  IPaymentStoreState,
  BackendTimeSlots,
  IDiscountStoreData,
  PaymentMethods,
} from "../types.d";
import { SelectedOrderType } from "../types";
import { postcodeCheckStatusType } from "../../Widgets/PostCodeChecker/PostCodeChecker";

/**
 * Actions for the payment reducer
 */

export function addDeliveryTimes(deliveryTimes: BackendTimeSlots[]) {
  return {
    type: ADD_DELIVERY,
    payload: deliveryTimes,
  };
}

export const ADD_DELIVERY = "ADD_DELIVERY";

export function addPaymentDetails(
  paymentDetails: any,
  userHasPaymentDetails: boolean
) {
  return {
    type: ADD_PAYMENT_DETAILS,
    payload: {
      paymentDetails: paymentDetails,
      userHasPaymentDetails: userHasPaymentDetails,
    },
  };
}
export const ADD_PAYMENT_DETAILS = "ADD_PAYMENT_DETAILS";

export function setIsDeliveryDrawerOpen(isOpen: boolean) {
  return {
    type: IS_DELIVERY_DRAWER_OPEN,
    payload: isOpen,
  };
}
export const IS_DELIVERY_DRAWER_OPEN = "IS_DELIVERY_DRAWER_OPEN";

export function setIsCardDetailsDrawerOpen(isOpen: boolean) {
  return {
    type: IS_CARD_DETAILS_DRAWER_OPEN,
    payload: isOpen,
  };
}
export const IS_CARD_DETAILS_DRAWER_OPEN = "IS_CARD_DETAILS_DRAWER_OPEN";

export function setIsCollectionDrawerOpen(isOpen: boolean) {
  return {
    type: IS_COLLECTION_DRAWER_OPEN,
    payload: isOpen,
  };
}

export const IS_COLLECTION_DRAWER_OPEN = "IS_COLLECTION_DRAWER_OPEN";

export function setIsPaymentProcessingDrawerOpen(isOpen: boolean) {
  return {
    type: IS_PAYMENT_PROCESSING_OPEN,
    payload: isOpen,
  };
}

export const IS_PAYMENT_PROCESSING_OPEN = "IS_PAYMENT_PROCESSING_OPEN";

export function changeOrderType(orderType: SelectedOrderType) {
  return {
    type: CHANGE_ORDER_TYPE,
    payload: orderType,
  };
}

export const CHANGE_ORDER_TYPE = "CHANGE_ORDER_TYPE";

export function changeStage(stage: PaymentStages) {
  return {
    type: CHANGE_STAGES,
    payload: stage,
  };
}

export const SET_IS_USING_PAYMENT_REQUEST = "SET_IS_USING_PAYMENT_REQUEST";

export function setIsUsingPaymentRequest(isUsingPaymentRequest: boolean) {
  return {
    type: SET_IS_USING_PAYMENT_REQUEST,
    payload: isUsingPaymentRequest,
  };
}

export const CHANGE_STAGES = "CHANGE_STAGE";

export function selectTime(time: BackendTimeSlots) {
  return {
    type: SELECT_TIME,
    payload: time,
  };
}

export const SELECT_TIME = "SELECT_TIME";

export function setIsTableServiceDrawerOpen(isOpen: boolean) {
  return {
    type: IS_TABLE_SERVICE_OPEN,
    payload: isOpen,
  };
}
export const IS_TABLE_SERVICE_OPEN = "IS_TABLE_SERVICE_OPEN";

export function setUserToken(token: string) {
  return {
    type: SET_USER_TOKEN,
    payload: token,
  };
}
export const SET_USER_TOKEN = "SET_USER_TOKEN";

export function changeTableNumber(tableNumber: string, areaName: string) {
  return {
    type: CHANGE_TABLE_NUMBER,
    payload: { tableNumber, areaName },
  };
}

export const CHANGE_TABLE_NUMBER = "CHANGE_TABLE_NUMBER";

export function changeDeliveryDetails(deliveryDetails: any) {
  return {
    type: CHANGE_DELIVERY_DETAILS,
    payload: deliveryDetails,
  };
}

export const CHANGE_DELIVERY_DETAILS = "CHANGE_DELIVERY_DETAILS";

export function onCompleteDeliveryDetails() {
  return {
    type: ON_COMPLETE_DELIVERY,
  };
}

export const ON_COMPLETE_DELIVERY = "ON_COMPLETE_DELIVERY";

export function setIsPaymentDrawerOpen(isOpen: boolean) {
  return {
    type: IS_PAYMENT_DRAWER_OPEN,
    payload: isOpen,
  };
}

export const IS_PAYMENT_DRAWER_OPEN = "IS_PAYMENT_DRAWER_OPEN";

export function isUpdatingPaymentDetails(isUpdating: boolean) {
  return {
    type: IS_UPDATING_PAYMENT_DETAILS,
    payload: isUpdating,
  };
}

export const IS_UPDATING_PAYMENT_DETAILS = "IS_UPDATING_PAYMENT_DETAILS";

export function setSubTotalCost(subTotalPrice: number) {
  return {
    type: SET_SUB_TOTAL_COST,
    payload: subTotalPrice,
  };
}

export const SET_SUB_TOTAL_COST = "SET_SUB_TOTAL_COST";

export function setTipPrice(tipPrice: number) {
  return {
    type: SET_TIP_PRICE,
    payload: tipPrice,
  };
}
export const SET_TOTAL_PRICE_WITHOUT_TIPS = "SET_TOTAL_PRICE_WITHOUT_TIPS";

export function setTotalPriceWithoutTips(price: number) {
  return {
    type: SET_TOTAL_PRICE_WITHOUT_TIPS,
    payload: price,
  };
}

export const SET_TOTAL_PRICE_OVERRIDE = "SET_TOTAL_PRICE_OVERRIDE";

export function setTotalPriceOverride(price: number) {
  return {
    type: SET_TOTAL_PRICE_OVERRIDE,
    payload: price,
  };
}

export const SET_TIP_PRICE = "SET_TIP_PRICE";

export function setTransactionFee(transactionFee: number) {
  return {
    type: SET_TRANSACTION_FEE,
    payload: transactionFee,
  };
}
export const SET_TRANSACTION_FEE = "SET_TRANSACTION_FEE";

export function setIsLoading(isLoading: boolean) {
  return {
    type: IS_LOADING,
    payload: isLoading,
  };
}

export const IS_LOADING = "IS_LOADING";

export function resetState(initalState: IPaymentStoreState) {
  return {
    type: RESET_STATE,
    payload: initalState,
  };
}

export const RESET_STATE = "RESET_STATE";

export function setMessage(message: string) {
  return {
    type: SET_MESSAGE,
    payload: message,
  };
}

export const SET_MESSAGE = "SET_MESSAGE";

export function setDeliveryPrice(deliveryPrice: number) {
  return {
    type: SET_DELIVERY_PRICE,
    payload: deliveryPrice,
  };
}

export const SET_DELIVERY_PRICE = "SET_DELIVERY_PRICE";

export function setPostcodeCheckStatus(status: postcodeCheckStatusType) {
  return {
    type: SET_POSTCODE_CHECK_STATUS,
    payload: status,
  };
}

export const SET_POSTCODE_CHECK_STATUS = "SET_POSTCODE_CHECK_STATUS";

export const SET_CART_TAX = "SET_CART_TAX";

export function setCartTax(cartTax: number) {
  return {
    type: SET_CART_TAX,
    payload: cartTax,
  };
}

export const SET_CART_INCLUSIVE_TAX = "SET_CART_INCLUSIVE_TAX";

export function setCartInclusiveTax(cartTax: number) {
  return {
    type: SET_CART_INCLUSIVE_TAX,
    payload: cartTax,
  };
}
export const SET_SERVICE_TAX = "SET_SERVICE_TAX";

export function setServiceTax(serviceTax: number) {
  return {
    type: SET_SERVICE_TAX,
    payload: serviceTax,
  };
}

export const SET_SERVICE_INCLUSIVE_TAX = "SET_SERVICE_INCLUSIVE_TAX";

export function setServiceInclusiveTax(serviceTax: number) {
  return {
    type: SET_SERVICE_INCLUSIVE_TAX,
    payload: serviceTax,
  };
}
export const SET_DELIVERY_TAX = "SET_DELIVERY_TAX";

export function setDeliveryTax(serviceTax: number) {
  return {
    type: SET_DELIVERY_TAX,
    payload: serviceTax,
  };
}

export const SET_DELIVERY_INCLUSIVE_TAX = "SET_DELIVERY_INCLUSIVE_TAX";

export function setDeliveryInclusiveTax(serviceTax: number) {
  return {
    type: SET_DELIVERY_INCLUSIVE_TAX,
    payload: serviceTax,
  };
}
export const SET_DISCOUNTS = "SET_DISCOUNTS";

export function setDiscounts(discounts: IDiscountStoreData) {
  return {
    type: SET_DISCOUNTS,
    payload: discounts,
  };
}
export const SET_DISCOUNTS_TOTAL_VALUE = "SET_DISCOUNTS_TOTAL_VALUE";
export function setDiscountsTotalValue(value: number) {
  return {
    type: SET_DISCOUNTS_TOTAL_VALUE,
    payload: value,
  };
}

export const SET_IS_PAYMENT_READY = "SET_IS_PAYMENT_READY";

export function setIsPaymentReady(isReady: boolean) {
  return {
    type: SET_IS_PAYMENT_READY,
    payload: isReady,
  };
}

export const SET_PAYMENT_PROVIDERS = "SET_PAYMENT_PROVIDERS";

export function setPaymentProviders(providers: Record<string, boolean>) {
  return {
    type: SET_PAYMENT_PROVIDERS,
    payload: providers,
  };
}
export const SET_PLACED_ORDER = "SET_PLACED_ORDER";

export function setPlacedOrder(placedOrderID: string) {
  return {
    type: SET_PLACED_ORDER,
    payload: placedOrderID,
  };
}
export const SET_SELECTED_PAYMENT_METHOD = "SET_SELECTED_PAYMENT_METHOD";

export function setSelectedPaymentMethod(
  selectedPaymentMethod: PaymentMethods
) {
  return {
    type: SET_SELECTED_PAYMENT_METHOD,
    payload: selectedPaymentMethod,
  };
}
