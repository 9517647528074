import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import T from "i18n-react";
import { PaymentContext } from "../../../PaymentStore";
import SquareDrawerWrapper from "../SquareDrawerWrapper";
import SquareFormWidget from "./SquareFormWidget";
import usePaymentDetails from "../../../PaymentHooks/usePaymentDetails";
import { useSnackbarMessages } from "../../../../../yoello-lib/modules/components/snackbar/SnackbarContext";
import useConfirmDigitalWalletOrder from "../PaymentComponent/useConfirmDigitalWalletOrder";
import { useCurrencyState } from "../../../../../yoello-lib/modules/hooks/useCurrencyState";
import { ISquareCardError } from "./SquareCardInputs";

export interface IVerificationDetails {
  intent: string;
  amount?: string;
  currencyCode?: string;
  billingContact: {
    givenName: string;
    familyName: string;
  };
}

const SquareForm = ({ square, closeDrawer, isOpen }) => {
  const { totalPrice, tipTotal, totalPriceOverride, isPaymentDrawerOpen } = useContext(
    PaymentContext
  );
  const { sendSnackbarMessage } = useSnackbarMessages()!;
  const { updateSquareDetails } = usePaymentDetails();
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [saveCard, setSaveCard] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState<ISquareCardError[]>([]);
  const { confirmDigitalWalletOrder } = useConfirmDigitalWalletOrder();
  const [digitalWalletNonce, setDigitalWalletNonce] = useState(null);
  const { iso_code } = useCurrencyState();
  const venue = useSelector((state: any) => state.Venue);

  const storeCardDetails = async (nonce, cardData) => {
    setIsSaving(true);
    updateSquareDetails(nonce, firstName, surname, saveCard, cardData)
      .then((response) => {
        closeDrawer();
      })
      .catch((error) => {
        if (error.response?.data?.data[0]?.code) {
          sendSnackbarMessage(
            T.translate(
              `Payment.Errors.${error.response?.data?.data[0]?.code}`
            ).toString(),
            "error"
          );
        } else {
          sendSnackbarMessage(
            T.translate("Payment.Errors.GENERIC_CARD_FAILURE").toString(),
            "error"
          );
        }
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const requestCardNonce = () => {
    setErrors([]);
    square.requestCardNonce();
  };

  const onFirstNameChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setFirstName(e.currentTarget.value);
  };

  const onSurnameChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSurname(e.currentTarget.value);
  };

  useEffect(() => {
    square.options.callbacks.cardNonceResponseReceived = (
      errors,
      nonce,
      cardData
    ) => {
      if (!errors) {
        if (cardData.digital_wallet_type !== "NONE") {
          setDigitalWalletNonce(nonce);
        } else {
          storeCardDetails(nonce, cardData);
        }
      } else {
        // setErrors(errors);
        sendSnackbarMessage(errors[0].message, "error");
      }
    };
    square.options.callbacks.createPaymentRequest = () => {
      const price = (
        ((totalPriceOverride || totalPrice) + tipTotal) /
        100
      ).toFixed(2);

      var paymentRequestJson = {
        currencyCode: iso_code.toUpperCase(),
        total: {
          label: venue.venue_name,
          amount: price,
          pending: false,
        },
      };
      return paymentRequestJson;
    };
    // eslint-disable-next-line
  }, [
    saveCard,
    firstName,
    surname,
    iso_code,
    venue,
    totalPriceOverride,
    totalPrice,
    tipTotal,
    isPaymentDrawerOpen
  ]);

  useEffect(() => {
    if (digitalWalletNonce) {
      confirmDigitalWalletOrder(digitalWalletNonce);
    }
    //eslint-disable-next-line
  }, [digitalWalletNonce]);

  //Had to fake the mui-drawer component due to iframe focus issue
  return (
    <SquareDrawerWrapper isOpen={isOpen} closeDrawer={closeDrawer}>
      <SquareFormWidget
        closeDrawer={closeDrawer}
        onSurnameChange={onSurnameChange}
        onFirstNameChange={onFirstNameChange}
        saveCard={saveCard}
        setSaveCard={setSaveCard}
        requestCardNonce={requestCardNonce}
        isSaving={isSaving}
        errors={errors}
      />
    </SquareDrawerWrapper>
  );
};

export default SquareForm;
