import React from "react";
import T from "i18n-react";
import Styles from "../shopList.module.scss";
import useCurrency from "../../../yoello-lib/modules/localisation/useCurrency";
interface ICartOrderText {
  cartLength: number;
  totalPrice: number;
}

function CartOrderText({ cartLength, totalPrice }: ICartOrderText) {
  const { formatCurrency } = useCurrency();
  const formattedTotal = formatCurrency(totalPrice);
  return (
    <div>
      {" "}
      {cartLength > 0 ? (
        <div className={Styles.subTotalPrice}>
          <T.p text={{ key: `ShopCart.Subtotal` }} />
          <span>{formattedTotal}</span>
        </div>
      ) : (
        <div className={Styles.emptyCartText}>
          <p>{T.translate("ShopCart.YourCartIsEmpty")}</p>
        </div>
      )}
    </div>
  );
}

export default CartOrderText;
