import React, { useRef, useEffect } from "react";

import Drawer from "../../../../Drawer";
import ConfirmationButtons from "../../../../Widgets/ConfirmPayment/ConfirmationButtons";
import {
  setIsPaymentDrawerOpen,
  setIsCardDetailsDrawerOpen,
  setIsPaymentProcessingDrawerOpen,
} from "../../../reducer/PaymentActions";
import { usePaymentState } from "../../../PaymentStore";
import { generatePay360Url } from "../generatePay360Url";
import { PaymentMethods } from "../../../types.d";

interface IConfirmPaymentComponent {
  requireCVV: boolean;
  confirmPayment: () => void;
  onVerificationComplete: (ev: any) => void;
  threedsUrl?: string;
  threedsType?: string;
  transactionId?: string;
  setCVV?: (cvv: string) => void;
}

const baseUrl = generatePay360Url();

const ConfirmPaymentComponent = ({
  requireCVV,
  confirmPayment,
  onVerificationComplete,
  threedsUrl,
  threedsType,
  transactionId,
  setCVV,
}: IConfirmPaymentComponent) => {
  const {
    isLoading,
    paymentDetails,
    dispatch,
    isPaymentDrawerOpen,
    isPaymentProcessingDrawerOpen,
    selectedPaymentMethod,
  } = usePaymentState();

  const formRef = useRef();

  useEffect(() => {
    if (formRef?.current && threedsUrl && transactionId && threedsType) {
      dispatch(setIsPaymentProcessingDrawerOpen(false));
      //@ts-ignore
      formRef.current.submit();
    }
    //eslint-disable-next-line
  }, [threedsUrl, transactionId, threedsType, formRef]);

  useEffect(() => {
    window.addEventListener("message", onVerificationComplete);
    return () => window.removeEventListener("message", onVerificationComplete);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ConfirmationButtons
        requireCVV={requireCVV}
        isDisabled={
          selectedPaymentMethod && selectedPaymentMethod !== PaymentMethods.CARD
        }
        updateCVV={setCVV}
        isLoading={isLoading}
        paymentDetails={paymentDetails}
        processPayment={confirmPayment}
        changeCard={() => {
          dispatch(setIsPaymentDrawerOpen(false));
          dispatch(setIsCardDetailsDrawerOpen(true));
        }}
      />
      {
        <Drawer
          isPersistent
          isOpen={
            !!transactionId &&
            !!threedsUrl &&
            isPaymentDrawerOpen &&
            !isPaymentProcessingDrawerOpen
          }
          closeDrawer={() => {}}
        >
          {!!transactionId && !!threedsUrl && (
            <iframe
              title="threeds-frame"
              name="threeds-frame"
              id="threeds-frame-1"
              style={{ minHeight: "900px", border: "none" }}
            />
          )}

          <form
            target="threeds-frame"
            action={threedsUrl}
            method="POST"
            ref={formRef}
            style={{ display: "none" }}
          >
            <input
              name={
                threedsType === "THREEDSECURE_V2_REDIRECT"
                  ? "transactionId"
                  : "PaReq"
              }
              value={transactionId}
            />
            <input
              name={
                threedsType === "THREEDSECURE_V2_REDIRECT"
                  ? "notificationUrl"
                  : "TermUrl"
              }
              value={`${baseUrl}/redirect/3ds2`}
            />
            <input name="MD" value="some merchant data" />
          </form>
        </Drawer>
      }
    </>
  );
};

export default ConfirmPaymentComponent;
