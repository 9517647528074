import SocialButton from "./SocialButton";
import React from "react";
import GoogleIcon from "./Icons/GoogleIcon";
import T from "i18n-react";

const GoogleButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <SocialButton
      onClick={onClick}
      text={T.translate("Login.Texts.google").toString()}
    >
      <GoogleIcon />
    </SocialButton>
  );
};

export default GoogleButton;
