import React from "react";
import Styles from "./externalLinks.module.scss";
import T from "i18n-react";
import { ReactComponent as ExternalLinksSVG } from "../../../imges/externalLinks.svg";
import { IExternalLinksProps } from "./types.d";
const ExternalLinks = ({ links }: IExternalLinksProps) => {
  if (!links || links.length === 0) {
    return null;
  }
  return (
    <div
      data-component-name="venue-external-links"
      className={Styles.externalLinksContainer}
    >
      <header>{T.translate("Venue.ExternalLinks")}</header>
      <ul>
        {links.map((link: externalLinkType) => (
          <li key={link.url}>
            <a href={link.url} target="_blank" rel="noopener noreferrer">
              <div>
                <ExternalLinksSVG />
                <span>{link.label}</span>
              </div>
              <span className="consumer-right-arrow"></span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ExternalLinks;
