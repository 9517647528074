import React from "react";
import Drawer from "../../../Drawer";
import Button from "../../../Widgets/Button";
import Styles from "./styles.module.scss";
import T from "i18n-react";

interface IInfoDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  onContinue: () => void;
  isLoading: boolean;
}

export function InfoDrawer(props: IInfoDrawerProps) {
  const { isOpen, onClose, onContinue, isLoading } = props;
  return (
    <Drawer
      isOpen={isOpen}
      isPersistent={true}
      closeDrawer={onClose}
      instructionClassName={Styles.InfoDrawerInstructions}
      instructions={[T.translate("Payment.OpenBankingTitle").toString()]}
    >
      <div className={Styles.InfoDrawerContainer}>
        <p>{T.translate("Payment.OpenBankingInfo")}</p>
        <p>{T.translate("Payment.OpenBankingInfo2")}</p>
        <Button
          isYoelloBtn
          title={"BankTransfer"}
          onClick={onContinue}
          isLoading={isLoading}
        />
      </div>
    </Drawer>
  );
}
