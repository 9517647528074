import React from "react";
import { makeStyles, FormControlLabel, Checkbox } from "@material-ui/core";
import T from "i18n-react";

import DrawerHeader from "../../../../Drawer/DrawerHeader";
import Button from "../../../../Widgets/Button";
import SquareCardInputs from "./SquareCardInputs";

const useStyles = makeStyles({
  Input: {
    padding: "1rem",
    borderRadius: "0.375rem",
    overflow: "hidden",
    margin: "1.3rem 0",
    boxSizing: "border-box",
    outline: "unset",
    border: "none",
    minHeight: "3rem",
    color: "#212121",
    fontSize: "14px", // Can't set fontSize to rem for Square so setting as 14px for consistency
    fontFamily: "Arial !important",
    backgroundColor: "#f9f9f9 !important",
    display: "block",
    width: "100%",
  },
});

const SquareFormWidget = ({
  closeDrawer,
  saveCard,
  setSaveCard,
  onFirstNameChange,
  onSurnameChange,
  requestCardNonce,
  isSaving,
  errors,
}) => {
  const classes = useStyles();

  return (
    <div>
      <DrawerHeader
        instructions={["Payment.EnterYour", "Forms.CardDetails"]}
        onClose={closeDrawer}
      />
      <div id="form-container">
        <input
          className={classes.Input}
          onChange={onFirstNameChange}
          placeholder={T.translate("Forms.FirstName").toString()}
        />
        <input
          className={classes.Input}
          onChange={onSurnameChange}
          placeholder={T.translate("Forms.LastName").toString()}
        />
        <SquareCardInputs errors={errors} />
        <FormControlLabel
          control={
            <Checkbox
              checked={saveCard}
              onChange={() => setSaveCard(!saveCard)}
              name="save-card"
            />
          }
          label={T.translate("Payment.RememberMyCardDetails")}
        />
        <Button
          isLoading={false}
          title={!isSaving ? "UseCardDetails" : "VerifyingCard"}
          disabled={isSaving}
          isYoelloBtn
          onClick={requestCardNonce}
          dataComponentName={"SaveCardDetailsButton"}
        />
      </div>
    </div>
  );
};

export default SquareFormWidget;
