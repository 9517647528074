import React from "react";
import T from "i18n-react";
import Button from "../../../Widgets/Button";
import Styles from "./orderCollection.module.scss";
import { ReactComponent as BlackQRCode } from "../../../../imges/BlackQRCode.svg";
import { ReactComponent as CompleteQRCode } from "../../../../imges/CompleteQRCode.svg";

interface IOrderCollectionProps {
  collectionPointName: string;
  orderId: string;
  onClick: () => void;
}

export function OrderCollectionActive({
  collectionPointName,
  orderId,
  onClick,
}: IOrderCollectionProps) {
  return (
    <div className={Styles.OrderCollectionContainer}>
      <T.p
        className={Styles.title}
        text={{ key: "OrderHistory.collectPoint" }}
      />
      <p>{collectionPointName}</p>
      <Button
        className={Styles.CollectionActiveButton}
        title="QRActive"
        Icon={<BlackQRCode />}
        dataComponentName="QRCodeActive"
        onClick={onClick}
      />
    </div>
  );
}

export function OrderCollectionComplete({
  collectionPointName,
  orderId,
  onClick,
}: IOrderCollectionProps) {
  return (
    <Button
      title="QRComplete"
      Icon={<CompleteQRCode />}
      className={Styles.CollectionButtonComplete}
      dataComponentName="QRCodeComplete"
      onClick={onClick}
    />
  );
}
