import { makeStyles, Checkbox } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import FormElement from "../../yoello-lib/modules/dynamicForms/FormElement";
import ICommonFormProps from "../../yoello-lib/modules/dynamicForms/interfaces/ICommonFormProps";

export interface IInfoCheckInput extends ICommonFormProps {
  Component: React.FunctionComponent<any>;
  inputOptions: {
    message: string;
    defaultChecked: boolean;
  };
}

const useStyles = makeStyles({
  label: {
    display: "flex",
    alignItems: "start",
    paddingBottom: "4rem",
  },
  infoText: {
    marginBottom: "0",
  },
});

const InfoCheckInput = ({
  inputOptions,
  setValue,
  keyName,
  ...props
}: IInfoCheckInput) => {
  const [isChecked, setIsChecked] = useState(inputOptions?.defaultChecked);
  const classes = useStyles();
  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    setIsChecked(e.currentTarget.checked);
  };

  useEffect(() => {
    setValue(keyName, isChecked);
  }, [isChecked, setValue, keyName]);

  return (
    <FormElement {...props} keyName={keyName}>
      <label className={classes.label}>
        <Checkbox
          style={{
            padding: "0",
          }}
          checked={isChecked}
          onChange={onChange}
        />
        <p className={classes.infoText}>{inputOptions?.message}</p>
      </label>
    </FormElement>
  );
};

export default InfoCheckInput;
