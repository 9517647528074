import { useEffect, useState } from "react";

const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [isTyping, setIsTyping] = useState(false);
  useEffect(() => {
    if (value === undefined) {
      return;
    }
    if (value?.length === 0) {
      return setDebouncedValue("");
    }
    setIsTyping(true);
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      setIsTyping(false);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line
  }, [value]);

  return { debouncedValue, isTyping };
};

export default useDebounce;
