import React from "react";
import Styles from "./orderStatus.module.scss";
import T from "i18n-react";
interface IOrderStatus {
  status: string;
}
export enum Status {
  STARTED = "STARTED",
  PLACED = "PLACED",
  FINISHED = "FINISHED",
  ACCEPTED = "ACCEPTED",
  REFUNDED = "REFUNDED",
  CLOSED = "CLOSED",
  REJECTED = "REJECTED",
  DECLINED = "DECLINED",
  VOID = "VOID",
  REFUND_FAILED = "REFUND_FAILED",
  PARTIALLY_REFUNDED = "PARTIALLY_REFUNDED",
  PROCESSING_REFUND = "PROCESSING_REFUND",
  READY_FOR_COLLECTION = "READY_FOR_COLLECTION",
  PAYMENT_PROCESSING = "PAYMENT_PROCESSING",
}
const OrderStatus = ({ status }: IOrderStatus) => {
  const renderStatus = (status) => {
    switch (status) {
      case Status.STARTED:
        return Styles.STARTED;
      case Status.PLACED:
        return Styles.PLACED;
      case Status.FINISHED:
        return Styles.FINISHED;
      case Status.ACCEPTED:
        return Styles.ACCEPTED;
      case Status.REFUNDED:
        return Styles.REFUNDED;
      case Status.CLOSED:
        return Styles.CLOSED;
      case Status.REJECTED:
        return Styles.REJECTED;
      case Status.REFUND_FAILED:
        return Styles.REFUND_FAILED;
      case Status.PARTIALLY_REFUNDED:
        return Styles.PARTIALLY_REFUNDED;
      case Status.PROCESSING_REFUND:
        return Styles.PROCESSING_REFUND;
      case Status.READY_FOR_COLLECTION:
        return Styles.READY_FOR_COLLECTION;
      case Status.PAYMENT_PROCESSING:
        return Styles.PAYMENT_PROCESSING;
      default:
        return undefined;
    }
  };
  const renderStatusMarkup = () => {
    if (status === Status.VOID || status === Status.DECLINED) {
      return (
        <div className={`${Styles.orderStatusContainer} ${Styles.REFUNDED}`}>
          <p data-component-name="orderStatus">
            {(T.translate(`OrderTypes.refunded`) as string).toUpperCase()}
          </p>
        </div>
      );
    } else {
      return (
        <div
          className={`${Styles.orderStatusContainer} ${renderStatus(status)}`}
        >
          <p>
            {(T.translate(
              `OrderTypes.${status.toLowerCase()}`
            ) as string).toUpperCase()}
          </p>
        </div>
      );
    }
  };
  return <>{renderStatusMarkup()}</>;
};
export default React.memo(OrderStatus);
