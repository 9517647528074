import { DiscountType } from "./Dsicounts/DiscountsComponent";

export interface IPaymentContainerState {
  isPaymentDrawerOpen: boolean;
  isCardDetailsDrawerOpen: boolean;
  isDeliveryDrawerOpen: boolean;
  isTableServiceDrawerOpen: boolean;
  isCollectionDrawerOpen: boolean;
  isPaymentProcessingDrawerOpen: boolean;
  /**User has payment details */
  userHasPaymentDetails: boolean;
  /**Delivery/collection times */
  deliveryTimes: BackendTimeSlots[];
  /**Selected order type */
  selectedOrderType: SelectedOrderType | null;
  /**Different stages of the payment page */
  paymentStages: PaymentStages;
  /**The selected table */
  selectedTable: string | null;
  /**The table area name */
  areaName: string | null;
  /** Users delivery address*/
  deliveryAddress: any;
  /**Disabled ids */
  disabledIds: string[];
  /**Collection drawer */
  selectedTime: BackendTimeSlots | null;
  /**flag for updating card details */
  isUpdatingPaymentDetails: boolean;
  /**Sub total cost */
  subTotalCost: number;
  /**Tip price */
  tipTotal: number;
  /**Total cost of everything */
  totalPrice: number;
  /**Transaction fee */
  transactionFee: number;
  /**Is the page loading */
  isLoading: boolean;
  /**message left by the customer */
  message: string;
  deliveryPrice: number;
  postcodeCheckStatus: postcodeCheckStatusType;
  /** Tax amount for cart items or service fee*/
  cartItemTaxAmount: number | null;
  serviceTaxAmount: number | null;
  cartItemInclusiveTaxAmount: number | null;
  serviceTaxInclusiveTaxAmount: number | null;
  isUsingPaymentRequest: boolean;
  squareVerificationToken?: string;
  dispatch?: React.Dispatch<any>;
  deliveryTaxAmount: number | null;
  deliveryInclusiveTaxAmount: number | null;
  paymentDetails: any;
  totalPriceOverride?: number | null;
  /**used discounts code */
  discounts: IDiscountStoreData;
  /**total cost of discounts */
  totalDiscounts: number | null;
  /**is the payment ready */
  isPaymentReady: boolean;
  paymentProviders: Record<string, boolean>;
  /**placed order id*/
  placedOrderID: string | null;
  /**disabling other methods based on selected payment method*/
  selectedPaymentMethod: PaymentMethods | null;
}

export enum SelectedOrderType {
  CLICK = "COLLECTION",
  TABLE = "TABLE_DELIVERY",
  DELIVERY = "HOME_DELIVERY",
  COLLECTION_POINT = "COLLECTION_POINT_PICKUP",
}

export interface IPaymentStoreState extends IPaymentContainerState {}

export enum PaymentStages {
  PAYMENT,
  CARDDETAILS,
}

export interface IOrderTypesChildren {
  dispatch: Dispatch<any>;
  orderType: SelectedOrderType;
  selectedTime?: BackendTimeSlots;
  deliveryAddress?: deliveryAddress;
  selectedTableNumber?: string;
  selectedTableArea?: string;
}

export interface IAction {
  type: string;
  payload: any;
}
export interface IDiscountStoreData {
  code: null | string;
  message: null | string;
  value: number | null;
  error: boolean;
  type?: DiscountType;
  absoluteValue?: number;
  id?: string;
}
declare type BackendTimeSlots = {
  collection?: boolean;
  delivery?: boolean;
  time_utc: string;
  venue_time: string;
};

declare type DiscountsApiData = {
  discount: DiscountData;
  code: string;
  valid: boolean;
  validation_error: string | null;
  validation_context: object | null;
};
declare type DiscountData = {
  apply_after_tax: boolean;
  code: string;
  created_on: string;
  deleted: boolean;
  description: string;
  enabled: boolean;
  id: stringl;
  max_number_of_uses: boolean;
  metadata: boolean;
  minimum_order_value: number;
  number_of_uses: number;
  type: DiscountType;
  updated_on: string;
  value: number;
  venue_id: string;
};

export interface IPaymentContext extends IPaymentContainerState {
  retrieveToken: () => Promise<string>;
  dispatch: any;
  isAuthenticated: boolean;
}

export enum PaymentMethods {
  CARD = "Card",
  BANK_TRANSFER = "Bank Transfer",
  GOOGLE_PAY = "Google Pay",
  APPLE_PAY = "Apple Pay",
}
