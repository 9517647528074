import React from "react";
import T from "i18n-react";
import { AlertStatusCard } from "../../../../yoello-lib/modules/components/UI/Alerts/AlertStatusCard";
import { ReactComponent as AlertErrorIcon } from "../../../../imges/AlertErrorIcon.svg";
import { ReactComponent as AlertSuccessIcon } from "../../../../imges/AlertSuccessIcon.svg";
import { postcodeCheckStatusType } from "../PostCodeChecker";
interface IPostCodeAlertProps {
  postcodeCheckStatus: postcodeCheckStatusType;
  menuTimingAlerts?: IAvailableMenus[];
}

function PostCodeAlert({
  postcodeCheckStatus,
  menuTimingAlerts,
}: IPostCodeAlertProps) {
  const renderAlert = () => {
    function getMenusTimings(item: IAvailableMenus) {
      let timings = [];
      item.timings.forEach((time) => {
        timings.push(
          ` ${time.start_time.slice(0, -3)} - ${time.end_time.slice(0, -3)}`
        );
      });
      return timings;
    }
    let alertsList = [];

    if (menuTimingAlerts?.length) {
      menuTimingAlerts.forEach((item) => {
        alertsList.push(
          <AlertStatusCard
            alertProps={{
              color: "error",
              icon: <AlertErrorIcon />,
              style: {
                color: "#F65164",
                borderRadius: "0.75rem",
                fontWeight: 600,
                marginBottom: "12px",
              },
            }}
            alertMessage={T.translate("Forms.InvalidTimeSlotForMenu", {
              menuName: item.name_public,
              menuTime: getMenusTimings(item),
            })}
          />
        );
      });
    }

    switch (postcodeCheckStatus) {
      case postcodeCheckStatusType.PostCodeInValid:
        alertsList.push(
          <AlertStatusCard
            alertProps={{
              color: "error",
              icon: <AlertErrorIcon />,
              style: {
                color: "#F65164",
                borderRadius: "0.75rem",
                fontWeight: 600,
              },
            }}
            alertTitle={T.translate("Forms.InvalidPostcode")}
            alertMessage={T.translate("Forms.EnterValidPostCode")}
          />
        );
        break;

      case postcodeCheckStatusType.OutOfDeliveryRadius:
        alertsList.push(
          <AlertStatusCard
            alertProps={{
              color: "error",
              icon: <AlertErrorIcon />,
              style: {
                color: "#F65164",
                borderRadius: "0.75rem",
                fontWeight: 600,
              },
            }}
            alertMessage={T.translate("Forms.OurOfDeliveryRadius")}
          />
        );
        break;

      case postcodeCheckStatusType.InDeliveryRadius:
        alertsList.push(
          <AlertStatusCard
            alertProps={{
              color: "success",
              icon: <AlertSuccessIcon />,
              style: {
                color: "#24C277",
                borderRadius: "0.75rem",
              },
            }}
            alertTitle={T.translate("Forms.GreatNews")}
            alertMessage={T.translate("Forms.InDeliveryRadius")}
          />
        );
        break;

      default:
        break;
    }
    return alertsList;
  };

  return <div>{renderAlert()}</div>;
}

export default PostCodeAlert;
