import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

const usePrimaryStyles = makeStyles({
    tabsRoot: {
        "&.selected": {
            minHeight: "30px",
            height: "30px",
        }
    },
    tabItem: {
        opacity: "0.5 !important",
        transition: "0.25s ease-in-out",
        minWidth: "0",
        width: "auto",
        fontSize: "1rem",
        "&.secondary": {
            margin: "4px",
            padding: "0 18px",
            opacity: "1 !important",
            borderRadius: "8px",
            minHeight: "40px",
            height: "40px",
        }
    },
    tabLabel: {
        color: "#212121",
        textTransform: "capitalize",
        "&.secondary": {
            color: "#8D98B7",
            fontWeight: 400
        }
    },
    tabCounter: {
        marginLeft: "12px",
        color: "#757575",
        fontSize: "0.8rem",
        borderRadius: "6px",
        border: "1px solid #757575",
        padding: "0.15rem 0.6rem",
    },
    indicator: {
        height: "3px",
        borderRadius: "3px",
        "&.secondary": {
            height: "0"
        }
    },
    tabSelected: {
        opacity: "1 !important",
        "&.secondary": {
            background: '#FFFFFF',
            "& $tabLabel": {
                fontWeight: 600,
                color: "#212121",
            }
        }
    }
});

export interface ITabItem {
    id?: string;
    label: string;
    counter?: number;
    value: any;
}

export interface ITabList {
    level: 'primary' | 'secondary';
    tabs: ITabItem[];
    defaultTab: string;
    onChange: (value: any) => any;
    hideCount?: boolean;
    selectedTab?: string;
}

const TabList = (props: ITabList) => {
    const [selected, setSelected] = useState<any>(props.defaultTab);
    const classes = usePrimaryStyles();

    useEffect(() => {
        setSelected(props.selectedTab);
    }, [props.selectedTab]);

    const onChange = (ev: object, value: any) => {
        setSelected(value);
        props.onChange(value);
    };

    const renderTabItem = (tab: ITabItem) => {
        return (
            <Tab
                classes={{
                    root: `${classes.tabItem} ${props.level}`,
                    selected: `${classes.tabSelected} ${props.level}`,
                }}
                label={
                    <div>
                        <span className={`${classes.tabLabel} ${props.level}`}>{tab.label}</span>
                        {(tab.counter && !props.hideCount && tab.counter > 0) ? <span className={`${classes.tabCounter} ${props.level}`}>{tab.counter}</span>
                            : null
                        }
                    </div>
                }
                value={tab.value}
                key={tab.id}
            />
        )
    }

    return (
        <Tabs
            classes={{
                root: `${classes.tabsRoot} ${props.level}`,
                indicator: `${classes.indicator} ${props.level}`
            }}
            value={selected}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor={'primary'}
            onChange={onChange}
        >
            {props.tabs.map(tab => renderTabItem(tab))}
        </Tabs>
    )
}

export default TabList;