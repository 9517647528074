import * as types from "../actions/ActionTypes"

const DietaryTags = (state = {}, action) => {
  switch (action.type) {
    case types.SetDietaryTags:

      state = action.data;
      return state;

    default:

      return state
  }
};
export default DietaryTags
