/**
 * Handles consumer api errors
 *  - 401 removes the user token
 *  - 422 stringify the response body of errors
 *  - default response message
 *  - if non-api failure returns the error message
 */
function consumerErrorHandler(error) {
  // console.log("consumer error handler", error.response);
  let errMsg = "error with consumer API";
  let errStatus = error.status || "INTERNAL";
  if (error.response && error.response.status) {
    const response = error.response.data;
    const statusCode = error.response.status;
    errStatus = statusCode;
    switch (statusCode) {
      case 401:
        window.localStorage.removeItem(`user_token_${window.location.host}`);
        errMsg = "User had invalid token";
        break;
      case 422:
        errMsg = JSON.stringify(response.errors);
        break;
      case 500:
        if (response.message !== null) {
          errMsg = response.message;
        } else {
          errMsg = "internal server error";
        }
        break;
      default:
        errMsg = response.message;
        break;
    }
  } else if (error.message) {
    errMsg = error.message;
  }
  return {
    isError: true,
    errMsg,
    errStatus,
  };
}

export default consumerErrorHandler;
