import { getServiceEndpoints } from "../../environments/services";
import { IAPIDefinitions } from "../APIDefs";
import { Dispatch } from "react";
import {
    addNotification,
    resetCart, setDietaryTags, setExtras, setGroupInfo,
    setGroupName, setMenuItems, setMenuItemsAsExtra, setPreferences, setSelectedMenuType,
    setTaxItems, setVenue, setVenueOpenNow, setAvailableMenus
} from "../../redux/actions/consumer.actions";
import { setCurrencies, setCurrency, setLanguage } from "../../redux/actions/localization";
import { generateTradingTimesObject, ITradingTimes } from "../../utils/tradingTimesObject";
import { checkVenueOpen } from "../../utils/venueOpen";

import { groupVenueType } from "../../interfaces/GroupInfo.type";

export enum APIConsumer {
    consumerVenue = 'consumerVenue',
    consumerGroup = 'consumerGroup',
    consumerMenu = 'consumerMenu',
    consumerOrders = 'consumerOrders',
    consumerLatestOrder = 'consumerLatestOrder',
    consumerPay360Cards = 'consumerPay360Cards',
    consumerVenueTimeSlots = 'consumerVenueTimeSlots',
    consumerAvailableMenus = 'consumerAvailableMenus'
}

function getFullURL() {
    const stage = process.env.REACT_APP_STAGE;
    const overwriteHost = process.env.REACT_APP_SERVICE_ENDPOINT;
    if (!stage) {
        return;
    }
    let services = getServiceEndpoints(stage, overwriteHost);
    return services['consumerAPI'];
}

const url = getFullURL();

export const consumerAPIDefs: IAPIDefinitions = {};


consumerAPIDefs[APIConsumer.consumerVenue] = {
    baseURL: url,
    defaultURL: `/venues/`,
    dataStorage: {
        defaultDataKey: 'Venue',
        selectorKey: () => { return 'Venue'; },
        dispatchFunction: (dispatch: Dispatch<any>, result: any, args) => {
            if (Array.isArray(args) && !args[0]) {
                dispatch(resetCart());
                dispatch(setLanguage(result.language_iso.toUpperCase()));
            }
            const currencyObj = {
                id: result.currency_id,
                iso_code: result.currency_iso,
                english_name: result.currency_name,
                symbol: result.currency_symbol,
            };
            if ((result?.alc_drink_limit !== undefined ||
                result?.alc_drink_limit !== null) &&
                typeof result?.alc_drink_limit === "number") {
                dispatch(addNotification({
                    label: "Restrictions.alcoholRestriction",
                    status: "warning",
                    labelObj: {
                        count: result?.alc_drink_limit
                    },
                    pathname: `/v/${result.venue_nickname}/menu`,
                    notificationLength: 10000
                }))
            }
            const trading_times = generateTradingTimesObject(result.trading_times);

            const venue = {
                ...result,
                trading_times
            }
            /**dispatch venue check notifications */
            const { message, status,languageProps  } = checkVenueOpen(venue, undefined);
            if (message) {
                dispatch(addNotification({
                    label: message,
                    status: status ?? "error",
                    pathname: `/v/${venue.venue_nickname}`,
                    labelObj:languageProps
                }))
            }

            dispatch(setCurrencies([currencyObj]));
            dispatch(setCurrency(currencyObj.id));
            dispatch(setVenue(venue));
        }
    },
    GET: {}
};

consumerAPIDefs[APIConsumer.consumerGroup] = {
    baseURL: url,
    defaultURL: `/venue-groups/`,
    dataStorage: {
        defaultDataKey: 'Group',
        selectorKey: () => { return 'Group'; },
        dispatchFunction: (dispatch: Dispatch<any>, result: any, args) => {
            dispatch(setLanguage(result.group_language_iso.toUpperCase()));
            dispatch(setGroupName(result.group_name_public));
            const venues = result.group_venues;
            const group_venues = venues
                .filter((groupV: groupVenueType) => groupV.venue_nickname !== null)
                .map((groupV: groupVenueType) => {
                    return {
                        ...groupV,
                        trading_times: generateTradingTimesObject(
                            groupV.venue_trading_times as ITradingTimes[]
                        ),
                    };
                });
            const groupInfo = {
                ...result,
                group_venues,
            };
            dispatch(setGroupInfo(groupInfo));
            dispatch(setSelectedMenuType(null));
        }
    },
    GET: {}
};

consumerAPIDefs[APIConsumer.consumerMenu] = {
    baseURL: url,
    defaultURL: `/menu-items/`,
    dataStorage: {
        defaultDataKey: 'MenuItem',
        selectorKey: () => { return 'MenuItem'; },
        dispatchFunction: (dispatch: Dispatch<any>, result: any, args): void => {
            dispatch(setMenuItems({
                menuItems: result.menu_items,
                categories: result.subcategories,
                menusList: result?.venue_details?.on_menu_setup ? false : null
            }));
            dispatch(setPreferences(result.menu_item_preferences));
            dispatch(setExtras(result.menu_item_extra_groups));
            dispatch(setMenuItemsAsExtra(result.menu_items_as_extras));
            dispatch(setDietaryTags(result.dietary_tags));
            if (result.taxes) {
                dispatch(setTaxItems(result.taxes));
            }
            if (Array.isArray(args) && args[0] && args[1]) {
                const venue: any = args[1];
                const { message, status,languageProps  } = checkVenueOpen(venue, args[0]);
                if (message) {
                    dispatch(addNotification({
                        label: message,
                        status: status ?? "error",
                        pathname: `/v/${venue.venue_nickname}/menu`,
                        labelObj:languageProps
                    }))
                }
            }
        }
    },
    GET: {}
};

consumerAPIDefs[APIConsumer.consumerAvailableMenus] = {
    baseURL: url,
    defaultURL: `/menus/`,
    dataStorage: {
        defaultDataKey: 'AvailableMenus',
        selectorKey: () => { return 'AvailableMenus'; },
        dispatchFunction: (dispatch: Dispatch<any>, result: any, args): void => {
            console.log(result,"resultresult");
            dispatch(setMenuItems({
                menuItems: null,
                categories: null,
                menusList: result
            }));
            dispatch(setAvailableMenus(result));
        }
    },
    GET: {}
};

consumerAPIDefs[APIConsumer.consumerOrders] = {
    baseURL: url,
    defaultURL: '/orders',
    GET: {}
};

consumerAPIDefs[APIConsumer.consumerVenueTimeSlots] = {
    baseURL: url + "/venues/",
    defaultURL: 'time_slots',
    dataStorage: {
        defaultDataKey: 'VenueOpenNow',
        selectorKey: () => { return 'VenueOpenNow'; },
        dispatchFunction: (dispatch: Dispatch<any>, result: any, args): void => {
            if (result) {
                dispatch(setVenueOpenNow(result.open_now));
            }
        }
    },
    GET: {}
};

consumerAPIDefs[APIConsumer.consumerLatestOrder] = {
    baseURL: url,
    defaultURL: '/orders-latest',
    GET: {}
};

//Pay 360

export const generatePay360Url = () => {
    const stage = process.env.REACT_APP_STAGE;
    const prefix = stage === "prod" ? "" : `${stage}-`;
    return `https://${prefix}pay360.yllo.uk/api/v1`;
};

const pay360Url = generatePay360Url();

consumerAPIDefs[APIConsumer.consumerPay360Cards] = {
    baseURL: pay360Url,
    defaultURL: '/cards',
    GET: {}
};
