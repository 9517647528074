import { useState, useEffect } from "react";

export interface IBillingAddress {
  line1: string;
  city: string;
  country_code: string;
  postcode: string;
}

const initialBillingAddress = {
  line1: "",
  city: "",
  country_code: "",
  postcode: "",
};

const useBillingAddressValidation = () => {
  const [line1, setLine1] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [countryCode, setCountryCode] = useState<string>("");
  const [postCode, setPostCode] = useState<string>("");

  const [billingAddress, setBillingAddress] = useState<IBillingAddress>(
    initialBillingAddress
  );

  useEffect(() => {
    setBillingAddress({
      line1,
      city,
      country_code: countryCode,
      postcode: postCode,
    });
  }, [line1, city, countryCode, postCode]);

  const checkForErrors = () => {
    //@TODO - better validation
    return {
      line1IsValid: line1.length > 3,
      cityIsValid: city.length > 2,
      countryCodeIsValid: countryCode.length === 3,
      postCodeIsValid: postCode.length > 3,
    };
  };

  const clearForm = () => {
    setLine1("");
    setCity("");
    setCountryCode("");
    setPostCode("");
    setBillingAddress(initialBillingAddress);
  };

  return {
    line1,
    city,
    countryCode,
    postCode,
    setLine1,
    setCity,
    setCountryCode,
    setPostCode,
    billingAddress,
    clearForm,
    checkForErrors,
  };
};

export default useBillingAddressValidation;
