import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { priceToPounds } from "../../utils/pricingCalculator";
import { timeConverterAsString } from "../../utils/timeConverter";
import ExternalLinks from "../Widgets/ExternalLinks";
import TradingTimes from "../Widgets/TradingTimes/index";
import VenueOrderTypes from "../Widgets/VenueOrderTypes/VenueOrderTypes";
import Styles from "./styles.module.scss";
import { VenueSocials } from "./VenueSocials/VenueSocials";
import { VenueTopBanner } from "./VenueDisplay/VenueTopBanner";
import { VenueLogo } from "./VenueDisplay/VenueLogo";
import { VenueContent } from "./VenueDisplay/VenueContent";
import { VenueThreshold } from "./VenueDisplay/VenueThreshold";
import { CardiffCastleButton } from "./VenueDisplay/CardiffCastleButton";
import { useVenueOpen } from "../../customHooks/useVenueOpen";
import { useVenueTimezones } from "../../customHooks/useVenueTimezones";
import { setSelectedMenuType } from "../../yoello-lib/modules/redux/actions/consumer.actions";

export function VenueComponent({
  openSideDrawer,
  openShareDrawer,
  ...props
}): JSX.Element {
  const dispatch = useDispatch();
  const Venue = useSelector((state: any) => state.Venue);
  useVenueTimezones();
  useVenueOpen();

  React.useEffect(() => {
    dispatch(setSelectedMenuType(null));
    // eslint-disable-next-line
  }, []);

  return (
    <div
      data-component-name="VenuePageContainer"
      id="venue_container"
      className={Styles.venueContainer}
      data-url={window.location.href}
      data-title={Venue.venue_name}
      data-description={Venue.venue_description}
      data-media={Venue.url_logo}
    >
      <VenueTopBanner
        Venue={Venue}
        area={props.area}
        openSideDrawer={openSideDrawer}
      />
      <div className={Styles.venueDetails} id="venueDetailsId">
        <VenueLogo venueLogoUrl={Venue?.url_logo} />
        {/* <VenueShare
          openShareDrawer={openShareDrawer}
          className={Styles.share}
          childClassName={Styles.ShareText}
        /> */}

        <div className={Styles.venueContent}>
          <VenueContent Venue={Venue} />
          <div>
            <VenueOrderTypes
              TableServiceEnable={
                Venue.table_enabled && Venue.table_layout?.length > 0
              }
              CollectionEnable={Venue.pickup_enabled}
              DeliveryEnable={Venue.delivery_enabled}
              PrepTime={
                Venue.food_prep_time > 0
                  ? timeConverterAsString(Venue.food_prep_time)
                  : null
              }
              DeliveyFee={Venue.delivery_charge}
              DeliveyTime={
                Venue.delivery_max_lead_time
                  ? timeConverterAsString(Venue.delivery_max_lead_time)
                  : "0"
              }
              MinOrderValue={
                Venue.min_delivery_price > 0
                  ? priceToPounds(Venue.min_delivery_price)
                  : null
              }
              MenuPageLink={getMenuPageLink(props, Venue)}
            />
          </div>
          <VenueThreshold area={props.area} />
          <div className={Styles.venueTradingTimes}>
            <TradingTimes trading_times={Venue?.trading_times} info={Venue} />
          </div>
          <ExternalLinks links={Venue?.custom_links} />
          <VenueSocials Venue={Venue} />
        </div>
      </div>
      <CardiffCastleButton Venue={Venue} />
    </div>
  );
}

function getMenuPageLink(props, venue) {
  if (props.area) {
    if (props.A) {
      return `/a/hayes-cardiff/ ${venue.venue_nickname}/menu`;
    }
    return `/g/${props.GroupName}/${venue.venue_nickname}/menu`;
  } else {
    return "/v/" + venue.venue_nickname + "/menu";
  }
}
