import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { _addCartItem } from "../redux/actions";
import { generateItemsForCart } from "../utils/cartController";
import { HTTPMethods } from "../yoello-lib/modules/API/API";
import APIExtensions from "../yoello-lib/modules/API/APIDefs";
import useAPI from "../yoello-lib/modules/API/useAPI";
import { useAuth0 } from "../yoello-lib/modules/auth/Auth0";
import { getPlaceOrderItem } from "../utils/ordersController";
import { addNotification } from "../yoello-lib/modules/redux/actions/consumer.actions";
import { CART } from "../constants/URL.const";

export function useGetLastOrder() {
  const { retrieveToken, isAuthenticated } = useAuth0()!;
  const Venue = useSelector((state: any) => state.Venue);
  const Preferences = useSelector((state: any) => state.Preferences);
  const rawItem = useSelector((state: any) => state.MenuItem.raw);
  const Extras = useSelector((state: any) => state.Extras);
  const ExtraMenuItem = useSelector((state: any) => state.MenuItemsAsExtra);
  const dispatch = useDispatch();

  const { callAPI: getOrders } = useAPI<IOrders>(
    APIExtensions.consumerLatestOrder,
    retrieveToken,
    { onMountDisabled: true, params: { venue_id: Venue.id } }
  );
  const [lastOrder, setLastOrder] = useState<IOrders | null>(null);
  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    const getLastVenueOrder = async () => {
      const orders = await getOrders(HTTPMethods.GET);
      if (orders) {
        if (orders.venue_id === Venue.id) {
          setLastOrder(orders);
        }
      }
    };
    getLastVenueOrder();
    //eslint-disable-next-line
  }, []);

  /** Uses the last order data to try and re-create last order if items are available */
  function addOrderToCart() {
    if (lastOrder) {
      const { placed_order_items } = lastOrder;
      let failCounter = 0;
      for (const placeItem of placed_order_items) {
        const orderItem = getPlaceOrderItem(
          placeItem,
          rawItem,
          Extras,
          Preferences,
          ExtraMenuItem
        );

        if (!orderItem.isDisabled) {
          const cartBody = generateItemsForCart(
            orderItem.extras,
            orderItem.preferences,
            orderItem.priceName,
            orderItem.price,
            orderItem.menuItem as IMenuItem,
            orderItem.quantity,
            ExtraMenuItem
          );
          dispatch(_addCartItem(cartBody));
        } else {
          failCounter++;
        }
      }

      if (failCounter > 0) {
        const total = placed_order_items.length;

        const sendMessage = (message, isCart = false) => {
          dispatch(
            addNotification({
              label: message,
              status: "error",
              pathname: `/v/${Venue.venue_nickname}/menu${isCart ? CART : ""}`,
            })
          );
        };
        if (failCounter === total) {
          setLastOrder(null);
          sendMessage("ShopCart.Errors.RepeatOrderNoItems");
          return false;
        }
        sendMessage("ShopCart.Errors.RepeatOrderMissingItems", true);
      }
      return true;
    }
  }
  return { lastOrder, addOrderToCart };
}
