import * as types from "../actions/ActionTypes"

const Group = (state = {}, action) => {
  switch (action.type) {
    case types.SetGroupInfo:

      state = action.data;
      return state;

    default:

      return state
  }
};
export default Group
