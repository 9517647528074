import React, { Component } from "react";
import { connect } from "react-redux";
import "./ShareWidget.css";
import T from "i18n-react";
import withSnackbarWrapper from "../../../yoello-lib/modules/components/snackbar/SnackbarWrapper";
import withModalWrapper from "../../Modal/withModalWrapper";
import Button from "../Button";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SocialMediaShare from "../SocialMediaShare/SocialMediaShare";
import { iOS } from "../../../constants";
import { IShareWidgetProps, IShareWidgetState } from "./types.d";

const customStyles = {
  content: {
    left: "50%",
    right: "auto",
    bottom: "5%",
    top: "unset",
    marginRight: "-50%",
    overflow: "visible",
    marginTop: "30px",
    marginBottom: "30px",
    transform: "translate(-50%, -50%)",
    width: "calc(100% - 15px)",
    height: "10%",
    borderRadius: "25px",
    zIndex: "999999999999999",
    // transition: '0.5s all ease-in',
    background: "white",
    border: "1px solid #DAEFFF",
    transition: "0.2s height ease-in",
    padding: "0",
    boxShadow: "0px 4px 50px rgba(0, 0, 0, 0.5)",
  },
};
const ShareItems = [
  {
    name: "SMS",
    icon: "consumer-message",
    link: iOS
      ? "sms:&body=%23I've%20just%20backed%20my%20local,%20will%20you%20back%20it%20too?%0A" +
        window.location
      : "sms:?body=%0AI've%20just%20got%20a%20round%20of%20drinks%20in%20to%20support%20my%20local%20during%20Covid%2019!%0A%0AWill%20you%20get%20the%20next%20round?%0A" +
        window.location,
  },
  {
    name: "Email",
    icon: "consumer-email",
    link:
      "mailto:?subject=Who's%20round%20is%20it?&body=I've%20just%20got%20a%20round%20of%20drinks%20in%20to%20support%20my%20local%20during%20Covid%2019!%20Will%20you%20get%20the%20next%20round?%0A%0AHospitality%20is%20one%20of%20the%20worst%20hit%20industries%20during%20this%20current%20pandemic.%20Many%20businesses%20are%20closed%20until%20the%20foreseeable%20future%20and%20when%20social%20distancing%20gradually%20gets%20lifted,%20hospitality%20will%20be%20one%20of%20the%20last%20industries%20to%20resume%20normal%20operations.%20%0A%0AThat's%20why%20it's%20important%20to%20support%20your%20local%20hospitality%20businesses%20now%20so%20that%20they%20are%20able%20to%20reopen%20when%20the%20time%20is%20right.%0A%0AWith%20%20you%20can%20pre-order%20items%20online%20from%20your%20favourite%20local%20to%20help%20the%20business%20keep%20afloat%20during%20these%20testing%20times.%20There%20are%20plenty%20of%20deals%20and%20discounts%20to%20take%20advantage%20of%20too.%0A%0AYou%20can%20redeem%20items%20from%20your%20order%20at%20any%20time%20at%20the%20venue%20when%20it%20reopens!%0A%0ASupport%20your%20local%20hospitality%20business%20here%3A%20" +
      window.location,
  },
  {
    name: "CopyLink",
    icon: "consumer-copy",
  },
  {
    name: "Socials",
    icon: "consumer-social",
  },
];
class ShareWidget extends Component<IShareWidgetProps, IShareWidgetState> {
  state = {
    openModal: false,
    open: false,
    stage: 1,
  };
  setCopiedTrue = () => {
    this.props.snackbarContext.sendSnackbarMessage(
      T.translate("SharingTexts.LinkCopied") as string,
      "success"
    );
  };
  onClickWidget = (item) => {
    if (item.name === "Socials") {
      this.props.modalContext.showModal(SocialMediaShare, {}, customStyles);
      if (this.props.CloseDrawer) {
        this.props.CloseDrawer();
        // try {
        //     // document.getElementById('PaymentsConfirmOrder').style.zIndex = 0;
        // } catch (error) {

        // }
      }
    }
  };
  // onRequestCloseModal = () => {
  //   this.setState({
  //     openModal: false,
  //   });
  //   setTimeout(() => {
  //     //@TODO - Poorya needs to look over this bit of code-> fails in venues
  //     try {
  //       document.getElementById("PaymentsConfirmOrder").style.zIndex = 1300;
  //     } catch (error) {}
  //   }, 500);
  // };
  // onCloseSnackbar = () =>
  //   this.setState({
  //     open: false,
  //   });
  render() {
    console.log("this.state.stage", this.state.stage);
    return (
      <div className="ShareWidget">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <span
            className="consumer-question-circle mr-1"
            style={{
              fontSize: "1.5rem",
            }}
          />
          <T.p
            className="EnjoyText"
            text={{ key: `SharingTexts.EnjoyShopping` }}
          />
        </div>

        {this.props.title ? (
          <T.p
            className="ShareText"
            text={{ key: `SharingTexts.${this.props.title}` }}
          />
        ) : (
          <T.p
            className="ShareText"
            text={{ key: `SharingTexts.WithFriends` }}
          />
        )}
        {this.state.stage === 1 && (
          <div className="Flex JustBetween mt-2">
            {ShareItems.map((item, i) => {
              if (item.link) {
                return (
                  <div className="ShareBoxDivs" key={i}>
                    {" "}
                    <a
                      key={"sharewidget" + i}
                      href={item.link}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <div className="ShareItemDiv">
                        {/* <img
                        src={require(`../../../imges/${item.icon}.svg`)}
                        width="20px"
                        height="20px"
                        alt=""
                      /> */}
                        <span
                          className={item.icon}
                          style={{
                            color: "initial",
                          }}
                        />
                      </div>

                      <T.p
                        className="ShareItemName"
                        text={{ key: `SharingTexts.${item.name}` }}
                      />
                    </a>
                  </div>
                );
              } else if (item.name === "CopyLink") {
                return (
                  <CopyToClipboard
                    key={"sharewidget" + i}
                    text={window.location.origin + window.location.pathname}
                    onCopy={this.setCopiedTrue}
                  >
                    <div className="ShareBoxDivs">
                      <div className="ShareItemDiv">
                        {/* <img
                        src={require(`../../../imges/${item.icon}.svg`)}
                        width="20px"
                        height="20px"
                        alt=""
                      /> */}
                        <span className={item.icon} />
                      </div>
                      <T.p
                        className="ShareItemName"
                        text={{ key: `SharingTexts.${item.name}` }}
                      />
                    </div>
                  </CopyToClipboard>
                );
              } else {
                return (
                  <div
                    key={"sharewidget" + i}
                    onClick={() => {
                      // this.props.shareOnVenueAndGroupPage
                      //   ? this.setState({ stage: 2 })
                      //   : this.onClickWidget(item);
                      this.setState({ stage: 2 });
                    }}
                    className="ShareBoxDivs"
                  >
                    <div className="ShareItemDiv">
                      {/* <img
                      src={require(`../../../imges/${item.icon}.svg`)}
                      width="20px"
                      height="20px"
                      alt=""
                    /> */}
                      <span className={item.icon} />
                    </div>
                    <T.p
                      className="ShareItemName"
                      text={{ key: `SharingTexts.${item.name}` }}
                    />
                  </div>
                );
              }
            })}
          </div>
        )}
        {this.state.stage === 2 && (
          <div
            className={`Flex AlignCenter JustBetween mt-2 SocialMediaWrapper 
           ${
             this.props.shareOnConfirmOrderPage
               ? "shareOnConfirmOrderPage"
               : null
           }
          `}
          >
            <Button
              isHeaderBtn
              iconClassName="consumer-left-arrow"
              onClick={() => this.setState({ stage: 1 })}
            />
            <SocialMediaShare />
          </div>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return state;
}

export default connect(
  mapStateToProps,
  {}
)(withModalWrapper(withSnackbarWrapper(ShareWidget)));
