export const SetCard = "SetCard";
export const ShowUp = "ShowUp";
export const RemoveItem = "RemoveItem";
export const ChangeItemCount = "ChangeItemCount";
export const ClearShoppingCard = "ClearShoppingCard";
export const ChangeTip = "ChangeTip";
export const SetVenueInfo = "SetVenueInfo";
export const ChangeExpiry = "ChangeExpiry";
export const ChangeRedirect = "ChangeRedirect";
export const ChangeCustomerName = "ChangeCustomerName";
export const SetUserInfo = "SetUserInfo";
export const SaveRedux = "SaveRedux";
export const ChangeLng = "ChangeLng";
export const SetGroupName = "SetGroupName";
export const SetGroupInfo = "SetGroupInfo";
export const ChangeTable = "ChangeTable";
export const SetPreferences = "SetPreferences";
export const SetExtras = "SetExtras";
export const SetMenuItemsAsExtra = "SetMenuItemsAsExtra";
export const setMenuItems = "setMenuItems";
export const SetDietaryTags = "SetDietaryTags";
export const resetMenuItems = "ResetMenuItems";
export const removeCartItem = "removeCartItem";
export const resetCart = "resetCart";
export const updateCartID = "updateCartID";
export const updateCartItem = "updateCartItem";
export const deleteCartItem = "deleteCartItem";
export const removeCartItems = "removeCartItems";
export const addCartItem = "addCartItem";
export const SetSelectedMenuType = "SetSelectedMenuType";
export const SetLanguageIso = "SetLanguageIso";
export const SetAppCurrency = "SetAppCurrency";
export const SetOrderType = "SetOrderType";
