import React from "react";
import { useHistory } from "react-router-dom";
import { ORDERS, QR_CODE } from "../../../../constants/URL.const";
import { AlertStatusCard } from "../../../../yoello-lib/modules/components/UI/Alerts/AlertStatusCard";
import {
  OrderCollectionActive,
  OrderCollectionComplete,
} from "./OrderCollection";
import Styles from "./orderCollection.module.scss";
import { ReactComponent as BlueLocation } from "../../../../imges/BlueLocation.svg";
import T from "i18n-react";

interface IOrderCollectionContainerProps {
  orderItem: IOrders;
}

const isDisabled = false;

export function OrderCollectionContainer({
  orderItem,
}: IOrderCollectionContainerProps) {
  const history = useHistory();

  function onClickQRCode() {
    history.push(`${ORDERS}/${orderItem.placed_order_id}${QR_CODE}`);
  }
  if (isDisabled) return null;

  function renderQR() {
    const props = {
      onClick: onClickQRCode,
      orderId: orderItem.placed_order_ref,
      collectionPointName: orderItem.collection_point_name,
    };
    switch (orderItem.placed_order_status) {
      case "READY_FOR_COLLECTION":
        return <OrderCollectionActive {...props} />;
      case "FINISHED":
        return <OrderCollectionComplete {...props} />;
      case "ACCEPTED":
      case "PLACED":
        return (
          <AlertStatusCard
            alertTitle={
              <b>{T.translate("OrderHistory.collectionPoint").toString()}</b>
            }
            alertMessage={T.translate("OrderHistory.collectionPointInfo")}
            alertProps={{
              color: "info",
              icon: <BlueLocation />,
            }}
          />
        );
      default:
        return null;
    }
  }

  return <div className={Styles.OrderCollectionParent}>{renderQR()}</div>;
}
