import { useEffect } from "react";
import { useSelector } from "react-redux";
import { HTTPMethods } from "../../../yoello-lib/modules/API/API";
import APIExtensions from "../../../yoello-lib/modules/API2/APIDefs";
import { useAPIV2 } from "../../../yoello-lib/modules/API2/useAPIv2";
import { setPaymentProviders } from "../reducer/PaymentActions";

/**Retrieves the current venues payment providers */
export function usePaymentProviders(dispatch: any) {
  const Venue = useSelector((state: any) => state.Venue);
  const { callAPI } = useAPIV2(APIExtensions.consumerVenues, {
    query: `${Venue?.id}/flags/payment_processors`,
    onMountDisabled: true,
  });

  useEffect(() => {
    callAPI(HTTPMethods.GET).then(({ isSuccessful, data }) => {
      if (isSuccessful) {
        if (Array.isArray(data)) {
          const paymentProviders = data.reduce((prevItem, nextItem) => {
            return {
              ...prevItem,
              [nextItem]: true,
            };
          }, {});
          dispatch(setPaymentProviders(paymentProviders));
        }
      }
    });
    //eslint-disable-next-line
  }, []);
}
