import React, {
  useRef,
  useEffect,
  RefObject,
  useState,
  ChangeEvent,
} from "react";
import { Input } from "antd";
import useDebounce from "../../customHooks/useDebounce";
import T from "i18n-react";

interface ISearchBoxProps {
  searchText: string;
  onTextChange: (value: string) => void;
  /**On mount focus search box, defaults to true */
  focusOnMount?: boolean;
}

const SearchBox = ({
  searchText,
  onTextChange,
  focusOnMount = true,
}: ISearchBoxProps) => {
  const searchInput = useRef() as RefObject<Input>;
  const [value, setValue] = useState(searchText);

  const { debouncedValue } = useDebounce(value, 100);

  useEffect(() => {
    if (focusOnMount === true) {
      // searchInput?.current?.focus();
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    onTextChange(debouncedValue);
    //eslint-disable-next-line
  }, [debouncedValue]);
  function onChange(event: ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
  }
  return (
    <div className="searchInput">
      <Input
        ref={searchInput}
        value={value}
        className="SearchInput"
        placeholder={T.translate("Forms.Search") as string}
        onChange={onChange}
      />
    </div>
  );
};

export default SearchBox;
