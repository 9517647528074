import React from "react";
import T from "i18n-react";
import { useHistory } from "react-router";
// import useIsStandardBrowser from "../../customHooks/useIsStandardBrowser";
import Styles from "./styles.module.scss";
import { Tabs } from "antd";
import { makeStyles, TextField } from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import Button from "../Widgets/Button";
import useLoginView from "./LoginView/useLoginView";
import { VerificationMethod } from "../../constants/verificationmethod";

const { TabPane } = Tabs;

const useStyles = makeStyles({
  title: {
    fontSize: "1.375rem",
    fontWeight: 600,
  },
  tabs: {
    width: "100%",
    marginTop: "1.375rem",
    position: "relative",

    "& .ant-tabs-nav": {
      display: "flex",
    },

    "& .ant-tabs-nav .ant-tabs-tab": {
      flexGrow: 1,
      marginRight: "0px",
      width: "100%",
      textAlign: "center",
    },

    "& .ant-tabs-nav-animated > div": {
      display: "flex",
      width: "100%",
    },

    "& .ant-tabs-bar": {
      borderBottom: "unset",
    },

    "& .ant-tabs-ink-bar": {
      borderTop: "2px solid #212121",
      backgroundColor: "#fff",
      height: "2px!important",
    },

    "& .ant-tabs-nav-container": {
      borderBottom: "1px solid #ededed",
    },
  },

  topMenuTabs: {
    height: "3.1rem",
    backgroundColor: "#fff",
    width: "100%",
    margin: "auto",
  },

  input: {
    border: "none",
    outline: "unset",
    width: "100%",
    marginBottom: "1rem",
    position: "relative",

    "& .MuiInput-root": {
      border: "1px solid #F9F9F9",
      backgroundColor: "#F9F9F9",
      transition: "0.25s ease-in-out",
      borderRadius: "6px",
      width: "100%",
      display: "block",
      boxSizing: "border-box",

      "&.Mui-error": {
        border: "1px solid #F44336",
      },

      "&.Mui-focused": {
        backgroundColor: "#FFFFFF",
        border: "1px solid #FFDD02",
      },

      "& input": {
        padding: "1.6rem",
        boxSizing: "border-box",
      },
    },
  },
});

const ManualSignIn = () => {
  const [connectionType, setConnectionType] = React.useState("email");

  const history = useHistory<{ title: string }>();
  const classes = useStyles();

  const {
    onInputChange,
    onSubmit,
    verificationMethod,
    isLoadingLogin,
    countryCode,
    updateInternationalCode,
    username,
    clearUsername,
  } = useLoginView();

  const handleTabChange = (tab: string) => {
    clearUsername();
    setConnectionType(tab);
  };

  const handlePhoneChange = (value: string, country: Record<string, any>) => {
    if (countryCode.toLowerCase() !== country.countryCode.toLowerCase()) {
      const event = {
        target: {
          value:
            country?.countryCode.toUpperCase() ?? countryCode.toUpperCase(),
        },
      } as React.ChangeEvent<HTMLSelectElement>;
      updateInternationalCode(event);
    }

    const numberWithOutAreaCode = value
      .replace(`+${country.dialCode}`, "")
      .replace(/ /g, "");
    const event = {
      currentTarget: { value: numberWithOutAreaCode },
    } as React.ChangeEvent<HTMLInputElement>;
    onInputChange(event);
  };

  const getTitle = React.useCallback(() => {
    const typeString = connectionType === "sms" ? "Mobile" : "Email";
    return `Login.Texts.enter${typeString}`;
  }, [connectionType]);

  return (
    <div className={Styles.Login}>
      <h2 className={classes.title}>
        {T.translate(
          history?.location?.state?.title ?? "Login.Texts.letsCompleteOrder"
        )}
      </h2>
      <div className={classes.tabs}>
        <Tabs
          className={classes.topMenuTabs}
          activeKey={connectionType}
          onChange={handleTabChange}
        >
          <TabPane
            tab={<span>{T.translate("Login.Texts.email")}</span>}
            key={"email"}
          />
          <TabPane
            tab={<span>{T.translate("Login.Texts.phone")}</span>}
            key={"sms"}
          />
        </Tabs>
      </div>
      <div className={Styles.SectionDivs} id="PhoneInputDiv">
        <p className={Styles.Text2}>
          {T.translate(getTitle())}
          <br />
          <strong>{T.translate("Login.Texts.CompleteProcess")}</strong>{" "}
        </p>
        <form onSubmit={onSubmit}>
          {connectionType === "sms" ? (
            <>
              <PhoneInput
                copyNumbersOnly
                enableLongNumbers
                country={countryCode.toLowerCase()}
                value={username}
                onChange={handlePhoneChange}
              />
              <T.p
                className={Styles.InputGuideText}
                text={{ key: "Login.Texts.phoneExampleText" }}
              />
            </>
          ) : (
            <TextField
              className={classes.input}
              placeholder={T.translate("Forms.EmailAddress") as string}
              onChange={onInputChange}
              InputProps={{ disableUnderline: true }}
              data-component-name="email-text"
            />
          )}
        </form>
      </div>
      <div className={Styles.SectionDivs}>
        <Button
          title="Continue"
          isYoelloBtn
          onClick={onSubmit}
          dataComponentName={"SendTextButton"}
          disabled={verificationMethod === VerificationMethod.INVALID}
          isLoading={isLoadingLogin}
        />
      </div>
    </div>
  );
};

export default ManualSignIn;
