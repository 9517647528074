import React from "react";
import { makeStyles } from "@material-ui/core";

interface IDetailCard {
  icon: JSX.Element;
  title: string;
  displayValue: string;
}

const useStyles = makeStyles({
  Wrapper: {
    display: "inline-block",
    padding: "0.5rem 1rem",
  },
  FlexWrapper: {
    display: "flex",
    alignItems: "center",
  },
  Icon: {
    boxSizing: "border-box",
    padding: "0 1.25rem 0 0",
    flex: "0 0 2rem",
    "& svg": {
      fill: "#C1CAE1",
    },
  },
  TextWrapper: {
    flex: "1 1 auto",
  },
  Title: {
    fontSize: "0.8rem",
    color: "#8D98B7",
  },
  DisplayValue: {
    fontSize: "1.1rem",
    color: "#212121",
  },
});

const DetailCard = ({ icon, title, displayValue }: IDetailCard) => {
  const classes = useStyles();
  return (
    <div className={classes.Wrapper}>
      <div className={classes.FlexWrapper}>
        <div className={classes.Icon}>{icon}</div>
        <div className={classes.TextWrapper}>
          <div className={classes.Title}>{title}</div>
          <div className={classes.DisplayValue}>{displayValue}</div>
        </div>
      </div>
    </div>
  );
};

export default DetailCard;
