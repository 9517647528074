import React, { useEffect, useState } from "react";
import Styles from "./styles.module.scss";
import DrawerHeader from "../DrawerHeader/DrawerHeader";
import T from "i18n-react";
import Button from "../Button";
import { useSelector } from "react-redux";
import { PostCodeValidator } from "../../../utils/PostCodeValidator";
import { checkPostCodeInDeliveryRadius } from "../../../utils/checkPostCodeInDeliveryRadius";
import PostCodeAlert from "./PostCodeAlert/PostCodeAlert";
import { AddressForm } from "../AddressForm/AddressForm";
import useDebounce from "../../../customHooks/useDebounce";

export enum postcodeCheckStatusType {
  InDeliveryRadius = "InDeliveryRadius",
  OutOfDeliveryRadius = "OutOfDeliveryRadius",
  PostCodeInValid = "PostCodeInValid",
}

interface IPostCodeCheckerProps {
  closeDrawer: () => void;
  goToMenuPage: () => void;
}

function PostCodeChecker({ closeDrawer, goToMenuPage }: IPostCodeCheckerProps) {
  const [userSavedAddress, setUserSavedAddress] = useState<DeliveryAddressType>(
    {
      full_name: "",
      address: "",
      city: "",
      postcode: "",
    }
  );
  const [postcodeCheckStatus, setPostcodeCheckStatus] = useState<
    postcodeCheckStatusType
  >(null);
  const [postcodeCheckButtonLoading, setPostcodeCheckButtonLoading] = useState<
    boolean
  >(false);
  const Venue = useSelector((state: any) => state.Venue);

  const handleDeliveryDetailsChange = (
    deliveryDetails: DeliveryAddressType
  ) => {
    setUserSavedAddress(deliveryDetails);
  };

  const { debouncedValue, isTyping } = useDebounce(
    userSavedAddress.postcode,
    350
  );

  useEffect(() => {
    if (debouncedValue.length > 0 && !isTyping) {
      validatePostCode();
    }
    //eslint-disable-next-line
  }, [debouncedValue, isTyping]);

  const validatePostCode = () => {
    const value = userSavedAddress.postcode;

    if (value.length > 2) {
      const postCodeValid = PostCodeValidator(value);
      if (postCodeValid) {
        setPostcodeCheckStatus(null);
        return true;
      }
    }
    setPostcodeCheckStatus(postcodeCheckStatusType.PostCodeInValid);
    return false;
  };

  async function checkPostCode() {
    // TO DO: move this logic into a reuseable component

    if (validatePostCode()) {
      setPostcodeCheckButtonLoading(true);
      const deliveryAddress = `${Venue.address_country}, ${userSavedAddress.address}, ${userSavedAddress.city}, ${userSavedAddress.postcode}`;
      try {
        await checkPostCodeInDeliveryRadius(
          Venue,
          deliveryAddress,
          Venue.delivery_radius_km,
          afterResponseFunction
        );
      } catch (error) {
        setPostcodeCheckButtonLoading(false);
      }
    }
  }

  function afterResponseFunction(InRadius) {
    if (InRadius) {
      setPostcodeCheckStatus(postcodeCheckStatusType.InDeliveryRadius);
      window.localStorage.setItem(
        "BillingAddress",
        JSON.stringify(userSavedAddress)
      );
      setTimeout(() => {
        closeDrawer();
        goToMenuPage();
      }, 1000);
    } else {
      setPostcodeCheckStatus(postcodeCheckStatusType.OutOfDeliveryRadius);
      setPostcodeCheckButtonLoading(false);
    }
  }

  return (
    <div
      data-component-name="PostCodeChecker"
      className={Styles.PostCodeChecker}
    >
      <DrawerHeader
        title={T.translate("Forms.EnterAddress") as string}
        closeDrawer={closeDrawer}
      />

      <div className={Styles.PostCodeCheckerContent}>
        <p className={Styles.Description}>
          {T.translate("Venue.AddressDescription")}
        </p>
        <AddressForm
          handleDeliveryInfoChange={handleDeliveryDetailsChange}
          setPostCodeChanged={() => null}
          noFullName={true}
          onPostcodeBlur={() => null}
        />
        <div
          style={{
            minHeight: "80px",
            marginBottom: "2.2rem",
          }}
        >
          <PostCodeAlert postcodeCheckStatus={postcodeCheckStatus} />
        </div>
        <div>
          <Button
            isYoelloBtn
            title="CheckPostCode"
            onClick={checkPostCode}
            isLoading={postcodeCheckButtonLoading}
            dataComponentName="PostcodeCheckBtn"
          />
        </div>
      </div>
    </div>
  );
}

export default PostCodeChecker;
