import _ from "lodash";
import { useEffect } from "react";
import { useFormState, useWatch } from "react-hook-form";
import useDebounce from "../hooks/useDebounce";
import { typeTransform } from "./utils";

/**Lists of hardcoded fields that we ignore */
const IGNORED_FIELDS = ["is_extra"];


/**Hook for estiblishing of a forms data is dirty */
export function useDirtyForm(defaultValues: any, control: any, hasDataChanged: any) {

    const watchedValues = useWatch({
        control,
        disabled: hasDataChanged ? false : true
    });

    const { isDirty, dirtyFields } = useFormState({
        control
    });
    /**Use debouce to try and stop spamming the useEffect */
    const { debouncedValue } = useDebounce(watchedValues, 200, true)

    useEffect(() => {
        if (!hasDataChanged) return;
        function compareDirtyFields() {
            let _isDirty = false;
            for (let dFKey in dirtyFields) {
                if (IGNORED_FIELDS.indexOf(dFKey as string) > -1) {
                    continue;
                }
                if (_isDirty) {
                    break;
                }
                let value = watchedValues[dFKey];
                const formValue = defaultValues ? defaultValues[dFKey] : undefined;

                const newValue = typeTransform(value, formValue);
                console.log(newValue, formValue);
                /**Compare the two entities */
                if (!_.isEqual(newValue, formValue)) {
                    _isDirty = true;
                }
            }
            return _isDirty;
        }
        let dirty = isDirty;
        if (isDirty || (typeof dirtyFields === "object" && Object.keys(dirtyFields).length > 0)) {
            dirty = compareDirtyFields();
        }
        hasDataChanged(dirty);
        // eslint-disable-next-line
    }, [debouncedValue, isDirty, dirtyFields]);
}