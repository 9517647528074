export default function findItemInList(id: string, list: string[]): boolean {
  if (list.length === 0) {
    return false;
  }
  if (list.indexOf(id) > -1) {
    return true;
  }

  return false;
}
