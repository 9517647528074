import React, {
  createContext,
  useState,
  useContext,
  ComponentType,
} from "react";
import ModalParent from "./ModalParent";
import { IModelState } from "./types.d";
interface IModelContext {
  showModal: (Component: ComponentType, props: any) => void;
  hideModal: () => void;
}

export const ModalContext = createContext<IModelContext | undefined>(undefined);

export const useModal = () => useContext(ModalContext);

function ModalProvider(props: any) {
  const [modalState, setModalState] = useState<IModelState>({
    isOpen: false,
    Component: null,
    props: {},
  });
  const showModal = (Component: ComponentType, props = {}) => {
    setModalState({ isOpen: true, Component, props });
  };
  const hideModal = () => {
    setModalState({ isOpen: false, Component: null, props: {} });
  };
  return (
    <ModalContext.Provider value={{ showModal, hideModal }}>
      <ModalParent {...modalState} hideModal={hideModal} />
      {props.children}
    </ModalContext.Provider>
  );
}

export default ModalProvider;
