import { useSelector } from "react-redux";
import { abvCartCheck } from "../utils/abvController";

export function useABVCheck() {
  const Venue = useSelector((state: any) => state.Venue);
  const cartItems = useSelector((state: any) => state.Cart);
  const MenuItems = useSelector((state: any) => state.MenuItem.apiData);

  function abvCheck() {
    return abvCartCheck(cartItems, MenuItems, Venue?.alc_drink_limit);
  }

  return {
    abvCheck,
  };
}
