import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isObject } from "../../../utils/ObjectCheck";
import { setSelectedMenuType } from "../../../yoello-lib/modules/redux/actions/consumer.actions";

export function useBack() {
  const Venue = useSelector((state: any) => state.Venue);
  const groupName = useSelector((state: any) => state.GroupName);
  const dispatch = useDispatch();
  const history = useHistory();

  function goBack() {
    dispatch(setSelectedMenuType(null));
    history.push(generateURL());
  }
  function generateURL() {
    return getBackURL(Venue, groupName);
  }
  return {
    goBack,
    generateURL,
  };
}

function getBackURL(venue, groupName) {
  if (!isObject(venue, true)) {
    const pathNameSplit = window.location.pathname.split("/menu")[0];
    return pathNameSplit;
  }
  const windowSplit = window.location.pathname.split("/");
  if (windowSplit[1] === "g") {
    return `/g/${groupName}/${venue.venue_nickname}`;
  } else if (windowSplit[1] === "v") {
    /**IF you navigate to a new venues menu without going to the menu page, navigate back to the venue page */
    if (windowSplit[2] !== venue.venue_nickname) {
      return "/v/" + windowSplit[2];
    }
    return "/v/" + venue.venue_nickname;
  } else {
    return "/a/hayes-cardiff/" + venue.venue_nickname;
  }
}
