import { useSelector } from "react-redux";
import { HTTPMethods } from "../yoello-lib/modules/API/API";
import APIExtensions from "../yoello-lib/modules/API/APIDefs";
import useAPI from "../yoello-lib/modules/API/useAPI";
import { useAuth0 } from "../yoello-lib/modules/auth/Auth0";
import { getCurrentEpochTime } from "../yoello-lib/modules/auth/epochTime";
import usePaymentView from "../Components/Payment/PaymentHooks/usePaymentView";
import { useCartAvailability } from "./useCartAvailablity";

export function useCheckCartItems() {
  const { retrieveToken } = useAuth0()!;
  const { backFunction } = usePaymentView();
  const orderType = useSelector((state: any) => state.SelectedMenuType);
  const Venue = useSelector((state: any) => state.Venue);
  const MenuExpireTime = useSelector((state: any) => state.MenuExpireTime);
  const { checkAvailablity } = useCartAvailability();

  const { callAPI: getMenuItems } = useAPI<any>(
    APIExtensions.consumerMenu,
    retrieveToken,
    { onMountDisabled: true }
  );
  const checkCart = async () => {
    const { isSuccess, disabledItems } = await checkCartAvailability();
    if (!isSuccess) {
      backFunction(disabledItems);
    }
    return isSuccess;
  };
  async function checkCartAvailability() {
    const disabledItems = [];
    if (getCurrentEpochTime() >= MenuExpireTime) {
      const menuBody = await getMenuItems(HTTPMethods.GET, {
        query: Venue.venue_nickname,
        params: { order_type: orderType },
      });
      return checkAvailablity(menuBody);
    }
    return {
      isSuccess: true,
      disabledItems,
    };
  }
  return {
    checkCartAvailability,
    checkCart,
  };
}
