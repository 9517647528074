import { Dispatch } from "redux";
import { APIAdminDashboard, AdminVenueAPIDefs } from "./apiDefs/AdminVenueDefs";
import { APIConsumer, consumerAPIDefs } from "./apiDefs/ConsumerAPIDefs";
import { APIEPos, EPosAPIdefs } from "./apiDefs/EPosNowAPIDefs";
import { APIMerchantDashboard, merchantDashboardAPIDefs } from "./apiDefs/MerchantDashboardAPIDefs";

/** Supported API endpoints */
export const APIExtensions = {
    ...APIMerchantDashboard,
    ...APIAdminDashboard,
    ...APIConsumer,
    ...APIEPos

};
/**creates a type for API extentions */
//eslint-disable-next-line
export type APIExtensions = APIAdminDashboard | APIMerchantDashboard | APIEPos | APIConsumer;
/** Object containing API definitions */
export interface IAPIDefinitions {
    [key: string]: IAPIDefinition;
}

export interface IAPIDefinition {
    /**URL of the API */
    baseURL?: string;
    /** Default api endpoint for this method */
    defaultURL: string,
    /** Default api redux key */
    dataStorage?: IDataStore,
    /** Overrides for GET requests. Property required to enable GET requests. */
    GET?: methodDetail,
    /** Overrides for POST requests. Property required to enable POST requests. */
    POST?: methodDetail,
    /** Overrides for PUT requests. Property required to enable PUT requests. */
    PUT?: methodDetail,
    /** Overrides for DELETE requests. Property required to enable DELETE requests. */
    DELETE?: methodDetail;
}

interface IDataStore {
    /** Default api redux key */
    defaultDataKey: string;
    /**Dispatch function for redux */
    dispatchFunction?: <T>(dispatch: Dispatch<any>, dataToSave: T, args?: string[]) => void;
    /**Selector key function returns string as dot notion ie merchants.merchantList
     * can also send through object with key for more advanced redux retrieving*/
    selectorKey: (keys?: { [key: string]: any; }) => string;
    /**Allows you to manipulate the data before you set it */
    setData?: <T>(result: T) => T;
}

type methodDetail =
    {
        /** API url endpoint extension */
        url?: string;
        /** Optional key for data being stored in redux */
        dataKey?: string;
    };

/**Exports the combined defintions */
export const APIDefinitions: IAPIDefinitions = {
    ...AdminVenueAPIDefs,
    ...merchantDashboardAPIDefs,
    ...consumerAPIDefs,
    ...EPosAPIdefs
};

export default APIExtensions;