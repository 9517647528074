import { getServiceEndpoints } from "../../environments/services";

function getFullURL() {
    const stage = process.env.REACT_APP_STAGE;
    const overwriteHost = process.env.REACT_APP_SERVICE_ENDPOINT;
    if (!stage) {
        return;
    }
    let services = getServiceEndpoints(stage, overwriteHost);
    return `${services['consumerAPI']}/`;
}

const url = getFullURL();

export const consumerAPIDefs = {
    consumerVenues: {
        baseURL: url,
        defaultURL: 'venues',
        GET: {}
    }
}


export enum APIConsumer {
    consumerVenues = "consumerVenues"
}