import React from "react";
import { PaymentMethods } from "../../../types.d";
import "./apple-pay.css";
import "./google-pay.css";

interface IDigitalWalletComponent {
  method: PaymentMethods;
}
const DigitalWalletComponent = ({ method }: IDigitalWalletComponent) => {
  const disable =
    (method && method === PaymentMethods.BANK_TRANSFER) ||
    (method && method === PaymentMethods.CARD);

  return (
    <div>
      <button
        className="sq-apple-pay-button sq-apple-pay-button-black"
        id="sq-apple-pay"
        disabled={disable}
      ></button>
      <button
        id="sq-google-pay"
        className="button-google-pay"
        disabled={disable}
      ></button>
    </div>
  );
};

export default DigitalWalletComponent;
