import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SquareCardDrawer from "./SquareCardDrawer";
import SquarePaymentComponent from "./PaymentComponent";
import PaymentProcessing from "./PaymentProcessing";

const SquarePaymentContainer = ({ paymentForm, config }) => {
  const venue = useSelector((state: any) => state.Venue);
  const locationId = venue.integration_object_id;
  const [square] = useState(
    new paymentForm({
      ...config,
      locationId,
      callbacks: {
        ...config.callbacks,
        cardNonceResponseReceived: () => {},
      },
    })
  );
  const processPayment = (nonce, details, callback) => {
    square.verifyBuyer(nonce, details, callback);
  };

  useEffect(() => {
    square.build();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <SquarePaymentComponent processPayment={processPayment} />
      <SquareCardDrawer square={square} />
      <PaymentProcessing />
    </>
  );
};

export default SquarePaymentContainer;
