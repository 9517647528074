import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import T from "i18n-react";

import Button from "../../../../Widgets/Button";
import Drawer from "../../../../Drawer";

interface ICardValidation {
  isCardValid: boolean;
  isExpiryValid: boolean;
  isCvvValid: boolean;
  isNameValid: boolean;
}

interface ICardDetailsDrawer {
  classes: any;
  isCardDetailsDrawerOpen: boolean;
  billingDetailsOpen: boolean;
  confirmDetailsOpen: boolean;
  hasLoaded: boolean;
  cardNumber: string;
  expiry: string;
  fullName: string;
  cvv: string;
  closeDrawer: () => void;
  openBillingDrawer: () => void;
  handleInputChange: ({ target }) => void;
  checkForCardErrors: () => ICardValidation;
}

const defaultValidationState = {
  isCvvValid: true,
  isCardValid: true,
  isExpiryValid: true,
  isNameValid: true,
};

const CardDetailsDrawer = ({
  isCardDetailsDrawerOpen,
  billingDetailsOpen,
  confirmDetailsOpen,
  hasLoaded,
  cardNumber,
  classes,
  expiry,
  fullName,
  cvv,
  closeDrawer,
  openBillingDrawer,
  handleInputChange,
  checkForCardErrors,
}: ICardDetailsDrawer) => {
  const [cardValidation, setCardValidation] = useState<ICardValidation>(
    defaultValidationState
  );

  const onButtonClick = () => {
    const validation = checkForCardErrors();
    setCardValidation(validation);
    if (
      !validation.isCardValid ||
      !validation.isCvvValid ||
      !validation.isExpiryValid ||
      !validation.isNameValid
    ) {
      return;
    } else {
      openBillingDrawer();
    }
  };

  return (
    <Drawer
      isPersistent
      title="Card Details"
      instructions={["Payment.EnterYour", "Forms.CardDetails"]}
      isOpen={
        isCardDetailsDrawerOpen && !billingDetailsOpen && !confirmDetailsOpen
      }
      closeDrawer={closeDrawer}
    >
      {isCardDetailsDrawerOpen && hasLoaded && (
        <>
          <div className={classes.FormWrapper}>
            <div className={classes.InputsWrapper}>
              <TextField
                error={!cardValidation.isCardValid}
                helperText={
                  cardValidation.isCardValid
                    ? ""
                    : T.translate("Forms.ValidationErrors.CardNumber")
                }
                placeholder={T.translate("Forms.CardNumber").toString()}
                name="number"
                className={classes.Input}
                value={cardNumber}
                onChange={handleInputChange}
                onBlur={() =>
                  setCardValidation({ ...cardValidation, isCardValid: true })
                }
                InputProps={{ disableUnderline: true }}
              />
              <TextField
                placeholder={T.translate("Forms.FullName").toString()}
                error={!cardValidation.isNameValid}
                helperText={
                  cardValidation.isNameValid
                    ? ""
                    : T.translate("Forms.ValidationErrors.Name")
                }
                className={classes.Input}
                name="name"
                value={fullName}
                onChange={handleInputChange}
                onBlur={() =>
                  setCardValidation({ ...cardValidation, isNameValid: true })
                }
                InputProps={{ disableUnderline: true }}
              />
              <TextField
                error={!cardValidation.isExpiryValid}
                helperText={
                  cardValidation.isExpiryValid
                    ? ""
                    : T.translate("Forms.ValidationErrors.Expiry")
                }
                placeholder={"MM/YY"}
                className={classes.Input}
                name="expiry"
                value={expiry}
                onChange={handleInputChange}
                onBlur={() =>
                  setCardValidation({ ...cardValidation, isExpiryValid: true })
                }
                InputProps={{ disableUnderline: true }}
              />

              <TextField
                error={!cardValidation.isCvvValid}
                helperText={
                  cardValidation.isCvvValid
                    ? ""
                    : T.translate("Forms.ValidationErrors.CVV")
                }
                placeholder={T.translate("Forms.Cvc").toString()}
                className={classes.Input}
                name="cvc"
                value={cvv}
                onChange={handleInputChange}
                onBlur={() =>
                  setCardValidation({ ...cardValidation, isCvvValid: true })
                }
                InputProps={{ disableUnderline: true }}
              />
              <Button
                isYoelloBtn
                id="go-to-billing-details"
                title={"Continue"}
                onClick={onButtonClick}
              />
            </div>
          </div>
        </>
      )}
    </Drawer>
  );
};

export default CardDetailsDrawer;
