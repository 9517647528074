import React from "react";
import { Divider } from "antd";
import ContentLoader from "react-content-loader";
import Styles from "./menuItemsPageSkeleton.module.scss";

interface IProps {
  hideHeader?: boolean;
}
const MenuItemsSkeleton = ({ hideHeader }: IProps) => {
  return (
    <div
      className={Styles.menuItemsPageContainer}
      data-component-name="menu-loading"
    >
      {!hideHeader && (
        <div className={Styles.header}>
          <div className={Styles.headerBtn}>
            <ContentLoader>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
          <div className={Styles.headerImg}>
            <ContentLoader>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
          <div className={Styles.headerBtn}>
            <ContentLoader>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
        </div>
      )}
      <div className={Styles.topMenuContainer}>
        <div className={Styles.topMenu}>
          <div className={Styles.mainCats}>
            <div>
              <ContentLoader>
                <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
              </ContentLoader>
            </div>
            <div>
              <ContentLoader>
                <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
              </ContentLoader>
            </div>
            <div>
              <ContentLoader>
                <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
              </ContentLoader>
            </div>
          </div>
          <Divider />
          <div className={Styles.subCats}>
            <div>
              <ContentLoader>
                <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
              </ContentLoader>
            </div>
            <div>
              <ContentLoader>
                <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
              </ContentLoader>
            </div>
            <div>
              <ContentLoader>
                <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
              </ContentLoader>
            </div>
            <div>
              <ContentLoader>
                <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
              </ContentLoader>
            </div>
            <div>
              <ContentLoader>
                <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
              </ContentLoader>
            </div>
          </div>
        </div>
        <div className={Styles.menuContents}>
          <div>
            <ContentLoader>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
          <div>
            <ContentLoader>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
          <div>
            <ContentLoader>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
          <div>
            <ContentLoader>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
        </div>
      </div>
      {/* <div className={Styles.myCart}>
        <ContentLoader backgroundColor={"#2a2a2f"}>
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
        </ContentLoader>
      </div> */}
    </div>
  );
};

export default MenuItemsSkeleton;
