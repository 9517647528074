import React, { useContext } from "react";
import Styles from "../styles.module.scss";
import { PaymentContext } from "../PaymentStore";
import Loading from "../../Widgets/ButtonsLoading";
import Button from "../../Widgets/Button";
import { ReactComponent as CloseIcon } from "../../../imges/close-icon.svg";
import T from "i18n-react";
export function OnApplyComponent(props) {
  const { discounts } = useContext(PaymentContext);
  function renderButton() {
    if (!discounts.error) {
      return (
        <Button
          onClick={props.onClick}
          title={T.translate("Apply") as string}
          className={Styles.applyDiscount}
          dataComponentName="ApplyDiscountBtn"
          iconClassName={"consumer-add"}
        />
      );
    } else {
      return (
        <Button
          className={Styles.appliedDiscount}
          onClick={props.reset}
          Icon={<CloseIcon />}
          dataComponentName="ResetDiscountBtn"
        />
      );
    }
  }
  return <>{props.isloading ? <Loading /> : renderButton()}</>;
}
