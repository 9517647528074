import React, { useState, useEffect } from "react";
// import PageTitle from '../PageTitle';
import { useSelector } from "react-redux";
import ChoiceBox from "../ChoiceBox/ChoiceBox";
import Styles from "./styles.module.scss";
interface IPreferencesProps {
  menuItemPreferences: string[];
  handlePreferencesChange: (preferences: Record<string, string>) => void;
  preferenceState?: Record<string, string>;
}

function Preferences({
  menuItemPreferences,
  handlePreferencesChange,
  preferenceState,
}: IPreferencesProps) {
  const [preferences, setPreferences] = useState<Record<string, string>>(
    preferenceState
  );
  const preferenceStore = useSelector((state: any) => state.Preferences);

  useEffect(() => {
    if (preferences !== undefined) {
      handlePreferencesChange(preferences);
    }
    //eslint-disable-next-line
  }, [preferences]);

  function handlePreferenceChange(
    preferenceKey: string,
    preferenceName: string,
    preferenceInternalName: string
  ) {
    setPreferences((prevState) => {
      if (prevState[preferenceKey] === preferenceName) {
        const preferences = { ...prevState };
        delete preferences[preferenceKey];
        return preferences;
      } else
        return {
          ...prevState,
          [preferenceKey]: preferenceName,
        };
    });
  }
  return (
    <>
      {/* <PageTitle title={T.translate("Menu.Preferences") ?.toString()} /> */}
      <div
        style={{
          marginTop: "1.5625rem",
          padding: "0 1.5rem",
        }}
      >
        {preferenceStore !== undefined &&
          menuItemPreferences !== undefined &&
          menuItemPreferences.map((prefKey: string, i) => {
            const prefGroup = preferenceStore[prefKey];
            return (
              <div>
                <p
                  className={Styles.PreferenceGroupLabel}
                  id={prefGroup?.menu_item_preference_id}
                >
                  {prefGroup?.menu_item_preference_name_public}?
                </p>
                {prefGroup?.menu_item_preference_preferences?.map(
                  (pref: string) => {
                    return (
                      <ChoiceBox
                        key={pref}
                        onClick={() =>
                          handlePreferenceChange(
                            prefKey,
                            pref,
                            prefGroup?.menu_item_preference_id
                          )
                        }
                        choiceBoxName={pref}
                        isSelected={preferences[prefKey] === pref}
                      />
                    );
                  }
                )}
              </div>
            );
          })}
      </div>
    </>
  );
}

export default React.memo(Preferences);
