import React, { useState, useEffect } from "react";
import Pay360CardDrawer from "./CardDetails";
import Pay360PaymentComponent from "./PaymentProcessor";
import OrderProcessing from "./OrderProcessing";
import { usePaymentState } from "../../PaymentStore";

const Pay360PaymentProcessor = () => {
  const { paymentDetails } = usePaymentState();
  const [token, setToken] = useState();
  const [requireCVV, setRequireCVV] = useState(true);

  useEffect(() => {
    if (paymentDetails) {
      setToken(paymentDetails.card_token);
      setRequireCVV(!!paymentDetails.id);
    }
  }, [paymentDetails]);

  return (
    <>
      <Pay360CardDrawer setToken={setToken} token={token} />
      <Pay360PaymentComponent requireCVV={requireCVV} />
      <OrderProcessing />
    </>
  );
};

export default Pay360PaymentProcessor;
