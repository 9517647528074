import React from "react";
import T from "i18n-react";
// import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Lottie from "react-lottie";
import Styles from "./withoutUrl.module.scss";
import Button from "../Widgets/Button";
// import SideMenu from "../SideMenu/SideMenu";
import PageComponent from "../Page/PageComponent";
// import SettingsPage from "../SettingsPage/SettingsPage";
import { ReactComponent as HamburgerMenuSVG } from "../../imges/HamburgerMenu.svg";
import { ReactComponent as YoelloIconSVG } from "../../imges/yoelloIcon_new.svg";
import { ReactComponent as YoelloTextSVG } from "../../imges/yoelloText.svg";
// import { ReactComponent as QRCodeSVG } from "../../imges/QRCode.svg";
import animationData from "../../lottie/data";
import { useNavigateLoggedUser } from "../../customHooks/useNavigateLoggedUser";
// const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const WithoutUrl = (props) => {
  useNavigateLoggedUser();
  return (
    <div
      data-component-name="WithoutUrl"
      className={Styles.notFoundPageContainer}
    >
      <header>
        <Button
          isHeaderBtn
          className={Styles.sidemenuToggleBtn}
          dataComponentName={"HamburgerMenu"}
          Icon={<HamburgerMenuSVG />}
          onClick={props.openSideDrawer}
        />
      </header>
      <div className={Styles.wrapper}>
        <div className={Styles.yoelloIcon}>
          <YoelloIconSVG />
        </div>

        <div className={Styles.descriptions}>
          <T.p text={{ key: "MainEmptyPage.WelcometoYoello" }} />
          <T.p text={{ key: "MainEmptyPage.NeedMarchUrl" }} />
        </div>
        <div id="ReScanQRCode" className={Styles.qrCode}>
          <Lottie
            options={defaultOptions}
            height={"10.625rem"}
            width={"10.625rem"}
            isStopped={false}
            isPaused={false}
            isClickToPauseDisabled={true}
            speed={2}
          />
        </div>
        <div className={Styles.scanQrCode}>
          <T.p text={{ key: "MainEmptyPage.ScanQrCode" }} />
        </div>
        <div className={Styles.yoelloText}>
          <YoelloTextSVG />
        </div>
        <div className={Styles.bottomText}>
          <p>{T.translate("Misc.scanOrderPay")}</p>
        </div>
      </div>
    </div>
  );
};

export default PageComponent(WithoutUrl);
