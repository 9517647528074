import React, { ComponentType, useEffect, useState } from "react";
import PageComponent from "./PageComponent";
import { useSelector } from "react-redux";
import { IPageComponentProps } from "./types.d";
import useAPI, { IMessageOptions } from "../../yoello-lib/modules/API/useAPI";
import { APIExtensions } from "../../yoello-lib/modules/API/APIDefs";
import { HTTPMethods } from "../../yoello-lib/modules/API/API";
import moment from "moment";
interface IPageContainerProps extends IPageComponentProps {
  ChildComponent: ComponentType<any>;
  LoadingComponent: ComponentType<any>;
  apiConfig: IMessageOptions;
  errorFunction: Function;
  APIExtension: APIExtensions;
}

function PageContainer({
  ChildComponent,
  retrieveToken,
  apiConfig,
  LoadingComponent,
  errorFunction,
  APIExtension,
  ...props
}: IPageContainerProps) {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const Venue = useSelector((state: any) => state.Venue);
  const SelectedMenuType = useSelector((state: any) => state.SelectedMenuType);
  const { isError, callAPI } = useAPI(APIExtension, retrieveToken, {
    ...apiConfig,
    canAPIGetCacheValue: false,
    doNotCacheValue: true,
    onMountDisabled: true,
  });
  const { callAPI: AvailableMenusAPI } = useAPI(
    APIExtensions.consumerAvailableMenus,
    () => null,
    {
      query: Venue.id,
      onMountDisabled: true,
    }
  );

  useEffect(() => {
    if (APIExtension) {
      setIsPageLoading(true);
      callAPI(HTTPMethods.GET, {
        ...apiConfig,
      }).then((res) => {
        if (APIExtension === APIExtensions.consumerMenu) {
          // @ts-ignore
          if (res?.venue_details?.on_menu_setup) {
            AvailableMenusAPI(HTTPMethods.GET, {
              query: Venue.id,
              params: {
                order_type: SelectedMenuType,
                datetime: `${moment.utc().format()}`,
              },
            });
          }
        }
        setIsPageLoading(false);
      });
    }
    // eslint-disable-next-line
  }, [APIExtension]);
  useEffect(() => {
    if (isError === true && typeof errorFunction === "function") {
      errorFunction();
    }
    //eslint-disable-next-line
  }, [isError]);

  return isPageLoading && LoadingComponent ? (
    <LoadingComponent />
  ) : (
    <ChildComponent {...props} />
  );
}

export default PageComponent(PageContainer);
