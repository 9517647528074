import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

export function useNavigateBack() {
  const history = useHistory();
  const Venue = useSelector((state: any) => state.Venue);
  const { id } = useParams<{ id: string }>();
  const SelectedMenuType = useSelector((state: any) => state.SelectedMenuType);

  useEffect(() => {
    if (
      id !== Venue.venue_nickname ||
      (!SelectedMenuType && history.location.pathname.split("/")[1] !== "g")
    ) {
      history.push("/v/" + id);
    }
    //eslint-disable-next-line
  }, []);
}
