import React, { memo } from "react";
import { makeStyles } from "@material-ui/core";
import Styles from "./productsList.module.scss";
import { areEqual, FixedSizeList as List } from "react-window";
import ProductCard from "../Widgets/ProductCard";
import { useMenu } from "../Menu/MenuContext";
import memoize from "memoize-one";

interface IProductListProps {
  /**any because of the funky typing with the cell */
  menuItemList: IMenuItem[] | any;
  listView: boolean;
  windowHeight?: number;
  padding: number;
  position: number;
}

const useStyles = makeStyles({
  root: {
    background: "white",
    transition: "height 0.15s ease-out",
    "-webkit-transition": "height 0.15s ease-out",
    "& > div": {
      paddingBottom: (padding: number) => padding,
    },
  },
});

const ITEM_SIZE = 100;

const ProductList = ({
  menuItemList,
  padding,
  position,
}: IProductListProps) => {
  const classes = useStyles(padding);
  const {
    handleItemSelect,
    state: { listHeight },
    formatCurrency,
  } = useMenu();

  const itemData = createListData(
    menuItemList,
    handleItemSelect,
    formatCurrency
  );

  return (
    <div className={Styles.itemsListContainer} id="itemsListContainerId">
      <List
        height={listHeight}
        itemCount={menuItemList.length + 1}
        itemSize={ITEM_SIZE}
        itemData={itemData}
        width={"100%"}
        style={{ paddingBottom: padding && padding }}
        className={`menueItemsList ${classes.root}`}
      >
        {Row}
      </List>
    </div>
  );
};

const createListData = memoize(
  (menuItemList, handleItemSelect, formatCurrency) => ({
    menuItemList,
    handleItemSelect,
    formatCurrency,
  })
);

const Row = memo(({ index, style, data }: any) => {
  const { menuItemList, handleItemSelect, formatCurrency } = data;
  const menuItem = menuItemList[index];

  return (
    <div style={style} key={menuItem?.menu_item_id}>
      {menuItem !== undefined ? (
        <ProductCard
          productInfo={menuItem}
          onClick={() => handleItemSelect(menuItem)}
          formatCurrency={formatCurrency}
        />
      ) : null}
    </div>
  );
}, areEqual);

export default ProductList;
