import React from 'react';

export interface IDisabledActionWrapper {
    children: React.ReactNode;
    disabled?: boolean;
    onDisabledClick?: Function;
}

const DisabledActionWrapper = ({children, disabled, onDisabledClick} : IDisabledActionWrapper) => {
    const onClick = (event: React.MouseEvent<HTMLElement>) => {
        if(disabled) {
            event.stopPropagation();
            onDisabledClick?.();
        }
    }

    return (
        <div onClick={onClick}> 
            {children}
        </div>
    )
}

export default DisabledActionWrapper;

