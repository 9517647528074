import React from "react";
import { convertToUTCTime } from "../../../yoello-lib/modules/utils/TimeConversion";
import { OrderCollectionContainer } from "./OrderCollection/OrderCollectionContainer";
import { OrderTitle } from "./OrderTitle";

interface IOrderTypesDetailsProps {
  orderItem: IOrders;
}

export function OrderTypesDetails({ orderItem }: IOrderTypesDetailsProps) {
  const { placed_order_order_type } = orderItem;
  switch (placed_order_order_type) {
    case "TABLE_DELIVERY":
      return <TableDelivery orderItem={orderItem} />;
    case "HOME_DELIVERY":
      return <HomeDelivery orderItem={orderItem} />;
    case "COLLECTION_POINT_PICKUP":
      return <OrderCollectionContainer orderItem={orderItem} />;
    case "COLLECTION":
      return <Collection orderItem={orderItem} />;
  }
  return null;
}

function TableDelivery({ orderItem }) {
  const { placed_order_delivery_table, placed_order_table_area } = orderItem;

  return (
    <OrderTitle
      titleKey="OrderHistory.VenueArea"
      valueText={`${placed_order_table_area}, ${placed_order_delivery_table}`}
    />
  );
}

function HomeDelivery({ orderItem }) {
  const {
    placed_order_delivery_address,
    placed_order_takeaway_timeslot,
  } = orderItem;

  const time = convertToUTCTime(placed_order_takeaway_timeslot, "HH:mma");
  const deliveryAddress = `${placed_order_delivery_address.address}, 
      ${placed_order_delivery_address.city},
      ${placed_order_delivery_address.postcode}`;

  return (
    <>
      <OrderTitle titleKey="OrderHistory.DeliveryTime" valueText={time} />
      <OrderTitle
        titleKey="Forms.DeliveryAddress"
        valueText={deliveryAddress}
      />
    </>
  );
}

function Collection({ orderItem }) {
  const { placed_order_takeaway_timeslot } = orderItem;
  const time = convertToUTCTime(placed_order_takeaway_timeslot, "HH:mma");

  return <OrderTitle titleKey="OrderHistory.CollectionTime" valueText={time} />;
}
