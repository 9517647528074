import React, { ReactChild, ReactFragment, ReactPortal } from "react";
import TT from "@material-ui/core/Tooltip";
import Fade from "@material-ui/core/Fade";
import { withStyles } from "@material-ui/core/styles";

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: "0.8rem",
    padding: "0.5rem 1rem",
    backgroundColor: "#2D3A59",
    opacity: "0.85 !important",
    borderRadius: "10px",
  },
})(TT);

interface Props {
  children: React.ReactElement;
  title: Element | string | ReactChild | ReactFragment | ReactPortal;
  placement?:
    | "top-start"
    | "top"
    | "top-end"
    | "left-start"
    | "left"
    | "left-end"
    | "right-start"
    | "right"
    | "right-end"
    | "bottom-start"
    | "bottom"
    | "bottom-end";
}

const Tooltip = (props: Props) => {
  const { children, title, placement, ...rest } = props;

  return (
    <CustomTooltip
      TransitionComponent={Fade}
      title={title}
      placement={placement}
      {...rest}
    >
      {children}
    </CustomTooltip>
  );
};

Tooltip.defaultProps = {
  placement: "top",
} as Partial<Props>;

export default Tooltip;
