import React from "react";
import Iframe from "react-iframe";

import Drawer from "../../Drawer";
import PageLoading from "../../Page/PageLoading";

export interface IIFrameComponent {
  url?: string;
  onCancel?: () => void;
}

const IFrameComponent = (props: IIFrameComponent) => {
  return (
    <Drawer isOpen={!!props.url} closeDrawer={props.onCancel}>
      <div style={{ position: "absolute", width: "100%", height: "100%" }}>
        <PageLoading />
      </div>
      <div style={{ height: "90vh", position: "relative" }}>
        <div style={{ height: "100%" }}>
          {props.url && (
            <Iframe
              frameBorder={0}
              url={props.url}
              width={"100%"}
              height={"100%"}
              position={"relative"}
              id={"3DS-Display-Iframe"}
            />
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default IFrameComponent;
