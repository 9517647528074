import React, { ComponentType } from "react";

import { VerificationMethod } from "../../constants/verificationmethod";
import PageComponent from "../Page/PageComponent";
import PageLoading from "../Page/PageLoading";
import { IPageComponentProps } from "../Page/types";

import AccountComponent from "./AccountComponent";
import NotificationsDrawer from "./NotificationsDrawer";
import useAccountView from "./useAccountView";

interface IAccountContainer extends IPageComponentProps {}

const AccountContainer = ({ isAuthenticated, User }: IAccountContainer) => {
  const {
    isLoading,
    isNotificationDrawerOpen,
    authUser,
    preferences,
    logout,
    closePage,
    openNotificationDrawer,
    closeNotificationDrawer,
    updateNotificationSettings,
    consumer,
  } = useAccountView({ isAuthenticated, User });

  if (isLoading || !authUser) return <PageLoading />;
  return (
    <>
      <AccountComponent
        closePage={closePage}
        profilePicture={authUser.picture}
        firstName={User.first_name}
        lastName={User.last_name}
        email={consumer ? consumer.contact_email : User.contact_email}
        phone={User.contact_phone}
        logout={logout}
        notificationsFrom={
          preferences.notify_by_email
            ? VerificationMethod.EMAIL
            : VerificationMethod.SMS
        }
        openNotificationDrawer={openNotificationDrawer}
      />
      <NotificationsDrawer
        selection={
          preferences.notify_by_email
            ? VerificationMethod.EMAIL
            : VerificationMethod.SMS
        }
        isOpen={isNotificationDrawerOpen}
        onClose={closeNotificationDrawer}
        onSubmit={updateNotificationSettings}
        hasEmail={!!User.contact_email}
      />
    </>
  );
};

export default PageComponent(AccountContainer as ComponentType);
