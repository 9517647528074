import React from "react";
import { List } from "@material-ui/core";

import BillItem, { IBillItem } from "./BillItem";
import Styles from "../styles.module.scss";

export interface IBillList {
  items: IBillItem[];
  isOpen: boolean;
}

const BillList = (props: IBillList) => {
  return (
    <List className={`${Styles.BillSummaryDiv} ${props.isOpen && Styles.open}`}>
      {props.items.map((item) => (
        <BillItem key={item.id} {...item} />
      ))}
    </List>
  );
};

export default BillList;
