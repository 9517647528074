import { menuItemExpires } from "../../constants/menuItemExpires.const";
import { getCurrentEpochTime } from "../../yoello-lib/modules/auth/epochTime";
import { SET_MENU_EXPIRES } from "../../yoello-lib/modules/redux/actions/consumer.actions";

export function MenuExpireTime(state: number | null = null, action: any) {
  switch (action.type) {
    case SET_MENU_EXPIRES:
      state = getExpireTime();
      return state;
    default:
      return state;
  }
}

function getExpireTime() {
  return getCurrentEpochTime() + menuItemExpires;
}
