import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import NotFoundComponent from "../Error/NotFoundComponent";
import LoginV2 from "../LoginV2";
import AccountContainer from "../AccountContainer";
import { GroupComponent } from "../GroupPage/GroupContainer";

import WithoutUrl from "../WithoutUrl/WithoutUrl";
import {
  ROOT,
  LOGIN,
  PAYMENT,
  VENUE,
  GROUP,
  GROUP_VENUE,
  PAYMENT_VERIFICATION,
  PAY360_VERIFICATION,
  NOT_FOUND,
  LOGINV2,
  ORDERS,
  ACCOUNT,
  OPENBANKING,
} from "../../constants/URL.const";
import PaymentStore from "../Payment/PaymentStore";
import PaymentVerification from "../Payment/PaymentVerification";
import PageContainer from "../Page/PageContainer";
import APIExtensions from "../../yoello-lib/modules/API/APIDefs";
import GroupPageSkeletonLoading from "../Widgets/Loading/GroupPage";
import OrdersRoute from "../Orders/OrdersRoute";
import { useNotifications } from "../../customHooks/useNotification";
import { VenueRouter } from "./VenueRouter";
import OpenBankingContainer from "../Payment/OpenBankingContainer";
import PaymentContainer from "../Payment/PaymentContainer";

function Router() {
  return (
    <BrowserRouter>
      <div
        id="BodyDivId"
        style={{
          width: "100%",
          height: "100%",
          top: "0",
          maxWidth: "768px",
          overflow: "hidden",
        }}
      >
        <RouterNotificationHandler />
        <Switch>
          <Route path={ROOT} exact component={WithoutUrl} />
          <Route path={[LOGIN, LOGINV2]} component={LoginV2} />
          <Route path={ACCOUNT} component={AccountContainer} />
          <Route
            path={PAY360_VERIFICATION}
            exact
            component={PaymentVerification}
          />
          <Route
            path={PAYMENT_VERIFICATION}
            exact
            component={PaymentVerification}
          />

          <Route
            path={OPENBANKING}
            render={(props) => (
              <PaymentStore Component={OpenBankingContainer} />
            )}
          />
          <Route
            path={PAYMENT}
            render={(props) => <PaymentStore Component={PaymentContainer} />}
          />
          <Route
            path={GROUP}
            exact
            render={(props) => (
              <PageContainer
                {...props}
                area={true}
                apiConfig={{
                  query: props.match.params["group"],
                  headers: {},
                }}
                APIExtension={APIExtensions.consumerGroup}
                ChildComponent={GroupComponent}
                isVenueSame={false}
                LoadingComponent={GroupPageSkeletonLoading}
                errorFunction={() =>
                  props.history.push(NOT_FOUND, {
                    message: "Group not found",
                  })
                }
              />
            )}
          />
          <Route path={GROUP_VENUE} component={VenueRouter} />
          <Route path={VENUE} component={VenueRouter} />

          <Route path={ORDERS} component={OrdersRoute} />
          <Route path={NOT_FOUND} exact component={NotFoundComponent} />
          <Route component={WithoutUrl} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default Router;

function RouterNotificationHandler() {
  useNotifications();

  return null;
}
