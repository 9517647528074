import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { LoginStorage } from "../../constants/storageVars.const";
import { VerificationMethod } from "../../constants/verificationmethod";
import { ConnectionType } from "../../yoello-lib/modules/auth/WebAuth0";

const useGetLoginParams = () => {
  const location = useLocation();
  // eslint-disable-next-line
  const params = new URLSearchParams(location.search);

  const [method, setMethod] = useState<ConnectionType | null>();
  const [isLoading, setIsLoading] = useState(true);
  const [username, setUsername] = useState<string>();

  useEffect(() => {
    setIsLoading(true);
    setMethod(
      params.get("method")
        ? (decodeURI(params.get("method")) as ConnectionType)
        : localStorage.getItem(LoginStorage.USERNAME)
        ? VerificationMethod.EMAIL
        : null
    );
    setUsername(
      params.get("username")
        ? decodeURI(params.get("username"))
        : localStorage.getItem(LoginStorage.USERNAME)
        ? localStorage.getItem(LoginStorage.USERNAME)
        : null
    );
    setIsLoading(false);
    // eslint-disable-next-line
  }, [params, location, location.search]);

  return {
    method,
    username,
    isLoading,
  };
};

export default useGetLoginParams;
