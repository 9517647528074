import { VerificationMethod } from "../../constants/verificationmethod";

import T from "i18n-react";
import IDynamicFormRow from "../../yoello-lib/modules/dynamicForms/interfaces/IDynamicFormRow";
import FormTextBox from "../../yoello-lib/modules/dynamicForms/FormComponents/FormTextBox";
import { ToggleInput } from "../Inputs";

function isValidEmail(value: string) {
  // eslint-disable-next-line
  const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(value);
}
/**Checks for valid email address */
function validateEmail(value: string, allowedEmpty?: boolean) {
  if (allowedEmpty && value.length === 0) return;

  return isValidEmail(value) || "Invalid email";
}

export function NotificationForm(
  selection: VerificationMethod,
  setSelectedOption: (option: string) => void,
  classes: any
): IDynamicFormRow[] {
  return [
    {
      inputProperties: {
        variant: "outlined",
      },
      registerOptions: {
        required: true,
      },
      keyName: "notification_method",
      Component: ToggleInput,
      label: "",
      formProps: {
        onOptionChenge: (option: string) => setSelectedOption(option),
      },
      inputOptions: {
        default: selection,
        options: [
          {
            value: VerificationMethod.EMAIL,
            name: T.translate(`Account.${VerificationMethod.EMAIL}`),
          },
          {
            value: VerificationMethod.SMS,
            name: T.translate(`Account.${VerificationMethod.SMS}`),
          },
        ],
      },
    },
    {
      label: T.translate("Account.Email") as string,
      keyName: "contact_email",
      gridColumnOptions: { formCol: 12 },
      Component: FormTextBox,
      className: classes.emailWrapper,
      inputProperties: {
        type: "email",
        autoComplete: "email",
        placeholder: T.translate("Login.Texts.email") as string,
      },
      registerOptions: {
        validate: {
          validateEmail,
        },
      },
    },
  ];
}
