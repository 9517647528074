import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

import PageComponent from "../Page/PageComponent";
import NavigationHeader from "../NavigationHeader/NavigationHeader";
import { IPageComponentProps } from "../Page/types";
import LoginRouter from "./LoginRouter";
import useGenerateNavPath from "../../customHooks/useGenerateNavPath";
import {
  VENUE,
  LOGINV2_VERIFY,
  LOGINV2_PROFILE,
  LOGIN_REDIRECT,
  LOGIN_EMAIL_ERROR,
  LOGINV2_EMAIL_ERROR,
  LOGIN_MANUAL,
  // LOGIN_EMAIL_SENT,
  // LOGINV2_EMAIL_SENT,
} from "../../constants/URL.const";
import useGetToAndFromPaths from "./useGetToAndFromUrls";
import { useAuth0 } from "../../yoello-lib/modules/auth/Auth0";
import { Route, Switch } from "react-router-dom";
import LoginRedirect from "./LoginRedirect";
import EmailErrorView from "./EmailErrorView";
// import EmailSentView from "./EmailSentView";

function LoginContainer({ openSideDrawer }: IPageComponentProps) {
  const history = useHistory();
  const location = useLocation();
  const { compareNavPath, generateNavPath } = useGenerateNavPath();
  const { isAuthenticated, logout } = useAuth0()!;
  const { fromPath } = useGetToAndFromPaths("login", "", VENUE);
  const [isVerifyPage, setIsVerifyPage] = useState(false);
  const [referrerPath, setReferrerPath] = useState("");

  const exitLogin = () => {
    history.push(referrerPath);
  };

  useEffect(() => {
    setIsVerifyPage(compareNavPath(location.pathname, LOGINV2_VERIFY));
    //eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    setReferrerPath(generateNavPath(fromPath));
    //eslint-disable-next-line
  }, [fromPath]);
  function goBack() {
    if (isVerifyPage) {
      history.goBack();
      return;
    }
    if (history.location.pathname.includes(LOGIN_MANUAL)) {
      history.goBack();
      return;
    }
    if (compareNavPath(location.pathname, LOGINV2_PROFILE)) {
      history.goBack();
      if (isAuthenticated) {
        logout();
      }
      return;
    }
    exitLogin();
  }
  return (
    <div>
      <NavigationHeader backURL={goBack} openSideDrawer={openSideDrawer} />
      <Switch>
        <Route exact path={LOGIN_REDIRECT} component={LoginRedirect} />
        <Route
          exact
          path={[LOGIN_EMAIL_ERROR, LOGINV2_EMAIL_ERROR]}
          component={EmailErrorView}
        />
        {/* <Route
          exact
          path={[LOGIN_EMAIL_SENT, LOGINV2_EMAIL_SENT]}
          component={EmailSentView}
        /> */}
        <Route component={LoginRouter} />
      </Switch>
    </div>
  );
}

export default PageComponent(LoginContainer);
