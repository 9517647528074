import React, { useMemo } from "react";
import Styles from "./ProductCard.module.scss";
import { ReactComponent as DiscountSVG } from "../../../imges/DiscountIcon.svg";
import T from "i18n-react";
import { IProductCardProps } from "./types";
import { ReactComponent as PlusSVG } from "../../../imges/plus.svg";
import { useItemSelect } from "../../Menu/hooks";
import { useSnackbarMessages } from "../../../yoello-lib/modules/components/snackbar/SnackbarContext";

const ProductCard = ({
  productInfo,
  onClick,
  formatCurrency,
}: IProductCardProps) => {
  const { _addToCard } = useItemSelect();
  const { sendSnackbarMessage } = useSnackbarMessages();

  const price = useMemo(() => {
    if (checkPricingOptions() === true) {
      const menuPrice = productInfo.menu_item_pricing_options;
      const key = Object.keys(menuPrice)[0];

      return formatCurrency(menuPrice[key].option_price);
    }
    return null;
    // eslint-disable-next-line
  }, []);

  function checkPricingOptions() {
    const priceOps = productInfo.menu_item_pricing_options;
    if (priceOps !== undefined && priceOps !== null) {
      if (typeof priceOps === "object" && Object.keys(priceOps).length > 0) {
        return true;
      }
    }
    return false;
  }

  function getOff(info) {
    let flag = false;
    if (info.menu_item_pricing_options) {
      Object.keys(info.menu_item_pricing_options).forEach((item, i) => {
        if (info.menu_item_pricing_options[item].option_discount > 0) {
          flag = true;
        }
      });
    }

    return flag;
  }

  function quickAddItem(event) {
    event.cancelBubble = true;
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    const itemPOPrice: number = Object.values(
      productInfo.menu_item_pricing_options
    )[0].option_price;
    const itemPOKey: string = Object.keys(
      productInfo.menu_item_pricing_options
    )[0];

    _addToCard(
      {},
      {},
      itemPOKey,
      itemPOPrice,
      // @ts-ignore
      productInfo,
      1
    );

    sendSnackbarMessage(
      T.translate("Menu.AddItemSuccessMsg") as string,
      "success"
    );
  }
  if (productInfo) {
    return (
      <div
        data-component-name="ProductCard"
        className={`${Styles.ProductCard} ${
          productInfo.menu_item_enabled ? "" : Styles.ProductCardDisabled
        }`}
        onClick={() => {
          if (productInfo.menu_item_enabled && checkPricingOptions()) {
            onClick();
          }
        }}
      >
        {getOff(productInfo) ? (
          <div className={Styles.DiscountIcon}>
            <DiscountSVG height="14px" width="16px" />
          </div>
        ) : null}

        {productInfo.menu_item_url_photo && (
          <div>
            <div
              style={{
                position: "relative",
                height: "100%",
              }}
            >
              <div className={Styles.cardImage}>
                <img src={productInfo.menu_item_url_photo} alt="" />
              </div>
            </div>
          </div>
        )}

        <div
          className={`${Styles.productDetails} ${
            !productInfo.menu_item_description ? Styles.flexDirecton : null
          }`}
          style={
            productInfo.menu_item_url_photo
              ? { width: "calc(100% - 68px)" }
              : { width: "100%" }
          }
        >
          <div>
            <p
              data-component-name="ProductCardTitle"
              data-menu-item={productInfo.menu_item_id}
              className={Styles.Title}
            >
              {productInfo.menu_item_name_public}
            </p>
          </div>
          <div>
            <div>
              {productInfo.menu_item_description && (
                <p className={Styles.Description}>
                  {productInfo.menu_item_description}
                </p>
              )}
              {!productInfo.menu_item_enabled ? (
                <T.p
                  className={Styles.OutOfStuckText}
                  text={{ key: `Errors.OutOfStock` }}
                />
              ) : null}
            </div>
            <div></div>
          </div>
        </div>
        <div>
          {productInfo.menu_item_enabled &&
          checkPricingOptions() &&
          price !== undefined &&
          price !== null ? (
            <p
              data-component-name="ProductCardPrice"
              className={`${Styles.CurrentPrice}  m-0`}
            >
              {price}
            </p>
          ) : null}
          {productInfo?.menu_item_pricing_options
            ? Object.keys(productInfo?.menu_item_pricing_options)?.length ===
                1 &&
              productInfo.menu_item_preference_ids?.length === 0 &&
              productInfo.menu_item_extras_groups_ids?.length === 0 && (
                <div onClick={quickAddItem} className={Styles.PlusIconDiv}>
                  <PlusSVG width={"14px"} height={"14px"} />
                </div>
              )
            : null}
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default React.memo(ProductCard);
