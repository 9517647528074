import { useContext } from "react";

import { PaymentContext } from "../PaymentStore";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { convertToUTCTime } from "../../../yoello-lib/modules/utils/TimeConversion";

import usePaymentView from "../PaymentHooks/usePaymentView";

import { SelectedOrderType } from "../types.d";
import {
  _ChangeRedirect,
  _ChangeTable,
  _resetCart,
} from "../../../redux/actions";
import { CONFIRM, LOGIN } from "../../../constants/URL.const";
import useAPI from "../../../yoello-lib/modules/API/useAPI";
import { HTTPMethods } from "../../../yoello-lib/modules/API/API";
import APIExtensions from "../../../yoello-lib/modules/API/APIDefs";
import { useAuth0 } from "../../../yoello-lib/modules/auth/Auth0";
import { setSelectedPaymentMethod } from "../reducer/PaymentActions";

const useOnSuccessfulOrder = () => {
  const {
    selectedOrderType,
    selectedTable,
    selectedTime,
    deliveryAddress,
    areaName,
    dispatch: contextDispatch,
  } = useContext(PaymentContext);
  const { getRedirectURL } = usePaymentView();
  const dispatch = useDispatch();
  const history = useHistory();
  const { retrieveToken, isAuthenticated } = useAuth0()!;
  const { callAPI } = useAPI<any>(APIExtensions.consumerOrders, retrieveToken, {
    onMountDisabled: true,
  });

  const onSuccessfulOrder = async (
    orderId: string,
    orderLongID: string,
    isOpenBankingOrder?: boolean
  ) => {
    const placedOrderId = localStorage.getItem("placed_order_id");

    dispatch(_resetCart());
    dispatch(_ChangeRedirect(null));
    const url = getRedirectURL() + CONFIRM;
    if (!isOpenBankingOrder) {
      if (selectedOrderType === SelectedOrderType.TABLE) {
        dispatch(_ChangeTable(selectedTable));
      }
      history.push(url, {
        orderId: orderId,
        OrderType: selectedOrderType,
        TimeSlot: selectedTime ? convertToUTCTime(selectedTime.venue_time) : "",
        deliveryAddress: deliveryAddress,
        tableArea: areaName,
        orderLongID: orderLongID,
      });
    } else {
      if (!isAuthenticated) {
        history.push(LOGIN);
      } else {
        try {
          const response = await callAPI(HTTPMethods.GET, {
            query: orderLongID ?? placedOrderId,
          });
          if (response) {
            if (selectedOrderType === SelectedOrderType.TABLE) {
              dispatch(_ChangeTable(response.placed_order_delivery_table));
            }
            history.push(url, {
              orderId: orderId ?? response.placed_order_ref,
              OrderType: response.placed_order_order_type,
              TimeSlot: response.placed_order_takeaway_timeslot
                ? convertToUTCTime(response.placed_order_takeaway_timeslot)
                : "",
              deliveryAddress: response.placed_order_delivery_address,
              tableArea: response.placed_order_table_area,
              orderLongID: orderLongID,
            });
            localStorage.removeItem("placed_order_id");
          }
        } catch (error) {
          console.error(error);
        }
      }
    }

    contextDispatch(setSelectedPaymentMethod(null));
  };

  return {
    onSuccessfulOrder,
  };
};

export default useOnSuccessfulOrder;
