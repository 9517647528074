import React from "react";
import T from "i18n-react";
import Styles from "./styles.module.scss";

interface IOrderTypeHeaderProps {
  title: string;
  custom?: string;
  svgImage: JSX.Element;
  selected?: boolean;
}

function OrderTypeHeader({
  title,
  svgImage,
  selected,
  custom,
}: IOrderTypeHeaderProps) {
  return (
    <div
      className={
        selected ? Styles.SelectedOrderTypeTitleDiv : Styles.OrderTypeTitleDiv
      }
    >
      {svgImage}
      {!custom && <T.p text={{ key: title }} />}
      {custom && <p>{custom}</p>}
    </div>
  );
}

export default OrderTypeHeader;
