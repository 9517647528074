import { AxiosError } from "axios";
import { HTTPMethods } from "../API/API";
import { ICheckMethodReturn, IErrorCall, IMessageOptions } from "./types.d";
import { generateServiceEndpoints } from "../environments/services";
import { APIDefinitions } from './APIDefs/';


/**Resolves the API making sure that extension is available */
export function resolveAPIMethod(extension: any, messageOptions?: IMessageOptions) {
    const apiDefinition = APIDefinitions[extension];
    if (!apiDefinition) {
        //@ts-ignore
        throw Error(`Unsupported API endpoint "${APIExtensions[extension]}", available endpoints are ${Object.keys(APIDefinitions).map((key) => APIExtensions[key])}`);
    }
    const selectKey = apiDefinition.dataStorage?.selectorKey(messageOptions?.selectorKeyParams) || undefined;
    return {
        dispatchFunction: apiDefinition.dataStorage?.dispatchFunction || undefined,
        selectKey,
        baseURL: apiDefinition.baseURL,
        setDataTransform: apiDefinition.dataStorage?.setData || undefined,
        childrenAPI: apiDefinition?.childrenAPIs || undefined,
    };
}


/**Maps the redux state object to the string selector keys*/
export function getSelectorKey(obj: Record<string, any>, selectorKeys: string | undefined) {
    if (selectorKeys === undefined) {
        return undefined;
    }
    try {
        return selectorKeys.split(".").reduce((prevVal, curVal) => {
            return prevVal[curVal];
        }, obj);
    } catch (error) {
        return undefined;
    }
};

/**Generates the error message string */
export function generateErrorMessage(errorCall: IErrorCall, error: AxiosError) {
    const apiMessage = errorCall;
    if (typeof apiMessage === "string") {
        return apiMessage;
    }
    if (typeof apiMessage === "object") {
        let message: string = apiMessage[error?.response?.status!];
        if (!message) {
            message = "Network Error";
        }
        return message;
    }
    return "Network Error";
}

/**Generates the service baseurl  */
export function generateServiceURL(serviceName?: string, serviceOverride?: string) {
    const stage = process.env.REACT_APP_ENVIRONMENT;
    const overwriteHost = process.env.REACT_APP_SERVICE_ENDPOINT;
    const url = generateServiceEndpoints(stage, overwriteHost, serviceOverride);
    return `${url}/${serviceName ? serviceName + '-' : ''}api/`;
}
/**
 * Checks if the defintion has the method selected
 * Returns the URL for that method or the default URL
 */
export function checkMethodAndGetData(
    method: HTTPMethods,
    extension: any,
    messageOptions: IMessageOptions): ICheckMethodReturn {
    const apiDefinition = APIDefinitions[extension];
    let methodDefinition;
    let disabledFields;
    switch (method) {
        case HTTPMethods.GET:
            methodDefinition = apiDefinition.GET;
            break;
        case HTTPMethods.POST:
            methodDefinition = apiDefinition.POST;
            disabledFields = apiDefinition.POST?.disabledFields;
            break;
        case HTTPMethods.PUT:
            methodDefinition = apiDefinition.PUT;
            disabledFields = apiDefinition.PUT?.disabledFields;
            break;
        case HTTPMethods.PATCH:
            methodDefinition = apiDefinition.PATCH;
            disabledFields = apiDefinition.PATCH?.disabledFields;
            break;
        case HTTPMethods.DELETE:
            methodDefinition = apiDefinition.DELETE;
            break;
    }
    /**throws if unsupported */
    if (!methodDefinition) {
        throw Error(`Attempted use of unsupported HTTP Method '${method}' for api endpoint '${extension}', supported methods for this API are ${Object.keys(apiDefinition)}`);
    }
    console.log({ apiDefinition });
    return {
        url: messageOptions.apiVersion + "/" + (methodDefinition.url || apiDefinition.defaultURL),
        disabledFields
    };
}
