export function shouldResetOrderType(settingTime: number) {
  const start = new Date(settingTime);
  const end = new Date(Date.now());
  if (!areInSameDay(start, end)) {
    return true;
  }
  if (end.getHours() - start.getHours() >= 1 || !settingTime) {
    return true;
  }
  return false;
}

function areInSameDay(start, end) {
  return (
    start.getFullYear() === end.getFullYear() &&
    start.getMonth() === end.getMonth() &&
    start.getDate() === end.getDate()
  );
}
