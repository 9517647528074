import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { _addCartItem, _SaveRedux } from "../../../redux/actions";
import { generateItemsForCart } from "../../../utils/cartController";
import { useDrawer } from "../../Drawer/DrawerContext";
import { ProductDetailsContainer } from "../../ProductDetails/ProductDetailsContainer";

export function useItemSelect() {
  const dispatch = useDispatch();
  const { openDrawer, closeDrawer } = useDrawer()!;
  const menuItemAsExtra = useSelector((state: any) => state.MenuItemsAsExtra);
  /**Function to add to the cart */
  const _addToCard = (
    Extras: ISelectedCartExtras,
    Preferences: Record<string, string>,
    priceOptionName: string,
    price: number,
    productInfo: IMenuItem,
    count: number
  ) => {
    const cartBody = generateItemsForCart(
      Extras,
      Preferences,
      priceOptionName,
      price,
      productInfo,
      count,
      menuItemAsExtra
    );
    dispatch(_addCartItem(cartBody));

    // hideModal();
    closeDrawer();

    setTimeout(() => {
      dispatch(_SaveRedux());
    }, 100);
  };
  const handleItemSelect = useCallback(
    (menuItem: any) => {
      openDrawer(
        ProductDetailsContainer as any,
        {
          addToCart: _addToCard,
          extraPrices: 0,
          count: 1,
          menuItem,
          closeDrawer: closeDrawer,
        },
        "bottom",
        "ProductDetailsDrawer",
        true
      );
    },
    // eslint-disable-next-line
    [menuItemAsExtra]
  );

  return {
    handleItemSelect,
    _addToCard,
  };
}
