import { IAPIDefinitions } from "../types";
import { merchantAPIDefs, APIMerchantDashboard } from "./MerchantAPIDefs";
import { APIOpenBanking, openBankingDefs } from "./OpenBanckingAPIDefs";
import { APIPay360, pay360Defs } from "./Pay360APIDefs";
import { consumerAPIDefs, APIConsumer } from './ConsumerAPIDefs';

/**If you add extra API defintions files, then you must add here */
const APIExtensions = {
    ...APIMerchantDashboard,
    ...APIPay360,
    ...APIOpenBanking,
    ...APIConsumer
}

export const APIDefinitions: IAPIDefinitions = {
    ...merchantAPIDefs,
    ...pay360Defs,
    ...openBankingDefs,
    ...consumerAPIDefs
}

export default APIExtensions;