import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { PaymentContext } from "../PaymentStore";
import { SelectedOrderType } from "../types.d";
import { CARDIFF_CASTLE } from "../../../constants";
import BillList from "./BillList";
import { IBillItem } from "./BillItem";
import { TotalPriceWidget } from "./TotalPriceWidget";
import { addTaxes } from "../../../yoello-lib/modules/utils/TaxController";
import useCurrency from "../../../yoello-lib/modules/localisation/useCurrency";

import Styles from "../styles.module.scss";

const StandardPricing = () => {
  const {
    cartItemTaxAmount,
    serviceTaxAmount,
    cartItemInclusiveTaxAmount,
    serviceTaxInclusiveTaxAmount,
    deliveryInclusiveTaxAmount,
    deliveryTaxAmount,
    totalPrice,
    tipTotal,
    subTotalCost,
    deliveryPrice,
    transactionFee,
    totalDiscounts,
    discounts,
  } = useContext(PaymentContext);
  const { formatCurrency } = useCurrency();

  const Group = useSelector((state: any) => state.Group);
  const Venue = useSelector((state: any) => state.Venue);
  const SelectedMenuType = useSelector((state: any) => state.SelectedMenuType);
  const [billItems, setBillItems] = useState<IBillItem[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const totalTax = addTaxes(
    cartItemTaxAmount,
    serviceTaxAmount,
    deliveryTaxAmount
  );
  const inclusiveTax = addTaxes(
    cartItemInclusiveTaxAmount,
    serviceTaxInclusiveTaxAmount,
    deliveryInclusiveTaxAmount
  );
  const shouldHideDelivery = () => {
    return !(
      (Group?.group_service_charge_enabled &&
        Group?.group_nickname === CARDIFF_CASTLE) ||
      SelectedMenuType === SelectedOrderType.DELIVERY
    );
  };
  const totalPriceProps = {
    total: { ...billItems[billItems.length - 1] },
    onClick: () => setIsOpen(!isOpen),
    isOpen: isOpen,
  };
  useEffect(() => {
    setBillItems([
      {
        id: "item-price",
        titleKey: "Payment.ItemsPrice",
        className: Styles.BillSummarySectionDivs,
        value: formatCurrency(subTotalCost),
      },
      {
        id: "item-discounts",
        titleKey: "Payment.TotalDiscounts",
        className: `${Styles.BillSummarySectionDivs} ${Styles.totalDiscounts}`,
        value: `- ${formatCurrency(totalDiscounts)}`,
        subTitle: `${discounts.code} (-${
          discounts.absoluteValue
            ? formatCurrency(discounts.absoluteValue)
            : `${discounts.value}%`
        })`,
        hidden: !totalDiscounts,
      },
      {
        id: "tip-total",
        titleKey: "Payment.TipPrice",
        className: Styles.BillSummarySectionDivs,
        value: formatCurrency(tipTotal),
        hidden: !Venue?.tipping_enabled,
      },
      {
        id: "delivery",
        titleKey: "Payment.Delivery",
        className: Styles.BillSummarySectionDivs,
        value: formatCurrency(deliveryPrice),
        hidden: shouldHideDelivery(),
      },
      {
        id: "service-charge",
        titleKey: "Payment.ServiceCharge",
        className: Styles.BillSummarySectionDivs,
        value: formatCurrency(transactionFee),
        hidden: !transactionFee,
      },
      {
        id: "taxes",
        titleKey: "Payment.Taxes",
        className: Styles.BillSummarySectionDivs,
        value: formatCurrency(totalTax + inclusiveTax),
        hidden: !totalTax && !inclusiveTax,
      },
      {
        id: "total",
        titleKey: "Payment.TotalPay",
        className: Styles.TotalPriceDiv,
        value: formatCurrency(totalPrice + totalTax),
        hidden: true,
      },
    ]);
    //eslint-disable-next-line
  }, [
    cartItemTaxAmount,
    serviceTaxAmount,
    cartItemInclusiveTaxAmount,
    serviceTaxInclusiveTaxAmount,
    totalPrice,
    tipTotal,
    subTotalCost,
    deliveryPrice,
    transactionFee,
    totalPrice,
    totalTax,
    totalDiscounts,
    discounts,
    inclusiveTax,
  ]);

  return (
    <div className={Styles.PricingWrapper}>
      <TotalPriceWidget {...totalPriceProps} />
      <BillList items={billItems} isOpen={isOpen} />
    </div>
  );
};

export default StandardPricing;
