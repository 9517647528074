import React, { useState } from "react";
import NavigationHeader from "../../NavigationHeader/NavigationHeader";
import { useMenu } from "../MenuContext";
import Styles from "../styles.module.scss";
import { useNavigationTitle } from "../hooks/useNavigationTitle";
import { useSelector } from "react-redux";

import LeavingWarning from "./LeavingWarning";
interface IProps {
  openSideDrawer: () => void;
}

export function MenuNavigation({ openSideDrawer }: IProps) {
  const [openLeavingWarning, setOpenLeavingWarning] = useState(false);
  const cartItems = useSelector((state: any) => state.Cart);
  const Venue = useSelector((state: any) => state.Venue);
  const title = useNavigationTitle();
  const { goBack, elementRefs } = useMenu();
  const handleGoBack = () => {
    cartItems.length === 0 ? goBack() : setOpenLeavingWarning(true);
  };
  const handleContinue = () => {
    goBack();
  };
  return (
    <>
      <NavigationHeader
        openSideDrawer={openSideDrawer}
        backURL={handleGoBack}
        className={Styles.Navigationheader}
        displayVenueImage={false}
        title={title}
        ref={(ref) => (elementRefs.current.navigation = ref)}
      />
      <LeavingWarning
        venueName={Venue.venue_name}
        isOpen={openLeavingWarning}
        onCancel={() => setOpenLeavingWarning(false)}
        onContinue={() => handleContinue()}
      />
    </>
  );
}
