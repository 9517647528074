import { ADD_VIEW_DATA, ADD_MENU_ITEM, ADD_CATEGORIES, ADD_SUBCATEGORIES } from "../consts/merchantview.actions";

interface IDataToMerchantView {
    venueId: string;
    data: any;
}

export const addDataToMerchantView = (viewData: IDataToMerchantView) => {
    return {
        type: ADD_VIEW_DATA,
        payload: viewData
    };
};

export const addMenuItem = (venueId: string, menuItem: any) => {
    console.log("adding menu item action");
    return {
        type: ADD_MENU_ITEM,
        payload: { venueId, menuItem }
    };
};



export const addCategories = (categoryPayload: any, venueId: string) => {
    return {
        type: ADD_CATEGORIES,
        payload: { categories: categoryPayload, venueId }
    };
};

export const addSubCategory = (categoryType: string, subcategory: any, venueId: string) => {
    return {
        type: ADD_SUBCATEGORIES,
        payload: {
            categoryType,
            venId: venueId,
            subcategory
        }
    };
};