import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

interface IFormTitle {
    title: string;
    description?: string;
}

const useStyles = makeStyles({
    root: {},
    title: {
        fontSize: "1.143rem",
        fontWeight: 600,
        color: "#212121",
        margin: 0,


    },
    description: {
        fontSize: "0.929rem",
        color: "#737FA4",
        fontWeight: 500,
        margin: 0
    }
})

const FormTitle = ({
    title,
    description
} : IFormTitle) => {

    const classes = useStyles();

    return (
        <Grid className={classes.root} item xs={12}>
            <h3 className={classes.title}>{title}</h3>
            {
                description && <h5 className={classes.description}>{description}</h5>
            }
        </Grid>
    )
}

export default FormTitle;