/**Basic Helper function for checking if object is empty */
export function isObjectEmpty<T>(object: T) {
    if (object === undefined || object === null) {
        return true;
    }
    if (Object.keys(object).length > 0) {
        return false;
    } else {
        return true;
    }
};