import React from "react";
import ProductPrice from "./ProductPrice";
import Styles from "../styles.module.scss";

interface IProductPriceOptionProps {
  isSelected: boolean;
  pricingOptions: IPricingOptions;
  onClick: () => void;
}

function ProductPriceOption({
  isSelected,
  pricingOptions,
  onClick,
}: IProductPriceOptionProps) {
  return (
    <div
      data-component-name="ProductPricingOption"
      className={`${Styles.PricingOptionDiv} ${
        isSelected ? Styles.PricingOptionDivSelected : ""
      }`}
      onClick={onClick}
    >
      <p
        className={Styles.PricingOptionName}
        data-component-name={
          isSelected ? "ProductPricingOptionSelected" : undefined
        }
      >
        {pricingOptions.option_name}{" "}
        {pricingOptions.option_discount
          ? `(${pricingOptions.option_discount}% off)`
          : ""}
      </p>
      <div className="Flex AlignCenter">
        <ProductPrice {...pricingOptions} />
        {isSelected && <div className={Styles.SelectedOptionLine}></div>}
      </div>
    </div>
  );
}

export default React.memo(ProductPriceOption);
