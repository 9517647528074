import { useContext } from "react";
import { useSelector } from "react-redux";
import { PaymentContext } from "../PaymentStore";
import { SelectedOrderType } from "../types.d";
import {
  changeOrderType,
  changeTableNumber,
  setIsTableServiceDrawerOpen,
  setIsCollectionDrawerOpen,
  selectTime,
  setIsDeliveryDrawerOpen,
} from "../reducer/PaymentActions";
import { shouldResetOrderType } from "../../Utils/ChekTime";
import { getDeliveryAddress } from "../../../utils/getdeliveryAddress";

export function useCheckIfOrderTypeSelected() {
  const OrderType = useSelector((state: any) => state.OrderType);
  const { dispatch, selectedOrderType } = useContext(PaymentContext);

  function renderOrderTypeDrawer() {
    switch (selectedOrderType) {
      case SelectedOrderType.TABLE:
        if (!OrderType || shouldResetOrderType(OrderType?.settingTime)) {
          dispatch(changeTableNumber(null, null));
          setTimeout(() => {
            dispatch(changeOrderType(SelectedOrderType.TABLE));
            dispatch(setIsTableServiceDrawerOpen(true));
          }, 100);
        }
        break;
      case SelectedOrderType.CLICK:
        if (!OrderType || shouldResetOrderType(OrderType?.settingTime)) {
          dispatch(selectTime(null));
          setTimeout(() => {
            dispatch(changeOrderType(SelectedOrderType.CLICK));
            dispatch(setIsCollectionDrawerOpen(true));
          }, 100);
        }
        break;
      case SelectedOrderType.DELIVERY:
        const address = getDeliveryAddress();
        if (
          !OrderType ||
          shouldResetOrderType(OrderType?.settingTime) ||
          !address?.postcode ||
          !address?.address ||
          !address.full_name
        ) {
          dispatch(selectTime(null));
          setTimeout(() => {
            dispatch(changeOrderType(SelectedOrderType.DELIVERY));
            dispatch(setIsDeliveryDrawerOpen(true));
          }, 100);
        }
        break;

      default:
        return null;
    }
  }
  return {
    renderOrderTypeDrawer,
  };
}
