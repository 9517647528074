import React, { useEffect, useRef, useState } from "react";

import T from "i18n-react";

import { makeStyles } from "@material-ui/core";
import useCardDetailsValidation from "./useCardDetailsValidation";
import useBillingAddressValidation from "./useBillingAddressValidation";

import {
  setIsCardDetailsDrawerOpen,
  setIsPaymentDrawerOpen,
} from "../../../reducer/PaymentActions";

import CardDetailsDrawer from "./CardDetailsDrawer";
import BillingAddressDrawer from "./BillingAddressDrawer";
import ConfirmDetails from "./ConfirmDetails";
import usePaymentDetails from "../../../PaymentHooks/usePaymentDetails";
import usePay360Form from "./usePay360Form";

import { usePaymentState } from "../../../PaymentStore";
import { useSnackbarMessages } from "../../../../../yoello-lib/modules/components/snackbar/SnackbarContext";

const useStyles = makeStyles({
  Form: {
    padding: "2rem",
    position: "relative",
  },
  ExpiryInput: {
    backgroundColor: "#F9F9F9",
    border: "none",
    outline: "unset",
    width: "2rem",
  },
  FormWrapper: {
    padding: "0.25rem 2rem 2rem 2rem",
    boxSizing: "border-box",
  },
  InputsWrapper: {},
  Input: {
    border: "none",
    outline: "unset",
    width: "100%",
    marginBottom: "1rem",
    position: "relative",
    "& .MuiInput-root": {
      border: "1px solid #F9F9F9",
      backgroundColor: "#F9F9F9",
      transition: "0.25s ease-in-out",
      borderRadius: "6px",
      width: "100%",
      display: "block",
      boxSizing: "border-box",
      "&.Mui-error": {
        border: "1px solid #F44336",
      },
      "&.Mui-focused": {
        backgroundColor: "#FFFFFF",
        border: "1px solid #FFDD02",
      },
      "& input": {
        padding: "1.6rem",
        boxSizing: "border-box",
      },
    },
  },
  Select: {
    color: "rgba(0, 0, 0, 0.87)",
    border: "1px solid #F9F9F9",
    padding: "1rem",
    width: "100%",
    marginBottom: "1rem",
    outline: "unset",
    backgroundColor: "#F9F9F9",
    borderRadius: "6px",
  },
  Error: {
    border: "1px solid #F44336",
  },
});

const CardDetailsComponent = ({ setToken, token }) => {
  const classes = useStyles();
  const { updatePay360Details } = usePaymentDetails();

  const [isBillingDetailsOpen, setIsBillingDetailsOpen] = useState(false);
  const [isConfirmDetailsOpen, setisConfirmDetailsOpen] = useState(false);
  const [shouldUpdatePaymentDetails, setShouldUpdatePaymentDetails] = useState(
    false
  );
  const [saveCard, setSaveCard] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const hiddenRef = useRef<HTMLInputElement>();
  const { isCardDetailsDrawerOpen, dispatch } = usePaymentState();
  const { sendSnackbarMessage } = useSnackbarMessages()!;

  const {
    cardNumber,
    cvv,
    expiry,
    issuer,
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
    fullName,
    setFullName,
    cleanCardNumber,
    cleanCvv,
    cleanExpiry,
    clearForm: clearCardDetailsForm,
    checkForErrors: checkForCardErrors,
  } = useCardDetailsValidation();

  const {
    setLine1,
    setCity,
    setCountryCode,
    setPostCode,
    postCode,
    billingAddress,
    clearForm: clearBillingAddressForm,
    checkForErrors: checkForAddressErrors,
  } = useBillingAddressValidation();

  /*This is called before Pay360 attempts to generate a card token
  there's no callback, so this function listens for when token exists*/
  const preSubmitCallback = () => {
    let attempts = 100; //try for 10 seconds
    setIsSubmitting(true);
    const getToken = setInterval(() => {
      if (attempts > 0) {
        if (hiddenRef.current.value) {
          setToken(hiddenRef.current.value);
          setIsSubmitting(false);
          clearInterval(getToken);
          setShouldUpdatePaymentDetails(true);
          closeDrawer();
        }
      } else {
        sendSnackbarMessage(
          T.translate("Payment.Errors.CARD_DETAILS_FAILURE").toString(),
          "error"
        );
        setIsSubmitting(false);
        clearInterval(getToken);
      }
      attempts--;
    }, 100);
    return true;
  };

  const { hasLoaded, createCardLock } = usePay360Form(preSubmitCallback);

  const resetForm = () => {
    setFullName("");
    setSaveCard(false);
    clearBillingAddressForm();
    clearCardDetailsForm();
  };

  const closeDrawer = () => {
    resetForm();
    dispatch(setIsPaymentDrawerOpen(true));
    dispatch(setIsCardDetailsDrawerOpen(false));
    setIsBillingDetailsOpen(false);
    setisConfirmDetailsOpen(false);
  };

  const openCardDetailsDrawer = () => {
    setIsBillingDetailsOpen(false);
    setisConfirmDetailsOpen(false);
  };

  const openBillingDrawer = () => {
    setIsBillingDetailsOpen(true);
    setisConfirmDetailsOpen(false);
  };

  const openConfirmDetailsDrawer = () => {
    setIsBillingDetailsOpen(false);
    setisConfirmDetailsOpen(true);
  };

  const handleInputChange = ({ target }) => {
    if (target.name === "number") {
      formatCreditCardNumber(target.value);
    } else if (target.name === "expiry") {
      formatExpirationDate(target.value);
    } else if (target.name === "cvc") {
      formatCVC(target.value);
    } else if (target.name === "name") {
      setFullName(target.value);
    }
  };

  useEffect(() => {
    if (shouldUpdatePaymentDetails) {
      setShouldUpdatePaymentDetails(false);
    }
    //eslint-disable-next-line
  }, [shouldUpdatePaymentDetails]);

  useEffect(() => {
    if (hasLoaded && isCardDetailsDrawerOpen) {
      createCardLock();
      // resetForm();
    }
    //eslint-disable-next-line
  }, [isCardDetailsDrawerOpen, hasLoaded]);

  useEffect(() => {
    if (shouldUpdatePaymentDetails && token) {
      updatePay360Details({
        name_on_card: fullName,
        masked_card_number: cleanCardNumber.substr(
          cleanCardNumber.length - 4,
          cleanCardNumber.length
        ),
        card_token: token,
        expiry_date: cleanExpiry,
        billing_address: billingAddress,
        issuer: issuer,
        save_card: saveCard,
      });
    }
    //eslint-disable-next-line
  }, [shouldUpdatePaymentDetails, token]);

  return (
    <>
      <CardDetailsDrawer
        isCardDetailsDrawerOpen={isCardDetailsDrawerOpen}
        billingDetailsOpen={isBillingDetailsOpen}
        confirmDetailsOpen={isConfirmDetailsOpen}
        hasLoaded={hasLoaded}
        cardNumber={cardNumber}
        classes={classes}
        expiry={expiry}
        fullName={fullName}
        cvv={cvv}
        closeDrawer={closeDrawer}
        openBillingDrawer={openBillingDrawer}
        handleInputChange={handleInputChange}
        checkForCardErrors={checkForCardErrors}
      />
      <BillingAddressDrawer
        setLine1={setLine1}
        setCity={setCity}
        setCountryCode={setCountryCode}
        setPostCode={setPostCode}
        isCardDetailsDrawerOpen={isCardDetailsDrawerOpen}
        isBillingDetailsOpen={isBillingDetailsOpen}
        setIsBillingDetailsOpen={setIsBillingDetailsOpen}
        openConfirmDetails={openConfirmDetailsDrawer}
        classes={classes}
        checkForAddressErrors={checkForAddressErrors}
      />
      <ConfirmDetails
        isSubmitting={isSubmitting}
        isCardDetailsDrawerOpen={isCardDetailsDrawerOpen}
        isConfirmDetailsOpen={isConfirmDetailsOpen}
        cardNumber={cardNumber}
        cleanCardNumber={cleanCardNumber}
        expiry={expiry}
        cleanExpiry={cleanExpiry}
        cvv={cvv}
        cleanCvv={cleanCvv}
        hiddenRef={hiddenRef}
        fullName={fullName}
        postCode={postCode}
        billingAddress={billingAddress}
        setSaveCard={setSaveCard}
        closeDrawer={openBillingDrawer}
        openCardDetailsDrawer={openCardDetailsDrawer}
      />
    </>
  );
};

export default CardDetailsComponent;
