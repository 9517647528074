import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDrawer } from "../Components/Drawer/DrawerContext";
import DifferentTimezoneWarning from "../Components/Widgets/DifferentTimezoneWarningComponent/DifferentTimezoneWarning";
import moment from "moment-timezone";
import { useParams } from "react-router";

export function useVenueTimezones() {
  const Venue = useSelector((state: any) => state.Venue);
  const { openDrawer, closeDrawer } = useDrawer()!;
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (Venue.timezone_warning_enabled) {
      /**Current date/time */
      const date = new Date();
      /**Browsers timezone */
      const userBrowserTimezone = Intl.DateTimeFormat().resolvedOptions()
        .timeZone;
      /**calculate browser time & venue time */
      const userBrowserTime = moment.tz(date, userBrowserTimezone).format();
      const venueTime = moment.tz(date, Venue.time_zone_name).format();

      if (userBrowserTime !== venueTime && id === Venue.venue_nickname) {
        openDrawer(
          DifferentTimezoneWarning as any,
          {
            closeDrawer: closeDrawer,
          },
          "bottom",
          "DifferentTimezoneWarning"
        );
      }
    }
    // eslint-disable-next-line
  }, [Venue]);
}
