import React, { useState, useEffect } from "react";
import { Collapse } from "antd";
import { connect, useDispatch } from "react-redux";
import Styles from "./selectTable.module.scss";
import Button from "../Button";
import T from "i18n-react";
import { ISelectTable } from "./types.d";
import { _SetSelectedOrderType } from "../../../redux/actions";
const { Panel } = Collapse;
const SelectTable = (props: ISelectTable) => {
  const [state, setState] = useState<Partial<ISelectTable>>({
    selectedTable: props.selectedTable,
    areaName: props.areaName,
  });
  const [isTableServiceReady, setIsTableServiceReady] = useState<boolean>(
    false
  );
  const [activeKey, setActiveKey] = useState<string | string[]>([]);
  const [searchTableNumber, setSearchTableNumber] = useState<string>("");
  const dispatch = useDispatch();
  const { areaName, selectedTable } = state;
  useEffect(() => {
    if (searchTableNumber.length > 0) {
      if (props.Venue?.table_layout?.length > 1) {
        let activeLayout = [];
        props.Venue.table_layout.forEach((table, index) => {
          for (
            let i = table.table_range_lower_bound || 0;
            i <= table.table_range_upper_bound;
            i++
          ) {
            if (i.toString().includes(searchTableNumber)) {
              activeLayout.push(`${index + 1}`);
              break;
            }
          }
        });
        setActiveKey(activeLayout);
      }
    } else setActiveKey([""]);
  }, [searchTableNumber, props.Venue]);
  useEffect(() => {
    if (isTableServiceReady) {
      //this is for applying selected table style first then closing modal
      setTimeout(() => {
        props.handleTableSelect(selectedTable, areaName);
        //set if user set order type first time
        dispatch(
          _SetSelectedOrderType({
            orderType: state,
            settingTime: Date.now(),
          })
        );
      }, 100);
    }
    // eslint-disable-next-line
  }, [isTableServiceReady]);
  const _getLowerTableRange = () => {
    if (props.GroupsTable) {
      return props.Group.group_table_range_lower_bound;
    } else {
      return props.Venue.table_range_lower_bound;
    }
  };

  const _getUpperTableRange = () => {
    if (props.GroupsTable) {
      return props.Group.group_table_range_upper_bound;
    } else {
      return props.Venue.table_range_upper_bound;
    }
  };

  const _renderVenueTables = (min, max, table_range_name) => {
    if (min === null || max === null) {
      return [];
    }
    let temp = [];
    for (let i = min; i <= max; i++) {
      if (i.toString().includes(searchTableNumber)) {
        temp.push(
          <div
            key={i}
            className={`${Styles.tableRow} ${
              selectedTable === i && areaName === table_range_name
                ? Styles.TableRowSelected
                : ""
            }`}
            onClick={() => {
              setState({
                selectedTable: i,
                areaName: table_range_name,
              });
              setIsTableServiceReady(true);
            }}
            data-component-name={`TableDelivery${i}`}
          >
            <p>{i}</p>

            {selectedTable === i && areaName === table_range_name && (
              <div className={Styles.SelectedBoxLine}></div>
            )}
          </div>
        );
      }
    }
    return temp;
  };

  const _renderTables = () => {
    let temp = [];
    if (props.GroupsTable) {
      return _renderVenueTables(
        _getLowerTableRange(),
        _getUpperTableRange(),
        props.Group.group_nickname
      );
    } else if (props.Venue?.table_layout?.length > 0) {
      if (props.Venue?.table_layout?.length > 1) {
        return (
          <Collapse activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
            {props.Venue.table_layout.map((table, i) => (
              <Panel
                data-component-name={`TableService${table.table_range_name}`}
                header={`${table.table_range_name}`}
                key={`${i + 1}`}
              >
                {_renderVenueTables(
                  table.table_range_lower_bound,
                  table.table_range_upper_bound,
                  table.table_range_name
                )}
              </Panel>
            ))}
          </Collapse>
        );
      } else {
        const tableLayout = props.Venue?.table_layout[0];
        if (
          tableLayout.table_range_upper_bound === null ||
          tableLayout.table_range_lower_bound === null
        ) {
          return [];
        }
        return _renderVenueTables(
          tableLayout.table_range_lower_bound,
          tableLayout.table_range_upper_bound,
          tableLayout.table_range_name
        );
      }
    }
    return temp;
  };

  return (
    <div className={Styles.selectTableContainer}>
      <div className={Styles.lineBar}>
        {" "}
        <span></span>{" "}
      </div>
      <div className={Styles.DeliveryHeader}>
        <p>{T.translate("Payment.VenueArea")}</p>
        <div className="Flex1">
          <Button
            onClick={props.closeDrawer}
            dataComponentName="ExitButton"
            iconClassName={"consumer-close"}
            style={{
              position: "unset",
              color: "#AAB6D3",
              fontSize: "1.5rem",
            }}
            isHeaderBtn
          />
        </div>
      </div>
      <div className={Styles.searchInputContainer}>
        <span className="consumer-search" />
        <input
          inputMode="numeric"
          placeholder={T.translate("SelectTable.SearchForTable") as string}
          className={Styles.searchInput}
          value={searchTableNumber}
          onChange={(e) => setSearchTableNumber(e.target.value)}
        />
      </div>
      <div className={Styles.divider}></div>

      <div
        className={Styles.tablesListWrapper}
        data-component-name="tablesListWrapper"
      >
        {_renderTables()}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps, {})(SelectTable);
