import React from "react";
import { makeStyles } from "@material-ui/core";

export type MenuTimeBoxStylesPropsType = {
  backgroundColor: string;
  textColor: string;
};

const useStyles = makeStyles({
  root: {
    height: "1.5625rem",
    minWidth: "6.5rem",
    border: "1px solid #FFDD02",
    borderRadius: "15px",
    backgroundColor: (props: MenuTimeBoxStylesPropsType) =>
      props.backgroundColor,
    margin: "0 0.3125rem",
    "& p": {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "0.875rem",
      textAlign: "center",
      color: (props: MenuTimeBoxStylesPropsType) => props.textColor,
      margin: "0",
    },
  },
});

export type TimeBoxType = {
  start_time: string;
  end_time: string;
  iso_weekday: number;
  enabled: boolean;
};

export interface IMenuTimeBox {
  time: TimeBoxType;
  styles: MenuTimeBoxStylesPropsType;
}
export function MenuTimeBox(props: IMenuTimeBox) {
  const classes = useStyles(props.styles);
  return (
    <div className={classes.root}>
      <p>
        {props.time.start_time.slice(0, -3)} -{" "}
        {props.time.end_time.slice(0, -3)}
      </p>
    </div>
  );
}
