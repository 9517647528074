import * as types from "../actions/ActionTypes"

const Preferences = (state = {}, action) => {
  switch (action.type) {
    case types.SetPreferences:

      state = action.data;
      return state;

    default:

      return state
  }
};
export default Preferences
