import { useEffect } from "react";
import { useSelector } from "react-redux";
import TagManager from "react-gtm-module";
import { IAddCartItem } from "../redux/Reducers/Cart";

export function useCartDataLayer() {
  const cart = useSelector((state: any) => state.Cart);

  useEffect(() => {
    function setDataLayer() {
      const dataLayer = generateDataLayer(cart);
      TagManager.dataLayer({
        dataLayer,
      });
    }
    if (Array.isArray(cart) && cart.length > 0) {
      setDataLayer();
    }
  }, [cart]);
}

function generateDataLayer(cartItems: IAddCartItem[]) {
  let itemCount = 0;
  let totalPrice = 0;
  let extrasPrice = 0;
  let itemsPrice = 0;
  let extrasCount = 0;
  for (let cart of cartItems) {
    itemCount += cart.itemCount;
    totalPrice += cart.finalPrice;
    extrasPrice += cart.extrasPrice;
    itemsPrice += cart.price;
    extrasCount += generateExtrasCount(cart.extras);
  }
  return {
    itemCount,
    totalPrice,
    extrasPrice,
    itemsPrice,
    extrasCount,
    totalItemCount: itemCount + extrasCount,
  };
}

function generateExtrasCount(extras: Record<string, ISelectedExtras[]>) {
  if (!extras) return 0;
  /**for each extra group */
  return Object.keys(extras).reduce((_prevItem: number, nextItem: string) => {
    const items = extras[nextItem];
    /**Get the count of each extra items group */
    return items.reduce((prevItem: number, nextExtra: ISelectedExtras) => {
      return prevItem + nextExtra.count;
    }, _prevItem);
  }, 0);
}
