import { SwipeableDrawer } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ConfirmOrder from "../Widgets/ConfirmOrder/ConfirmOrder";

export function ConfirmOrderContainer() {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory<any>();
  const Venue = useSelector((state: any) => state.Venue);
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    setIsOpen(true);
    setMounted(true);
    return () => {
      closeDraw();
    };
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isOpen && isMounted) {
      /**Animation timeout */
      setTimeout(() => {
        history.replace(`/v/${Venue.venue_nickname}/menu`);
      }, 350);
    }
    //eslint-disable-next-line
  }, [isOpen, history]);

  function closeDraw() {
    setIsOpen(false);
  }
  const locationState = history.location.state;

  const props = {
    TimeSlot: locationState?.TimeSlot,
    selectedOrderType: locationState?.OrderType,
    CloseDrawer: closeDraw,
    orderId: locationState?.orderId,
    deliveryAddress: locationState?.deliveryAddress,
    tableArea: locationState?.tableArea,
    shareOnConfirmOrderPage: true,
    prepTime: Venue.food_prep_time,
    history,
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={isOpen}
      onClose={closeDraw}
      disableSwipeToOpen={true}
      disableDiscovery={true}
      id={"OrderQrCode"}
      transitionDuration={350}
      data-component-name="confirm-order-drawer"
      onOpen={() => null}
    >
      <ConfirmOrder {...props} />
    </SwipeableDrawer>
  );
}
