import React from "react";
import Button from "../../Widgets/Button";
import Styles from "../groupPage.module.scss";
import { ReactComponent as HamburgerMenuSVG } from "../../../imges/HamburgerMenu.svg";
import { GroupTrackTrace } from "./GroupTrack&Trace";

export function GroupPageTop({ group, openSideDrawer }) {
  return (
    <>
      <Button
        isHeaderBtn
        className={Styles.SideMenuDrawerBtn}
        Icon={<HamburgerMenuSVG />}
        onClick={openSideDrawer}
      />
      <GroupTrackTrace group={group} />
    </>
  );
}
