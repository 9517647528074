import React, { useMemo } from "react";
import SwipeableViews from "react-swipeable-views";
import SwipeItems from "../TopMenu/SwipeItems/SwipeItems";
import { useMenu } from "./MenuContext";
// import { CategoryDescription } from "./UI/CategoryDescription";
import { ProductItems } from "./UI/ProductItems";

export interface IElementItems {
  data: IMenuItem[];
  position: number;
  name: string;
  description: string;
}

export function MenuLists() {
  const {
    menuItemState,
    menuItemRaw,
    onChangePosition,
    state: { menuKeys, position, menuPosition, searchText },
  } = useMenu();
  /**Generate the array of elements items*/
  const elements: IElementItems[] = useMemo(() => {
    if (menuItemState && menuItemRaw && menuKeys) {
      const els = [];
      for (const menuKey of menuKeys) {
        const treeCats = menuItemState[menuKey]?.subCats;
        for (const subKey in treeCats) {
          const obj = treeCats[subKey];
          const menuItems = obj.menuItems;
          els.push(
            <ProductItems
              key={`${subKey}-${obj.index}`}
              data={menuItems}
              position={obj.index}
              name={obj.subcatName}
              description={obj.subcatDesc}
            />
          );
        }
      }
      return els;
    }
  }, [menuItemState, menuItemRaw, menuKeys]);

  const searchItems = useMemo(() => {
    if (!searchText || searchText.length === 0) {
      return menuItemRaw;
    }
    return menuItemRaw.filter((item) => {
      return item?.menu_item_name_public
        ?.toLowerCase()
        .includes(searchText.toLowerCase());
    });
  }, [searchText, menuItemRaw]);
  // console.log(ref.current.getBoundingClientRect().height);
  return (
    <div
      key="menuList"
      data-component-name="menu_container"
      className="menu_contents"
      style={{
        backgroundColor: "white",
      }}
    >
      {menuPosition === -1 ? (
        <div key="searchList">
          <SwipeItems
            key={`swipe-search-list`}
            data={searchItems}
            position={-1}
          />
        </div>
      ) : (
        <SwipeableViews
          key={"Swipeableviews"}
          onChangeIndex={(index) => onChangePosition(`${index}`)}
          index={position}
          enableMouseEvents
        >
          {elements}
        </SwipeableViews>
      )}
    </div>
  );
}
