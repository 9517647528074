export default function getAvailableExtras(
  ids: string[],
  extras: Record<string, IMenuExtra>
): string[] {
  return ids.filter((extraId) => {
    const extra = extras?.[extraId];

    if (!extra) return false;

    const hasMinQuantity = extra.menu_item_extra_group_quantity_min >= 1;
    const isOutOfStock = !!!extra.menu_item_extra_group_item_id_list.length;

    if (!hasMinQuantity && isOutOfStock) return false;

    return true;
  });
}
