import React from "react";
import SearchBox from "../TopMenu/SearchBox";
import { useMenu } from "./MenuContext";
import { setSearchText } from "./state/Menu.Actions";

export function MenuSearch() {
  const {
    dispatch,
    state: { searchText },
  } = useMenu();

  function onChange(text: string) {
    dispatch(setSearchText(text));
  }
  return (
    <div className="top_menu__filter-wrapper" data-component-name="search_box">
      <SearchBox onTextChange={onChange} searchText={searchText} />
    </div>
  );
}
