import React, { useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { PaymentRouter } from "@yoello/open_banking";
import { PaymentContext } from "./PaymentStore";
import { PAYMENT } from "../../constants/URL.const";
import useOnSuccessfulOrder from "./PaymentHooks/useOnSuccessfulOrder";
import { useSnackbarMessages } from "../../yoello-lib/modules/components/snackbar/SnackbarContext";
import { _updateCartID } from "../../redux/actions";
export enum Stage {
  prod = "prod",
  staging = "staging",
  dev = "dev",
}
export default function OpenBankingContainer() {
  const { totalPrice, placedOrderID } = useContext(PaymentContext);
  const { onSuccessfulOrder } = useOnSuccessfulOrder();
  const venue = useSelector((state: any) => state.Venue);
  const { sendSnackbarMessage } = useSnackbarMessages();
  const rxdDispatch = useDispatch();
  const { push } = useHistory();

  return (
    <PaymentRouter
      userId=""
      merchant={{
        id: venue.id,
        merchant_name: venue.venue_name,
        merchant_nickname: venue.venue_nickname,
      }}
      paymentAmount={totalPrice}
      onCancelProcess={function (status): void {
        if (status) {
          switch (status) {
            case "DECLINED":
              sendSnackbarMessage("payments declined", "error");
              break;

            default:
              sendSnackbarMessage("payments failed", "error");
              break;
          }
        }
        rxdDispatch(_updateCartID(uuidv4()));
        push(PAYMENT);
      }}
      onComplete={function (data: any): void {
        if (data.order_id) {
          onSuccessfulOrder(data.order_reference_id, data.order_id, true);
          return;
        }
      }}
      paymentReference={`Yoello - [${venue.venue_name}]`}
      currencyISO={venue.currency_iso}
      authoriser={() => {
        return new Promise((resolve) => resolve("123"));
      }}
      language="en"
      placedOrderId={placedOrderID}
      stage={Stage[process.env.REACT_APP_STAGE]}
    />
  );
}
