import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";

import T from "i18n-react";

import {
  LOGINV2_VERIFY,
  LOGINV2_PROFILE,
  LOGIN_VERIFY,
  LOGIN_PROFILE,
  LOGIN,
  LOGINV2,
  LOGIN_START,
  LOGINV2_START,
  LOGIN_MANUAL,
  LOGIN_MANUALV2,
  VENUE_MENU,
  VENUE,
} from "../../constants/URL.const";

import LoginView from "./LoginView";
import VerifyView from "./VerifyView";
import ProfileView from "./ProfileView";

import { getConsumer } from "../../Api.js";
import { useAuth0 } from "../../yoello-lib/modules/auth/Auth0";

import ManualSignIn from "../LoginV2/ManualSignIn";

import useGetToAndFromUrls from "./useGetToAndFromUrls";

import useGenerateNavPath from "../../customHooks/useGenerateNavPath";
import useGetLoginParams from "./useGetLoginParams";
import { useSnackbarMessages } from "../../yoello-lib/modules/components/snackbar/SnackbarContext";
import PageLoading from "../Page/PageLoading";

const LoginRouter = () => {
  const {
    isAuthenticated,
    token,
    setUpAuthedUser,
    isRetrievingUser,
  } = useAuth0()!;

  const { generateNavPath } = useGenerateNavPath();
  const history = useHistory();
  const location = useLocation<locationState>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { method, username } = useGetLoginParams();
  const { sendSnackbarMessage } = useSnackbarMessages();
  const { logout } = useAuth0();

  const { toPath, isLoadingPaths } = useGetToAndFromUrls(
    "login",
    generateNavPath(VENUE),
    generateNavPath(VENUE)
  );

  const onAuthenticatedUser = async () => {
    try {
      const consumer = await getConsumer(token.token);
      //@ts-ignore
      if (consumer.data.data.user_details_set) {
        setUpAuthedUser(consumer.data.data, token);
        console.log(toPath || generateNavPath(VENUE_MENU));
        history.push(toPath || generateNavPath(VENUE_MENU));
      } else {
        onNoConsumerDetails();
      }
    } catch (error) {
      if (error && error.response) {
        if (error.response.status === 404) {
          onNoConsumerDetails();
          return;
        }
      } else {
        sendSnackbarMessage(
          T.translate("Login.Errors.CouldntGetUser").toString(),
          "error"
        );
        logout();
      }
    }
    setIsLoading(false);
  };

  const onNoConsumerDetails = () => {
    let profilePath = generateNavPath(LOGINV2_PROFILE, {
      method,
      username,
    });
    history.push(profilePath, location.state);
    setIsLoading(false);
  };

  const onIsNotAuthenticated = () => {
    history.push(
      generateNavPath(LOGINV2_START, {
        method,
        username,
      }),
      location.state
    );
  };

  useEffect(() => {
    if (isAuthenticated && token && !isLoadingPaths) {
      setIsLoading(true);
      onAuthenticatedUser();
    } else {
      onIsNotAuthenticated();
    }
    //eslint-disable-next-line
  }, [isAuthenticated, token, isLoadingPaths]);

  return (
    <Switch>
      <Route
        exact
        path={[LOGIN_VERIFY, LOGINV2_VERIFY]}
        component={VerifyView}
      />
      <Route
        exact
        path={[LOGIN_PROFILE, LOGINV2_PROFILE]}
        component={ProfileView}
      />

      <Route
        exact
        path={[LOGIN_START, LOGINV2_START]}
        render={() => (
          <LoginView
            isLoading={isRetrievingUser || isLoadingPaths || isLoading}
          />
        )}
      />

      <Route
        exact
        path={[LOGIN_MANUAL, LOGIN_MANUALV2]}
        component={ManualSignIn}
      />

      <Route path={[LOGIN, LOGINV2]} component={PageLoading} />
    </Switch>
  );
};

export default LoginRouter;
