import React from "react";
import "./TipCards.css";
import T from "i18n-react";
import { TipType } from "../../Payment/Tips/TipComponent";
interface ITipCards {
  selected?: boolean;
  title: string;
  onClick: () => void;
  tipType?: TipType;
}
function TipCards({ selected, onClick, title, tipType }: ITipCards) {
  return (
    <div
      data-component-name={tipType}
      className={` ${selected ? "SelectedTipCard" : "TipCards"}`}
      onClick={onClick}
    >
      <T.p
        text={{
          key:
            tipType === TipType.PERCENTAGE ? `${title}%` : `TipCards.${title}`,
        }}
      />
    </div>
  );
}

export default TipCards;
