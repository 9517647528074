import { createMuiTheme } from "@material-ui/core";

import { IThemeOptions } from './IThemeOptions';

const primary = "#FFDD02";
const error = "#F52D5C";
const bg1 = "#F3F4F9";
const bg2 = "#757575";
const paper =  "#FFFFFF";
const infoText = "#AAB6D3";

const themeOptions: IThemeOptions = {
    overrides: {

        MuiButton: {
            root: {
                borderRadius: '8px',
                fontSize: '14px',
                textTransform: 'capitalize',
                fontFamily: "-apple-system, BlinkMacSystemFont, 'Open Sans', 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif",
                fontWeight: 600,
                padding: "0.4rem 2rem",
            },
            contained: {
                boxShadow: "none",
                '&:hover': {
                    boxShadow: "none !important",
                },
                '&:active': {
                    boxShadow: "none !important",
                },
            },
        },

        MuiPaper: {
            rounded: {
                borderRadius: "16px",
            },
            elevation1: {
                boxShadow: "none",
                borderRadius: "16px",
            }
        },
        MuiBackdrop: {
            root: {
                backdropFilter: "blur(5px)"
            }
        },
        MuiGrid: {
            root: {
                "& .formErrors": {
                    fontSize: "0.85rem",
                    position: "absolute"
                }
            }
        },
        MuiInput: {
          underline: {
            "&::before": {
              borderBottom: "none !important"
            }
          }
        },
        MuiSelect: {
          selectMenu: {
            padding: "1rem",
            borderRadius: '8px',
            backgroundColor: bg1
          },
          root: {
            
          }
        },
        MuiDrawer: {
            paperAnchorDockedLeft: {
                borderRight: "none",
            },
        },

        MuiOutlinedInput: {
            root: {
                borderRadius: '8px',
                fontSize: '0.9rem',

                padding: "0",

                border: `1px solid ${bg1}`,
                transition: "0.2s ease-in-out",
                backgroundColor: bg1,
                "&.Mui-focused": {
                    backgroundColor: paper,
                    border: `1px solid ${primary}`,
                    boxShadow: `0px 0px 3px ${primary}99`
                }
            },
            notchedOutline: {
                border: "none"
            },
            input: {
                height: "calc(1rem - 2px)",
                padding: "1rem",
                "&::placeholder": {
                    color: infoText,
                    opacity: "1 !important"
                },
                "&.MuiOutlinedInput-inputMultiline": {
                    height: "auto !important"
                }
            },
            error: {
                border: `1px solid ${error}`
            }
        },
        MuiInputBase: {
            root: {
                "&.MuiAutocomplete-inputRoot": {
                    padding: "0.25rem !important"
                }
            }
        },
        MuiInputLabel: {
          root: {
            transform: "none",
            position: "relative",
            paddingBottom: "10px",
            top: "0",
            left: "6px",
            color: bg2,
          },
            formControl: {
                transform: "none",
                position: "relative",
                paddingBottom: "10px",
                top: "0",
                left: "6px",
                color: bg2,
                "&.Mui-focused": {
                    color: bg2
                }
            },
            outlined: {
                transform: "none",
            },
            shrink: {
                transform: "none !important"
            }
        }

    }, 
    palette: {
        primary: {
            main: primary,
            contrastText: "#212121",
        },
        secondary: {
            main: "#4F3CC9",
            contrastText: "#FFFFFF",
        },
        background: {
            default: "#F3F4F9",
            paper: "#ffffff",
        },
        text: {
            primary: '#2C2C2C',
            secondary: '#737FA4',
        },

    },
    typography: {
        fontFamily: "-apple-system, BlinkMacSystemFont, 'Open Sans', 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif",
    },
    custom: {
        color: {
            bgGreyMid: "#E8EAF4"
        }
    }
};


const mainTheme = createMuiTheme(themeOptions);

export default mainTheme;
