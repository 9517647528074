import React from "react";
import Styles from "./orderDetails.module.scss";
import T from "i18n-react";

interface IOrderTitleProps {
  titleKey: string;
  valueText?: string;
  valueComponentName?: string;
  isLarge?: boolean;
  valueComponment?: JSX.Element;
}

export function OrderTitle({
  titleKey,
  valueText,
  valueComponentName,
  valueComponment,
  isLarge = false,
}: IOrderTitleProps) {
  return (
    <div className={Styles.OrderTitle}>
      <T.p text={{ key: titleKey }} />
      {valueComponment ? (
        valueComponment
      ) : (
        <p
          data-component-name={valueComponentName}
          className={`Text1 ${isLarge ? Styles.Large : Styles.Small}`}
        >
          {valueText}
        </p>
      )}
    </div>
  );
}
