import React from "react";

import { ReactComponent as EditSVG } from '../../../imges/EditSquare.svg';
interface IEditButton {
    onClick: () => void;
}

function EditButton({
    onClick
}: IEditButton) {
    return <div onClick={onClick}>
        <EditSVG width="20px" height="20px" />
    </div>;
}

export default React.memo(EditButton);