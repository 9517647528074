import { useDispatch, useSelector } from "react-redux";
import React from "react";
import T from "i18n-react";
import { Link } from "react-router-dom";
import VenueCard from "../../Widgets/VenueCard";
import { resetCart } from "../../../redux/actions";
import { CARDIFF_CASTLE } from "../../../constants";

export function GroupPageList({ filters, filtersCount }) {
  const groupVenueList = useSelector(
    (state: any) => state.Group.group_venues
  ) as any[];
  const GroupName = useSelector((state: any) => state.GroupName);
  if (!groupVenueList || groupVenueList.length === 0) {
    return (
      <T.p
        id="EmptyVenuesListText"
        className="text-center mt-4"
        text={{ key: `Errors.EmptyVenuesListText` }}
      />
    );
  }
  return (
    <>
      {groupVenueList.map((groupVenue) => {
        if (!isDisplayed(filters, filtersCount, groupVenue)) {
          return null;
        }
        return <GroupCard venue={groupVenue} groupName={GroupName} />;
      })}
    </>
  );
}

function isDisplayed(filters, filterCount, venue) {
  if (filterCount === 0) {
    return true;
  }
  const { TableService, Delivery, Collection, PickupNow } = filters;
  if (TableService) {
    if (venue.venue_table_enabled) {
      return true;
    }
  }
  if (Delivery) {
    if (venue.venue_delivery_enabled) {
      return true;
    }
  }
  if (Collection) {
    if (venue.venue_pickup_enabled) {
      return true;
    }
  }
  if (PickupNow) {
    if (venue.venue_pickup_now_enabled) {
      return true;
    }
  }
  return false;
}
interface IGroupCard {
  venue: groupVenueType;
  groupName: string;
}
function GroupCard({ venue, groupName }: IGroupCard) {
  const dispatch = useDispatch();
  return (
    <div key={venue.venue_id}>
      <Link
        onClick={() => {
          dispatch(resetCart());
        }}
        to={{
          pathname: getVenuePath(venue, groupName),
          state: { venueId: venue.venue_id, showBackButton: true },
        }}
      >
        <VenueCard key={`v-card-${venue.venue_id}`} venueInfo={venue} />
      </Link>{" "}
    </div>
  );
}

function getVenuePath(venue: groupVenueType, groupName: string) {
  if (groupName === CARDIFF_CASTLE) {
    return `/g/${this.props.GroupName}/${venue.venue_nickname}`;
  }
  return `/v/${venue.venue_nickname}`;
}
