import React from "react";

import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  FacebookIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

const SocialMediaShare = () => (
  <div
    style={{
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <div className="mr-2 ml-2" style={{ cursor: "pointer" }}>
      <TwitterShareButton
        url={window.location.origin + window.location.pathname}
        title="I'm grabbing a bite to eat thanks to @YoelloPay - check them out!"
        hashtags={[""]}
        related={["YoelloPay"]}
      >
        <TwitterIcon size={32} round={true} />
      </TwitterShareButton>
    </div>
    <div className="mr-2 ml-2" style={{ cursor: "pointer" }}>
      <TelegramShareButton
        url={window.location.origin + window.location.pathname}
        title="Yoello"
      >
        <TelegramIcon size={32} round={true} />
      </TelegramShareButton>
    </div>
    <div className="mr-2 ml-2" style={{ cursor: "pointer" }}>
      <FacebookShareButton
        url={window.location.origin + window.location.pathname}
        title="Yoello"
        quote="Quick, Easy and COVID Safe!"
        hashtag=""
      >
        <FacebookIcon size={32} round={true} />
      </FacebookShareButton>
    </div>
    <div className="mr-2 ml-2" style={{ cursor: "pointer" }}>
      <WhatsappShareButton
        url={window.location.origin + window.location.pathname}
        title="Yoello Save your Local"
      >
        <WhatsappIcon size={32} round={true} />
      </WhatsappShareButton>
    </div>
  </div>
);

export default SocialMediaShare;
