import React, { useEffect, useContext } from "react";
import ChooseOrderTypeButton from "../../../Widgets/ChooseOrderTypeButton";
import OrderTypeHeader from "../../../Widgets/OrderTypeHeader/OrderTypeHeader";
import {
  changeDeliveryDetails,
  changeOrderType,
  selectTime,
  setIsDeliveryDrawerOpen,
} from "../../reducer/PaymentActions";
import { ReactComponent as BicycleSVG } from "../../../../imges/Bicycle.svg";
import { YellowColorCodeForOrderTypeSvgs } from "../OrderTypesComponent";
import { SelectedOrderType } from "../../types.d";
import { useSelector } from "react-redux";
import { PaymentContext } from "../../PaymentStore";
import { shouldResetOrderType } from "../../../Utils/ChekTime";
import { getDeliveryAddress } from "../../../../utils/getdeliveryAddress";

export function DeliveryOrderType() {
  const Venue = useSelector((state: any) => state.Venue);
  const OrderType = useSelector((state: any) => state.OrderType);
  const {
    dispatch,
    selectedOrderType,
    selectedTime,
    deliveryAddress,
  } = useContext(PaymentContext);

  useEffect(() => {
    const address = getDeliveryAddress();
    if (
      !OrderType ||
      shouldResetOrderType(OrderType?.settingTime) ||
      !address?.address ||
      !address?.postcode
    ) {
      dispatch(selectTime(null));
      setTimeout(() => {
        dispatch(changeOrderType(SelectedOrderType.DELIVERY));
        dispatch(setIsDeliveryDrawerOpen(true));
      }, 100);
    } else if (OrderType && address?.address && address?.postcode) {
      const { time } = OrderType?.orderType;
      dispatch(selectTime(time));
      dispatch(changeDeliveryDetails(address));
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <OrderTypeHeader
        title="Payment.Delivery"
        custom={Venue.delivery_label}
        svgImage={<BicycleSVG fill={YellowColorCodeForOrderTypeSvgs} />}
        selected={true}
      />
      <ChooseOrderTypeButton
        key={"delivery"}
        title="CompleteDeliveryInfo"
        onClick={() => {
          dispatch(changeOrderType(SelectedOrderType.DELIVERY));
          dispatch(setIsDeliveryDrawerOpen(true));
        }}
        selected={
          selectedOrderType === SelectedOrderType.DELIVERY &&
          deliveryAddress &&
          selectedTime !== null
        }
        EditClicked={() => dispatch(setIsDeliveryDrawerOpen(true))}
        DeliveryTime={
          selectedOrderType === SelectedOrderType.DELIVERY &&
          selectedTime !== null
            ? selectedTime
            : null
        }
        DeliveryAddress={
          selectedOrderType === SelectedOrderType.DELIVERY && deliveryAddress
            ? deliveryAddress
            : null
        }
        dataComponentName="Delivery"
      />
    </div>
  );
}
