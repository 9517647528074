import { ConsumerUpdatePreferences } from "../Api.js";
import { User } from "../yoello-lib/modules/auth/Auth0.js";

export async function updateUserLanguagePreference(
  selectedLanguageKey: string,
  user: User,
  retrieveToken: () => Promise<string>,
  setUser: (user: User) => void
) {
  const token = await retrieveToken();
  /**If no token just return */
  if (!token) return;
  await ConsumerUpdatePreferences(
    {
      language: selectedLanguageKey,
    },
    token
  ).then((res) => {
    const newUserData = user;
    // @ts-ignore
    newUserData.consumer_preferences.language = selectedLanguageKey;
    setUser(newUserData);
  });
}
