import { useContext } from "react";
import {
  setIsPaymentProcessingDrawerOpen,
  setIsPaymentDrawerOpen,
} from "../../../reducer/PaymentActions";
import { PaymentContext } from "../../../PaymentStore";
import useConfirmSquareOrder from "./useConfirmSquareOrder";

const useConfirmDigitalWalletOrder = () => {
  const { dispatch } = useContext(PaymentContext);
  const { confirmSquareOrder } = useConfirmSquareOrder();
  const confirmDigitalWalletOrder = (card_nonce: string) => {
    dispatch(setIsPaymentDrawerOpen(false));
    dispatch(setIsPaymentProcessingDrawerOpen(true));
    confirmSquareOrder(null, undefined, card_nonce);
  };
  return {
    confirmDigitalWalletOrder,
  };
};

export default useConfirmDigitalWalletOrder;
