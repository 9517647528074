import React, { useContext } from "react";
import { SwipeableDrawer } from "@material-ui/core";

import { PaymentContext } from "../PaymentStore";
import UnmountDrawerWrapper from "../../Drawer/UnmountDrawerWrapper";
import CollectTimePicker from "../../Widgets/CollectTimePicker/CollectTimePicker";

import { SelectedOrderType } from "../types.d";
import {
  setIsCollectionDrawerOpen,
  selectTime,
} from "../reducer/PaymentActions";
import T from "i18n-react";

/**
 * Drawer for handling the delivery or collection time
 * @param param0
 */
const TimeSelectionComponent = () => {
  const {
    selectedTime,
    deliveryTimes,
    selectedOrderType,
    isCollectionDrawerOpen,
    dispatch,
  } = useContext(PaymentContext);
  function onClose() {
    dispatch(setIsCollectionDrawerOpen(false));
  }
  return (
    <SwipeableDrawer
      anchor={"bottom"}
      open={isCollectionDrawerOpen}
      onClose={onClose}
      onOpen={() => null}
      transitionDuration={350}
      disableSwipeToOpen={true}
      disableDiscovery={true}
      id="deliveryTimeDrower"
    >
      <UnmountDrawerWrapper closeDrawer={onClose}>
        <CollectTimePicker
          title={
            selectedOrderType === SelectedOrderType.DELIVERY
              ? (T.translate("Payment.DeliveryTime") as string)
              : (T.translate("Payment.CollectionTime") as string)
          }
          timeTitle={
            selectedOrderType === SelectedOrderType.DELIVERY
              ? (T.translate("Payment.DeliveryTime") as string)
              : (T.translate("Payment.PickupTime") as string)
          }
          onClick={() => dispatch(setIsCollectionDrawerOpen(false))}
          handleTimeSelect={(time) => dispatch(selectTime(time))}
          selectedTime={selectedTime}
          deliveryTimes={deliveryTimes}
          selectedOrderType={selectedOrderType}
          onClose={() => dispatch(setIsCollectionDrawerOpen(false))}
          buttonText={
            selectedOrderType === SelectedOrderType.DELIVERY
              ? "Confirm"
              : "ConfirmPickup"
          }
        />
      </UnmountDrawerWrapper>
    </SwipeableDrawer>
  );
};

export default TimeSelectionComponent;
