import { useEffect, useRef, useState } from "react";
import Axios from "axios";

import { IOrderBody } from "../../typings/IPayment";
import { usePaymentState } from "../../Components/Payment/PaymentStore";
import { generateSquareEndpoint } from "./generateEndpoints";
import useOrderBody from "../../Components/Payment/PaymentHooks/useOrderBody";
import { setIsPaymentReady } from "../../Components/Payment/reducer/PaymentActions";

const useSquareCalculateOrder = (orderCalculateFunction: any) => {
  const {
    selectedTime,
    isUsingPaymentRequest,
    cartItemTaxAmount,
    serviceTaxAmount,
    message,
    selectedOrderType,
    deliveryTaxAmount,
    deliveryPrice,
    tipTotal,
    areaName,
    selectedTable,
    totalPrice,
    deliveryAddress,
    deliveryTimes,
    retrieveToken,
    isAuthenticated,
    dispatch,
  } = usePaymentState();

  const url = generateSquareEndpoint("orders/calculate");
  const [orderBody, setOrderBody] = useState<IOrderBody>();
  const [isCalculatingOrder, setIsCalculatingOrder] = useState(false);
  const { generateOrderBody } = useOrderBody();
  /**Issues with the orderBody constantly updating causing it to keep loading, we only want to calculate once */
  const isCalled = useRef(false);

  useEffect(() => {
    const order = generateOrderBody(
      selectedTime,
      isUsingPaymentRequest,
      cartItemTaxAmount,
      serviceTaxAmount,
      deliveryTaxAmount,
      message,
      selectedOrderType,
      deliveryPrice,
      tipTotal,
      areaName,
      selectedTable,
      totalPrice,
      deliveryAddress,
      deliveryTimes
    );
    setOrderBody(order);
    //eslint-disable-next-line
  }, [
    selectedTime,
    isUsingPaymentRequest,
    cartItemTaxAmount,
    serviceTaxAmount,
    message,
    selectedOrderType,
    deliveryPrice,
    tipTotal,
    areaName,
    selectedTable,
    totalPrice,
    deliveryAddress,
  ]);

  useEffect(() => {}, [isCalculatingOrder]);
  useEffect(() => {
    if (isAuthenticated && orderBody) {
      calculateOrder().then(orderCalculateFunction);
    }
    // eslint-disable-next-line
  }, [isAuthenticated, orderBody]);
  const calculateOrder = async () => {
    if (isCalculatingOrder || isCalled.current) return;
    setIsCalculatingOrder(true);
    _isPaymentReady(false);
    try {
      console.log({ orderBody });
      const userToken = await retrieveToken();
      const response = await Axios.post(url, orderBody, {
        headers: { Authorization: `Bearer ${userToken}` },
      });
      setIsCalculatingOrder(false);
      isCalled.current = true;
      return { order: response.data.data };
    } catch (error) {
      setIsCalculatingOrder(false);
      return { error };
    } finally {
      _isPaymentReady(true);
    }
  };
  function _isPaymentReady(isLoading: boolean) {
    dispatch(setIsPaymentReady(isLoading));
  }
  return {
    calculateOrder,
    isCalculatingOrder,
    orderBody,
  };
};

export default useSquareCalculateOrder;
