import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { formatPhoneNumber } from "../../utils/PhoneNumberFormatting";

import FormElement from "../../yoello-lib/modules/dynamicForms/FormElement";
import ICommonFormProps from "../../yoello-lib/modules/dynamicForms/interfaces/ICommonFormProps";
import { generateRegister } from "../../yoello-lib/modules/dynamicForms/utils";

import "./phone-input-style.scss";

export interface IPhoneInput extends ICommonFormProps {
  Component: React.FunctionComponent<any>;
  label: string;
  inputOptions?: {
    type: string;
  };
}

const useStyles = makeStyles({
  root: {},
  label: {
    width: "100%",
  },
  labelText: {
    color: "#626262",
    fontSize: "0.875rem",
    lineHeight: "2rem",
  },
  input: {
    border: "none",
    outline: "unset",
    width: "100%",
    position: "relative",
    "& .MuiInput-root": {
      border: "1px solid #F9F9F9",
      backgroundColor: "#F9F9F9",
      transition: "0.25s ease-in-out",
      borderRadius: "0.5rem",
      width: "100%",
      display: "block",
      boxSizing: "border-box",
      "&.Mui-error": {
        border: "1px solid #F44336",
      },
      "&.Mui-focused": {
        backgroundColor: "#FFFFFF",
        border: "1px solid #FFDD02",
      },
      "& input": {
        padding: "1.6rem",
        boxSizing: "border-box",
      },
    },
  },
});

const TextInput = ({ setValue, keyName, label, ...props }: IPhoneInput) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState<string>(props.value);

  const handleChange = (value: string) => {
    if (props.errors) {
      props.triggerValidation(keyName);
    }
    setInputValue(value);
  };

  useEffect(() => {
    setValue(keyName, inputValue);
  }, [inputValue, setValue, keyName]);
  function formatNumber() {
    const countryCode = inputValue?.split(" ");
    const phone = inputValue?.replace(countryCode[0], "");
    const formattedPhoneNumber = formatPhoneNumber(countryCode[0], phone);
    setInputValue(formattedPhoneNumber);
  }
  return (
    <div className={classes.root} onBlur={formatNumber}>
      <label className={classes.label}>
        <span className={classes.labelText}>{label}</span>
      </label>
      <FormElement {...props} keyName={keyName}>
        <PhoneInput
          copyNumbersOnly
          enableLongNumbers
          // @ts-ignore
          {...generateRegister({ ...props, label, keyName, setValue })}
          showDropdown={true}
          value={inputValue ?? ""}
          disableSearchIcon={true}
          onChange={handleChange}
          name={keyName}
          country={"gb"}
          className="PhoneNumberInput"
        />
      </FormElement>
    </div>
  );
};

export default TextInput;
