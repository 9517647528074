import React, { useContext } from "react";
import Styles from "../styles.module.scss";
import T from "i18n-react";
import { useSelector } from "react-redux";

import { PaymentContext } from "../PaymentStore";
import { CARDIFF_CASTLE } from "../../../constants";
import { DineInOrderType } from "./OrderTypes/DineIn";
import { CollectionsType } from "./OrderTypes/Collections";
import { DeliveryOrderType } from "./OrderTypes/Delivery";
import { SelectedOrderType, BackendTimeSlots } from "../types.d";
import { Pickup } from "./OrderTypes/Pickup";

export const YellowColorCodeForOrderTypeSvgs = "#FFDD02";

const OrderTypesComponent = ({ isVenueOpen }) => {
  const { deliveryTimes, selectedOrderType } = useContext(PaymentContext);
  const Venue = useSelector((state: any) => state.Venue);
  const Group = useSelector((state: any) => state.Group);
  function renderTypes() {
    if (
      orderTypeRenderChecks(
        selectedOrderType,
        isVenueOpen,
        Venue,
        Group,
        deliveryTimes
      )
    ) {
      switch (selectedOrderType) {
        case SelectedOrderType.TABLE:
          return <DineInOrderType />;
        case SelectedOrderType.DELIVERY:
          return <DeliveryOrderType />;
        case SelectedOrderType.CLICK:
          return <CollectionsType />;
        case SelectedOrderType.COLLECTION_POINT:
          return <Pickup />;
      }
    }
    function generateMenuTypeNames(selectedOrderType: SelectedOrderType) {
      switch (selectedOrderType) {
        case SelectedOrderType.CLICK:
          return Venue.collection_label || T.translate("Venue.Collection");
        case SelectedOrderType.DELIVERY:
          return Venue.delivery_label || T.translate("Venue.Delivery");
        case SelectedOrderType.TABLE:
          return Venue.dine_in_label || T.translate("Venue.TableService");
        case SelectedOrderType.COLLECTION_POINT:
          return (
            Venue.collection_point_pickup_lable ||
            T.translate("Venue.pickupNow")
          );
      }
    }
    return (
      <div className={Styles.NoOrderType}>
        <T.p
          text={{
            key: selectedOrderType
              ? "Payment.NoAvailable"
              : "Payment.NoOrderType",
            orderType: generateMenuTypeNames(selectedOrderType),
          }}
        />
      </div>
    );
  }
  return (
    <div className={Styles.OrderTypeSection}>
      <div>
        <T.p
          className={Styles.ChooseMethodText}
          text={{ key: `Payment.OrderType` }}
        />
      </div>
      {renderTypes()}
    </div>
  );
};

function orderTypeRenderChecks(
  OrderType: SelectedOrderType,
  isVenueOpen: boolean,
  Venue: any,
  Group: any,
  deliveryTimes: BackendTimeSlots[]
) {
  switch (OrderType) {
    case SelectedOrderType.CLICK:
      return (
        isVenueOpen &&
        Venue?.pickup_enabled &&
        deliveryTimes.length > 0 &&
        SelectedOrderType.CLICK === OrderType &&
        Group?.group_nickname !== CARDIFF_CASTLE
      );

    case SelectedOrderType.DELIVERY:
      return (
        isVenueOpen &&
        Venue.delivery_enabled &&
        deliveryTimes.length > 0 &&
        SelectedOrderType.DELIVERY === OrderType &&
        Group?.group_nickname !== CARDIFF_CASTLE
      );

    case SelectedOrderType.TABLE:
      return (
        isVenueOpen &&
        Venue?.table_enabled &&
        Venue?.table_layout !== null &&
        (SelectedOrderType.TABLE === OrderType ||
          Group?.group_nickname === CARDIFF_CASTLE)
      );
    case SelectedOrderType.COLLECTION_POINT:
      return isVenueOpen && SelectedOrderType.COLLECTION_POINT === OrderType;
    default:
      return false;
  }
}

export default React.memo(OrderTypesComponent);
