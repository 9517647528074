export function setVenue(data: any) {
    console.log("Setting venue");
    return {
        type: 'SetVenueInfo',
        data
    };
}
export const SetSelectedMenuType = "SetSelectedMenuType";

export function setSelectedMenuType(data: any) {
    return {
        type: SetSelectedMenuType,
        data
    };
}


export const SetGroupInfo = "SetGroupInfo";

export function setGroupInfo(data: any) {
    return {
        type: SetGroupInfo,
        data
    };
}

export const SetGroupName = "SetGroupName";

export function setGroupName(name: string) {
    return {
        type: SetGroupName,
        data: name
    };
}

export const ResetCart = "resetCart";


export function resetCart() {
    return {
        type: ResetCart
    };
}

export const SetMenuItems = "setMenuItems";

export function setMenuItems(data: any) {
    return {
        type: SetMenuItems,
        data
    };
}

export const SetDietaryTags = "SetDietaryTags";

export function setDietaryTags(data: any) {
    return {
        type: SetDietaryTags,
        data
    };
}

export const SetPreferences = "SetPreferences";

export function setPreferences(data: any) {
    return {
        type: SetPreferences,
        data
    };
}

export const SetExtras = "SetExtras";

export function setExtras(data: any) {
    return {
        type: SetExtras,
        data
    };
}

export const SetMenuItemsAsExtra = "SetMenuItemsAsExtra";

export function setMenuItemsAsExtra(data: any) {
    return {
        type: SetMenuItemsAsExtra,
        data
    };
}

export const SetTaxes = "SetTaxes";

export function setTaxItems(data: any) {
    return {
        type: SetTaxes,
        data
    };
}
export const SET_MENU_EXPIRES = "SET_MENU_EXPIRES";

export function setExpiresMenuTime() {
    return {
        type: SET_MENU_EXPIRES
    };
}

export const SET_VENUE_OPEN_NOW = "SET_VENUE_OPEN_NOW";
export function setVenueOpenNow(data: any) {
    return {
        type: SET_VENUE_OPEN_NOW,
        data
    };
}



export const SET_AVAILABLE_MENUS = "SET_AVAILABLE_MENUS";
export function setAvailableMenus(data: any) {
    return {
        type: SET_AVAILABLE_MENUS,
        data
    }
}
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";

export function addNotification(payload: any) {
    console.log({ payload });
    return {
        type: ADD_NOTIFICATION,
        payload
    };
}