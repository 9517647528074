import { IAPIDefinitions } from "../APIDefs";
import { setUsersVenues, setVenueData } from "../../redux/actions/venue.actions";
import { Dispatch } from "redux";
import { setCurrencies, setCurrency, setLanguage, setLanguages } from "../../redux/actions/localization";
import { getServiceEndpoints, IServiceURLs } from "../../environments/services";
import { addTimezones } from "../../redux/actions/timezone.actions";
import { setOutstandingBody } from "../../redux/actions/oustanding.actions";
import { ITask } from "../../interfaces/ITask";

/**Defintion of available Merchant Dashboard APIs */
export enum APIMerchantDashboard {
    venues = "venues",
    venuesv2 = "venuesv2",
    venueTag = "venueTag",
    venueSettings = "venueSettings",
    menuItems = "menuItems",
    menuItemCategories = "menuItemCategories",
    menuItemSubcategories = "menuItemSubcategories",
    menuItemSubcategoriesSort = "menuItemSubcategoriesSort",
    vatRates = "vatRates",
    onBoard = "onBoard",
    leads = "leads",
    groups = "groups",
    orders = "orders",
    order = "order",
    void = "void",
    stock = "stock",
    stockByArea = "stockByArea",
    orderReport = "orderReport",
    totalsReport = "totalsReport",
    transactionFeeReport = "transactionFeeReport",
    extraGroupsToMenuItems = "extraGroupsToMenuItems",
    preferencesToMenuItems = "preferencesToMenuItems",
    menuItemsSort = "menuItemsSort",
    extraGroups = "extraGroups",
    itemInGroup = "itemInGroup",
    menuItemToExtraGroup = "menuItemToExtraGroup",
    preferences = "preferences",
    stripeAccount = "stripeAccount",
    stripeLogin = "stripeLogin",
    stripeOnboarding = "stripeOnboarding",
    dietaryTags = "dietaryTags",
    tradingTimes = "tradingTimes",
    venueNameSearch = 'venueNameSearch',
    details = "details",
    venue = 'venue',
    locations = "locations",
    merchant = 'merchant',
    currencies = 'currencies',
    getLanguages = 'getLanguages',
    accept = 'accept',
    finish = 'finish',
    decline = 'decline',
    analyticsTopFiveSales = 'analyticsTopFiveSales',
    analyticsTotalsList = 'analyticsTotalsList',
    analyticsRushData = 'analyticsRushData',
    outstanding = 'outstanding',
    eposSyncStatus = 'eposSyncStatus',
    timeZones = "timeZones",
    orderLimits = "orderLimits"
}
/**Config of APIs for merchant dashboard  */
export const merchantDashboardAPIDefs: IAPIDefinitions = {};

function getFullURL(isV2?: boolean) {
    const stage = process.env.REACT_APP_ENVIRONMENT;
    const overwriteHost = process.env.REACT_APP_SERVICE_ENDPOINT;
    if (!stage) {
        return;
    }
    let services = getServiceEndpoints(stage, overwriteHost, isV2);
    return services;
}

function v2EndPoint(endPointType: keyof IServiceURLs, isNotVenue: boolean = false) {
    const url = getFullURL(true);
    if (url) {
        const newURL = url[endPointType];
        return `${newURL}/${isNotVenue ? "" : "venue/"}`;
    }
    return "";
}

const reportURL = v2EndPoint('dashboardReportsAPI');
const venueURL = v2EndPoint('dashboardVenueAPI');
const menuURL = v2EndPoint('dashboardMenusAPI');
const orderURL = v2EndPoint('dashboardOrdersAPI');
const financeURL = v2EndPoint('dashboardFinanceAPI');

merchantDashboardAPIDefs[APIMerchantDashboard.stock] = {
    baseURL: reportURL,
    defaultURL: 'stock/',
    GET: {},
};
merchantDashboardAPIDefs[APIMerchantDashboard.orderReport] = {
    baseURL: reportURL,
    defaultURL: `order/`,
    GET: {},
};
merchantDashboardAPIDefs[APIMerchantDashboard.totalsReport] = {
    baseURL: reportURL,
    defaultURL: `totals/`,
    GET: {},
};
merchantDashboardAPIDefs[APIMerchantDashboard.stockByArea] = {
    baseURL: reportURL,
    defaultURL: `stockByArea/`,
    GET: {},
};
merchantDashboardAPIDefs[APIMerchantDashboard.transactionFeeReport] = {
    baseURL: reportURL,
    defaultURL: `transaction_fee/`,
    GET: {},
};

merchantDashboardAPIDefs[APIMerchantDashboard.venues] =
{
    baseURL: v2EndPoint('dashboardVenueAPI', true),
    defaultURL: "venues",
    dataStorage: {
        defaultDataKey: "venueData",
        selectorKey: (selectorObject) => {
            if (!selectorObject!?.venue_id) {
                return "";
            }
            return "venue." + selectorObject!.venue_id! + ".venueData";
        },
        dispatchFunction: (dispatch: Dispatch<any>, results: any, args) => {
            if (Array.isArray(results)) {
                dispatch(setUsersVenues(results));
            } else {
                dispatch(setVenueData('venueData', results.id, results));
            }
        },
    },
    GET: {},
    POST: {},
    PUT: {}
};
merchantDashboardAPIDefs[APIMerchantDashboard.venuesv2] =
{
    baseURL: v2EndPoint('dashboardVenueAPI'),
    defaultURL: "",
    dataStorage: {
        defaultDataKey: "venueData",
        selectorKey: (selectorObject) => {
            if (!selectorObject!?.venue_id) {
                return "";
            }
            return "venue." + selectorObject!.venue_id! + ".venueData";
        },
        dispatchFunction: (dispatch: Dispatch<any>, results: any, args) => {
            if (Array.isArray(results)) {
                dispatch(setUsersVenues(results));
            } else {
                dispatch(setVenueData('venueData', results.id, results));
            }
        },
    },
    GET: {},
    POST: {},
    PUT: {}
};
merchantDashboardAPIDefs[APIMerchantDashboard.venueTag] =
{
    baseURL: venueURL,
    defaultURL: "tag",
    dataStorage: {
        defaultDataKey: "venue-tag",
        selectorKey: () => { return ""; }
    },
    GET: {},
    POST: {},
    DELETE: {}
};


merchantDashboardAPIDefs[APIMerchantDashboard.menuItemSubcategories] =
{
    baseURL: menuURL,
    defaultURL: "menu-item-subcategories/",
    dataStorage: {
        defaultDataKey: "menuSubCategories",
        selectorKey: () => { return ""; }
    },
    GET: {},
    POST: {},
    PUT: {},
    DELETE: {},
};

merchantDashboardAPIDefs[APIMerchantDashboard.menuItemSubcategoriesSort] =
{
    baseURL: menuURL,
    defaultURL: "menu-item-subcategories/sort",
    dataStorage: {
        defaultDataKey: "menuSubCategories",
        selectorKey: () => { return ""; }
    },
    PUT: {},
    GET: {},
};

merchantDashboardAPIDefs[APIMerchantDashboard.menuItemCategories] =
{
    baseURL: v2EndPoint('dashboardMenusAPI', true),
    defaultURL: "menu-item-categories",
    dataStorage: {
        defaultDataKey: "menuCategories",
        selectorKey: () => { return ""; }
    },
    GET: {}
};

merchantDashboardAPIDefs[APIMerchantDashboard.menuItems] =
{
    baseURL: menuURL,
    defaultURL: "menu-items/",
    dataStorage: {
        defaultDataKey: "menuItems",
        selectorKey: () => { return ""; }
    },
    GET: {},
    POST: {},
    PUT: {},
    DELETE: {},
};

merchantDashboardAPIDefs[APIMerchantDashboard.vatRates] =
{
    baseURL: v2EndPoint('dashboardVenueAPI', true),
    defaultURL: "vat-rates",
    dataStorage: {
        defaultDataKey: "VATs",
        selectorKey: () => { return ""; }
    },
    GET: {}
};

merchantDashboardAPIDefs[APIMerchantDashboard.venueSettings] =
{
    baseURL: venueURL,
    defaultURL: 'settings/',
    dataStorage: {
        defaultDataKey: "venueSettings",
        selectorKey: (selectorObject): string => {
            if (!selectorObject!?.venue_id) {
                return "";
            }
            return "venue." + selectorObject!.venue_id! + ".venueSettings";
        },
        dispatchFunction: (dispatch: Dispatch<any>, results: any, args) => {
            console.log("Dispatching settings", results);
            if (results?.language_iso) {
                dispatch(setLanguage(results.language_iso));
            }
            if (results?.currency_id) {
                dispatch(setCurrency(results.currency_id));
            }
            dispatch(setVenueData('venueSettings', results.venue_id, results));
        }
    },
    GET: {},
    PUT:
    {
        dataKey: "venueSettingsPut"
    }
};
merchantDashboardAPIDefs[APIMerchantDashboard.leads] = {
    baseURL: venueURL,
    defaultURL: "leads",
    dataStorage: {
        defaultDataKey: "leads",
        selectorKey: () => { return ""; }
    },
    POST: {},
    PUT: {}
};

merchantDashboardAPIDefs[APIMerchantDashboard.orders] = {
    baseURL: orderURL,
    defaultURL: "orders/",
    GET: {},
};
merchantDashboardAPIDefs[APIMerchantDashboard.order] = {
    baseURL: orderURL,
    defaultURL: "order/",
    GET: {},
};

merchantDashboardAPIDefs[APIMerchantDashboard.void] = {
    baseURL: orderURL,
    defaultURL: `void/`,
    PUT: {}
};

merchantDashboardAPIDefs[APIMerchantDashboard.extraGroupsToMenuItems] = {
    baseURL: menuURL,
    defaultURL: "extra-group-to-menu-item/",
    dataStorage: {
        defaultDataKey: "",
        selectorKey: () => { return ""; }
    },
    GET: {},
    POST: {},
    DELETE: {},
    PUT: {}
};

merchantDashboardAPIDefs[APIMerchantDashboard.preferencesToMenuItems] = {
    baseURL: menuURL,
    defaultURL: "menu-items-to-preferences/",
    dataStorage: {
        defaultDataKey: "",
        selectorKey: () => { return ""; }
    },
    POST: {},
    DELETE: {}
};

merchantDashboardAPIDefs[APIMerchantDashboard.menuItemsSort] =
{
    baseURL: menuURL,
    defaultURL: "menu-items/sort",
    dataStorage: {
        defaultDataKey: "",
        selectorKey: () => { return ""; }
    },
    PUT: {}
};

merchantDashboardAPIDefs[APIMerchantDashboard.extraGroups] = {
    baseURL: menuURL,
    defaultURL: "menu-items-extras-groups/",
    dataStorage: {
        defaultDataKey: "extras",
        selectorKey: () => { return ""; }
    },
    GET: {},
    POST: {},
    PUT: {},
    DELETE: {}
};

merchantDashboardAPIDefs[APIMerchantDashboard.itemInGroup] = {
    baseURL: menuURL,
    defaultURL: "menu-items-in-extras-group/",
    dataStorage: {
        defaultDataKey: "itemInGroup",
        selectorKey: () => { return ""; }
    },
    GET: {},
    POST: {},
    PUT: {}
};

merchantDashboardAPIDefs[APIMerchantDashboard.menuItemToExtraGroup] = {
    baseURL: menuURL,
    defaultURL: "menu-item-to-extra-group/",
    dataStorage: {
        defaultDataKey: "to-extras",
        selectorKey: () => { return ""; }
    },
    GET: {},
    POST: {},
    PUT: {},
    DELETE: {}
};

merchantDashboardAPIDefs[APIMerchantDashboard.preferences] = {
    baseURL: menuURL,
    defaultURL: "menu-item-preferences/",
    dataStorage: {
        defaultDataKey: "preferences",
        selectorKey: () => { return ""; }
    },
    GET: {},
    POST: {},
    PUT: {},
    DELETE: {}
};

merchantDashboardAPIDefs[APIMerchantDashboard.stripeAccount] = {
    baseURL: financeURL,
    defaultURL: "stripe-account/",
    dataStorage: {
        defaultDataKey: "paymentAccount",
        selectorKey: () => { return ""; }
    },
    GET: {},
    PUT: {}
};

merchantDashboardAPIDefs[APIMerchantDashboard.stripeLogin] = {
    baseURL: financeURL,
    defaultURL: "stripe-login/",
    dataStorage: {
        defaultDataKey: "paymentLogin",
        selectorKey: () => { return ""; }
    },
    PUT: {}
};

merchantDashboardAPIDefs[APIMerchantDashboard.stripeOnboarding] = {
    baseURL: financeURL,
    defaultURL: "stripe-onboarding/",
    dataStorage: {
        defaultDataKey: "paymentOnboard",
        selectorKey: () => { return ""; }
    },
    PUT: {}
};

merchantDashboardAPIDefs[APIMerchantDashboard.dietaryTags] = {
    baseURL: v2EndPoint('dashboardMenusAPI', true),
    defaultURL: "dietary-tags",
    dataStorage: {
        defaultDataKey: "dietarytags",
        selectorKey: () => { return ""; }
    },
    GET: {}
};

merchantDashboardAPIDefs[APIMerchantDashboard.tradingTimes] = {
    baseURL: venueURL,
    defaultURL: "trading-times/",
    dataStorage: {
        defaultDataKey: "tradingTimes",
        selectorKey: () => { return ""; }
    },
    GET: {},
    PUT: {},
    POST: {},
    DELETE: {},
};
merchantDashboardAPIDefs[APIMerchantDashboard.venueNameSearch] = {
    baseURL: v2EndPoint('dashboardVenueAPI', true),
    defaultURL: 'check_nickname/',
    GET: {}
};


merchantDashboardAPIDefs[APIMerchantDashboard.locations] = {
    baseURL: venueURL,
    defaultURL: "location/",
    dataStorage: {
        defaultDataKey: "details",
        selectorKey: (selectorObject) => {
            if (!selectorObject!?.venue_id) {
                console.log('No selector obj');
                return "";
            }
            return "venue." + selectorObject!.venue_id! + ".venueLocation";
        },
        dispatchFunction: (dispatch: Dispatch<any>, results: any, args) =>
            dispatch(setVenueData('venueLocation', args![0], results)),
    },
    GET: {},
    PUT: {},
};

merchantDashboardAPIDefs[APIMerchantDashboard.currencies] = {
    baseURL: v2EndPoint('dashboardFinanceAPI', true),
    defaultURL: "get-currencies",
    dataStorage: {
        defaultDataKey: "currencies",
        selectorKey: () => { return ""; },
        dispatchFunction: (dispatch: Dispatch<any>, results: any, args) =>
            dispatch(setCurrencies(results))
    },
    GET: {},
};

merchantDashboardAPIDefs[APIMerchantDashboard.analyticsTopFiveSales] = {
    baseURL: reportURL,
    defaultURL: "analytics/dashboard/top-sales",
    GET: {},
};



merchantDashboardAPIDefs[APIMerchantDashboard.analyticsTotalsList] = {
    baseURL: reportURL,
    defaultURL: "analytics/dashboard/statistics",
    GET: {},
};

merchantDashboardAPIDefs[APIMerchantDashboard.analyticsRushData] = {
    baseURL: reportURL,
    defaultURL: "analytics/dashboard/rush-graph",
    GET: {},
};

merchantDashboardAPIDefs[APIMerchantDashboard.getLanguages] = {
    baseURL: v2EndPoint("dashboardVenueAPI", true),
    defaultURL: "get-languages",
    dataStorage: {
        defaultDataKey: "languages",
        selectorKey: () => { return "general.languages"; },
        dispatchFunction: (dispatch: Dispatch<any>, results: any, args) =>
            dispatch(setLanguages(results)),
    },
    GET: {},
};
merchantDashboardAPIDefs[APIMerchantDashboard.accept] = {
    baseURL: orderURL,
    defaultURL: "accept/",
    dataStorage: {
        defaultDataKey: "accept",
        selectorKey: () => { return ""; }
    },
    PUT: {},
};
merchantDashboardAPIDefs[APIMerchantDashboard.finish] = {
    baseURL: orderURL,
    defaultURL: "finish/",
    dataStorage: {
        defaultDataKey: "finish",
        selectorKey: () => { return ""; }
    },
    PUT: {},
};
merchantDashboardAPIDefs[APIMerchantDashboard.decline] = {
    baseURL: orderURL,
    defaultURL: "decline/",
    dataStorage: {
        defaultDataKey: "decline",
        selectorKey: () => { return ""; }
    },
    PUT: {}
};

merchantDashboardAPIDefs[APIMerchantDashboard.outstanding] = {
    baseURL: v2EndPoint('dashboardVenueAPI', true),
    defaultURL: "onboarding/",
    dataStorage: {
        defaultDataKey: "outstanding",
        selectorKey: () => { return ""; },
        dispatchFunction: (dispatch, result: any, args) => {
            let venueId = "";
            if (args && args[0]) {
                venueId = args[0];
            }
            dispatch(setOutstandingBody({ tasks: result as ITask[], venueId: venueId }));
        }
    },
    GET: {},
};

merchantDashboardAPIDefs[APIMerchantDashboard.eposSyncStatus] = {
    baseURL: venueURL,
    // baseURL: url?.dashboardVenueAPI + 'venues/',
    defaultURL: 'sync_status/',
    GET: {}
};
merchantDashboardAPIDefs[APIMerchantDashboard.timeZones] = {
    baseURL: v2EndPoint('dashboardVenueAPI', true),
    // baseURL: url?.dashboardVenueAPI + 'dashboardVenueAPI/',
    defaultURL: 'get-time-zones',
    dataStorage: {
        defaultDataKey: "timezones",
        selectorKey: () => "timezones",
        dispatchFunction: (dispatch, result, args) => {
            dispatch(addTimezones(result as any));
        }
    },
    GET: {}
};

merchantDashboardAPIDefs[APIMerchantDashboard.orderLimits] = {
    baseURL: venueURL,
    defaultURL: "order-limits-defaults/",
    GET: {},
    PUT: {},
    POST: {},
};