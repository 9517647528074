import { DIRECTION } from "../ProductSlider/ProductSlider";
import { IProductDetailsReducerState } from "../types";
import {
  SET_EXTRAS,
  SET_IS_CONTINUE,
  SET_IS_TAGS_SHOWN,
  SET_ITEM_COUNT,
  SET_POSITION,
  SET_PREFERENCES,
  SET_PRICE,
  SET_EXTRAS_ALLERGENS,
} from "./ProductDetails.action";

export function ProductDetailsReducer(
  state: IProductDetailsReducerState,
  action: any
) {
  switch (action.type) {
    case SET_POSITION:
      let dir;
      if (action.payload > state.position) {
        dir = DIRECTION.RIGHT;
      } else {
        dir = DIRECTION.LEFT;
      }
      return {
        ...state,
        position: action.payload,
        direction: dir,
      };
    case SET_PRICE:
      return {
        ...state,
        selectedPrice: action.payload.priceAmount,
        selectedPriceName: action.payload.priceName,
        isContinue: true,
      };
    case SET_PREFERENCES:
      return {
        ...state,
        selectedPreferences: action.payload,
      };
    case SET_EXTRAS:
      const { extraId, extras } = action.payload;
      return {
        ...state,
        selectedExtras: {
          ...state.selectedExtras,
          [extraId]: extras,
        },
        selectedExtrasPageIndex: {
          ...state.selectedExtrasPageIndex,
          [extraId]: state.position,
        },
      };
    case SET_ITEM_COUNT:
      return {
        ...state,
        itemCount: action.payload,
      };
    case SET_IS_CONTINUE:
      return {
        ...state,
        isContinue: action.payload,
      };
    case SET_IS_TAGS_SHOWN:
      return {
        ...state,
        isShownTags: action.payload,
      };
    case SET_EXTRAS_ALLERGENS:
      return {
        ...state,
        menuItemAsExtra: action.payload.menuItem,
        isOpen: action.payload.isOpen,
      };
    default:
      return { ...state };
  }
}
