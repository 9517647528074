import React from "react";
import { Button, Grid, ButtonProps } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import EColourPanel from "./interfaces/EColourPanel";
import { IMateriaulGridOps } from "./interfaces/IDynamicFormRow";

interface FormWizardButton extends ButtonProps {
  /**OnClick handler used if you dont want to submit the form*/
  onClick?: () => void;
  /**Colour for the buttons using material UI */
  color: EColourPanel;
  /**Button Label */
  label: string;
  /**Button type, submit type is used with the react-hook-form lib to handle submits */
  type: "button" | "submit" | "reset" | undefined;
}

export interface IFormWizardButtons {
  /**Button on the left */
  leftButton?: FormWizardButton;
  /**Button on the right */
  rightButton?: FormWizardButton;
  /**If the dynamic form has a formKey it's sent here too */
  formKey?: string;
  /**custom styles */
  leftButtonCustomClassName?: string;
  rightButtonCustomClassName?: string;
  leftCols?: any;
  rightCols?: any;
  useGrid?: boolean;
}

/**Generating the dynamic form buttons */
const FormWizardButtons = (props: IFormWizardButtons) => {
  const { t } = useTranslation();

  let buttonWidthCols: IMateriaulGridOps = 12;

  if (props.leftButton && props.rightButton) {
    buttonWidthCols = 6;
  }

  const leftButton = props.leftButton ? (
    <Button
      className="leftButton"
      data-component-name={"LEFT_BUTTON"}
      {...props.leftButton}
      name={props.leftButton.label}
      key={props.formKey + "1"}
      variant="contained"
    >
      {t(props.leftButton.label)}
    </Button>
  ) : null;

  const rightButton = props.rightButton ? (
    <Button
      className="rightButton"
      data-component-name={"RIGHT_BUTTON"}
      {...props.rightButton}
      name={props.rightButton.label}
      key={props.formKey + "2"}
      variant="contained"
    >
      {t(props.rightButton.label)}
    </Button>
  ) : null;

  const leftWrapper = props.useGrid ? (
    <Grid
      item
      xs={props.leftCols || buttonWidthCols}
      justify="center"
      className={props.leftButtonCustomClassName}
    >
      {leftButton}
    </Grid>
  ) : (
    <div className={props.leftButtonCustomClassName}>{leftButton}</div>
  );

  const rightWrapper = props.useGrid ? (
    <Grid
      item
      xs={props.rightCols || buttonWidthCols}
      justify="center"
      className={props.rightButtonCustomClassName}
    >
      {rightButton}
    </Grid>
  ) : (
    <div className={props.rightButtonCustomClassName}>{rightButton}</div>
  );

  return (
    <>
      {leftWrapper}
      {rightWrapper}
    </>
  );
};

FormWizardButtons.defaultProps = {
  useGrid: true,
} as Partial<IFormWizardButtons>;

export default FormWizardButtons;
