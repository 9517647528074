import { useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import T from "i18n-react";

import { PaymentContext } from "../PaymentStore";

import { paymentContainerInitalState } from "../PaymentStore";

import useGetRedirectUrl from "./useGetRedirectUrl";

import {
  getCartItemTaxAmount,
  getCartSubTotal,
} from "../../../utils/pricingCalculator";
import {
  changeStage,
  resetState,
  changeOrderType,
  setSubTotalCost,
  setCartTax,
  setCartInclusiveTax,
} from "../reducer/PaymentActions";
import { isObject } from "../../../utils/ObjectCheck";
import { PaymentStages } from "../types.d";

import { useSnackbarMessages } from "../../../yoello-lib/modules/components/snackbar/SnackbarContext";
import { CART } from "../../../constants/URL.const";

const usePaymentView = () => {
  const venue = useSelector((state: any) => state.Venue);
  const cart = useSelector((state: any) => state.Cart);
  const group = useSelector((state: any) => state.Group);
  const taxes = useSelector((state: any) => state.Taxes);
  const selectedMenuType = useSelector((state: any) => state.SelectedMenuType);
  const extraItems = useSelector((state: any) => state.MenuItemsAsExtra);
  const { pathname } = useLocation();
  const { paymentStages, dispatch, discounts } = useContext(PaymentContext);
  const { getRedirectURL } = useGetRedirectUrl();
  const history = useHistory();
  const { sendSnackbarMessage } = useSnackbarMessages()!;

  function backFunction(disabledItems: any[] = []) {
    if (paymentStages === PaymentStages.PAYMENT) {
      let url = getRedirectURL();
      /**if they go back to the menu */
      if (url !== "/") {
        url += CART;
      }
      history.push(url, { disabledItems });
    } else {
      dispatch(changeStage(PaymentStages.PAYMENT));
    }
  }

  useEffect(() => {
    dispatch(changeOrderType(selectedMenuType));
    // eslint-disable-next-line
  }, [selectedMenuType]);

  useEffect(() => {
    /**Whenever cart updates, update the cart subtotal  */
    const cartSubTotal = getCartSubTotal(cart);
    dispatch(setSubTotalCost(cartSubTotal));
    // if (isObject(taxes, true)) {
    // @ts-ignore
    const taxAmount = getCartItemTaxAmount(cart, taxes, extraItems, discounts);
    dispatch(setCartTax(taxAmount.totalTaxAmount));
    dispatch(setCartInclusiveTax(taxAmount.totalInclusiveTaxAmount));
    // }
    // eslint-disable-next-line
  }, [cart, discounts]);

  useEffect(() => {
    if (!isObject(venue, true) && !pathname.includes("ob")) {
      sendSnackbarMessage(
        T.translate("Payment.Errors.NoVenue").toString(),
        "error"
      );
    }

    return () => {
      /**On unmount reset the state */
      dispatch(resetState(paymentContainerInitalState));
    };
    // eslint-disable-next-line
  }, []);

  return {
    venue,
    cart,
    group,
    taxes,
    selectedMenuType,
    extraItems,
    getRedirectURL,
    backFunction,
  };
};

export default usePaymentView;
