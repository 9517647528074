import React from "react";

import SquarePaymentProcessor from "./Square";
import StripePaymentProcessor from "./Stripe";
import Pay360PaymentProcessor from "./Pay360";
import { usePaymentState } from "../PaymentStore";
import PaymentProcessingComponent from "./PaymentProcessingComponent";

const PaymentProcessorContainer = () => {
  const { isPaymentReady, paymentProviders } = usePaymentState();

  if (paymentProviders["square"]) {
    if (!isPaymentReady) return <PaymentProcessingComponent />;
    return <SquarePaymentProcessor />;
  }
  if (paymentProviders["pay360"]) return <Pay360PaymentProcessor />;
  if (paymentProviders["stripe"]) return <StripePaymentProcessor />;
  return null;
};

export default PaymentProcessorContainer;
