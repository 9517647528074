import { SwipeableDrawer } from "@material-ui/core";
import React, { useState, useContext } from "react";

import { PaymentContext } from "../PaymentStore";

import useDelivery from "../PaymentHooks/useDelivery";
import UnmountDrawerWrapper from "../../Drawer/UnmountDrawerWrapper";
import { AddressForm } from "../../Widgets/AddressForm/AddressForm";
import Button from "../../Widgets/Button";
import {
  changeDeliveryDetails,
  setIsDeliveryDrawerOpen,
  onCompleteDeliveryDetails,
} from "../reducer/PaymentActions";
import Styles from "../styles.module.scss";
import T from "i18n-react";
import { PostCodeValidator } from "../../../utils/PostCodeValidator";

/**Drawer handler for selecting the delivery address */
const DeliveryComponent = ({ isAuthenticated }) => {
  const { isDeliveryDrawerOpen, dispatch } = useContext(PaymentContext);
  const { checkPostCodeRadius } = useDelivery(isAuthenticated);
  const [postCodeInValid, setPostCodeInValid] = useState<boolean>(false);
  const [postCodeChanged, setPostCodeChanged] = useState<boolean>(false);
  const [deliveryAddress, setDeliveryAddress] = useState<DeliveryAddressType>();
  function onClose() {
    dispatch(setIsDeliveryDrawerOpen(false));
  }

  function handleDeliveryInfoChange(deliveryAddress: DeliveryAddressType) {
    setDeliveryAddress(deliveryAddress);
    if (!postCodeInValid) {
      dispatch(changeDeliveryDetails(deliveryAddress));
    }
  }

  function validatePostCode(event: any) {
    const postCode = event.target.value;
    if (PostCodeValidator(postCode)) {
      dispatch(changeDeliveryDetails(deliveryAddress));
      setPostCodeInValid(false);
    } else {
      setPostCodeInValid(true);
    }
  }
  return (
    <SwipeableDrawer
      anchor={"bottom"}
      open={isDeliveryDrawerOpen}
      onClose={onClose}
      onOpen={() => null}
      transitionDuration={350}
      disableSwipeToOpen={true}
      disableDiscovery={true}
      id="deliveryTimeDrower"
    >
      <UnmountDrawerWrapper closeDrawer={onClose}>
        <div className={Styles.delivery_time}>
          <div className={Styles.lineBar}>
            {" "}
            <span></span>{" "}
          </div>
          <div className={Styles.DeliveryHeader}>
            <div className="Flex1" />
            <p>{T.translate("Forms.DeliveryAddress")}</p>
            <div className="Flex1">
              <Button
                onClick={onClose}
                dataComponentName="ExitButton"
                iconClassName={"consumer-close"}
                style={{
                  position: "unset",
                  color: "#AAB6D3",
                  fontSize: "1.5rem",
                }}
                isHeaderBtn
              />
            </div>
          </div>
          <div>
            <p className={Styles.DeliveryText}>
              {T.translate("Forms.CompleteYour")}{" "}
              <span className={Styles.AddressInfo}>
                {T.translate("Forms.AddressInformation")}:
              </span>
            </p>
            <AddressForm
              onPostcodeBlur={validatePostCode}
              postCodeInValid={postCodeInValid}
              setPostCodeChanged={setPostCodeChanged}
              handleDeliveryInfoChange={handleDeliveryInfoChange}
            />
          </div>
          <Button
            disabled={postCodeInValid}
            isYoelloBtn
            onClick={() => {
              dispatch(onCompleteDeliveryDetails());
              if (postCodeChanged) {
                checkPostCodeRadius();
              }
            }}
            title={"ConfirmInfo"}
            dataComponentName="DeliveryAddressBtn"
          />
        </div>
      </UnmountDrawerWrapper>
    </SwipeableDrawer>
  );
};

export default DeliveryComponent;
