import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import T from "i18n-react";

import {
  setIsLoading,
  setIsPaymentProcessingDrawerOpen,
  setSelectedPaymentMethod,
  setIsPaymentDrawerOpen,
} from "../reducer/PaymentActions";
import { usePaymentState } from "../PaymentStore";

import useOrderBody from "./useOrderBody";
import useOnSuccessfulOrder from "./useOnSuccessfulOrder";

import { LOGIN } from "../../../constants/URL.const";
import { CreateOrder } from "../../../Api.js";
import { APIError } from "../../../utils/APIErrorController";
import { useSnackbarMessages } from "../../../yoello-lib/modules/components/snackbar/SnackbarContext";
import { useSentry } from "../../../customHooks/useSentry";
import * as Sentry from "@sentry/browser";

const useCompleteOrder = () => {
  const {
    selectedTime,
    cartItemTaxAmount,
    serviceTaxAmount,
    message,
    selectedOrderType,
    deliveryPrice,
    tipTotal,
    areaName,
    selectedTable,
    totalPrice,
    deliveryAddress,
    isLoading,
    dispatch,
    deliveryTaxAmount,
    deliveryTimes,
    retrieveToken,
    discounts,
  } = usePaymentState();
  const { generateOrderBody } = useOrderBody();
  const { onSuccessfulOrder } = useOnSuccessfulOrder();
  const { sendSnackbarMessage } = useSnackbarMessages()!;
  const { captureException } = useSentry();
  const history = useHistory();
  const venue = useSelector((state: any) => state.Venue);

  const onOrderFailure = (message?: string) => {
    dispatch(setSelectedPaymentMethod(null));
    dispatch(setIsPaymentProcessingDrawerOpen(false));
    if (message) {
      sendSnackbarMessage(T.translate(message) as string, "error");
    }
  };

  const completeOrder = async (isUsingPaymentRequest?: boolean) => {
    const price = totalPrice;
    try {
      dispatch(setIsLoading(true));
      const orderBody = {
        ...generateOrderBody(
          selectedTime,
          isUsingPaymentRequest,
          cartItemTaxAmount,
          serviceTaxAmount,
          deliveryTaxAmount,
          message,
          selectedOrderType,
          deliveryPrice,
          tipTotal,
          areaName,
          selectedTable,
          price,
          deliveryAddress,
          deliveryTimes,
          discounts
        ),
      };

      const userToken = await retrieveToken();

      const createOrderObject = await CreateOrder(
        orderBody,
        userToken,
        venue.id
      );
      const orderId = createOrderObject.data.data.order_reference_number;
      const orderLongID = createOrderObject.data.data.order_id;
      Sentry.addBreadcrumb({
        level: Sentry.Severity.Info,
        message: orderId,
        data: orderBody,
      });

      if (isUsingPaymentRequest) {
        return createOrderObject;
      } else {
        onSuccessfulOrder(orderId, orderLongID);
      }
    } catch (error) {
      onOrderFailure();
      captureException(error, { feature: "complete-order" });
      let errMessage = T.translate("Payment.Errors.CreateOrderError") as string;
      if (error.response) {
        const status = error.response.data.status;
        if (status >= 400 && status < 500) {
          if (status === 401) {
            localStorage.removeItem(`user_token_${window.location.host}`);
            history.push(LOGIN);
          } else {
            const errors = error.response.data.data.errors;
            errMessage = APIError(errors);
          }
        }
      }
      sendSnackbarMessage(errMessage, "error");      
      dispatch(setIsPaymentDrawerOpen(false));
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  return {
    completeOrder,
    isLoading,
  };
};

export default useCompleteOrder;
