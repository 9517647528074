import { SetTaxes } from "../../yoello-lib/modules/redux/actions/consumer.actions";

export type ITaxesState = any | null;

export function TaxesReducer(state: ITaxesState = null, payload) {
  switch (payload.type) {
    case SetTaxes:
      console.log({ payload });
      return payload.data;
    default:
      return state;
  }
}
