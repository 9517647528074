import { IAPIDefinitions } from "../APIDefs";
import { Dispatch } from "react";
import { addMerchants } from "../../redux/actions/merchants.actions";
import { addDataToMerchantView } from "../../redux/actions/merchantview.actions";
import { getDynamicEnviroment } from "../../utils/enviromentSetup";

/**Definiton of available APIs for Admin dashboard using the venues APIs */
export enum APIAdminDashboard {
    adminvenue = "adminvenue",
    adminvenueSearch = "adminvenueSearch",
    adminvenueDetails = "adminvenueDetails",
    adminvenueSettings = "adminvenueSettings",
    adminvenueLocation = "adminvenueLocation",
    adminvenueTradingtimes = "adminvenueTradingtimes",
    adminmenuItemExtra = "adminmenuItemExtra",
    adminVenueGroups = "adminVenueGroups",
    adminPaymentProcessor = "adminPaymentProcessor",
    pricePlans = "pricePlans",
    feePlans = "feePlans",
    languages = "languages",
    menuUpload = "menuUpload",
    adminOrders = "adminOrders",
    venueStatus = "venueStatus",
    adminCurrencies = "adminCurrencies",
    eposNowStatus = "eposNowStatus",
    barclaycardCsvData = "barclaycardCsvData",
    rmsCsvData = "rmsCsvData",
    pay360CsvData = "pay360CsvData",
    cardSaverCsvData = "cardSaverCsvData",

}

const env = getDynamicEnviroment();
const baseURL = `https://mgmtapi.yllo.uk/${env === "prod" ? "" : `${env}-`}mgmt-venues`;
// const baseURL = `https://${urlConfig[env]["venues"]}.execute-api.eu-west-1.amazonaws.com/${env}`;
const pay360BaseUrl = env === "prod" ? `https://pay360.yllo.uk/api` : `https://${env}-pay360.yllo.uk/api`;

/**Config of APIs for admin dashboard  */
export const AdminVenueAPIDefs: IAPIDefinitions = { };

AdminVenueAPIDefs[APIAdminDashboard.adminvenue] = {
    baseURL: baseURL,
    defaultURL: "/venues",
    dataStorage: {
        defaultDataKey: "merchantList",
        dispatchFunction: (dispatch: Dispatch<any>, results: any) => dispatch(addMerchants(results)),
        selectorKey: () => { return "merchants.merchantList"; }
    },
    GET: { },
};

AdminVenueAPIDefs[APIAdminDashboard.adminvenueSearch] = {
    baseURL: baseURL,
    defaultURL: "/venues/search",
    dataStorage: {
        defaultDataKey: "search",
        selectorKey: (selectorObject) => { return MERCH_VIEW_REDUX_KEY + ".search"; }
    },
    GET: { },
};

const MERCH_VIEW_REDUX_KEY = "viewMerchants.merchantViews.";

AdminVenueAPIDefs[APIAdminDashboard.adminvenueDetails] = {
    baseURL: baseURL,
    defaultURL: "/venues",
    dataStorage: {
        defaultDataKey: "venueDetails",
        dispatchFunction: (dispatch: Dispatch<any>, results: any, args) => dispatch(addDataToMerchantView({ venueId: args![0], data: { venueDetails: results } })),
        selectorKey: (selectorObject) => { return MERCH_VIEW_REDUX_KEY + selectorObject!.venueId + ".venueDetails"; }
    },
    GET: { },
    PUT: { },
    POST: { }
};

AdminVenueAPIDefs[APIAdminDashboard.adminvenueLocation] = {
    baseURL: baseURL,
    defaultURL: "/locations",
    dataStorage: {
        defaultDataKey: "locationDetails",
        dispatchFunction: (dispatch: Dispatch<any>, results: any, args) => dispatch(addDataToMerchantView({ venueId: args![0], data: { locationDetails: results } })),
        selectorKey: (selectorObject) => { return MERCH_VIEW_REDUX_KEY + selectorObject!.venueId + ".locationDetails"; }
    },
    GET: { },
    PUT: { },
    POST: { }
};

AdminVenueAPIDefs[APIAdminDashboard.adminvenueSettings] = {
    baseURL: baseURL,
    defaultURL: "/venue-settings",
    dataStorage: {
        defaultDataKey: "venueSettings",
        dispatchFunction: (dispatch: Dispatch<any>, results: any, args) => dispatch(addDataToMerchantView({ venueId: args![0], data: { venueSettings: results[0] } })),
        selectorKey: (selectorObject) => { return MERCH_VIEW_REDUX_KEY + selectorObject!.venueId + ".venueSettings"; },
        setData: (result: any) => { return result[0]; }
    },
    GET: { },
    PUT: { },
    POST: { }
};

AdminVenueAPIDefs[APIAdminDashboard.adminvenueTradingtimes] = {
    baseURL: baseURL,
    defaultURL: "/trading-times",
    dataStorage: {
        defaultDataKey: "tradingTimes",
        dispatchFunction: (dispatch: Dispatch<any>, results: any, args) => dispatch(addDataToMerchantView({ venueId: args![0], data: { tradingTimes: results } })),
        selectorKey: (selectorObject) => { return MERCH_VIEW_REDUX_KEY + selectorObject!.venueId + ".tradingTimes"; }
    },
    GET: { },
    PUT: { },
    POST: { }
};

// menu-item-extra-groups
AdminVenueAPIDefs[APIAdminDashboard.adminmenuItemExtra] = {
    baseURL: baseURL,
    defaultURL: "/menu-items-extras-groups",
    dataStorage: {
        defaultDataKey: "extraGroups",
        selectorKey: (selectorObject) => { return MERCH_VIEW_REDUX_KEY + ".extraGroups"; }
    },
    GET: { },
    PUT: { },
    POST: { }
};

// groups
AdminVenueAPIDefs[APIAdminDashboard.adminVenueGroups] = {
    baseURL: baseURL,
    defaultURL: "/venue-groups",
    dataStorage: {
        defaultDataKey: "venueGroups",
        selectorKey: (selectorObject) => { return MERCH_VIEW_REDUX_KEY + ".venueGroups"; }
    },
    GET: { },
    PUT: { },
    POST: { }
};

const financeURL = `https://mgmtapi.yllo.uk/${env === "prod" ? "" : `${env}-`}mgmt-finance`;

// Deprecated?
AdminVenueAPIDefs[APIAdminDashboard.adminPaymentProcessor] = {
    baseURL: financeURL,
    defaultURL: "/worldpay-api-keys",
    dataStorage: {
        defaultDataKey: "worldpay",
        selectorKey: (selectorObject) => { return MERCH_VIEW_REDUX_KEY + ".worldpay"; }
    },
    GET: { },
    PUT: { },
    POST: { }
};

AdminVenueAPIDefs[APIAdminDashboard.pricePlans] = {
    baseURL: financeURL,
    defaultURL: "/pricing-plans",
    dataStorage: {
        defaultDataKey: "pricingPlans",
        selectorKey: (selectorObject) => { return MERCH_VIEW_REDUX_KEY + ".pricingPlans"; }
    },
    GET: { }
};

AdminVenueAPIDefs[APIAdminDashboard.feePlans] = {
    baseURL: financeURL,
    defaultURL: "/processing-fees-plans",
    dataStorage: {
        defaultDataKey: "feePlans",
        selectorKey: (selectorObject) => { return MERCH_VIEW_REDUX_KEY + ".feePlans"; }
    },
    GET: { }
};

AdminVenueAPIDefs[APIAdminDashboard.menuUpload] = {
    baseURL: baseURL,
    defaultURL: "/auto-menu",
    dataStorage: {
        defaultDataKey: "menuUpload",
        selectorKey: (selectorObject) => { return MERCH_VIEW_REDUX_KEY + ".menuUpload"; }
    },
    GET: { }
};

AdminVenueAPIDefs[APIAdminDashboard.adminOrders] = {
    baseURL: baseURL,
    defaultURL: "/placed-orders",
    dataStorage: {
        defaultDataKey: "adminOrders",
        selectorKey: (selectorObject) => { return MERCH_VIEW_REDUX_KEY + ".adminOrders"; }
    },
    GET: { }
};

AdminVenueAPIDefs[APIAdminDashboard.adminCurrencies] = {
    baseURL: financeURL,
    defaultURL: "/get-currencies",
    dataStorage: {
        defaultDataKey: "currencies",
        selectorKey: (selectorObject) => { return MERCH_VIEW_REDUX_KEY + ".currencies"; }
    },
    GET: { }
};

AdminVenueAPIDefs[APIAdminDashboard.languages] = {
    baseURL: baseURL,
    defaultURL: "/get-languages",
    dataStorage: {
        defaultDataKey: "languages",
        selectorKey: (selectorObject) => { return MERCH_VIEW_REDUX_KEY + ".languages"; }
    },
    GET: { }
}

AdminVenueAPIDefs[APIAdminDashboard.venueStatus] = {
    baseURL: baseURL,
    defaultURL: "/venue-status",
    dataStorage: {
        defaultDataKey: "venueStatus",
        selectorKey: (selectorObject) => { return MERCH_VIEW_REDUX_KEY + ".venueStatus"; }
    },
    GET: { }
};

AdminVenueAPIDefs[APIAdminDashboard.eposNowStatus] = {
    baseURL: baseURL,
    defaultURL: "/get-eposnow-operations-data",
    GET: { }
}
AdminVenueAPIDefs[APIAdminDashboard.barclaycardCsvData] = {
    baseURL: pay360BaseUrl,
    defaultURL: "/v1/venues/barclaycard",
    GET: { }
}
AdminVenueAPIDefs[APIAdminDashboard.rmsCsvData] = {
    baseURL: pay360BaseUrl,
    defaultURL: "/v1/venues/rms",
    GET: { }
}
AdminVenueAPIDefs[APIAdminDashboard.pay360CsvData] = {
    baseURL: pay360BaseUrl,
    defaultURL: "/v1/venues/pay360",
    GET: { }
}
AdminVenueAPIDefs[APIAdminDashboard.cardSaverCsvData] = {
    baseURL: pay360BaseUrl,
    defaultURL: "/v1/venues/card_saver",
    GET: { }
}
