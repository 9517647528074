import React, { useEffect, useState } from "react";
import T from "i18n-react";
import { useSelector } from "react-redux";
import Styles from "./tags.module.scss";
import { ReactComponent as DangerSVG } from "../../../imges/Danger.svg";

interface IProductTagProps {
  menuItemName?: string;
  menuItemTags?: string[];
}

interface ISortedItemTags {
  suitableTags: JSX.Element[];
  warningTags: JSX.Element[];
}
const WARNING = "WARNING";
function ProductTags({ menuItemName, menuItemTags }: IProductTagProps) {
  const [sortedItemTags, setSortedItemTags] = useState<ISortedItemTags>({
    suitableTags: [],
    warningTags: [],
  });
  const dietTags = useSelector((state: any) => state.DietaryTags);

  useEffect(() => {
    if (dietTags !== undefined && menuItemTags !== undefined) {
      const suitableTags = [];
      const warningTags = [];
      for (const menuItemTagsId of menuItemTags) {
        const element = (
          <li key={menuItemTagsId} className={Styles.tagsText}>
            {T.translate(`Allergens.${dietTags[menuItemTagsId].name_public}`)}
          </li>
        );
        if (dietTags[menuItemTagsId].type === WARNING) {
          suitableTags.push(element);
        } else {
          warningTags.push(element);
        }
      }
      setSortedItemTags({
        suitableTags,
        warningTags,
      });
    }
  }, [menuItemTags, dietTags]);
  return (
    <div className={Styles.tagsContainer}>
      <p className={Styles.title}>{menuItemName}</p>
      {sortedItemTags.suitableTags?.length > 0 && (
        <div className={Styles.suitableTagsWrapper}>
          <p className={Styles.InfoTitles}>
            {T.translate("Menu.SuitableFor")}:
          </p>
          <ul className={Styles.tags}>{sortedItemTags.suitableTags}</ul>
        </div>
      )}
      {sortedItemTags.warningTags?.length > 0 && (
        <div className={Styles.warningWrapper}>
          <div>
            <DangerSVG />
            <p className={Styles.InfoTitles}>
              {T.translate("Menu.WarningContainsText")}:
            </p>
          </div>
          <ul className={Styles.tags}>{sortedItemTags.warningTags}</ul>
        </div>
      )}
      <div className={Styles.InfoBottomText}>
        <div>
          <i className="consumer-info-circle" />
          <p>{T.translate("Menu.MoreInformations")} </p>
        </div>
        <p>{T.translate("Menu.AllergiesText")} </p>
      </div>
    </div>
  );
}

export default React.memo(ProductTags);
