import {
  LANGUAGE_SET,
  CURRENCY_SET,
  CURRENCIES_SET,
} from "../consts";
import { ICurrency, ReduxAction } from "../actions/localization";

export interface ILocalizationState {
  language: string;
  currency: string;
  currencies: ICurrency[];
}

const INITIAL_STATE: ILocalizationState = {
  language: "EN",
  currency: "GBP",
  currencies: [],
};

const reducer = (state = INITIAL_STATE, action: ReduxAction) => {
  switch (action.type) {

    case LANGUAGE_SET:
      return { ...state, language: action.payload };
    case CURRENCY_SET:
      return { ...state, currency: action.payload };
    case CURRENCIES_SET:
      return { ...state, currencies: action.payload };
    default:
      return state;
  }
};

export default reducer;
