import React, { useReducer } from "react";
import { useSelector } from "react-redux";
// import { VenueShare } from "../Venue/VenueDisplay/VenueShare";
import { GroupLogo } from "./GroupDisplay/GroupLogo";
import { GroupPageList } from "./GroupDisplay/GroupPageList";
import { GroupPageTop } from "./GroupDisplay/GroupPageTop";
import { GroupDescription } from "./GroupDisplay/GroupDescription";
import Styles from "./groupPage.module.scss";
import { FilterComponent } from "../Utils/Filter";
import { GroupReducer } from "./state/group.reducer";
import { setFilters } from "./state/group.actions";
import { CARDIFF_CASTLE } from "../../constants";

const initalState = {
  filters: {
    TableService: false,
    Delivery: false,
    Collection: false,
    PickupNow: false,
  },
  filtersCount: 0,
};

export function GroupComponent({ openSideDrawer, openShareDrawer, ...props }) {
  const Group = useSelector((state: any) => state.Group);
  const [state, dispatch] = useReducer(GroupReducer, initalState);
  const { filters, filtersCount } = state;

  return (
    <div className={Styles.groupPageContainer} id="groupPageContainerId">
      <div className={Styles.header}>
        <GroupPageTop group={Group} openSideDrawer={openSideDrawer} />
        <div className={Styles.groupDetails}>
          <GroupLogo group={Group} />
          <div>
            <div className={Styles.groupName}>
              <p>{Group.group_name_public}</p>
            </div>
            {/* <VenueShare
              openShareDrawer={openShareDrawer}
              className={Styles.groupAddressAndShare}
            /> */}
          </div>
        </div>
        <GroupDescription description={Group.group_description} />
      </div>

      <div className={Styles.content}>
        {props.match.params?.group !== CARDIFF_CASTLE && (
          <FilterComponent
            setFilters={(filters) => dispatch(setFilters(filters))}
            filters={filters}
            filterId={"GroupPageFilters"}
            filtersCount={filtersCount}
          />
        )}

        <div className={Styles.lineBar}>
          <span />
        </div>
        <div className={Styles.shadowTop}></div>
        <div className={Styles.venuesList}>
          <GroupPageList filters={filters} filtersCount={filtersCount} />
        </div>

        <div className={Styles.shadowBottom}></div>
      </div>
    </div>
  );
}
