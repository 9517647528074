import React from "react";
import Styles from "../styles.module.scss";
import { ReactComponent as LogoPlaceholderSVG } from "../../../imges/venue-logo-placeholder.svg";
interface IVenueLogoProps {
  venueLogoUrl: string;
}

export function VenueLogo({ venueLogoUrl }: IVenueLogoProps) {
  return (
    <div data-component-name="venue-logo" className={Styles.logo}>
      {venueLogoUrl?.length > 0 ? (
        <img src={venueLogoUrl} alt="venue_brand" />
      ) : (
        <LogoPlaceholderSVG />
      )}
    </div>
  );
}
