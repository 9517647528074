import React from "react";
import "./ShareButton.css";
import { IShareButtonProps } from "./types.d";
function ShareButton({ href, Icon, SideMenu, style }: IShareButtonProps) {
  return (
    <a href={href} rel="noopener noreferrer" target="_blank">
      <div
        className={`ShareButton ${
          SideMenu !== undefined ? "ShareButtonSideMenu" : ""
        }`}
        style={style}
      >
        <Icon />
      </div>
    </a>
  );
}

export default ShareButton;
