import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { MenuTimeBox } from "./MenuTimeBox";
import T from "i18n-react";
import { IMenuItemStateMenu } from "../../Menu/types";
import { useScrolled } from "../../Menu/hooks/useScrolled";

export interface IMenuTimeBox {
  Menus: IMenuItemStateMenu;
}

const useStyles = makeStyles({
  root: {
    overflow: "hidden",
    marginBottom: "0.25rem",
    position: "relative",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
    maxHeight: "4.75rem",
    "& >p": {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "0.9375rem",
      textAlign: "center",
      color: "#424242",
      margin: "0.75rem 0",
      "& >strong": {
        paddingRight: ".2rem",
      },
    },
  },
  TimesListDiv: {
    display: "flex",
    justifyContent: (filteredTimings: Array<any>) =>
      filteredTimings.length > 3 ? "space-between" : "center",
    alignItems: "center",
    overflowX: "auto",
    paddingBottom: "0.75rem",
    position: "relative",
  },
  ShadowRight: {
    position: "absolute",
    width: "30px",
    height: "1.5625rem",
    right: "0",
    bottom: "8px",
    background:
      "linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)",
  },
  onScroll: {
    maxHeight: 0,
    transition: "max-height 500ms linear",
  },
  onScrollOut: {
    maxHeight: "4.75rem",
    transition: "max-height 500ms linear",
  },
});

export function MenuTimelines(props: IMenuTimeBox) {
  const [filteredTimings, setFilteredTimings] = useState([]);
  const classes = useStyles(filteredTimings);
  const SelectedMenuType = useSelector((state: any) => state.SelectedMenuType);

  const { className } = useScrolled(classes.onScroll, classes.onScrollOut);

  useEffect(() => {
    if (props.Menus.timings) {
      const day = new Date().getDay();
      const FilteredTimings = props.Menus.timings.filter(
        (item) => item.iso_weekday === day && item.enabled
      );
      setFilteredTimings(FilteredTimings);
    } else {
      setFilteredTimings([]);
    }
  }, [props.Menus.timings]);

  function renderTimings() {
    if (filteredTimings) {
      return filteredTimings.map((item, index) => {
        return (
          <MenuTimeBox
            key={`${index}-menutimebox`}
            time={item}
            styles={{
              backgroundColor: "#FFFCE6",
              textColor: "#212121",
            }}
          />
        );
      });
    }
  }

  function getOrderType() {
    switch (SelectedMenuType) {
      case "TABLE_DELIVERY":
        return "dine in";
      case "HOME_DELIVERY":
        return "delivery";
      case "COLLECTION":
        return "collection";
      default:
        return null;
    }
  }

  if (filteredTimings?.length) {
    return (
      <div className={`${classes.root} ${className}`}>
        <p>
          <strong>{T.translate("Menu.TimingOrderTypePartOne")}</strong>
          {T.translate("Menu.TimingOrderTypePartTwo", {
            orderType: getOrderType(),
          })}
        </p>
        <div className={classes.TimesListDiv}>{renderTimings()}</div>
        <div className={classes.ShadowRight}></div>
      </div>
    );
  }
  return null;
}
