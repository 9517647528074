import React, {
  ChangeEvent,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { onKeyboardPressHandler } from "../../../utils/keyboard";
import Styles from "../styles.module.scss";
import { useSelector } from "react-redux";

interface ITipInputProps {
  customTipValue?: number;
  onChangeCustomTip: onChangeCustomTipFunction;
}

type onChangeCustomTipFunction = (event: number) => void;

function TipInput({ customTipValue, onChangeCustomTip }: ITipInputProps) {
  const input = useRef() as RefObject<HTMLInputElement>;
  const [customStateValue, setCustomStateValue] = useState<any>(0);
  const Venue = useSelector((state: any) => state.Venue);
  useEffect(() => {
    setCustomStateValue(customTipValue || 0);
    if (input) {
      input.current?.focus();
    }
    //eslint-disable-next-line
  }, []);
  function onChange(event: ChangeEvent<HTMLInputElement>) {
    const regex = /^\d*\.?\d*$/;
    const { value } = event.target;
    if (value === "" || regex.test(value)) {
      if (value === ".") {
        setCustomStateValue("0" + value);
      } else {
        setCustomStateValue(value);
      }
    }
  }
  useEffect(() => {
    onChangeCustomTip(customStateValue);
    //eslint-disable-next-line
  }, [customStateValue]);

  return (
    <>
      <input
        data-component-name="tip-input"
        type="text"
        maxLength={12}
        value={customStateValue}
        onChange={onChange}
        className={Styles.TipCardInput}
        ref={input}
        onKeyPress={onKeyboardPressHandler}
      />
      <span>{Venue?.currency_symbol}</span>
    </>
  );
}

export default TipInput;
