import { IMenuReducerState, MenuPayloadTypes } from "../types";
import {
  SET_SEARCH_TEXT,
  SET_SCROLL,
  SET_POSITION,
  SET_MENU_TAB,
  SET_MENU_KEYS,
  SET_LIST_HEIGHT,
  SET_CART_DATA,
  SET_DISABLED_ITEMS,
} from "./Menu.Actions";

export const menuReducerInitState = {
  searchText: "",
  isScrolled: false,
  position: 0,
  menuPosition: 0,
  menuKeys: [],
  listHeight: window.innerHeight - 100,
  cartCount: 0,
  cartAmount: 0,
  disabledItems: [],
};

export function MenuReducer(
  state: IMenuReducerState,
  action: { type: string; payload: MenuPayloadTypes }
) {
  switch (action.type) {
    case SET_POSITION:
      return {
        ...state,
        position: action.payload as number,
      };
    case SET_SCROLL:
      return {
        ...state,
        isScrolled: action.payload as boolean,
      };
    case SET_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.payload as string,
      };
    case SET_MENU_TAB:
      return {
        ...state,
        menuPosition: action.payload as number,
      };
    case SET_MENU_KEYS:
      return {
        ...state,
        menuKeys: action.payload as string[],
      };
    case SET_LIST_HEIGHT:
      return {
        ...state,
        listHeight: action.payload as number,
      };
    case SET_CART_DATA:
      const payload = action.payload as any;
      return {
        ...state,
        cartCount: payload.totalCount as number,
        cartAmount: payload.totalAmount as number,
      };
    case SET_DISABLED_ITEMS:
      return {
        ...state,
        disabledItems: action.payload as string[],
      };
  }
}
