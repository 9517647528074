import React, { useState } from "react";
import { useSelector } from "react-redux";
import { usePaymentState } from "../PaymentStore";
import { setDiscounts } from "../reducer/PaymentActions";
import { onKeyboardPressHandler } from "../../../utils/keyboard";
import { OnApplyMessageComponent } from "./OnApplyMessageComponent";
import { OnApplyComponent } from "./OnApplyComponent";
import { useAuth0 } from "../../../yoello-lib/modules/auth/Auth0";
import { useHistory } from "react-router-dom";
import { AppliedDiscounts } from "./AppliedDiscounts";
import useCurrency from "../../../yoello-lib/modules/localisation/useCurrency";
// import {getDiscountsCodeInfo} from "../../../Api.js"
import Styles from "../styles.module.scss";
import T from "i18n-react";
import { getDiscountsCodeInfo } from "../../../Api.js";
import { DiscountData, DiscountsApiData } from "../types";
import { LOGIN } from "../../../constants/URL.const";
import { APIError } from "../../../utils/APIErrorController";
import { useSnackbarMessages } from "../../../yoello-lib/modules/components/snackbar/SnackbarContext";
import { useSentry } from "../../../customHooks/useSentry";

export const discountsInitialValue = {
  code: "",
  message: "",
  value: null,
  error: false,
};

export enum DiscountType {
  ABSOLUTE = "ABSOLUTE",
  PERCENTAGE = "PERCENTAGE",
}
const DiscountsComponent = ({ subTotalCost }) => {
  const { formatCurrency } = useCurrency();
  const { retrieveToken } = useAuth0()!;
  const Venue = useSelector((state: any) => state.Venue);
  const { dispatch, discounts } = usePaymentState();
  const [isloading, setIsLoading] = useState<boolean>(false);
  const [code, setCode] = useState<string>(null);
  const history = useHistory();
  const { sendSnackbarMessage } = useSnackbarMessages()!;
  const { captureException } = useSentry();
  function onChange(event) {
    const { value } = event.target;
    if (discounts.error) {
      dispatch(setDiscounts(discountsInitialValue));
    }
    setCode(value);
  }
  async function getCodeInfo() {
    setIsLoading(true);
    if (code) {
      const token = await retrieveToken();
      const data = {
        "venue_id":Venue.id,
        "discount_code":code
      };
      let discountApiData: DiscountsApiData;
      try {
        const response = await getDiscountsCodeInfo(token,data);
        discountApiData = response.data.data;
      } catch (error) {
        captureException(error, { feature: "place-order-discount" });
        let errMessage = T.translate("ApiErrors.default") as string;
        if (error.response) {
          const status = error.response.data.status;
          if (status >= 400 && status < 500) {
            if (status === 403) {
              localStorage.removeItem(`user_token_${window.location.host}`);
              history.push(LOGIN);
            } else {
              const errors = error.response.data.data.errors;
              errMessage = APIError(errors);
            }
          }
        }
        sendSnackbarMessage(errMessage, "error");
      }
      const discountData:DiscountData = discountApiData.discount
      if (discountApiData.valid) {
        let discountValue = discountData.value;
        if (discountData.type === DiscountType.ABSOLUTE) {
          discountValue = (discountData.value * 100) / subTotalCost;
        }
        if (discountData.minimum_order_value > subTotalCost) {
          dispatch(
            setDiscounts({
              id: discountData.id,
              code: code,
              message: T.translate("Payment.DiscountMinValidText", {
                price: formatCurrency(discountData.minimum_order_value),
              }) as string,
              value: 0,
              error: true,
              type: DiscountType.PERCENTAGE,
            })
          );
        } else {
          dispatch(
            setDiscounts({
              id: discountData.id,
              code: code,
              message: "",
              value: discountValue,
              error: false,
              type: DiscountType.PERCENTAGE,
              absoluteValue:
                discountData.type === DiscountType.ABSOLUTE
                  ? discountData.value
                  : null,
            })
          );
          setCode("");
        }
      } else {
        dispatch(
          setDiscounts({
            code: "",
            message: APIError([{"error_code":discountApiData.validation_error}]),
            value: 0,
            error: true,
          })
        );
      }
    }
    setIsLoading(false);
  }
  function resetDiscountCode() {
    setCode("");
    dispatch(setDiscounts(discountsInitialValue));
  }
  return (
    <div className={Styles.TipsSectionDiv}>
      <div
        className={`${Styles.MessageInputDiv} ${Styles.discountsInputWrapper} ${
          discounts.error ? Styles.error : undefined
        }`}
        style={{
          position: "relative",
        }}
      >
        <input
          data-component-name="DiscountInput"
          type="text"
          maxLength={12}
          onKeyPress={onKeyboardPressHandler}
          className={Styles.TipCardInput}
          style={{
            width: "100%",
          }}
          placeholder={T.translate("Forms.AddDiscounts") as string}
          onChange={onChange}
          value={code}
        />
        <OnApplyComponent
          isloading={isloading}
          onClick={getCodeInfo}
          reset={resetDiscountCode}
        />
      </div>
      <OnApplyMessageComponent />
      {discounts.code && !discounts.error && <AppliedDiscounts />}
    </div>
  );
};

export default DiscountsComponent;
