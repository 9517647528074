import Queue from "../../utils/Queue";
import { SnackbarMessageStatus } from "../../yoello-lib/modules/components/snackbar/SnackbarContext";
import { ADD_NOTIFICATION } from "../../yoello-lib/modules/redux/actions/consumer.actions";

interface INotification {
  label: string;
  status: SnackbarMessageStatus;
  labelObj?: Record<string, any>;
  pathname: string;
  notificationLength?: number;
}

interface IAction {
  type: string;
  payload: INotification;
}

type INotificationState = Record<string, Queue<INotification>>;

export function Notifications(state: INotificationState = {}, action: IAction) {
  switch (action.type) {
    case ADD_NOTIFICATION:
      console.log(action);
      const {
        payload: { pathname, ...props },
      } = action;
      const currentState = state;
      if (pathname !== undefined) {
        if (currentState[pathname]) {
          currentState[pathname].enqueue({ pathname, ...props });
        } else {
          currentState[pathname] = new Queue();
          currentState[pathname].enqueue({ pathname, ...props });
        }
      }
      return {
        ...currentState,
      };
    default:
      return state;
  }
}
