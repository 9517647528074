import { LOGIN_REDIRECT, LOGINV2 } from "../../../../constants/URL.const";
import useGenerateNavPath from "../../../../customHooks/useGenerateNavPath";
import { useAuth0 } from "../../../../yoello-lib/modules/auth/Auth0";

const useSocialLogins = () => {
  const { loginWithSocial } = useAuth0()!;
  const { generateNavPath } = useGenerateNavPath();
  const redirectUrl = `${
    document.location.origin
  }${LOGIN_REDIRECT}?returnPath=${encodeURIComponent(
    generateNavPath(LOGINV2)
  )}`;

  const loginWithGoogle = async () => {
    loginWithSocial("google-oauth2", "openid email profile", redirectUrl);
  };

  const loginWithFacebook = async () => {
    loginWithSocial("facebook", "openid email profile", redirectUrl);
  };

  return {
    loginWithGoogle,
    loginWithFacebook,
  };
};

export default useSocialLogins;
