import React from "react";
import { useHistory } from "react-router-dom";
import { ORDERS, REVIEW } from "../../../constants/URL.const";

interface IOrderReviewButtonProps {
  buttonText: string;
  className: string;
  orderID: string;
}

export function OrderReviewButton({
  buttonText,
  className,
  orderID,
}: IOrderReviewButtonProps) {
  const history = useHistory();

  function onReviewClick() {
    if (orderID) {
      history.push(`${ORDERS}/${orderID}${REVIEW}`);
    } else {
      history.push(`${ORDERS}`);
    }
  }
  return (
    <div
      className={className}
      onClick={onReviewClick}
      data-component-name="OrderReviewButton"
    >
      <p>{buttonText}</p>
    </div>
  );
}
