import { useEffect } from "react";
import T from "i18n-react";
import { useHistory, useLocation } from "react-router";
import { LOGINV2, LOGIN_EMAIL_ERROR } from "../../constants/URL.const";
import { useAuth0 } from "../../yoello-lib/modules/auth/Auth0";
import { useSnackbarMessages } from "../../yoello-lib/modules/components/snackbar/SnackbarContext";
import { LoginStorage } from "../../constants/storageVars.const";
import useGenerateNavPath from "../../customHooks/useGenerateNavPath";

const LoginRedirect = () => {
  const location = useLocation();
  const history = useHistory();
  const { generateNavPath } = useGenerateNavPath();
  const { retrieveUser, logout } = useAuth0()!;
  const params = new URLSearchParams(location.search);
  const redirectUrl = params.get("returnPath")
    ? decodeURI(params.get("returnPath"))
    : null;

  const { sendSnackbarMessage } = useSnackbarMessages();

  useEffect(() => {
    if (location.hash.indexOf("error=") > -1) {
      history.push(LOGIN_EMAIL_ERROR, {
        ...(location.state as any),
        error: location.hash.split("error=")[1],
      });
      return;
    }
    try {
      retrieveUser();
    } catch (error) {
      sendSnackbarMessage(
        T.translate("Login.Texts.LoginIssue").toString(),
        "error"
      );
      logout();
    }
    history.push(generateNavPath(LOGINV2), {
      ...(location.state as any),
      to: localStorage.getItem(LoginStorage.LOCATION_TO),
      from: localStorage.getItem(LoginStorage.LOCATION_FROM),
    });
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (redirectUrl) {
      history.push(redirectUrl);
    }
    //eslint-disable-next-line
  }, [redirectUrl]);

  return null;
};

export default LoginRedirect;
