import React from "react";
import T from "i18n-react";
import Styles from "./styles.module.scss";

interface ISideMenuEntityProps {
  keyString: string;
  onClick?: () => void;
  componentName?: string;
  name: string;
  icon: JSX.Element;
}

export function SideMenuEntity({
  keyString,
  onClick,
  name,
  icon,
}: ISideMenuEntityProps) {
  return (
    <div
      key={keyString}
      onClick={() => {
        if (onClick) onClick();
      }}
      data-component-name={`SideMenu-${name}`}
      className={Styles.MenuItemDiv}
    >
      <div className={Styles.SVGsDiv}>{icon}</div>
      <T.p className={Styles.MenuItemName} text={{ key: `SideMenu.${name}` }} />
    </div>
  );
}
