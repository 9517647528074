import React from "react";
import { PAYMENT_VERIFICATION } from "../../constants/URL.const";
import PageLoading from "../Page/PageLoading";

const parseQueryParams = (params: string) => {
  const splitParams = params.replace("?", "").split("&");
  const formattedParams = {};
  for (let param of splitParams) {
    const keyValuePair = param.split("=");
    formattedParams[keyValuePair[0].replace("=", "")] = keyValuePair[1].replace(
      "=",
      ""
    );
  }
  return formattedParams;
};

const PaymentVerification = () => {
  const message = {
    name: "payment-verification-redirect",
    payload: parseQueryParams(document.location.search),
  };
  window.parent.postMessage(
    message,
    document.location.origin + PAYMENT_VERIFICATION
  );
  return (
    <div style={{ position: "absolute", width: "0vw", height: "0vh" }}>
      <PageLoading />
    </div>
  );
};

export default PaymentVerification;
