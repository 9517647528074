import { ITax, ITaxType } from "../interfaces/ITaxes";

function convertToPercent(number: number) {
    return number / 100;
}

export function calculateAdditiveTax(percent: number, item: number) {
    const p = convertToPercent(percent);
    return p * item;
}

/**Generates the tax amount from the tax items */
export function generatePercentFromTaxItems(taxItems: ITax[]) {
    if (!taxItems) {
        return 0;
    }
    return taxItems.reduce((prevItem, currentItem) => {
        if (currentItem.enabled === false) {
            return prevItem;
        }
        return prevItem + currentItem.tax_percentage;
    }, 0);
}

export function subtractPercent(item: number, percent: number) {
    return item - calculateAdditiveTax(percent, item);
}

export function generateTotalFromPercent(percent: number, percentTotal: number) {
    const p = convertToPercent(percent);
    const reverseP = 1 - p;
    return percentTotal / reverseP;
}

/**Gets the taxes types  from the first item or it will default to inclusive*/
export function getTaxesType(taxItems: Record<string, ITax> | ITax[]): ITaxType {
    if (!taxItems) {
        return ITaxType.INCLUSIVE;
    }
    if (Array.isArray(taxItems)) {
        return taxItems[0]?.tax_inclusion_type;
    }
    const keys = Object.keys(taxItems);
    if (keys.length > 0) {
        console.log("Tax type", taxItems[keys[0]].tax_inclusion_type);
        return taxItems[keys[0]].tax_inclusion_type;
    }
    return ITaxType.INCLUSIVE;
}

/**Takes as many arguments as you like then adds all the tax totals together*/
export function addTaxes(...args: Array<number | null>) {
    let totalTaxes = 0;
    for (const item of args) {
        if (item === null) {
            totalTaxes += 0;
        } else {
            totalTaxes += item;
        }
    }
    return totalTaxes;
}

/**Calculates the inclusive tax amount */
export function calculateInclusiveTax(itemAmount: number, taxPercent: number) {
    return (itemAmount / (100 + taxPercent)) * taxPercent;
}
export function calculateInclusiveReverse(itemAmount: number, taxPercent: number) {
    const p = convertToPercent(taxPercent);
    const calc = 1 + p;
    return itemAmount * calc;
}

export function calculateInclusiveTaxes(taxItems: ITax[], itemPrice: number) {
    if (!taxItems || !Array.isArray(taxItems)) {
        return 0;
    }
    return taxItems.reduce((prevItem, nextItem) => {
        if (nextItem.enabled === false) {
            return prevItem;
        }
        const calc = calculateInclusiveTax(itemPrice, nextItem.tax_percentage);
        return prevItem + calc;
    }, 0);

}
export function calculateAdditiveTaxes(taxItems: ITax[], itemPrice: number) {
    if (!taxItems || !Array.isArray(taxItems)) {
        return 0;
    }
    return taxItems.reduce((prevItem, nextItem) => {
        if (nextItem.enabled === false) {
            return prevItem;
        }
        const calc = calculateAdditiveTax(nextItem.tax_percentage, itemPrice);
        return prevItem + calc;
    }, 0);
}