import React, { useContext } from "react";

import Button from "../../Widgets/Button";
import { PaymentContext } from "../PaymentStore";
import Drawer from "../../Drawer";
import {
  setIsPaymentDrawerOpen,
  setIsCardDetailsDrawerOpen,
  setSelectedPaymentMethod,
} from "../reducer/PaymentActions";
import T from "i18n-react";
import Style from "./payment.module.scss";
import { Openbanking } from "./Yapily";
import { PaymentMethods } from "../types.d";

export interface IPaymentDrawer {
  digitalWalletComponent?: React.ReactNode;
  confirmPaymentComponent?: React.ReactNode;
  isPaymentLoading?: boolean;
  isPersistent?: boolean;
}

const PaymentDrawer = (props: IPaymentDrawer) => {
  const {
    isPaymentDrawerOpen,
    dispatch,
    paymentDetails,
    selectedPaymentMethod,
  } = useContext(PaymentContext);

  return (
    <Drawer
      isPersistent={props.isPersistent}
      isOpen={isPaymentDrawerOpen}
      closeDrawer={() => {
        if (props.isPaymentLoading) return;
        dispatch(setIsPaymentDrawerOpen(false));
        dispatch(setSelectedPaymentMethod(null));
      }}
      instructions={[
        T.translate("Payment.SelectYour").toString(),
        T.translate("Payment.PaymentOption").toString(),
      ]}
    >
      <div className={Style.PaymentContainer} id={"PaymentDetailsContainer"}>
        <Openbanking />
        {props.digitalWalletComponent}

        <div className={Style.PayWithCard}>
          <div className={Style.Line} />
          <div>
            <p>{T.translate("Payment.PayWithCard")}</p>
          </div>
        </div>
        {paymentDetails ? (
          <div>{props.confirmPaymentComponent}</div>
        ) : (
          <Button
            iconClassName="consumer-credit"
            dataComponentName={"AddCardDetaials"}
            disabled={
              selectedPaymentMethod &&
              selectedPaymentMethod !== PaymentMethods.CARD
            }
            isYoelloBtn
            style={{ flexDirection: "row-reverse" }}
            title={"AddCardDetaials"}
            onClick={() => {
              dispatch(setIsPaymentDrawerOpen(false));
              dispatch(setIsCardDetailsDrawerOpen(true));
            }}
          />
        )}
      </div>
    </Drawer>
  );
};

export default PaymentDrawer;
