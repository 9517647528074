import React, { FunctionComponent } from "react";
import useCurrency from "../../../yoello-lib/modules/localisation/useCurrency";
import CountComponent from "../../Widgets/CountComponent/CountComponent";
import Styles from "./styles.module.scss";
import { ReactComponent as DeleteSVG } from "../../../imges/delete.svg";

interface IChoiceBoxProps {
  isSelected: boolean;
  onClick: () => void;
  choiceBoxName: string;
  choiceBoxPricingOption?: { option_price: number; option_discount: number };
  menuItem?: any;
  setDisplayeExtrasAllergens?: (menuItem: any) => void;
  disabled?: boolean;
  changeCount?: (increaseCountBoolean: boolean) => void;
  extraCount?: number;
  Square?: boolean;
}

const ChoiceBox: FunctionComponent<IChoiceBoxProps> = ({
  isSelected,
  onClick,
  choiceBoxPricingOption,
  choiceBoxName,
  menuItem,
  setDisplayeExtrasAllergens,
  disabled,
  changeCount,
  extraCount,
  Square,
}) => {
  const getAfterDiscountPrice = (price: number, discount: number) => {
    let finalPrice = price;
    if (discount) {
      finalPrice = price - (price * discount) / 100;
    }
    return finalPrice;
  };
  const price = getAfterDiscountPrice(
    choiceBoxPricingOption?.option_price,
    choiceBoxPricingOption?.option_discount
  );
  const { formatCurrency } = useCurrency();
  const formattedPrice = formatCurrency(price);

  const handleExtrasAllergense = (e) => {
    e.stopPropagation();
    setDisplayeExtrasAllergens(menuItem);
  };

  const handleChoiceBoxClick = () => {
    // this is for without pricing options(for example Preferences / Table selection)
    if (!disabled && !choiceBoxPricingOption) {
      onClick();
    }
  };

  const reduceItem = (event) => {
    event.cancelBubble = true;
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    changeCount(false);
  };
  return (
    <div
      onClick={handleChoiceBoxClick}
      data-component-name="ChoiceBox"
      className={`${Styles.ChoiceBoxContainer} ${
        isSelected && !disabled ? Styles.Selected : ""
      } ${disabled ? Styles.ChoiceBoxContainerDisabled : ""}`}
    >
      <div
        className={Styles.ChoiceBoxInnerDiv}
        onClick={() => {
          // this is for with Pricing Options meaning Extras
          if (!disabled && choiceBoxPricingOption) {
            changeCount(true);
          }
        }}
      >
        {choiceBoxPricingOption !== undefined &&
        typeof choiceBoxPricingOption === "object" ? (
          // this is for with Pricing options
          <>
            <div style={{
              maxWidth:"70%"
            }}>
              {isSelected && (
                <div onClick={reduceItem}>
                  <DeleteSVG width="16px" data-component-name="DeleteItem" />
                </div>
              )}

              <p
                className={`${Styles.ChoiceBoxName}  ${
                  choiceBoxPricingOption !== undefined &&
                  typeof choiceBoxPricingOption === "object"
                    ? Styles.ExtraChoiceBoxName
                    : null
                }`}
                data-component-name={
                  choiceBoxPricingOption !== undefined &&
                  typeof choiceBoxPricingOption === "object"
                    ? "extraItem"
                    : "preferenceItem"
                }
              >
                {choiceBoxName}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <p
                className={Styles.ChoiceBoxName}
                style={{
                  width: "fit-content",
                  paddingRight: "10px",
                }}
              >
                +{formattedPrice}
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {isSelected && extraCount > 0 && (
                  <CountComponent
                    onMinusClick={() => changeCount(false)}
                    onPlusClick={() => changeCount(true)}
                    count={extraCount}
                    disablePlus={Square}
                    choiceBox={true}
                  />
                )}
              </div>
            </div>
          </>
        ) : (
          // this is for without pricing options(for example Preferences / Table selection)
          <>
            <p
              className={`${Styles.ChoiceBoxName}  ${
                choiceBoxPricingOption !== undefined &&
                typeof choiceBoxPricingOption === "object"
                  ? Styles.ExtraChoiceBoxName
                  : null
              }`}
              data-component-name={
                choiceBoxPricingOption !== undefined &&
                typeof choiceBoxPricingOption === "object"
                  ? "extraItem"
                  : "preferenceItem"
              }
            >
              {choiceBoxName}
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p
                className={Styles.ChoiceBoxName}
                style={{
                  width: "fit-content",
                  paddingRight: "10px",
                }}
              ></p>
              <div>
                {isSelected && <div className={Styles.SelectedBoxLine}></div>}
              </div>
            </div>
          </>
        )}
      </div>
      {menuItem?.extra_tags?.length > 0 && (
        <div
          className={Styles.ExtrasAllergens}
          onClick={handleExtrasAllergense}
        >
          <i className={`consumer-info-circle ${Styles.InfoIcon}`} />
        </div>
      )}
    </div>
  );
};

export default React.memo(ChoiceBox);
