import React from "react";
import T from "i18n-react";
import ProductPricingOptions, {
  onClickPricingOption,
} from "../ProductPricing/ProductPricingOptions";
import Styles from "../styles.module.scss";

interface IProductDetailsDisplayProps {
  productDetails: {
    menu_item_url_photo?: string;
    menu_item_name_public: string;
    menu_item_abv?: number;
    menu_item_description: string;
    menu_item_pricing_options?: Record<string, IPricingOptions>;
  };
  selectedPrice: string;
  onClickPricingOption: onClickPricingOption;
}

function ProductDetailsDisplay({
  productDetails,
  selectedPrice,
  onClickPricingOption,
}: IProductDetailsDisplayProps) {
  return (
    <div className={Styles.ProductDetails}>
      {productDetails.menu_item_url_photo ? (
        <div className={Styles.ImageDiv}>
          <img
            src={productDetails.menu_item_url_photo}
            alt={productDetails.menu_item_name_public}
          />
        </div>
      ) : null}
      <p className={Styles.Descritpion}>
        {productDetails.menu_item_abv ? (
          <span>
            {" "}
            ({T.translate("Menu.ABV")} {productDetails.menu_item_abv / 10}% )
          </span>
        ) : (
          ""
        )}
        <br />
        {productDetails.menu_item_description
          ? productDetails.menu_item_description
          : ""}
      </p>

      <ProductPricingOptions
        menuItemPricingOptions={productDetails.menu_item_pricing_options}
        selectedPrice={selectedPrice}
        onClickPricingOption={onClickPricingOption}
      />
    </div>
  );
}

export default React.memo(ProductDetailsDisplay);
