export const countryPhoneRegex = {
  //eslint-disable-next-line
  "+44": /^(((\+44\s?\d{4}|\(?7\d{3}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
  "+1": /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/,
  GENERIC: /^(?:(?:00|\+)?\d{1,4}|0)\d{8,12}$/,
};

export const formatPhoneNumber = (
  internationalCode: string,
  number: string
) => {
  const phoneNumber = number.replace(/\s/g, "").replace(/[()-]/g, "");
  if (phoneNumber.substring(0, 2) === "00" || phoneNumber[0] === "+") {
    return phoneNumber.replace(/\s/g, "");
  }
  return `${internationalCode}${parseInt(phoneNumber).toString()}`.replace(
    /\s/g,
    ""
  );
};
