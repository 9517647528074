import React from "react";
import Styles from "./ring.module.scss";

export function Ring() {
  return (
    <div className={Styles.Ring}>
      <span></span>
    </div>
  );
}
