import React, { ChangeEvent, RefObject, useEffect, useRef } from "react";
import { blurKeyBoard, onKeyboardPressHandler } from "../../../utils/keyboard";
import T from "i18n-react";
import Styles from "../styles.module.scss";
import { ConnectionType } from "../../../yoello-lib/modules/auth/WebAuth0";

interface IVerificationComponent {
  username: string;
  oneTimeCode: string;
  smsLoading: boolean;
  setOneTimeCode: (value: string) => void;
  resendSMS: () => void;
  connectionType: ConnectionType;
}

function SmsConfirmation({
  username,
  setOneTimeCode,
  oneTimeCode,
  resendSMS,
  connectionType,
  smsLoading,
}: IVerificationComponent) {
  const inputRef = useRef() as RefObject<HTMLInputElement>;

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current?.focus();
    }
    // eslint-disable-next-line
  }, []);
  function changeOneTimeCode(event: ChangeEvent<HTMLInputElement>) {
    setOneTimeCode(event.target.value);
    if (event.target.value.length === 6) {
      blurKeyBoard(event.currentTarget);
    }
  }

  return (
    <div className={Styles.Login}>
      <div>
        <T.p
          className={Styles.Text1}
          text={{
            key:
              connectionType === "sms"
                ? "Login.Texts.SmsVerification"
                : "Login.Texts.emailVerification",
          }}
        />
        <p className={Styles.Text2}>
          {connectionType === "sms"
            ? T.translate("Login.Texts.Yourmobilenumber")
            : T.translate("Login.Texts.youremail")}
          <br />
          <strong>{username}</strong>{" "}
        </p>
      </div>
      <div className={`${Styles.verificationContainer} w-100 mt-5`}>
        <input
          onKeyPress={onKeyboardPressHandler}
          autoComplete="one-time-code"
          ref={inputRef}
          type="number"
          placeholder={T.translate("Login.Texts.EnterCode") as string}
          value={oneTimeCode}
          maxLength={6}
          className={Styles.OtpInput}
          onChange={changeOneTimeCode}
          data-component-name="OneTimeCode"
        />
      </div>
      <div>
        <p
          className={
            smsLoading
              ? `${Styles.ResendCode} ${Styles.Disabled}`
              : Styles.ResendCode
          }
        >
          {connectionType === "sms"
            ? T.translate("Login.Texts.ReceiveCode")
            : T.translate("Login.Texts.recieveEmail")}
          <strong onClick={smsLoading ? () => {} : resendSMS}>
            {T.translate(
              `Login.Texts.${
                connectionType === "sms" ? "ResendSms" : "ResendEmail"
              }`
            )}
          </strong>
        </p>
      </div>
    </div>
  );
}

export default SmsConfirmation;
