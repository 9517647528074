import { Dispatch } from "redux";
import { IAPIDefinitions } from "../types";
import { generateServiceURL } from "../utils";
import { setFeePlans, setUsersVenues, setVenueData, setVenueMerchant, setVenueTypes } from "../../redux/actions/venue.actions";
import { setCurrencies, setCurrency, setLanguage, setLanguages } from "../../redux/actions/localization";
import { addTimezones } from "../../redux/actions/timezone.actions";
import { setFeesMigration, setItemsMigration } from "../../redux/actions/migration.actions";
import { HTTPMethods } from "../../API/API";

export enum APIMerchantDashboard {
    language = "language",
    menus = "menus",
    menuTimings = "menuTimings",
    venues = "venues",
    venueSettings = "venueSettings",
    menusItems = "menusItems",
    menuItemSubcategories = "menuItemSubcategories",
    locations = "locations",
    menuItemCategories = "menuItemCategories",
    menuItemPreferences = "menuItemPreferences",
    currencies = "currencies",
    extraGroups = "extraGroups",
    dietaryTags = "dietaryTags",
    vatRates = "vatRates",
    zReports = "zReports",
    vatReport = 'vatReport',
    tradingTimes = "tradingTimes",
    consumers = "consumers",
    merchants = "merchants",
    invitations = "invitations",
    partnerSync = "partnerSync",
    partnerSyncSearch = "partnerSyncSearch",
    squareLocations = "squareLocations",
    createVenues = "createVenues",
    sendVerificationEmail = "sendVerificationEmail",
    resyncVenue = "resyncVenue",
    resyncMenu = "resyncMenu",
    timezones = "timezones",
    taxes = "taxes",
    orderLimits = "orderLimits",
    orderLimitExceptions = "orderLimitExceptions",
    rawMenuItems = "rawMenuItems",
    venueFees = "venueFees",
    migrationsFees = "migrationsFees",
    migrationItems = "migrationItems",
    discounts = "discounts",
    tipping = "tipping",
    registration = "registration",
    feePlans = "feePlans",
    invoicing = "invoicing",
    subscriptions = "integration_subscriptions",
    menuItemsSearch = "menuItemsSearch",
    menuItemsExtraGroups = "menuItemsExtraGroups",
    eposnowSyncStatus = "eposnowSyncStatus",
    menuItemsExtrasGroups = "menuItemsExtrasGroups",
    menuMigration = "menuMigration",
    collectionPoints = "collectionPoints",
    placedOrders = "placedOrders",
    customersDetails = "customersDetails",
    venueTypes = "venueTypes",
    venueMerchant = 'venueMerchant',
    permissionSet = 'permissionSet',
}

const disabledFields = ["id", "deleted", "serial_id", "metadata_", "created_on", "updated_on"];

export const merchantAPIDefs: IAPIDefinitions = {

};

merchantAPIDefs[APIMerchantDashboard.permissionSet] = {
    baseURL: generateServiceURL('permission_sets'),
    defaultURL: 'permission_sets',
    GET: {},
}

merchantAPIDefs[APIMerchantDashboard.language] = {
    baseURL: generateServiceURL('languages'),
    defaultURL: 'languages',
    dataStorage: {
        selectorKey: () => { return "general.languages"; },
        dispatchFunction: (dispatch: Dispatch<any>, results: any, args) =>
            dispatch(setLanguages(results)),
    },
    GET: {},
    POST: {},
    PUT: {
        disabledFields
    },
    PATCH: {
        disabledFields
    },
};

merchantAPIDefs[APIMerchantDashboard.venueMerchant] = {
    baseURL: generateServiceURL('venues'),
    defaultURL: 'venues',
    GET: {},
    POST: {},
    PUT: {},
    DELETE: {},
}

merchantAPIDefs[APIMerchantDashboard.venues] = {
    baseURL: generateServiceURL("venues"),
    defaultURL: "venues",
    dataStorage: {
        selectorKey: (selectorObject) => {
            if (!selectorObject!?.venue_id) {
                return "";
            }
            return "venue." + selectorObject!.venue_id! + ".venueData";
        },
        dispatchFunction: (dispatch: Dispatch<any>, results: any, args) => {
            if (Array.isArray(results)) {
                dispatch(setUsersVenues(results));
            } else {
                dispatch(setVenueData('venueData', results.id, results));
            }
        },
    },
    childrenAPIs: [{
        apiConfigFunction: function (data): any {
            return {
                query: data.location_id
            };
        },
        key: 'venue_locations',
        extension: APIMerchantDashboard.locations,
        // setValue: (data) => data[0]
    },
    {
        apiConfigFunction: function (data): any {
            return {
                params: { venue_id: data.id },
                query: null
            };
        },
        key: 'venue_settings',
        extension: APIMerchantDashboard.venueSettings,
        setValue: (data) => data[0]
    },
    {
        apiConfigFunction: function (data): any {
            return {
                query: null,
                params: { venue_id: data.id }
            };
        },
        key: 'menu_items',
        extension: APIMerchantDashboard.menusItems,
        setValue: (data) => {
            const cats = data.categories;
            const extraItems = data.menu_item_extra_groups;
            const prefs = data.preferences;
            const subCats = data.subcategories;

            delete data.categories;
            delete data.menu_item_extra_groups;
            delete data.preferences;
            delete data.subcategories;

            return {
                menuItems: Object.keys(data).map((key) => data[key]),
                categories: cats,
                extraItems: extraItems,
                preferences: prefs,
                subcategories: subCats
            };
        }
    }
    ],
    GET: {},
    POST: {},
    PUT: {
        disabledFields
    },
    PATCH: {
        disabledFields
    },
};

const settingsDisabled = [
    "url_website",
    "url_facebook",
    "url_instagram",
    "url_twitter"
]
merchantAPIDefs[APIMerchantDashboard.venueSettings] = {
    baseURL: generateServiceURL("venue_settings"),
    defaultURL: "venue_settings",
    dataStorage: {
        selectorKey: (selectorObject): string => {
            if (!selectorObject!?.venue_id) {
                return "";
            }
            return "venue." + selectorObject!.venue_id! + ".venueSettings";
        },
        dispatchFunction: (dispatch: Dispatch<any>, results: any, args) => {
            /**Temp fix until we have the venue settings id on venue */
            let data = results;
            if (Array.isArray(data)) {
                if (data.length === 0) {
                    return;
                }
                data = results[0];
            }
            if (data?.language_iso) {
                dispatch(setLanguage(data.language_iso));
            }
            if (data?.currency_id) {
                dispatch(setCurrency(data.currency_id));
            }
            dispatch(setVenueData('venueSettings', data.venue_id, data));
        },
        setData: (data) => {
            if (Array.isArray(data)) {
                return data[0];
            }
            return data;
        }
    },
    GET: {},
    POST: {
        disabledFields: settingsDisabled
    },
    PUT: {
        disabledFields: [...settingsDisabled, ...disabledFields]
    },
    PATCH: {
        disabledFields: [...settingsDisabled, ...disabledFields]
    },
};

const menuItemDisabledFields = [
    "extra_tags", "preference_tags", "item_taxes", "extra_items", "extra_groups", "selectedExtraGroup", "is_extra", "subcategories"
];

merchantAPIDefs[APIMerchantDashboard.menusItems] = {
    baseURL: generateServiceURL("menu_items"),
    defaultURL: "menu_items",
    GET: {},
    POST: { disabledFields: menuItemDisabledFields },
    PUT: {
        disabledFields: [...menuItemDisabledFields, ...disabledFields]
    },
    PATCH: {
        disabledFields: [...menuItemDisabledFields, ...disabledFields]
    },
    DELETE: {},
    childrenAPIs: [{
        key: "subcategories",
        extension: APIMerchantDashboard.menuItemSubcategories,
        apiConfigFunction: (data) => {
            return {
                query: undefined
            };
        }
    }, {
        key: 'item_taxes',
        apiConfigFunction: (data) => {
            return {
                query: data.id + "/taxes",
                params: { include_disabled: true }
            };
        },
        extension: APIMerchantDashboard.rawMenuItems
    },
    {
        key: 'extra_items',
        apiConfigFunction: (data) => {
            return {
                query: data.id + "/menu_items_extras_groups",
                params: { include_disabled: true, sort_field: 'sort_position', sort_direction: 'asc' }

            };
        },
        extension: APIMerchantDashboard.rawMenuItems
    },
    {
        key: "preference_tags",
        apiConfigFunction: (data) => {
            return {
                query: data.id + "/menu_item_preferences",
                params: { include_disabled: true }
            };
        },
        extension: APIMerchantDashboard.rawMenuItems
    },
    {
        key: "extra_groups",
        apiConfigFunction: (data) => {
            return {
                query: `search`,
                body: {
                    filters: [
                        {
                            field_name: "id",
                            operator: "=",
                            value: data.id,
                            child: "menu-items",
                        }
                    ]
                },
                overrideMethod: HTTPMethods.POST,
                yoelloAPIParams: undefined,
                params: { include_disabled: true }
            }
        },
        extension: APIMerchantDashboard.extraGroups
    }, {
        key: "menus",
        apiConfigFunction: (data) => {
            return {
                query: "search",
                body: {
                    "filters": [
                        {
                            "field_name": "id",
                            "operator": "=",
                            "value": data.id,
                            "child": "menu-items"
                        }
                    ]
                },
                overrideMethod: HTTPMethods.POST,
                yoelloAPIParams: undefined,
                params: { include_disabled: true }
            }
        },
        extension: APIMerchantDashboard.menus
    }
    ]
};
merchantAPIDefs[APIMerchantDashboard.menuItemSubcategories] = {
    baseURL: generateServiceURL("menu_item_subcategories"),
    defaultURL: "menu_item_subcategories",
    GET: {},
    POST: {},
    PUT: {
        disabledFields
    },
    PATCH: {
        disabledFields
    },
    DELETE: {}
};

merchantAPIDefs[APIMerchantDashboard.locations] = {
    baseURL: generateServiceURL("locations"),
    defaultURL: "locations",
    dataStorage: {
        selectorKey: (selectorObject) => {
            if (!selectorObject!?.venue_id) {
                console.log('No selector obj');
                return "";
            }
            return "venue." + selectorObject!.venue_id! + ".venueLocation";
        },
        dispatchFunction: (dispatch: Dispatch<any>, results: any, args) =>
            dispatch(setVenueData('venueLocation', args![0], results)),
    },
    GET: {},
    POST: {},
    PUT: {
        disabledFields
    },
    PATCH: {
        disabledFields
    },
};

merchantAPIDefs[APIMerchantDashboard.menuItemCategories] = {
    baseURL: generateServiceURL("menu_item_categories"),
    defaultURL: "menu_item_categories",
    GET: {},
    POST: {},
    PUT: {
        disabledFields
    },
    PATCH: {
        disabledFields
    },
};

merchantAPIDefs[APIMerchantDashboard.menuItemPreferences] = {
    baseURL: generateServiceURL("menu_item_preferences"),
    defaultURL: "menu_item_preferences",
    GET: {},
    POST: {},
    PUT: {
        disabledFields
    },
    PATCH: {
        disabledFields
    },
    DELETE: {}
};

merchantAPIDefs[APIMerchantDashboard.currencies] = {
    baseURL: generateServiceURL("currencies"),
    defaultURL: "currencies",
    dataStorage: {
        selectorKey: () => { return "localization.currencies"; },
        dispatchFunction: (dispatch: Dispatch<any>, results: any, args) =>
            dispatch(setCurrencies(results))
    },
    GET: {},
    POST: {},
    PUT: {},
    PATCH: {}
};

const extraGroupsDisabled = [...disabledFields, "selectedItems"];
merchantAPIDefs[APIMerchantDashboard.extraGroups] = {
    baseURL: generateServiceURL("menu_items_extras_groups"),
    defaultURL: "menu_items_extras_groups",
    GET: {},
    POST: {
        disabledFields: extraGroupsDisabled
    },
    PUT: {
        disabledFields: extraGroupsDisabled
    },
    PATCH: {
        disabledFields: extraGroupsDisabled
    },
    DELETE: {}
};

merchantAPIDefs[APIMerchantDashboard.dietaryTags] = {
    baseURL: generateServiceURL("dietary_tags"),
    defaultURL: "dietary_tags",
    GET: {},
    POST: {},
    PUT: {
        disabledFields
    },
    PATCH: {
        disabledFields
    },
};

merchantAPIDefs[APIMerchantDashboard.vatRates] = {
    baseURL: generateServiceURL("vat_rates"),
    defaultURL: "vat_rates",
    GET: {},
    POST: {},
    PUT: {
        disabledFields
    },
    PATCH: {
        disabledFields
    },
};

merchantAPIDefs[APIMerchantDashboard.zReports] = {
    baseURL: generateServiceURL("reports"),
    defaultURL: "reports",
    GET: {},
    POST: {},
    PUT: {},
    PATCH: {}
};

merchantAPIDefs[APIMerchantDashboard.vatReport] = {
    baseURL: generateServiceURL('reports'),
    defaultURL: `reports/vat/`,
    GET: {},
};

merchantAPIDefs[APIMerchantDashboard.tradingTimes] = {
    baseURL: generateServiceURL("trading_times"),
    defaultURL: "trading_times",
    GET: {},
    POST: {},
    PUT: {
        disabledFields
    },
    PATCH: {
        disabledFields
    },
};

merchantAPIDefs[APIMerchantDashboard.consumers] = {
    baseURL: generateServiceURL("consumers"),
    defaultURL: "consumers",
    GET: {},
    POST: {},
    PUT: {},
    PATCH: {}
};

merchantAPIDefs[APIMerchantDashboard.merchants] = {
    baseURL: generateServiceURL("merchants"),
    defaultURL: "merchants",
    dataStorage: {
        selectorKey: () => "merchants",
        dispatchFunction: (dispatch, result, args) => {
            if (Array.isArray(result) && result.length > 0) {
                dispatch(setVenueMerchant(result[0]));
            }
        }
    },
    GET: {},
    POST: {},
    PUT: {},
    PATCH: {}
};

merchantAPIDefs[APIMerchantDashboard.invitations] = {
    baseURL: generateServiceURL("merchants"),
    defaultURL: "merchants",
    GET: {},
    POST: {},
    PUT: {},
    PATCH: {}
};

merchantAPIDefs[APIMerchantDashboard.partnerSync] = {
    baseURL: generateServiceURL("partner_sync_status"),
    defaultURL: "partner_sync_status",
    GET: {},
    POST: {},
    PUT: {},
    PATCH: {}
};

merchantAPIDefs[APIMerchantDashboard.timezones] = {
    baseURL: generateServiceURL("time_zones"),
    defaultURL: "time_zones",
    dataStorage: {
        selectorKey: () => "timezones",
        dispatchFunction: (dispatch, result, args) => {
            dispatch(addTimezones(result as any));
        }
    },
    GET: {},
    POST: {},
    PUT: {},
    PATCH: {}
};

merchantAPIDefs[APIMerchantDashboard.partnerSyncSearch] = {
    baseURL: generateServiceURL("partner_sync_status"),
    defaultURL: "partner_sync_status/search",
    POST: {},
}


merchantAPIDefs[APIMerchantDashboard.squareLocations] = {
    //@TODO - environment changes
    baseURL: generateServiceURL(undefined, "square"),
    defaultURL: "callback",
    GET: {},
}

merchantAPIDefs[APIMerchantDashboard.createVenues] = {
    //@TODO - environment changes
    baseURL: generateServiceURL(undefined, "square"),
    defaultURL: "venues",
    POST: {},
    PATCH: {},
    GET: {},
}

merchantAPIDefs[APIMerchantDashboard.sendVerificationEmail] = {
    baseURL: generateServiceURL("auth0"),
    defaultURL: "verification",
    POST: {},
}


merchantAPIDefs[APIMerchantDashboard.resyncVenue] = {
    baseURL: generateServiceURL(undefined, "square"),
    defaultURL: "venues/resync",
    POST: {},
}


merchantAPIDefs[APIMerchantDashboard.resyncMenu] = {
    baseURL: generateServiceURL(undefined, "square"),
    defaultURL: "menus/import",
    POST: {},
}

merchantAPIDefs[APIMerchantDashboard.taxes] = {
    baseURL: generateServiceURL("tax"),
    defaultURL: "taxes",
    GET: {},
    POST: {},
    PUT: {
        disabledFields
    },
    PATCH: {
        disabledFields
    },
    DELETE: {}
};
const disabledDiscountsFields = [...disabledFields, "apply_after_tax",
    "code",
    "created_on",
    "description",
    "max_number_of_uses",
    "minimum_order_value",
    "number_of_uses",
    "type",
    "updated_on",
    "value",
    "venue_id"];
merchantAPIDefs[APIMerchantDashboard.discounts] = {
    baseURL: generateServiceURL("discounts"),
    defaultURL: "discounts",
    GET: {},
    POST: {},
    PUT: { disabledFields: disabledDiscountsFields },
    PATCH: { disabledFields: disabledDiscountsFields },
    DELETE: {}
};
merchantAPIDefs[APIMerchantDashboard.feePlans] = {
    baseURL: generateServiceURL("processing_fees_plans"),
    defaultURL: "processing_fees_plans",
    GET: {},
    POST: {},
    PUT: { disabledFields },
    PATCH: { disabledFields },
    DELETE: {},
    dataStorage: {
        selectorKey: () => "feePlans",
        dispatchFunction: (dispatch: Dispatch<any>, results: any, args) => {
            if (Array.isArray(results)) {
                dispatch(setFeePlans(results));
            }
        }

    }
};
merchantAPIDefs[APIMerchantDashboard.invoicing] = {
    baseURL: generateServiceURL("invoicing"),
    defaultURL: "invoicing/stripe",
    POST: {}
};
merchantAPIDefs[APIMerchantDashboard.subscriptions] = {
    baseURL: generateServiceURL("integration_subscriptions"),
    defaultURL: "integration_subscriptions",
    GET: {}
};
merchantAPIDefs[APIMerchantDashboard.orderLimits] = {
    baseURL: generateServiceURL('order_limits'),
    defaultURL: 'order_limits_defaults',
    GET: {},
    PUT: {
        disabledFields,
    },
    POST: {},
};
///order_limits-api/v2/order_limits_defaults
merchantAPIDefs[APIMerchantDashboard.orderLimitExceptions] = {
    baseURL: generateServiceURL('order_limits'),
    defaultURL: 'order_limits_exceptions',
    GET: {},
    PUT: {
        disabledFields,
    },
    POST: {},
};

merchantAPIDefs[APIMerchantDashboard.rawMenuItems] = {
    baseURL: generateServiceURL("menu_items"),
    defaultURL: "menu_items",
    GET: {},
    POST: {},
    PUT: {},
    PATCH: {},
    DELETE: {}
};
const disabledFeeFields = [...disabledFields, "venue_id", "fee_type", "enabled", "updated_on", "metadata", "serial_id", "created_on"]
merchantAPIDefs[APIMerchantDashboard.venueFees] = {
    baseURL: generateServiceURL("venue_fees"),
    defaultURL: "venue_fees",
    GET: {},
    POST: {},
    PUT: { disabledFields: disabledFeeFields },
    PATCH: { disabledFields: disabledFeeFields },
    DELETE: {}
};

merchantAPIDefs[APIMerchantDashboard.migrationsFees] = {
    baseURL: generateServiceURL("data_migrations"),
    defaultURL: "fees",
    dataStorage: {
        selectorKey: () => "",
        dispatchFunction: (dispatch: Dispatch<any>, results: any, args) =>
            dispatch(setFeesMigration(results.fee_migration_needed))
    },
    GET: {},
    POST: {},
}

merchantAPIDefs[APIMerchantDashboard.migrationItems] = {
    baseURL: generateServiceURL("data_migrations"),
    defaultURL: "taxes",
    dataStorage: {
        selectorKey: () => "",
        dispatchFunction: (dispatch: Dispatch<any>, results: any, args) =>
            dispatch(setItemsMigration(results.taxes_migration_needed))
    },
    GET: {},
    POST: {},
}

merchantAPIDefs[APIMerchantDashboard.tipping] = {
    baseURL: generateServiceURL('tipping_options'),
    defaultURL: "tipping_options",
    GET: {},
    POST: {},
    PUT: {},
};

merchantAPIDefs[APIMerchantDashboard.registration] = {
    baseURL: generateServiceURL("registration"),
    defaultURL: "registration",
    GET: {},
    POST: {},
    PATCH: {}
};

merchantAPIDefs[APIMerchantDashboard.menuItemsSearch] = {
    baseURL: generateServiceURL("menu_items"),
    defaultURL: "menu_items/search",
    POST: {},
};

merchantAPIDefs[APIMerchantDashboard.menuItemsExtraGroups] = {
    baseURL: generateServiceURL("menu_items"),
    defaultURL: "menu_items",
    PATCH: {},
};

merchantAPIDefs[APIMerchantDashboard.eposnowSyncStatus] = {
    baseURL: generateServiceURL("partner_sync_status"),
    defaultURL: "partner_sync_status/search",
    POST: {},
};

const menuDisabled = [...disabledFields, "selectedMenuItemIDs", "menuTiming"];
merchantAPIDefs[APIMerchantDashboard.menus] = {
    baseURL: generateServiceURL("menus"),
    defaultURL: "menus",
    GET: {},
    POST: { disabledFields: menuDisabled },
    PUT: { disabledFields: menuDisabled },
    PATCH: { disabledFields: menuDisabled },
    DELETE: {}
}
merchantAPIDefs[APIMerchantDashboard.menuTimings] = {
    baseURL: generateServiceURL("menu_timings"),
    defaultURL: "menu_timings",
    GET: {},
    POST: {},
    PUT: {},
    PATCH: {},
    DELETE: {}
}

merchantAPIDefs[APIMerchantDashboard.menuMigration] = {
    baseURL: generateServiceURL("data_migrations"),
    defaultURL: "menus",
    POST: {},
}

merchantAPIDefs[APIMerchantDashboard.menuItemsExtrasGroups] = {
    baseURL: generateServiceURL("menu_items_extras_groups"),
    defaultURL: "menu_items_extras_groups",
    PATCH: {},
};

merchantAPIDefs[APIMerchantDashboard.collectionPoints] = {
    baseURL: generateServiceURL("collection_points"),
    defaultURL: "collection_points",
    GET: {},
    POST: { disabledFields },
    PUT: { disabledFields },
    PATCH: { disabledFields },
    DELETE: {}
};

merchantAPIDefs[APIMerchantDashboard.placedOrders] = {
    baseURL: generateServiceURL("placed_orders"),
    defaultURL: "placed_orders",
    GET: {},
    POST: { disabledFields },
    PUT: { disabledFields },
    PATCH: { disabledFields },
    DELETE: {}
};
merchantAPIDefs[APIMerchantDashboard.customersDetails] = {
    baseURL: generateServiceURL("consumers"),
    defaultURL: "consumers",
    GET: {},
    POST: { disabledFields },
    PUT: { disabledFields },
    PATCH: { disabledFields },
    DELETE: {}
};
merchantAPIDefs[APIMerchantDashboard.venueTypes] = {
    baseURL: generateServiceURL("venues"),
    defaultURL: "types",
    GET: {},
    dataStorage: {
        selectorKey: () => "venueTypes",
        dispatchFunction: (dispatch: Dispatch<any>, results: any, args) => {
            if (Array.isArray(results)) {
                dispatch(setVenueTypes(results))
            }
        }
    }
};
