import React from "react";
import { SwipeableDrawer, makeStyles } from "@material-ui/core";
import UnmountDrawerWrapper from "./UnmountDrawerWrapper";
import DrawerHeader from "./DrawerHeader";
interface IDrawerComponent {
  children: React.ReactNode;
  isOpen: boolean;
  closeDrawer: () => void;
  instructions?: string[]; //First item is regular, second is bold.
  title?: string;
  variant?: string;
  isPersistent?: boolean;
  instructionClassName?: string;
}

const useStyles = makeStyles({
  DrawerOverlay: {
    zIndex: 999,
    backgroundColor: "#000",
    opacity: 0,
    position: "fixed",
    left: "0",
    top: "0",
    height: "100vh",
    width: "100vw",
    transition: "350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    visibility: "hidden",
    "&.open": {
      opacity: 0.5,
      visibility: "visible",
    },
  },
});

const DrawerComponent = (props: IDrawerComponent) => {
  const classes = useStyles();
  return (
    <>
      {props.isPersistent && (
        <div
          className={`${classes.DrawerOverlay} ${
            props.isOpen ? "open" : "closed"
          }`}
          onClick={props.closeDrawer}
        />
      )}

      <SwipeableDrawer
        anchor={"bottom"}
        open={props.isOpen}
        onClose={props.closeDrawer}
        onOpen={() => null}
        variant={props.isPersistent ? "persistent" : undefined}
        disableSwipeToOpen={true}
        disableDiscovery={true}
        transitionDuration={350}
        id="CardDetailsDrawer"
        style={{ borderRadius: "16px" }}
        PaperProps={{ square: false, style: { borderRadius: "24px 24px 0 0" } }}
      >
        <UnmountDrawerWrapper closeDrawer={props.closeDrawer}>
          <>
            <DrawerHeader
              instructionClassName={props.instructionClassName}
              onClose={props.closeDrawer}
              title={props.title}
              instructions={props.instructions}
            />
            {props.children}
          </>
        </UnmountDrawerWrapper>
      </SwipeableDrawer>
    </>
  );
};

export default DrawerComponent;
