import { useState } from "react";
import Payment from "payment";

const valueAsNumber = (value = "") => {
  return value.replace(/\D+/g, "");
};

const useCardDetailsValidation = () => {
  const [issuer, setIssuer] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [fullName, setFullName] = useState<string>("");
  const [expiry, setExpiry] = useState("");
  const [cvv, setCvv] = useState("");
  const [cleanCardNumber, setCleanCardNumber] = useState("");
  const [cleanCvv, setCleanCvv] = useState("");
  const [cleanExpiry, setCleanExpiry] = useState("");

  const checkForErrors = () => {
    return {
      isCardValid: cardNumber.length >= 12, //@TODO - better validation
      isNameValid: fullName.length > 3, //@TODO - better validation
      isExpiryValid: Payment.fns.validateCardExpiry(expiry),
      isCvvValid: Payment.fns.validateCardCVC(cvv, issuer),
    };
  };

  const formatCreditCardNumber = (value: string) => {
    if (!value) {
      setCardNumber(value);
      return;
    }

    const cardIssuer = Payment.fns.cardType(value);
    const cleanValue = valueAsNumber(value);
    let nextValue;

    switch (cardIssuer) {
      case "amex":
        nextValue = `${cleanValue.slice(0, 4)} ${cleanValue.slice(
          4,
          10
        )} ${cleanValue.slice(10, 15)}`;
        break;
      case "dinersclub":
        nextValue = `${cleanValue.slice(0, 4)} ${cleanValue.slice(
          4,
          10
        )} ${cleanValue.slice(10, 14)}`;
        break;
      case "mastercard":
      case "visa":
        nextValue = `${cleanValue.slice(0, 4)} ${cleanValue.slice(
          4,
          8
        )} ${cleanValue.slice(8, 12)} ${cleanValue.slice(12, 16)}`;
        break;
      default:
        nextValue = `${cleanValue.slice(0, 4)} ${cleanValue.slice(
          4,
          8
        )} ${cleanValue.slice(8, 12)} ${cleanValue.slice(12, 19)}`;
        break;
    }
    setIssuer(cardIssuer);
    setCleanCardNumber(valueAsNumber(nextValue));
    setCardNumber(nextValue.trim());
  };

  const formatCVC = (value: string) => {
    const cleanValue = valueAsNumber(value);
    setCleanCvv(cleanValue);
    let maxLength = issuer === "amex" ? 4 : 3;
    setCvv(cleanValue.slice(0, maxLength));
  };

  const formatExpirationDate = (value: string) => {
    const cleanValue = valueAsNumber(value);
    setCleanExpiry(cleanValue);
    if (cleanValue.length >= 3) {
      setExpiry(`${cleanValue.slice(0, 2)}/${cleanValue.slice(2, 4)}`);
    } else {
      setExpiry(cleanValue);
    }
  };

  const clearForm = () => {
    setIssuer("");
    setCardNumber("");
    setCvv("");
    setExpiry("");
    setCleanCardNumber("");
    setCleanCvv("");
    setCleanExpiry("");
    setFullName("");
  };

  return {
    cardNumber,
    cvv,
    expiry,
    issuer,
    fullName,
    setFullName,
    formatCreditCardNumber,
    formatCVC,
    formatExpirationDate,
    cleanCardNumber,
    cleanCvv,
    cleanExpiry,
    clearForm,
    checkForErrors,
  };
};

export default useCardDetailsValidation;
