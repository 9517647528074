import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./style.scss";

// redux imports
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import promiseMiddleware from "redux-promise";
import ReduxThunk from "redux-thunk";
import reducers from "./redux/Reducers/index";

import * as Sentry from "@sentry/browser";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
import packageJSON from "../package.json";
import ModalProvider from "./Components/Modal/ModalContext";
import { SnackbarProvider } from "./yoello-lib/modules/components/snackbar/SnackbarContext";
import { Auth0Provider } from "./yoello-lib/modules/auth/Auth0";
import DrawerProvider from "./Components/Drawer/DrawerContext";
import { LOGIN } from "./constants/URL.const";
import SessionClient from "./yoello-lib/modules/auth/SessionClient";
import TagManager from "react-gtm-module";

/**Each time a user comes to the app we create them a session id */
SessionClient.createSession();

const tagManagerArgs = {
  gtmId: "GTM-M74D97C",
};

if (process.env.REACT_APP_STAGE !== "dev") {
  TagManager.initialize(tagManagerArgs);
}

// just add a comment to see if protected branch works
// const loadState = () => {
//     try {
//         const serializedState = localStorage.getItem('root21');
//         if (serializedState === null) {
//             return undefined;
//         }
//         return JSON.parse(serializedState);
//     } catch (err) {
//         return undefined;
//     }
// };
// const saveState = (state) => {
//     try {
//         const serializedState = JSON.stringify(state);
//         localStorage.setItem('root21', serializedState);
//     } catch {
//         // ignore write errors
//     }
// };
// const persistConfig = {
//     key: 'root21',
//     storage,
// };
// const persistedReducer = persistReducer(persistConfig, reducers);
// let store = createStore(persistedReducer, applyMiddleware(promiseMiddleware, ReduxThunk));
// const persistedState = loadState();
// const store = createStore(
//     persistedReducer, applyMiddleware(promiseMiddleware, ReduxThunk),
// );
// store.subscribe(() => {
//     saveState(
//         store.getState()
//     );
// });
// let persistor = persistStore(store);

// ReactDOM.render(<App />, document.getElementById('root'));
if (window.location.hostname !== "localhost") {
  console.log(
    `Sentry is enabled in environment: ${process.env.REACT_APP_STAGE} - version: ${packageJSON.version}`
  );
  Sentry.init({
    dsn:
      "https://0c02440fd4bc4aff98a9b58541dd347b@o329619.ingest.sentry.io/5215100",
    environment: process.env.REACT_APP_STAGE,
    release: `consumer-web@${packageJSON.version}`,
  });
}
const persistConfig = {
  key: "root",
  storage,
  blacklist: ["ShowUp", "MenuItem", "Preferences", "Notifications"],
};
const persistedReducer = persistReducer(persistConfig, reducers);
let store = createStore(
  persistedReducer,
  applyMiddleware(promiseMiddleware, ReduxThunk)
);
let persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <Auth0Provider
      config={{
        domain: process.env.REACT_APP_AUTH0_DOMAIN,
        clientID: process.env.REACT_APP_AUTH0_CLIENTID,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        redirectUri: `${window.location.origin}/login/redirect`,
        scope: "openid profile",
      }}
      isRefreshable={true}
      tokenKey={`user_token_${window.location.host}`}
      loginURL={LOGIN}
    >
      <SnackbarProvider>
        <ModalProvider>
          <DrawerProvider>
            <PersistGate loading={null} persistor={persistor}>
              <App />
            </PersistGate>
          </DrawerProvider>
        </ModalProvider>
      </SnackbarProvider>
    </Auth0Provider>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
