import React from "react";
import { makeStyles } from "@material-ui/core";
import T from "i18n-react";
import { AlertStatusCard } from "../../../yoello-lib/modules/components/UI/Alerts/AlertStatusCard";

const useStyles = makeStyles({
  root: {
    margin:"0 0.5rem",
    "& >div:first-child":{
      borderRadius:"12px"
    },
    "& .MuiAlert-message":{
      color:"#F65164"
    }
  },
});

const EmptyMinExtras = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AlertStatusCard  alertTitle={T.translate("Menu.extras.emptyList")} alertMessage={T.translate("Menu.extras.speakToStaff")} alertProps={{
        color:"error",
        severity:"error"
      }}/>
      
    </div>
  );
};

export default EmptyMinExtras;
