import React, { ComponentType } from "react";
import { ModalContext } from "./ModalContext";

/**HoC for handling the modal context to be used for class components */
const withModalWrapper = (Component: ComponentType) => (props: any) => (
  <ModalContext.Consumer>
    {(modal) => <Component {...props} modalContext={modal} />}
  </ModalContext.Consumer>
);

export default withModalWrapper;
