import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import T from "i18n-react";

import Button from "../../../../Widgets/Button";
import Drawer from "../../../../Drawer";
import {
  countryCodes,
  ICountryCode,
} from "../../../../../constants/countryCodes.const";

interface IAddressValidation {
  line1IsValid: boolean;
  cityIsValid: boolean;
  countryCodeIsValid: boolean;
  postCodeIsValid: boolean;
}

const defaultValidationState = {
  line1IsValid: true,
  cityIsValid: true,
  countryCodeIsValid: true,
  postCodeIsValid: true,
};

interface IBillingAddressDrawer {
  classes: any;
  setLine1: (value: string) => void;
  setCity: (value: string) => void;
  setCountryCode: (value: string) => void;
  setPostCode: (value: string) => void;
  isCardDetailsDrawerOpen: boolean;
  isBillingDetailsOpen: boolean;
  setIsBillingDetailsOpen: (value: boolean) => void;
  openConfirmDetails: () => void;
  checkForAddressErrors: () => IAddressValidation;
}

const BillingAddressDrawer = ({
  classes,
  setLine1,
  setCity,
  setCountryCode,
  setPostCode,
  isCardDetailsDrawerOpen,
  isBillingDetailsOpen,
  setIsBillingDetailsOpen,
  openConfirmDetails,
  checkForAddressErrors,
}: IBillingAddressDrawer) => {
  const [addressValidation, setAddressValidation] = useState<
    IAddressValidation
  >(defaultValidationState);

  const onButtonClick = () => {
    const validation = checkForAddressErrors();
    setAddressValidation(validation);
    if (
      !validation.line1IsValid ||
      !validation.cityIsValid ||
      !validation.countryCodeIsValid ||
      !validation.postCodeIsValid
    ) {
      return;
    } else {
      openConfirmDetails();
    }
  };

  return (
    <Drawer
      isPersistent
      isOpen={isCardDetailsDrawerOpen && isBillingDetailsOpen}
      closeDrawer={() => setIsBillingDetailsOpen(false)}
      title="Card Details"
      instructions={["Payment.EnterYour", "Forms.BillingAddress"]}
    >
      {isCardDetailsDrawerOpen && (
        <div className={classes.FormWrapper}>
          <div className={classes.InputsWrapper}>
            <TextField
              error={!addressValidation.line1IsValid}
              helperText={
                addressValidation.line1IsValid
                  ? ""
                  : T.translate(
                      "Forms.ValidationErrors.StreetAddress"
                    ).toString()
              }
              placeholder={T.translate("Forms.StreetAddress").toString()}
              className={classes.Input}
              InputProps={{ disableUnderline: true }}
              onChange={(e) => setLine1(e.target.value)}
              onBlur={() =>
                setAddressValidation({
                  ...addressValidation,
                  line1IsValid: true,
                })
              }
            />
            <TextField
              error={!addressValidation.cityIsValid}
              helperText={
                addressValidation.cityIsValid
                  ? ""
                  : T.translate("Forms.ValidationErrors.City").toString()
              }
              placeholder={T.translate("Forms.City").toString()}
              className={classes.Input}
              InputProps={{ disableUnderline: true }}
              onChange={(e) => setCity(e.target.value)}
              onBlur={() =>
                setAddressValidation({
                  ...addressValidation,
                  cityIsValid: true,
                })
              }
            />

            <select
              placeholder={T.translate("Forms.Country").toString()}
              className={`${classes.Select} ${
                addressValidation.countryCodeIsValid ? "" : classes.Error
              }`}
              onChange={(e) => setCountryCode(e.target.value)}
              onBlur={() =>
                setAddressValidation({
                  ...addressValidation,
                  countryCodeIsValid: true,
                })
              }
            >
              <option disabled selected>
                {T.translate("Forms.SelectCountry")}
              </option>
              {countryCodes.map((country: ICountryCode) => (
                <option value={country.alpha3}>{country.name}</option>
              ))}
            </select>
            <TextField
              error={!addressValidation.postCodeIsValid}
              helperText={
                addressValidation.postCodeIsValid
                  ? ""
                  : T.translate("Forms.ValidationErrors.Postcode").toString()
              }
              placeholder={T.translate("Forms.PostCode").toString()}
              className={classes.Input}
              InputProps={{ disableUnderline: true }}
              onChange={(e) => setPostCode(e.target.value)}
              onBlur={() =>
                setAddressValidation({
                  ...addressValidation,
                  postCodeIsValid: true,
                })
              }
            />
            <Button
              isYoelloBtn
              id="confirm-address"
              title="Continue"
              onClick={onButtonClick}
            />
          </div>
        </div>
      )}
    </Drawer>
  );
};

export default BillingAddressDrawer;
