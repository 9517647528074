import React, { useEffect, useState } from "react";

import T from "i18n-react";

import VerificationComponent from "./VerificationComponent";
import useGetLoginParams from "../useGetLoginParams";

import { LOGIN_REDIRECT, LOGINV2 } from "../../../constants/URL.const";

import { useAuth0 } from "../../../yoello-lib/modules/auth/Auth0";
import useGenerateNavPath from "../../../customHooks/useGenerateNavPath";
import { useSnackbarMessages } from "../../../yoello-lib/modules/components/snackbar/SnackbarContext";

const VerifyView = () => {
  const { passwordlessLogin, passwordlessVerifiySMS, logout } = useAuth0()!;
  const { generateNavPath } = useGenerateNavPath();
  const { method, username } = useGetLoginParams();

  const [smsLoading, setSmsLoading] = useState(false);
  const [oneTimeCode, setOneTimeCode] = useState("");

  const { sendSnackbarMessage } = useSnackbarMessages();

  const resendSMS = async () => {
    if (smsLoading) {
      sendSnackbarMessage(
        T.translate("Login.Errors.alreadySentCode") as string,
        "error"
      );
      return;
    }
    try {
      setSmsLoading(true);
      await passwordlessLogin(username, method);
      sendSnackbarMessage(
        T.translate("Login.Errors.codeResent") as string,
        "success"
      );
    } catch (e) {
      sendSnackbarMessage(
        T.translate("Login.Errors.ResendLoginFailed") as string,
        "error"
      );
      setSmsLoading(false);
      logout();
    }
    setTimeout(() => {
      setSmsLoading(false);
    }, 30000);
  };

  async function verifySMSCode() {
    const redirectUrl = `${
      document.location.origin
    }${LOGIN_REDIRECT}?returnPath=${encodeURIComponent(
      generateNavPath(LOGINV2, {
        username,
        method,
      })
    )}`;

    try {
      await passwordlessVerifiySMS(username, oneTimeCode, method, redirectUrl);
    } catch (error) {
      setSmsLoading(false);
      sendSnackbarMessage(
        T.translate("Login.Errors.InvalidCode").toString(),
        "error"
      );
    } finally {
      setOneTimeCode("");
    }
  }

  useEffect(() => {
    if (oneTimeCode.length === 6) {
      setSmsLoading(true);
      verifySMSCode();
    }
    //eslint-disable-next-line
  }, [oneTimeCode]);

  return (
    <div>
      <VerificationComponent
        username={username}
        oneTimeCode={oneTimeCode}
        setOneTimeCode={setOneTimeCode}
        resendSMS={resendSMS}
        connectionType={method}
        smsLoading={smsLoading}
      />
    </div>
  );
};

export default VerifyView;
