

export interface ITax {
    venue_id: string;
    tax_band_name: string;
    tax_percentage: number;
    tax_inclusion_type: ITaxType;
    enabled: boolean;
    deleted: boolean;
    id?: string;
}

export enum ITaxType {
    INCLUSIVE = "INCLUSIVE",
    ADDITIVE = "ADDITIVE"
}