import React from "react";
import { useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { ORDERS } from "../../constants/URL.const";
import { HTTPMethods } from "../../yoello-lib/modules/API/API";
import APIExtensions from "../../yoello-lib/modules/API/APIDefs";
import useAPI from "../../yoello-lib/modules/API/useAPI";
import { useAuth0 } from "../../yoello-lib/modules/auth/Auth0";
import OrderDetailsSkeleton from "../Widgets/Loading/OrderDetails";
import { OrderDetails } from "./OrderDetails/OrderDetails";

export function OrderDetailsContainer({ isStartRoute }) {
  let { id } = useParams<{ id: string }>();
  const { retrieveToken } = useAuth0()!;
  let { path } = useRouteMatch();

  const { isLoading, apiData, callAPI, isError } = useAPI<any>(
    APIExtensions.consumerOrders,
    retrieveToken,
    { onMountDisabled: true }
  );

  useEffect(() => {
    callAPI(HTTPMethods.GET, { query: id });
    //eslint-disable-next-line
  }, []);

  if (isError) {
    return <Redirect to={ORDERS} />;
  }

  if (isLoading || !apiData) {
    return <OrderDetailsSkeleton />;
  }

  return (
    <Switch>
      <Route path={path}>
        <OrderDetails isStartRoute={isStartRoute} orderItem={apiData} />
      </Route>
    </Switch>
  );
}
