import { useState } from "react";
import Axios from "axios";
import { useSelector } from "react-redux";

import { generateSquareEndpoint } from "./generateEndpoints";

import { usePaymentState } from "../../Components/Payment/PaymentStore";

export interface ICardDetailsItem {
  card_brand: string;
  created: string;
  last_4: string;
  consumer_linked_to_square_account: boolean;
}

const useSquareUserCard = () => {
  const venue = useSelector((state: any) => state.Venue);
  const { retrieveToken } = usePaymentState();
  const [verificationToken, setVerificationToken] = useState();
  const url = generateSquareEndpoint("consumers/cards");

  const parseCardToDetails = (detailsItem: any) => {
    return {
      card_type: detailsItem.card_brand.toLowerCase(),
      created_on: detailsItem.created,
      masked_card_number: detailsItem.last_4.toString(),
      name_on_card: "",
      consumer_linked_to_square_account:
        detailsItem.consumer_linked_to_square_account,
      card_nonce: detailsItem.card_nonce,
    };
  };

  const getCard = async (token: string) => {
    try {
      const cardDetails = await Axios.get(url, {
        params: { venue_id: venue.id },
        headers: { Authorization: `Bearer ${token}` },
      });
      const detailsItem = cardDetails.data.data.payment_accounts_details[0];
      return parseCardToDetails(detailsItem);
    } catch {
      return null;
    }
  };

  const createCard = (
    card_nonce: string,
    legal_first_name: string,
    legal_last_name: string
  ) => {
    return retrieveToken().then((userToken) => {
      return Axios.post(
        url,
        {
          card_nonce,
          legal_first_name,
          legal_last_name,
        },
        {
          headers: { Authorization: `Bearer ${userToken}` },
          params: { venue_id: venue.id },
        }
      );
    });
  };

  return {
    createCard,
    getCard,
    parseCardToDetails,
    setVerificationToken,
    verificationToken,
  };
};

export default useSquareUserCard;
