import { useSelector } from "react-redux";
import {
  generatePlaceOrderArray,
  getPlaceOrderItem,
} from "../utils/ordersController";

export function useCartAvailability() {
  const ExtraMenuItem = useSelector((state: any) => state.MenuItemsAsExtra);
  const cartItems = useSelector((state: any) => state.Cart);

  function checkAvailablity(menuBody: any) {
    const disabledItems = [];
    if (!menuBody) {
      return {
        isSuccess: false,
        disabledItems,
      };
    }
    try {
      const placeOrderArray = generatePlaceOrderArray(cartItems, ExtraMenuItem);
      const menuItems = menuBody.menu_items;
      const menuItemExtraGroups = menuBody.menu_item_extra_groups;
      const menuItemPreferencesGroups = menuBody.menu_item_preferences;
      const menuItemsAsExtras = menuBody.menu_items_as_extras;

      if (placeOrderArray.length > 0) {
        for (const item of placeOrderArray) {
          const orderItem = getPlaceOrderItem(
            item,
            menuItems,
            menuItemExtraGroups,
            menuItemPreferencesGroups,
            menuItemsAsExtras
          );
          if (orderItem.isDisabled) {
            const orderMenuItem = orderItem.menuItem as IMenuItem;
            console.log(`disabledItems`, orderMenuItem);
            disabledItems.push(orderMenuItem.menu_item_id);
          }
        }
      }
      return {
        isSuccess: disabledItems.length > 0 ? false : true,
        disabledItems,
      };
    } catch (error) {
      return {
        isSuccess: false,
        disabledItems: cartItems.map((item) => item.menuItem.menu_item_id),
      };
    }
  }
  return {
    checkAvailablity,
  };
}
