import React from "react";
import Styles from "../styles.module.scss";
import T from "i18n-react";

interface IInfoArray {
  key: string;
  value: string;
}

interface IOrderTypeProps {
  infoArray: IInfoArray[];
  handleButtonClick: () => void;
  HeaderComponent?: React.ReactElement;
}

function OrderType({
  infoArray,
  handleButtonClick,
  HeaderComponent,
}: IOrderTypeProps) {
  function renderFlexBoxs(infoArray: IInfoArray[]) {
    return infoArray
      .filter((info) => !(info.value === null || info.value === "null"))
      .map((item, i) => {
        return (
          <div key={`${i}`} className={Styles.OrderTypeInfo}>
            {i > 0 && <div className={Styles.OrderTypeInfoSpacing}></div>}
            <div>
              {item.key.length > 0 && (
                <p className={Styles.OrderTypeInfoKey}>
                  {T.translate(item.key)}
                </p>
              )}
              <p className={Styles.OrderTypeInfoValue}>{item.value} </p>
            </div>
          </div>
        );
      });
  }
  return (
    <div className={Styles.OrderTypeDiv} onClick={handleButtonClick}>
      {HeaderComponent}
      <div className={Styles.OrderTypeInfoDiv}>{renderFlexBoxs(infoArray)}</div>
    </div>
  );
}

export default OrderType;
