import React from 'react';
import Lottie from 'react-lottie';

import animationData from '../../../lottie/loading.json'

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};
function Loading(props) {
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
        }}
        className="loadingContainer"
        >
            <div className="LottieLoading" style={{
                backgroundColor: 'unset'
            }}>
                <Lottie options={defaultOptions}
                    height={"100%"}
                    width={"100%"}
                    isStopped={false}
                    isPaused={false}
                    isClickToPauseDisabled={true}
                    speed={2}

                />
            </div>
        </div>
    );
}

export default Loading;