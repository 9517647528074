import React from "react";
import SquareFormContainer from "./SquareFormContainer";

import {
  setIsCardDetailsDrawerOpen,
  setSelectedPaymentMethod,
  setIsPaymentDrawerOpen,
} from "../../../reducer/PaymentActions";

export interface ISquarePaymentForm {
  square: any;
  isOpen: boolean;
  dispatch: React.Dispatch<any>;
}

const SquarePaymentForm = (props: ISquarePaymentForm) => {
  const closeDrawer = () => {
    props.dispatch(setIsCardDetailsDrawerOpen(false));
    props.dispatch(setIsPaymentDrawerOpen(true));
    props.dispatch(setSelectedPaymentMethod(null));
  };

  return (
    <SquareFormContainer
      square={props.square}
      isOpen={props.isOpen}
      closeDrawer={closeDrawer}
    />
  );
};

export default SquarePaymentForm;
