import React, { useEffect } from "react";
import ProductPriceOption from "./ProductPriceOption";

interface IProductPricingOptionsProps {
  menuItemPricingOptions?: Record<string, IPricingOptions>;
  selectedPrice: string;
  onClickPricingOption: onClickPricingOption;
}

export type onClickPricingOption = (
  item: string,
  data: IPricingOptions
) => void;
//pass through the pricing options
//pass through the select pricing option
//set up map for all the pricing options
function ProductPricingOptions({
  menuItemPricingOptions,
  selectedPrice,
  onClickPricingOption,
}: IProductPricingOptionsProps) {
  useEffect(() => {
    if (menuItemPricingOptions !== undefined) {
      // if (Object.keys(menuItemPricingOptions).length === 1) {
      const firstKey = Object.keys(menuItemPricingOptions)[0];
      const pricingOp = menuItemPricingOptions[firstKey];
      onClickPricingOption(firstKey, pricingOp);
      // }
    }
    // eslint-disable-next-line
  }, [menuItemPricingOptions]);
  return (
    <div>
      {menuItemPricingOptions !== undefined &&
      typeof menuItemPricingOptions === "object"
        ? Object.keys(menuItemPricingOptions).map((key) => {
            const pricingOptions = menuItemPricingOptions[key];
            return (
              <ProductPriceOption
                key={key}
                isSelected={selectedPrice === key}
                pricingOptions={pricingOptions}
                onClick={() => onClickPricingOption(key, pricingOptions)}
              />
            );
          })
        : null}
    </div>
  );
}

export default React.memo(ProductPricingOptions);
