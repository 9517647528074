import React from "react";
import Lottie from "react-lottie";
import animationData from "../../../lottie/loading1.json";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
export default function index() {
  return (
    <section>
      <Lottie
        options={defaultOptions}
        isStopped={false}
        isPaused={false}
        isClickToPauseDisabled={true}
      />
    </section>
  );
}
