import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Styles from "./styles.module.scss";
import { makeStyles } from "@material-ui/core";
import { setDisplayeExtrasAllergens } from "../state/ProductDetails.action";
import Extras from "./Extras";
import ExtrasQuantityTitle from "./ExtrasQuantityTitle";
import findItemInList from "../utils/findItemInList";

interface IExtrasProps {
  extrasData: IMenuExtra;
  extrasId?: string;
  handleExtrasChange: (id: string, extras: ISelectedExtras[]) => void;
  extrasState?: ISelectedExtras[];
  canContinue?: (internalName: string, canContinue: boolean) => void;
  dispatch: any;
}

const useStyles = makeStyles({
  root: {
    marginTop: "1.5625rem",
  },
  extrasWrapper: {
    overflow: "auto",
    padding: "0 1.5rem",
  },
  ExtrasGroupLabel: {
    marginLeft: "0.9375rem",
    fontWeight: 600,
    fontSize: "1.125rem",
    color: "#212121",
    marginBottom: "0",

    padding: "0 1.5rem",
  },
});

/**Handles the extras */
function GroupedExtras({
  extrasData,
  handleExtrasChange,
  extrasId,
  extrasState,
  canContinue,
  dispatch,
}: IExtrasProps) {
  const classes = useStyles();
  const menuItemExtras = useSelector((state: any) => state.MenuItemsAsExtra);
  const Venue = useSelector((state: any) => state.Venue);
  const [selectedExtras, setSelectedExtras] = useState<IExtrasSelected>({
    extras: [],
    ids: [],
  });

  useEffect(() => {
    if (extrasState.length > 0) {
      const ids = extrasState.map((value) => value.id);
      setSelectedExtras({ extras: extrasState, ids });
    }
    // else {
    //   if (extrasData.menu_item_extra_group_quantity_min >= 1) {
    //     canContinue(false);
    // }
    // }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (selectedExtras) {
      const { extras } = selectedExtras;
      let SelectedExtraCount = 0;
      extras.forEach((item) => {
        SelectedExtraCount += item.count;
      });
      handleExtrasChange(extrasId, extras);
      let ExtraMin = extrasData.menu_item_extra_group_quantity_min;
      // if Max is 0 or null that means there are no limitations so we assume max is equal to total number of extra options
      const ExtraMax = extrasData.menu_item_extra_group_quantity_max;

      if (
        SelectedExtraCount >= ExtraMin &&
        (ExtraMax === null || SelectedExtraCount <= ExtraMax)
      ) {
        canContinue(
          extrasData.menu_item_extra_group_id,
          ExtraMin > 0
        );
      } else {
        if (extrasState.length === 0) {
          canContinue(extrasData.menu_item_extra_group_id, false);
        } else if (SelectedExtraCount < ExtraMax) {
          canContinue(extrasData.menu_item_extra_group_id, false);
        }
      }
    }
    // eslint-disable-next-line
  }, [selectedExtras]);

  const DisplayeExtrasAllergens = (menuItem: any) => {
    dispatch(setDisplayeExtrasAllergens(menuItem, true));
  };

  const handleExtraChange = (
    menuItem: any,
    pricingOptionName: string,
    price: number,
    increaseCountBoolean?: boolean
  ) => {
    const { extras, ids } = selectedExtras;
    const menuId = menuItem.extra_id;

    let SelectedExtraCount = 0;
    extras.forEach((item) => {
      SelectedExtraCount += item.count;
    });
    const ExtraAtMax: boolean =
      SelectedExtraCount === extrasData.menu_item_extra_group_quantity_max;

    if (findItemInList(menuId, selectedExtras.ids)) {
      const index = ids.indexOf(menuId);
      // Check whether it's the same extra but with another Pricing Option
      // If so then replace that one with this new selected one
      if (extras[index].priceName !== pricingOptionName) {
        extras.splice(index, 1);
        extras.push({
          priceName: pricingOptionName,
          id: menuId,
          price,
          count: 1,
        });
        setSelectedExtras((prevState: IExtrasSelected) => ({
          extras,
          ids: extras.map((ex) => ex.id),
        }));
      } else {
        if (increaseCountBoolean && !ExtraAtMax) {
          extras[index].count += 1;
          setSelectedExtras((prevState: IExtrasSelected) => ({
            extras,
            ids: extras.map((ex) => ex.id),
          }));
        } else if (!increaseCountBoolean) {
          if (extras[index].count > 1) {
            extras[index].count -= 1;
            setSelectedExtras((prevState: IExtrasSelected) => ({
              extras,
              ids: extras.map((ex) => ex.id),
            }));
          } else {
            extras.splice(index, 1);
            setSelectedExtras((prevState: IExtrasSelected) => ({
              extras,
              ids: extras.map((ex) => ex.id),
            }));
          }
        }
      }
    } else {
      /**If at max length just return and do nothing */
      if (ExtraAtMax) {
        return;
      }
      extras.push({
        priceName: pricingOptionName,
        id: menuId,
        price,
        count: 1,
      });
      setSelectedExtras({
        extras,
        ids: extras.map((ex) => ex.id),
      });
    }
  };

  return (
    <div className={`${Styles.extraContainer} ${classes.root}`}>
      <p className={classes.ExtrasGroupLabel}>
        {extrasData.menu_item_extra_group_name_public}
      </p>
      <ExtrasQuantityTitle
        min={extrasData.menu_item_extra_group_quantity_min}
        max={extrasData.menu_item_extra_group_quantity_max}
        internalName={extrasData.menu_item_extra_group_id}
      />
      <div className={classes.extrasWrapper}>
        <Extras
          selectedExtras={selectedExtras}
          extrasData={extrasData}
          menuItemExtras={menuItemExtras}
          DisplayeExtrasAllergens={DisplayeExtrasAllergens}
          Venue={Venue}
          handleExtraChange={handleExtraChange}
        />
      </div>
    </div>
  );
}

export default React.memo(GroupedExtras);
