import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { CARDIFF_CASTLE } from "../../../constants";
import Button from "../../Widgets/Button";
import Styles from "../styles.module.scss";

export function CardiffCastleButton({ Venue }) {
  const GroupName = useSelector((state: any) => state.GroupName);
  return window.location.pathname.split("/")[1] === "g" &&
    GroupName === CARDIFF_CASTLE ? (
    <Link
      to={
        this.props.area
          ? this.props.A
            ? `/a/hayes-cardiff/ ${Venue.venue_nickname}/menu`
            : `/g/${GroupName}/${Venue.venue_nickname}/menu`
          : "/v/" + Venue.venue_nickname + "/menu"
      }
      onClick={() => this.props._SetSelectedMenuType(null)}
    >
      <div
        className={Styles.viewMenuBtnWrapper}
        data-component-name="ViewTheMenu"
      >
        <Button
          title="Viewthemenu"
          className={Styles.viewMenuBtn}
          iconClassName={"consumer-right-arrow"}
        />
      </div>
    </Link>
  ) : null;
}
