import React, { useContext, useEffect } from "react";
import ChooseOrderTypeButton from "../../../Widgets/ChooseOrderTypeButton";
import OrderTypeHeader from "../../../Widgets/OrderTypeHeader/OrderTypeHeader";
import {
  changeOrderType,
  selectTime,
  setIsCollectionDrawerOpen,
} from "../../reducer/PaymentActions";
import { SelectedOrderType } from "../../types.d";
import { ReactComponent as CollectionBagSVG } from "../../../../imges/CollectionBag.svg";
import { YellowColorCodeForOrderTypeSvgs } from "../OrderTypesComponent";
import { useSelector } from "react-redux";
import { PaymentContext } from "../../PaymentStore";
import { shouldResetOrderType } from "../../../Utils/ChekTime";

export function CollectionsType() {
  const Venue = useSelector((state: any) => state.Venue);
  const OrderType = useSelector((state: any) => state.OrderType);
  const { dispatch, selectedOrderType, selectedTime } = useContext(
    PaymentContext
  );
  useEffect(() => {
    if (!OrderType || shouldResetOrderType(OrderType?.settingTime)) {
      dispatch(selectTime(null));
      setTimeout(() => {
        dispatch(changeOrderType(SelectedOrderType.CLICK));
        dispatch(setIsCollectionDrawerOpen(true));
      }, 100);
    } else if (OrderType) {
      const { time } = OrderType.orderType;
      dispatch(selectTime(time));
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <OrderTypeHeader
        title="Payment.Collection"
        custom={Venue.collection_label}
        svgImage={<CollectionBagSVG fill={YellowColorCodeForOrderTypeSvgs} />}
        selected={true}
      />

      <ChooseOrderTypeButton
        key={"collect"}
        title={"SelectCollectionTime"}
        ETA={
          selectedTime !== null && selectedOrderType === SelectedOrderType.CLICK
            ? selectedTime
            : null
        }
        onClick={() => {
          dispatch(changeOrderType(SelectedOrderType.CLICK));
          dispatch(setIsCollectionDrawerOpen(true));
        }}
        selected={
          selectedTime !== null && selectedOrderType === SelectedOrderType.CLICK
        }
        dataComponentName="Collect"
      />
    </div>
  );
}
