import { SwipeableDrawer } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ORDERS } from "../../../../constants/URL.const";
import { QRCodeDialog } from "./QRCodeDialog";

interface IOrderQRCodeContainerProps {
  orderItem: IOrders;
  isStartRoute: boolean;
}

export function OrderQRCodeContainer({
  orderItem,
  isStartRoute,
}: IOrderQRCodeContainerProps) {
  const history = useHistory();
  const [isMounted, setMounted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setIsOpen(true);
    setMounted(true);
    return () => {
      closeDraw();
    };
    //eslint-disable-next-line
  }, []);

  function closeDraw() {
    setIsOpen(false);
  }
  useEffect(() => {
    if (!isOpen && isMounted) {
      /**Animation timeout */
      setTimeout(() => {
        if (isStartRoute) {
          history.goBack();
        } else {
          history.replace(`${ORDERS}/${orderItem.placed_order_id}`);
        }
      }, 350);
    }
    //eslint-disable-next-line
  }, [isOpen]);

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={isOpen}
      onClose={closeDraw}
      disableSwipeToOpen={true}
      disableDiscovery={true}
      id={"OrderQrCode"}
      transitionDuration={350}
      data-component-name="QR-Code-Drawer"
      onOpen={() => null}
    >
      <QRCodeDialog orderItem={orderItem} closeDrawer={closeDraw} />
    </SwipeableDrawer>
  );
}
