import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import FormElement from "../../yoello-lib/modules/dynamicForms/FormElement";
import ICommonFormProps from "../../yoello-lib/modules/dynamicForms/interfaces/ICommonFormProps";
import { diallingCodes } from "../../constants/countryCodes.const";

export interface IDiallingCodeSelect extends ICommonFormProps {
  Component: React.FunctionComponent<any>;
  label: string;
  inputOptions?: {
    type: string;
    small?: boolean;
  };
}

const useStyles = makeStyles({
  root: {},
  label: {
    width: "100%",
  },
  labelText: {
    color: "#626262",
    fontSize: "0.875rem",
    lineHeight: "2rem",
  },
  wrapper: {
    display: "flex",
  },
  select: {
    width: "100%",
    marginBottom: "1rem",
    border: "1px solid #F9F9F9",
    backgroundColor: "#F9F9F9",
    borderRadius: "6px",
    padding: "0.875rem",
    "&.Mui-active": {
      backgroundColor: "#FFFFFF",
      border: "1px solid #FFDD02",
    },
    "& option": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "8vw",
    },
  },
  input: {
    border: "none",
    outline: "unset",
    width: "100%",
    position: "relative",
    "& .MuiInput-root": {
      border: "1px solid #F9F9F9",
      backgroundColor: "#F9F9F9",
      transition: "0.25s ease-in-out",
      borderRadius: "0.5rem",
      width: "100%",
      display: "block",
      boxSizing: "border-box",
      "&.Mui-error": {
        border: "1px solid #F44336",
      },
      "&.Mui-focused": {
        backgroundColor: "#FFFFFF",
        border: "1px solid #FFDD02",
      },
      "& input": {
        padding: "1.6rem",
        boxSizing: "border-box",
      },
    },
  },
});

const PhoneInput = ({
  setValue,
  keyName,
  label,
  ...props
}: IDiallingCodeSelect) => {
  const classes = useStyles();
  const [internationalCode, setInternationalCode] = useState("+44");

  const updateInternationalCode = (e: React.ChangeEvent<HTMLSelectElement>) => {
    localStorage.setItem("internationalCode", e.target.value);
    setInternationalCode(e.target.value);
  };

  useEffect(() => {
    setValue(keyName, internationalCode);
  }, [internationalCode, setValue, keyName]);

  return (
    <div className={classes.root}>
      <label className={classes.label}>
        <span className={classes.labelText}>{label}</span>
        <FormElement {...props} keyName={keyName}>
          <select
            className={classes.select}
            onChange={updateInternationalCode}
            value={internationalCode}
            data-component-name="phone-number-select"
          >
            {diallingCodes.map((code) => (
              <option
                data-component-name={`${code.dial_code}-pn-select`}
                key={`${code.dial_code}-${code.name}`}
                value={code.dial_code}
              >
                {`${code.dial_code} ${code.name}`}
              </option>
            ))}
          </select>
        </FormElement>
      </label>
    </div>
  );
};

export default PhoneInput;
