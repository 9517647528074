import React from "react";
import Styles from "./styles.module.scss";
import Lottie from "react-lottie";
import { ReactComponent as RightChevron } from "../../../../imges/RightChevron.svg";
import { ReactComponent as QuestionMark } from "../../../../imges/BlueQuestion.svg";
import T from "i18n-react";
import { defaultOptions } from "../../../Widgets/Button";

interface IPayButtonProps {
  onClickQuestion: () => void;
  onClickBtn: () => void;
  isLoading: boolean;
  isDisabled: boolean;
}

export function PayButton(props: IPayButtonProps) {
  const { onClickQuestion, onClickBtn, isLoading, isDisabled } = props;
  return (
    <div>
      <button
        className={`${Styles.PayButton} ${isDisabled && Styles.disabled}`}
        onClick={onClickBtn}
        disabled={isDisabled}
      >
        <QuestionMark
          onClick={(e) => {
            e.stopPropagation();
            if (!isDisabled) {
              onClickQuestion();
            }
          }}
        />
        {isLoading ? (
          <div className={Styles.Loader}>
            <Lottie
              options={defaultOptions}
              isStopped={false}
              isPaused={false}
              isClickToPauseDisabled={true}
              //@ts-ignore
              // margin="9px auto 0 auto"
            />
          </div>
        ) : (
          <p>{T.translate("Payment.ByBank")}</p>
        )}

        <div className={Styles.rightChevron}>
          <RightChevron />
        </div>
      </button>
    </div>
  );
}
