import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import ErrorBoundary from "./utils/ErrorBoundary";
import T from "i18n-react";
import ErrorPage from "./ErrorPage";
import Router from "./Components/Router/Router";
import { _ChangeTokenExpireDate, _resetCart } from "./redux/actions";
import { getCurrentEpochTime } from "./yoello-lib/modules/auth/epochTime";
import { useCustomerLogged } from "./customHooks/useCustomerLogged";
import { useSentry } from "./customHooks/useSentry";
import { useCartDataLayer } from "./customHooks/useCartDataLayer";

// var dictionary = require(`./LngLocales/EN.json`);
// T.setTexts(dictionary);
interface IAppProps {
  CartRefreshTime: null | number;
  _resetCart: () => void;
}
/**Force because of the langs doesn't update very well*/
function useForceUpdate() {
  const [, setValue] = useState(0);
  return () => setValue((value) => ++value); // update the state to force render
}
function App({ CartRefreshTime, _resetCart }: IAppProps) {
  const forceUpdate = useForceUpdate();
  useCustomerLogged();
  useCartDataLayer();
  const { captureException } = useSentry();

  const lang =
    useSelector((state: any) => state?.localization?.language?.toLowerCase()) ||
    "en";
  useEffect(() => {
    var dictionary = require(`./localisation/${lang}.json`);
    T.setTexts(dictionary);
    forceUpdate();
    //eslint-disable-next-line
  }, [lang]);
  function checkCart() {
    if (CartRefreshTime) {
      if (CartRefreshTime < getCurrentEpochTime()) {
        _resetCart();
      }
    }
  }
  function fullBackUI() {
    return (
      <BrowserRouter>
        <div
          id="BodyDivId"
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <ErrorPage />
        </div>
      </BrowserRouter>
    );
  }
  function renderComponent() {
    checkCart();
    return (
      <ErrorBoundary
        captureException={captureException}
        fullBackFunction={fullBackUI}
      >
        <Router />
      </ErrorBoundary>
    );
  }
  return renderComponent();
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps, { _ChangeTokenExpireDate, _resetCart })(
  App
);
