import { makeStyles } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles({
  button: {
    borderRadius: "0.5rem",
    border: "1px solid #E0E0E0",
    padding: "0.5625rem 1.125rem",
    display: "flex",
    width: "100%",
    backgroundColor: "#FFFFFF",
    height: "3.125rem",
    marginBottom: "1rem",
  },
  text: {
    flex: "1 1 auto",
  },
  icon: {
    flex: "0 0 auto",
  },
  chevron: {
    flex: "0 0 auto",
  },
});

export interface ISocialButton {
  onClick: () => void;
  text: string;
  children: React.ReactChild;
}

const SocialButton = ({ onClick, text, children }: ISocialButton) => {
  const classes = useStyles();
  return (
    <button className={classes.button} onClick={onClick}>
      <span className={classes.icon}>{children}</span>

      <span className={classes.text}>{text}</span>

      <span className={classes.chevron}>
        <ChevronRight className={classes.chevron} />
      </span>
    </button>
  );
};

export default SocialButton;
