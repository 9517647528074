import { v4 as uuid } from 'uuid';

/**
 * Handles the client session data
 * function for adding headers to the API
 * 
 */
export class SessionClient {
    public sessionId: string = "";

    public createSession(sessionId?: string) {
        if (sessionId) {
            this.sessionId = sessionId;
        } else {
            this.sessionId = uuid();
        }
    }
    public createSessionHeaders() {
        return {
            'yoello-session-id': this.sessionId
        }
    }
}

const sessionClient = new SessionClient();

export default sessionClient;