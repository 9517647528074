import React from "react";
import T from "i18n-react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    position: "fixed",
    bottom: "0",
    left: "0",
    height: "8.75rem",
    backgroundColor: "#FFFFFF",
    width: "100%",
    padding: "1rem",
    boxSizing: "border-box",
    boxShadow: "0 0 1rem rgba(0,0,0,0.3)",
    "@media (max-height: 500px)": {
      position: "relative",
      boxShadow: "none",
    },
  },
  submitButton: {
    width: "100%",
    padding: "1rem",
    backgroundColor: "#FFDD02",
    outline: "none",
    border: "none",
    borderRadius: "0.3rem",
  },
  termsConditions: {
    fontSize: "0.875rem",
    color: "#737fa4",
    "& > a": {
      color: "#4f3cc9 !important",
      fontWeight: 600,
    },
  },
});

const FixedContinue = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <p className={classes.termsConditions}>
        {" "}
        {T.translate("Login.Texts.Byregistering")}
        <T.a
          rel="noopener noreferrer"
          href={"https://www.yoello.com/terms-conditions"}
          target="_blank"
          text={{ key: "Login.Texts.Terms" }}
        />
        {T.translate("Login.Texts.AndOur")}
        <T.a
          rel="noopener noreferrer"
          href={"https://www.yoello.com/privacy-policy"}
          target="_blank"
          text={{ key: "Login.Texts.Privacy" }}
        />
      </p>
      <input
        className={classes.submitButton}
        type="submit"
        value={`${T.translate("ProfileBuilder.continue")}  >`}
      />
    </div>
  );
};

export default FixedContinue;
