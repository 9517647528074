import React, { useContext } from "react";

import { PaymentContext } from "../../PaymentStore";
import SquareCardDetails from "./CardDetails";

const SquareCardDrawer = ({ square }) => {
  const { isCardDetailsDrawerOpen, dispatch } = useContext(PaymentContext);
  return (
    <SquareCardDetails
      square={square}
      isOpen={isCardDetailsDrawerOpen}
      dispatch={dispatch}
    />
  );
};

export default SquareCardDrawer;
