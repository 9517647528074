import * as types from "../actions/ActionTypes"

const TokenExpireDate = (state = 0, action) => {
  switch (action.type) {
    case types.ChangeExpiry:

      state = action.data;
      return state;

    default:

      return state
  }
};
export default TokenExpireDate
