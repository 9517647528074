export async function checkPostCodeInDeliveryRadius(
  Venue: IVenue,
  destinationPostCode: string,
  deliveryRadius: number,
  afterResponseReturned: (flag: boolean) => void
) {
  const VenueAddress = `${Venue.address_country}, ${Venue.address_city}, ${Venue.location_name}, ${Venue.address_building}, ${Venue.address_postcode}`;
  let flag: boolean;
  // @ts-ignore
  var service = new google.maps.DistanceMatrixService();
  return await service.getDistanceMatrix(
    {
      origins: [VenueAddress],
      destinations: [destinationPostCode],
      travelMode: "DRIVING",
    },
    (res) => {
      const distance = res;
      const destinationDistanceInKm =
        distance?.rows[0]?.elements[0]?.distance?.value / 1000;
      const status = distance?.rows[0]?.elements[0]?.status === "OK";

      if (status) {
        console.log(
          deliveryRadius,
          "= Delivery radius of Venue===================================="
        );
        console.log(destinationDistanceInKm, "= Distance");
        console.log(status, "====================================");
        if (destinationDistanceInKm <= deliveryRadius) {
          flag = true;
        } else {
          flag = false;
        }
      } else {
        flag = false;
      }
      afterResponseReturned(flag);
    }
  );
}
