import React, { useState, useEffect } from "react";
import T from "i18n-react";
import { AxiosResponse } from "axios";
import { useStripe } from "@stripe/react-stripe-js";

import { usePaymentState } from "../../Payment/PaymentStore";
import IFrameComponent from "./IFrameComponent";
import ConfirmationButtons from "./ConfirmationButtons";
import { PAYMENT_VERIFICATION } from "../../../constants/URL.const";
import { IPlaceOrder } from "../../../typings/IPayment";
import { getPlacedOrderPaymentIntent } from "../../../Api.js";
import consumerErrorHandler from "../../../utils/consumerError";
import { useCheckCartItems } from "../../../customHooks/useCheckCartItems";
import { useSentry } from "../../../customHooks/useSentry";
import { PaymentMethods } from "../../Payment/types.d";
import { setSelectedPaymentMethod } from "../../Payment/reducer/PaymentActions";

export interface IConfirmCardPayment {
  paymentDetails: any;
  totalCost: number;
  dispatch: React.Dispatch<any>;
  paymentFunction: (
    isUsingPaymentRequest: boolean
  ) => Promise<AxiosResponse<IPlaceOrder>>;
  changeCard: () => void;
  onSuccessfulTransaction: (orderId: string, orderLongID: string) => void;
  errorMessenger: (errorMessage: string) => void;
  setIsPaymentLoading: (isLoading: boolean) => void;
  checkPaymentProceed: () => Promise<boolean>;
  // setNewCartID: () => void;
}

const ConfirmCardPayment = (props: IConfirmCardPayment) => {
  const [authUrl, setAuthUrl] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const {
    retrieveToken,
    selectedPaymentMethod,
    dispatch,
    isLoading: contextLoading,
  } = usePaymentState();
  const { checkCart } = useCheckCartItems();
  const stripe = useStripe();
  const { captureException } = useSentry();
  const onOrderFailure = () => {
    dispatch(setSelectedPaymentMethod(null));
  };
  const getPaymentIntentStatus = async (secret: string) => {
    const paymentIntent = await stripe.retrievePaymentIntent(secret);
    return paymentIntent;
  };
  const onVerificationComplete = async (ev: MessageEvent) => {
    if (ev.origin !== document.location.origin) return;
    if (ev.data.name === "payment-verification-redirect") {
      const secret = ev.data.payload.payment_intent_client_secret;
      if (!secret) {
        T.translate("Login.Errors.SomethingHappened").toString();
        onOrderFailure();
        return;
      }
      const intent = await getPaymentIntentStatus(secret);
      const paymentStatus = intent.paymentIntent.status;
      const error = intent.paymentIntent.last_payment_error;
      console.log("====================================");
      console.log(intent);
      console.log("===========intentintent=========================");
      switch (paymentStatus as string) {
        case "succeeded":
          // ts-ignore
          props.onSuccessfulTransaction(intent.paymentIntent.description, null);
          break;
        case "requires_source":
          setAuthUrl(undefined);
          onOrderFailure();
          if (error.type === "card_error") {
            props.errorMessenger(error.message);
          } else {
            props.errorMessenger(
              T.translate(
                "Payment.Errors.PaymentAuthenticationError"
              ).toString()
            );
          }
          break;
        default:
          setAuthUrl(undefined);
          if (error) {
            onOrderFailure();
            props.errorMessenger(error.message);
          }
      }
    }
  };
  const handleConfirm = async () => {
    setIsLoading(true);
    const paymentCanBeProcessed = await props.checkPaymentProceed();
    if (paymentCanBeProcessed && !contextLoading) {
      try {
        const cartSuccessful = await checkCart();
        console.log(`cartSuccessful`, cartSuccessful);
        if (!cartSuccessful) {
          onOrderFailure();
          return;
        }
        props.setIsPaymentLoading(true);
        const order = await props.paymentFunction(true);
        if (order?.data?.data?.order_id) {
          const userToken = await retrieveToken();
          const orderIntent = await getPlacedOrderPaymentIntent(
            userToken,
            order.data.data.order_id
          );

          const clientSecret = orderIntent.data.data.payment_intent_key;
          const payment = await stripe.retrievePaymentIntent(clientSecret);
          /**if order already succeeded  then we don't need to continue*/
          if (payment.paymentIntent.status === "succeeded") {
            props.onSuccessfulTransaction(
              payment.paymentIntent.description,
              order.data.data.order_id
            );
            return;
          }
          //@ts-ignore
          const confirmation = await stripe.confirmPaymentIntent(
            clientSecret,
            {
              payment_method: props.paymentDetails.payment_method,
              return_url: document.location.origin + PAYMENT_VERIFICATION,
            },
            { handleActions: false }
          );
          if (confirmation.error) {
            // props.setNewCartID();
            onOrderFailure();
            if (confirmation.error.type === "card_error") {
              props.errorMessenger(confirmation.error.message);
            } else {
              props.errorMessenger(
                T.translate(
                  "Payment.Errors.PaymentAuthenticationError"
                ).toString()
              );
            }
            setIsLoading(false);
            props.setIsPaymentLoading(false);

            return;
          }
          if (confirmation.paymentIntent?.next_action) {
            setAuthUrl(
              confirmation.paymentIntent.next_action.redirect_to_url.url
            );
          } else {
            if (confirmation.paymentIntent.status === "succeeded") {
              props.onSuccessfulTransaction(
                confirmation.paymentIntent.description,
                order.data.data.order_id
              );
            } else {
              setIsLoading(false);
              onOrderFailure();
              captureException(new Error("Payment intent not successful"), {
                feature: "confirm-payment",
              });
              props.setIsPaymentLoading(false);
              props.errorMessenger(
                T.translate("Payment.Errors.NoOrderReference").toString()
              );
              return;
            }
          }
        }
      } catch (error) {
        const { errMsg } = consumerErrorHandler(error);
        onOrderFailure();
        captureException(error, { feature: "stripe-confirm-payment" });
        props.errorMessenger(errMsg);
      } finally {
        setIsLoading(false);
        props.setIsPaymentLoading(false);
      }
    } else {
      setIsLoading(false);
      props.setIsPaymentLoading(false);
      onOrderFailure();
    }
  };
  //Listen for post message from Iframe
  useEffect(() => {
    // props.setNewCartID();
    window.addEventListener("message", onVerificationComplete);
    return () => window.removeEventListener("message", onVerificationComplete);
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <ConfirmationButtons
        paymentDetails={props.paymentDetails}
        isLoading={isLoading}
        processPayment={handleConfirm}
        changeCard={props.changeCard}
        isDisabled={
          selectedPaymentMethod && selectedPaymentMethod !== PaymentMethods.CARD
        }
      />
      <IFrameComponent url={authUrl} onCancel={() => setAuthUrl(undefined)} />
    </div>
  );
};

export default ConfirmCardPayment;
