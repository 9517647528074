import React from "react";
import LanguagePicker from "../../Components/Widgets/LanguagePicker";
import T from "i18n-react";
import Package from "../../../package.json";
import Styles from "./styles.module.scss";
import { ReactComponent as YoelloLogoSVG } from "../../imges/yoelloLogo-w.svg";
import { APP_LANGUAGES } from "../../constants";
import SelectLanguage from "../Widgets/SelectLanguage";

export function SideMenuFooter({ openDrawer, closeDrawer, dispatchLanguage }) {
  function openLanguageDrawer() {
    openDrawer(
      //@ts-ignore
      SelectLanguage,
      {
        languages: APP_LANGUAGES,
        onCloseDrawer: closeDrawer,
        onSelectedLanguageChange: onSelectedLanguageChange,
      },
      "bottom",
      "SelectLanguageDrawer"
    );
  }
  function onSelectedLanguageChange(key: string) {
    dispatchLanguage(key);
    closeDrawer();
  }
  return (
    <div className={Styles.PoweredBy}>
      <div className={Styles.BorderLine} />
      <div className={Styles.LanguageWrapper}>
        <LanguagePicker openDrawer={openLanguageDrawer} showFull={true} />
      </div>
      <p>
        {T.translate("SideMenu.PoweredBy")}
        <YoelloLogoSVG className={Styles.YoelloLogo} />
      </p>
      <span className={Styles.AppVersion}>
        {T.translate("SideMenu.Version")} {Package.version}
      </span>
    </div>
  );
}
