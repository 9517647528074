import React from "react";
import Button from "../Button";
import Styles from "./support.module.scss";
import T from "i18n-react";
import { ISupportProps } from "./types.d";
import { ReactComponent as SupportSVG } from "../../../imges/Support-y.svg";
function Support({ onCloseDrawer }: ISupportProps) {
  return (
    <div className={Styles.supportContainer}>
      <div className={Styles.lineBar}>
        <span />
      </div>
      <header>
        <div>
          <SupportSVG />
          <p>{T.translate("SideMenu.Support")}</p>
        </div>
        <div>
          <Button
            onClick={onCloseDrawer}
            dataComponentName="ExitButton"
            iconClassName={"consumer-close"}
            style={{
              position: "unset",
              color: "#AAB6D3",
              fontSize: "1.5rem",
            }}
            isHeaderBtn
          />
        </div>
      </header>
      <p>
        {T.translate("SideMenu.RequireSupportText")}
        <strong> {T.translate("SideMenu.PleaseContactText")} </strong>.
      </p>
    </div>
  );
}

export default Support;
