import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
// eslint-disable-next-line
import "./productanimation.css";

export enum DIRECTION {
  LEFT = "left",
  RIGHT = "right",
}

interface IProductSliderProps {
  position: number;
  direction: DIRECTION;
  children: React.ReactNode;
}

export function ProductSlider({
  position,
  direction,
  children,
}: IProductSliderProps) {
  const classNames = `slide${direction}`;
  return (
    <TransitionGroup
      childFactory={(child) => React.cloneElement(child, { classNames })}
    >
      <CSSTransition
        classNames={classNames}
        timeout={{ enter: 250, exit: 250 }}
        key={position}
      >
        {children}
      </CSSTransition>
    </TransitionGroup>
  );
}
