import React from "react";
import { TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import ICommonFormProps from "../interfaces/ICommonFormProps";
import { generateRegister } from "../utils";
import Label from "../../components/UI/Label/Label";
import { ReactComponent as InfoIcon } from "../../assets/images/icon-info.svg";

/**Form text box component */
const FormTextBox = (props: ICommonFormProps) => {
  const { t } = useTranslation();

  const label = (
    <div style={{ margin: "0 5px 10px 6px", fontSize: "1rem" }}>
      <Label
        title={props.label}
        tooltip={t(props.formProps?.tooltip)}
        icon={<InfoIcon />}
      />
    </div>
  );

  return (
    <>
      {props?.formProps?.overrideJSXLabel && props?.formProps?.overrideJSXLabel}

      {props?.formProps?.tooltip && label}

      <TextField
        {...props.inputProperties}
        placeholder={t(props.inputProperties?.placeholder || "")}
        {...generateRegister(props)}
        style={{ width: "100%" }}
        disabled={props.disabled || false}
        label={
          props?.formProps?.overrideJSXLabel || props.formProps?.tooltip
            ? undefined
            : props.label
        }
        name={props.keyName}
        error={props.errors ? true : false}
        data-component-name={props.keyName}
        className={props.className}
        InputLabelProps={{ shrink: true }}
        variant={props.inputProperties?.variant || "outlined"}
      />
    </>
  );
};

export default FormTextBox;
