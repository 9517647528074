import { cartExpireTime } from "../../constants/cartExpireTime.const";
import { getCurrentEpochTime } from "../../yoello-lib/modules/auth/epochTime";
import { addCartItem, resetCart, updateCartItem } from "../actions/ActionTypes";

function CartRefreshTime(state: number = getExpireTime(), action: any) {
  switch (action.type) {
    case resetCart:
    case addCartItem:
    case updateCartItem:
      state = getExpireTime();
      return state;
    default:
      return state;
  }
}

function getExpireTime() {
  return getCurrentEpochTime() + cartExpireTime;
}

export default CartRefreshTime;
