import React from "react";

import Styles from "./productShopCard.module.scss";
import CountComponent from "../CountComponent/CountComponent";
import { connect } from "react-redux";
import useCurrency from "../../../yoello-lib/modules/localisation/useCurrency";
import { generateExtraPricingOptionName } from "../../../utils/generateExtraPricingOptionName";
const getPrice = (item) => {
  return item.finalPrice / item.itemCount;
};
function ProductShopCard(props) {
  const { formatCurrency } = useCurrency();
  const info = props.info;
  const extras = props.data.extras;
  const preferences = props.data.preferences;
  const formattedPrice = formatCurrency(getPrice(props.data));

  const _onMinusBtnClick = () => {
    props.reduceCount();
  };
  const _onPlusBtnClick = () => {
    props.addCount();
  };

  return (
    <div
      className={Styles.ProductShopCardContainer}
      data-component-name="ProductShopCard"
    >
      <div>
        <div>
          {info.menu_item_url_photo && (
            <div className={Styles.productImage}>
              <img
                width="100%"
                height="100%"
                src={info.menu_item_url_photo}
                alt={info.menu_item_name_public}
              />
            </div>
          )}
          <div
            className={Styles.productDetails}
            style={
              info.menu_item_url_photo
                ? { width: "calc(100% - 68px)" }
                : { width: "100%" }
            }
          >
            <div>
              <p>{info.menu_item_name_public}</p>
            </div>
            <div>
              <div>
                <p>{info.menu_item_pricing_options[props.po]?.option_name}</p>
                <p>{formattedPrice}</p>
              </div>
              <div className={Styles.controllers}>
                <CountComponent
                  onMinusClick={_onMinusBtnClick}
                  onPlusClick={_onPlusBtnClick}
                  count={props.count}
                  // disableMinus={disableCountChange}
                />
              </div>
            </div>

            {/* {
                        info.menu_item_pricing_options[props.po].option_bulk_qty
                            ?
                            <T.p className="Description mt-1" text={{ key: `ShopCart.XBulkquantity`, count: info.menu_item_pricing_options[props.po].option_bulk_qty }} />
                            :
                            null
                    } */}
          </div>
        </div>
      </div>
      <div>
        {preferences
          ? Object.keys(preferences).map((item, i) => {
              // let temp = `${item.split('/')[0]}: `
              let arr = preferences[item];
              // arr.forEach(item => {
              //     temp = temp + `${item.split('/')[1]}x` + item.split('/')[0] + ', '
              // })
              return (
                <div key={i} className={Styles.description}>
                  <span></span>
                  <p>{arr}</p>
                </div>
              );
            })
          : null}
        {extras && props.MenuItemsAsExtra !== undefined
          ? Object.keys(extras).map((item, i) => {
              const obj = extras[item];
              return obj.map((extra, j) => {
                const menuItem = props.MenuItemsAsExtra[extra.id];
                const optionName = menuItem?.extra_pricing_options_extras
                  ? menuItem.extra_pricing_options_extras[extra.priceName]
                    ? menuItem.extra_pricing_options_extras[extra.priceName]
                        .option_name
                    : null
                  : null;
                return (
                  <div key={`ShopCard${i}${j}`} className={Styles.description}>
                    <span></span>
                    <p>
                      {generateExtraPricingOptionName(
                        menuItem?.extra_name_public,
                        optionName
                      )}{" "}
                      <strong>
                        {extra.count ? `x${extra.count * props.count}` : ""}
                      </strong>
                    </p>
                  </div>
                );
              });
            })
          : null}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps, {})(React.memo(ProductShopCard));
