import React from "react";
import { makeStyles } from "@material-ui/core";
import DynamicForm from "../../../yoello-lib/modules/dynamicForms/DynamicForms";
import useGenerateProfileForm from "./useGenerateProfileForm";
import useSubmitProfile from "./useSubmitProfile";
import T from "i18n-react";
import PageLoading from "../../Page/PageLoading";
import { PAYMENT } from "../../../constants/URL.const";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch",
    height: "calc(100vh - 6.75rem)",
  },
  header: {
    flex: "0 0 auto",
    maxHeight: "200px",
    "& h3": {
      fontSize: "1.375rem",
    },
    overflow: "hidden",
    transition: "0.15s ease-in-out",
    "@media (max-height: 500px)": {
      maxHeight: 0,
    },
    padding: "1.5rem",
  },
  content: {
    flex: "1 1 auto",
    position: "relative",
    overflowY: "scroll",
    //This is to stop unwanted scrolling caused by mui's +12/-12px margin logic
    overflowX: "hidden",
    padding: "0 1.5rem 24rem 1.5rem",
  },
  footerBlocker: {
    height: "0",
    width: "100%",
    flex: "0 0 auto",
    transition: "0.15s ease-in-out",
  },
});

const ProfileView = () => {
  const classes = useStyles();

  const { formRows, defaultData, isLoading } = useGenerateProfileForm();
  const { submitProfile, isSubmitting } = useSubmitProfile(PAYMENT);

  if (isSubmitting || isLoading) return <PageLoading />;

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <h3>{T.translate("ProfileBuilder.Header")}</h3>
        <p>{T.translate("ProfileBuilder.Description")}</p>
      </div>
      <div className={classes.content}>
        <DynamicForm
          formData={defaultData}
          formRows={formRows}
          formKeyName="profile-builder"
          useFormOptions={{
            mode: "onBlur",
            reValidateMode: "onChange",
          }}
          onHandleSubmit={submitProfile}
        />
      </div>
      <div className={classes.footerBlocker} />
    </div>
  );
};

export default ProfileView;
