import { useMemo } from "react";
import { useSelector } from "react-redux";
import Store from "../interfaces/store";
import { ICurrency } from "../redux/actions/localization";

export const useCurrencyState = () => {
  const currencyId = useSelector(
    (state: Store) => state.localization.currency
  ) as string;

  const currencies = useSelector(
    (state: Store) => state.localization.currencies
  ) as ICurrency[];

  const currency = useMemo(() => {
    return currencies ? currencies.filter(
      (item: ICurrency) => item.id === currencyId
    )[0] : {
      id: "",
      iso_code: "",
      symbol: "",
      english_name: "",
    };
  }, [currencies, currencyId]);

  return currency;
};
