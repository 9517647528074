import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { PaymentContext } from "../PaymentStore";
import { setDiscounts } from "../reducer/PaymentActions";
import { discountsInitialValue } from "./DiscountsComponent";
import T from "i18n-react";
const useStyles = makeStyles({
  root: {
    marginTop: "0.9375rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& >div:first-child": {
      "& p": {
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontSize: "1rem",
        alignItems: "center",
        color: "#24C277",
        margin: 0,
      },
    },
    "& >div:last-child": {
      cursor: "pointer",
      "& p": {
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "0.8125rem",
        display: "flex",
        alignItems: "center",
        color: "#424242",
        margin: 0,
      },
    },
  },
});
export function AppliedDiscounts() {
  const { dispatch, discounts } = useContext(PaymentContext);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <p>
          <strong data-component-name="DiscountCode">{discounts.code} </strong>{" "}
          <span>{T.translate("Forms.Applied")}</span>
        </p>
      </div>
      <div
        data-component-name="DiscountAppliedRemove"
        onClick={() => dispatch(setDiscounts(discountsInitialValue))}
      >
        <p>{T.translate("Forms.Remove")}</p>
      </div>
    </div>
  );
}
