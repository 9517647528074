import React from "react";
import Styles from "../styles.module.scss";
import T from "i18n-react";
import { isVenueOpen } from "../../../yoello-lib/modules/utils/tradingTimesObject";

interface IVenueRippleProps {
  Venue: IVenue;
}

export function VenueRippe({ Venue }: IVenueRippleProps) {
  return isVenueOpen(Venue.trading_times, Venue.time_zone_name) &&
    Venue.enabled ? (
    <div>
      <div className={Styles.openCircleRipple}></div>
      <p>{T.translate("Misc.open")}</p>
    </div>
  ) : (
    <div className={Styles.closeText}>
      <div className={Styles.closeCircleRipple}></div>
      <p>{T.translate("Misc.closed")}</p>
    </div>
  );
}
