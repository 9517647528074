import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

const BillLoading = () => {
  return (
    <>
      <Skeleton style={{ margin: "0.4rem", paddingTop: "0.75rem" }} />
      <Skeleton style={{ margin: "0.4rem", paddingTop: "0.75rem" }} />
      <Skeleton style={{ margin: "0.4rem", paddingTop: "0.75rem" }} />
      <Skeleton style={{ margin: "0.4rem", paddingTop: "0.75rem" }} />
    </>
  );
};

export default BillLoading;
