import { Snackbar } from "@material-ui/core";
import { useSnackbarMessages } from "./SnackbarContext";
import React from "react";
import { useTranslation } from "react-i18next";
import Alert from '@material-ui/lab/Alert';


/** Snackbar message which uses the snackbar context api */
const SnackbarComponent = () => {
    const { t } = useTranslation();
    const { isSnackbarOpen, closeSnackbar, snackbarMessage } = useSnackbarMessages()!;
    const onCloseSnackbar = () => {
        setTimeout(()=>{
            closeSnackbar();
        },snackbarMessage?.autoHideDuration??7000)
    };
    return <Snackbar open={isSnackbarOpen} onClose={onCloseSnackbar} data-component-name={`snackbar-${snackbarMessage?.status}`}
        autoHideDuration={snackbarMessage?.autoHideDuration ?? 7000} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert onClose={closeSnackbar} severity={snackbarMessage?.status || undefined} >
            {t(snackbarMessage?.message || "misc.unknownmsg")}
        </Alert>
    </Snackbar>;
};

export default SnackbarComponent;