import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CartOrderText from "./display/CartOrderText";
import CartList from "./list/CartList";
import { SnackbarMessageStatus, useSnackbarMessages } from "../../yoello-lib/modules/components/snackbar/SnackbarContext";
import { checkAbvContainsFood } from "../../utils/checkAbvContainsFood";
import { _ChangeRedirect, _resetCart, _ShowUp } from "../../redux/actions";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "../../yoello-lib/modules/auth/Auth0";
import { PAYMENT, LOGINV2, VENUE_MENU } from "../../constants/URL.const";
import Styles from "./shopList.module.scss";
import Button from "../Widgets/Button";
import { ReactComponent as CartSVG } from "../../imges/cart_new.svg";
import T from "i18n-react";
import { useVenueOpen } from "../../customHooks/useVenueOpen";
import { useABVCheck } from "../../customHooks/useABVCheck";
import useGenerateNavPath from "../../customHooks/useGenerateNavPath";
import { useMenu } from "../Menu/MenuContext";

interface IShoppingCartListProps {
  area: boolean;
}
interface ISnackbarMessageObject {
  translationKey:string;
  translationOptions:Object;
  status:SnackbarMessageStatus;
  autoHideDuration?:number;
}
const minPriceMap = {
  COLLECTION: "min_collection_price",
  HOME_DELIVERY: "min_delivery_price",
  TABLE_DELIVERY: "min_dine_in_price",
  COLLECTION_POINT_PICKUP: "min_pickup_now_price",
};

function ShoppingCartList({ area }: IShoppingCartListProps) {
  const { generateNavPath } = useGenerateNavPath();
  const [isOpen, setIsOpen] = useState(false);
  const [snackbarMessageObject, setSnackbarMessageObject] = useState<ISnackbarMessageObject | null>(null);
  const {
    formatCurrency,
    state: { cartAmount, cartCount },
  } = useMenu();
  const threshold = 200; //required min distance traveled to be considered swipe
  const [touchStartLocation, setTouchStartLocation] = useState(null);
  const cartItems = useSelector((state: any) => state.Cart);
  const group = useSelector((state: any) => state.Group);
  const Venue = useSelector((state: any) => state.Venue);
  const SelectedMenuType = useSelector((state: any) => state.SelectedMenuType);

  const history = useHistory();
  const { setRedirectURLs } = useAuth0()!;
  const dispatch = useDispatch();
  const { sendSnackbarMessage } = useSnackbarMessages()!;
  const isVenueOpen = useVenueOpen();
  const { abvCheck } = useABVCheck();
  const hasRendered = useRef(false);
  const { generateURL } = useMenu();

  useEffect(() => {
    /**This stops it from bugging and closing */
    setTimeout(() => {
      hasRendered.current = true;
      setIsOpen(true);
    }, 35);
  }, []);

  useEffect(() => {
    /**Wait for it to render first*/
    if (!isOpen && hasRendered.current) {
      /**Allows animation to play */
      setTimeout(() => {
        history.push(generateURL() + "/menu");
      }, 100);
    }
    // eslint-disable-next-line
  }, [isOpen]);

  function onOpenCart() {
    setIsOpen((prevOpen) => {
      return !prevOpen;
    });
  }
  useEffect(() => {
    if(snackbarMessageObject?.translationKey){
      sendSnackbarMessage(
        T.translate(snackbarMessageObject.translationKey,snackbarMessageObject.translationOptions ).toLocaleString(),
        snackbarMessageObject.status,
        snackbarMessageObject.autoHideDuration
      );
    }
    // eslint-disable-next-line
  }, [snackbarMessageObject])
  

  function onConfirmationClick() {
    // First: Cart Empty
    if (cartItems.length === 0) {
      setSnackbarMessageObject({
        translationKey:"ShopCart.YourCartIsEmpty",
        translationOptions:{},
        status:"error",
      })
      return;
    }
    const formattedMinimumDelivery = formatCurrency(
      Venue[minPriceMap[SelectedMenuType]]
    );
    // Second: Min delivery value check    
    if (cartAmount < Venue[minPriceMap[SelectedMenuType]]) {
      setSnackbarMessageObject({
        translationKey:"ShopCart.Errors.CartLessThan",
        translationOptions:{
          price: formattedMinimumDelivery,
        },
        status:"error",
      })
      return;
    }
    // if the venue has an alcohol limit then enforce
    if (
      (Venue?.alc_drink_limit !== undefined ||
        Venue?.alc_drink_limit !== null) &&
      typeof Venue?.alc_drink_limit === "number" &&
      !abvCheck()
    ) {
      setSnackbarMessageObject({
        translationKey:"Restrictions.alchoholRestrictionError",
        translationOptions:{
          count: Venue?.alc_drink_limit,
        },
        status:"error",
      })
      return;
    }
    // Third: ABV check
    if (
      area &&
      !checkAbvContainsFood(cartItems, group?.group_abv_food_threshold)
    ) {
      const minFoodPrice = formatCurrency(group?.group_abv_food_threshold);
      setSnackbarMessageObject({
        translationKey:"ShopCart.Errors.MinFoodItems",
        translationOptions:{
          price: minFoodPrice,
        },
        status:"error",
      })
      return;
    }
    /**Is venue open  */
    if (!isVenueOpen) {
      setSnackbarMessageObject({
        translationKey:"ShopCart.Errors.VenueClosed",
        translationOptions:{},
        status:"error",
      })
      return;
    }
    dispatch(_ShowUp(false));
    setRedirectURLs(PAYMENT);
    dispatch(_ChangeRedirect(getRedirectPlaceURL()));
    history.push(generateNavPath(LOGINV2), {
      area: window.location.pathname.split("/")[1] === "g",
      from: generateNavPath(VENUE_MENU),
      to: PAYMENT,
    });
  }
  function addShadowOnScroll() {
    // const distanceY = window.pageYOffset || document.documentElement.scrollTop,
    //   shrinkOn = 200,
    const containerEl = document.getElementById("shopListContainerId")!;
    const detailsElement = document.getElementById("cartItemsId")!;
    if (detailsElement.scrollTop > 5) {
      containerEl.classList.add(Styles.contentSlideToTop);
    } else {
      containerEl.classList.remove(Styles.contentSlideToTop);
    }
    if (
      detailsElement.offsetHeight + detailsElement.scrollTop >=
      detailsElement.scrollHeight
    ) {
      containerEl.classList.remove(Styles.contentSlideToBottom);
    } else containerEl.classList.add(Styles.contentSlideToBottom);
  }

  function handleTouchStart(e) {
    const firstTouchEvent = e.touches[0];
    const location = {
      x: firstTouchEvent.clientX,
      y: firstTouchEvent.clientY,
    };
    setTouchStartLocation(location);
  }
  function handleTouchEnd(e) {
    const firstTouchEvent = e.changedTouches[0];
    const location = {
      x: firstTouchEvent.clientX, //get the location of the end of the touch
      y: firstTouchEvent.clientY,
    };
    const differences = {
      x: location.x - touchStartLocation.x, //find the difference from the start to the end touch
      y: location.y - touchStartLocation.y,
    };
    if (differences.y > threshold && location.y > 0) {
      onOpenCart();
    }
  }

  return (
    <div
      className={Styles.shoppingCartContainer}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      {
        <div
          className={`${Styles.shoppingCartDrawer} ${
            isOpen && Styles.openCart
          }`}
          id="shoppingCartDrawer"
        >
          <div className={Styles.myCart}>
            <div className={Styles.shopListContainer} id="shopListContainerId">
              <>
                <div className={Styles.header}>
                  <div
                    className={Styles.lineBar}
                    onClick={() => onOpenCart()}
                  />
                </div>
                <div className={Styles.cartDetails}>
                  <div>
                    <div>
                      <CartSVG />
                      <p>{T.translate("ShopCart.MyCart")}</p>
                    </div>
                    <Button
                      iconClassName="consumer-close"
                      isHeaderBtn
                      className={Styles.closeDrawer}
                      onClick={() => onOpenCart()}
                    />
                  </div>
                  <div>
                    <div>
                      <span className={Styles.dot}></span>
                      <T.p
                        className={Styles.cartItemsCount}
                        data-component-name={"cartItemsCount"}
                        text={{
                          key: `buttons.Items`,
                          Count: cartCount,
                        }}
                      />
                    </div>
                    <div>
                      <Button
                        title={"EmptyCart"}
                        className={Styles.emptyCart}
                        onClick={() => {
                          onOpenCart();
                          dispatch(_resetCart());
                        }}
                        dataComponentName="ShoppingCartEmptyButton"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={Styles.cartItems}
                  onScroll={addShadowOnScroll}
                  id="cartItemsId"
                >
                  <div className={Styles.shadowTop}></div>
                  <CartList cartItems={cartItems} isOpen={isOpen} />
                </div>
                <div className={Styles.ShoppingCartBtnContainer}>
                  <CartOrderText
                    cartLength={cartItems.length || 0}
                    totalPrice={cartAmount}
                  />
                  <Button
                    title={"Checkout"}
                    onClick={onConfirmationClick}
                    dataComponentName="ConfirmOrderButton"
                    iconClassName={
                      cartItems.length > 0 ? "consumer-right-arrow" : undefined
                    }
                    className={
                      cartItems.length > 0
                        ? Styles.confirmOrderBtn
                        : Styles.emptyCheckoutBtn
                    }
                  />
                </div>
              </>
            </div>
          </div>
        </div>
      }
      <div
        id="backgroundMaskId"
        className={`${Styles.drawerMask} ${isOpen && Styles.maskBackground}`}
        onClick={onOpenCart}
      ></div>
    </div>
  );
}

function getRedirectPlaceURL() {
  if (window.location.pathname.split("/")[1] === "g") {
    return "PaymentA";
  } else {
    return "PaymentV";
  }
}

export default ShoppingCartList;
