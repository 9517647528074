import T from "i18n-react";
import React, { useEffect, useState } from "react";
import { FixedSizeList as List } from "react-window";
import { useWindowHeight } from "../../customHooks/useWindowHeight";
import OrderHistoryCard from "../Widgets/OrderHistoryCard/OrderHistoryCard";
import Styles from "./orders.module.scss";

interface IOrderListProps {
  orderHistoryItems: any[];
  onSelectedOrderItem: (orderId: string) => void;
  headerRef: React.MutableRefObject<HTMLDivElement>;
}

function OrderList({
  orderHistoryItems,
  onSelectedOrderItem,
  headerRef,
}: IOrderListProps) {
  const [heightWithOffset, setHeightWithOffset] = useState(0);
  const windowHeight = useWindowHeight({ isResizeable: true });
  function rowEntry({ index, style }: any) {
    let newStyle = { ...style };
    newStyle["height"] = "7.8125rem";
    const id = orderHistoryItems[index].id;
    return (
      <div key={id} style={newStyle}>
        <OrderHistoryCard
          key={index}
          info={orderHistoryItems[index]}
          onClick={() => {
            onSelectedOrderItem(id);
          }}
        />
      </div>
    );
  }

  useEffect(() => {
    if (headerRef && headerRef.current) {
      setHeightWithOffset(
        windowHeight - headerRef.current.getBoundingClientRect().height
      );
    }
  }, [windowHeight, headerRef]);

  return orderHistoryItems.length > 0 ? (
    <div data-component-name="OrderHistoryList" className={Styles.orderList}>
      <List
        className={Styles.historyList}
        height={heightWithOffset}
        itemCount={orderHistoryItems.length}
        itemSize={125}
        width={"100%"}
      >
        {rowEntry}
      </List>
    </div>
  ) : (
    <p className={Styles.noOrdersText}>
      {T.translate("OrderHistory.NoPreviousOrders")}
    </p>
  );
}

export default OrderList;
