import React, { useState, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { InfoDrawer } from "./InfoDrawer";
import { useHistory } from "react-router-dom";
import { PayButton } from "./PayButton";
import {
  setPlacedOrder,
  setSelectedPaymentMethod,
  setIsPaymentDrawerOpen,
} from "../../reducer/PaymentActions";
import { PaymentContext, usePaymentState } from "../../PaymentStore";
import { OPENBANKING } from "../../../../constants/URL.const";
import { useCheckCartItems } from "../../../../customHooks/useCheckCartItems";
import useCompleteOrder from "../../PaymentHooks/useCompleteOrder";
import { useSnackbarMessages } from "../../../../yoello-lib/modules/components/snackbar/SnackbarContext";
import { useDispatch } from "react-redux";
import { _updateCartID } from "../../../../redux/actions";
import { PaymentMethods } from "../../types.d";

export function Openbanking() {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const { sendSnackbarMessage } = useSnackbarMessages();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { checkCart } = useCheckCartItems();
  const { completeOrder } = useCompleteOrder();
  const { push } = useHistory();
  const { dispatch, selectedPaymentMethod } = useContext(PaymentContext);
  const { paymentProviders } = usePaymentState();
  const rdxDispatch = useDispatch();

  function onClose() {
    setIsDrawerOpen(false);
  }
  function onOpen() {
    setIsDrawerOpen(true);
  }
  async function onContinue() {
    setIsLoading(true);
    dispatch(setSelectedPaymentMethod(PaymentMethods.BANK_TRANSFER));
    try {
      const cartSuccessful = await checkCart();
      if (cartSuccessful) {
        const order = await completeOrder(true);
        dispatch(setPlacedOrder(order?.data?.data?.order_id));
        if (order?.data?.data?.order_id) {
          dispatch(setSelectedPaymentMethod(null));
          dispatch(setIsPaymentDrawerOpen(false));
          rdxDispatch(_updateCartID(uuidv4()));
          setIsLoading(false);
          localStorage.setItem("placed_order_id", order?.data?.data?.order_id);
          if (isDrawerOpen) {
            setIsDrawerOpen(false);
          }
          push(`${OPENBANKING}/pay`);
        }
      }
    } catch (err) {
      setIsLoading(false);
      dispatch(setSelectedPaymentMethod(null));
      sendSnackbarMessage(err, "error");
    }
  }
  if (!paymentProviders["yapily"]) return null;

  return (
    <>
      <PayButton
        onClickBtn={onContinue}
        onClickQuestion={onOpen}
        isLoading={isLoading && !isDrawerOpen}
        isDisabled={
          selectedPaymentMethod &&
          selectedPaymentMethod !== PaymentMethods.BANK_TRANSFER
        }
      />
      <InfoDrawer
        isOpen={isDrawerOpen}
        onClose={onClose}
        onContinue={onContinue}
        isLoading={isLoading}
      />
    </>
  );
}
