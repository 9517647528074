import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import Styles from "./styles.module.scss";
import Button from "../Button";
import ChoiceBox from "../../ProductDetails/ChoiceBox/ChoiceBox";
import { BackendTimeSlots, SelectedOrderType } from "../../Payment/types.d";
import { convertToUTCTime } from "../../../yoello-lib/modules/utils/TimeConversion";
import { _SetSelectedOrderType } from "../../../redux/actions";

interface ICollectTimePickerProps {
  title: string;
  deliveryTimes: Array<BackendTimeSlots>;
  onClick: () => void;
  handleTimeSelect: (time: BackendTimeSlots) => void;
  selectedTime: BackendTimeSlots;
  choiceBoxPricingOption?: { option_price: number; option_discount: number };
  onClose: () => void;
  buttonText: string;
  timeTitle: string;
  selectedOrderType: SelectedOrderType;
}

const CollectTimePicker: FunctionComponent<ICollectTimePickerProps> = ({
  title,
  onClick,
  deliveryTimes,
  selectedTime,
  handleTimeSelect,
  onClose,
  selectedOrderType,
}) => {
  const dispatch = useDispatch();
  return (
    <div className={Styles.delivery_time}>
      <div className={Styles.lineBar}>
        {" "}
        <span></span>{" "}
      </div>

      <div className={Styles.DeliveryHeader}>
        <div className="Flex1" />
        <p>{title}</p>
        <div className="Flex1">
          <Button
            onClick={onClose}
            dataComponentName="ExitButton"
            iconClassName={"consumer-close"}
            style={{
              position: "unset",
              color: "#AAB6D3",
              fontSize: "1.5rem",
            }}
            isHeaderBtn
          />
        </div>
      </div>
      <div className={Styles.divider}></div>
      <ul>
        {deliveryTimes?.map((time: BackendTimeSlots) => {
          const ChoiceBoxProps = {
            isSelected: selectedTime?.venue_time === time?.venue_time,
            onClick: () => {
              handleTimeSelect(time);
              dispatch(
                _SetSelectedOrderType({
                  orderType: {
                    time,
                  },
                  settingTime: Date.now(),
                })
              );
              onClick();
            },
            choiceBoxName: convertToUTCTime(time.venue_time),
            disabled:
              selectedOrderType === SelectedOrderType.DELIVERY
                ? !time.delivery
                : !time.collection,
          };
          return <ChoiceBox {...ChoiceBoxProps} />;
        })}
      </ul>
    </div>
  );
};

export default React.memo(CollectTimePicker);
