import React from "react";
import useSquareVenue from "../../../customHooks/Square/useSquareVenue";
import StandardPricing from "./StandardPricing";
import SquarePricing from "./SquarePricing";

const PricingComponent = () => {
  const { isSquareVenue } = useSquareVenue();
  if (isSquareVenue) return <SquarePricing />;
  return <StandardPricing />;
};

export default PricingComponent;
