import debounce from "lodash.debounce";
import * as React from "react";
import { useSwipeable } from "react-swipeable";
import { Transition } from "react-transition-group";
import { BackdropStyles, TransitionStyles } from "./lib/styles";
import useGlobalStyles from "./lib/hooks/useGlobalStyles";

interface IProps {
  isVisible: boolean;
  onClose: () => void;
  duration?: number;
  hideScrollbars?: boolean;
  unmountOnExit?: boolean;
  mountOnEnter?: boolean;
  children: React.ReactNode;
}

const SlideUpTransition = ({
  isVisible,
  children,
  onClose,
  unmountOnExit = true,
  mountOnEnter = true,
  duration = 250,
  hideScrollbars = false,
}: IProps) => {
  const classNames = useGlobalStyles(duration, hideScrollbars);
  // Swiping down interaction
  const threshold = 250; //required min distance traveled to be considered swipe
  const [touchStartLocation, setTouchStartLocation] = React.useState(null);
  const [currentDeltaY, setDeltaY] = React.useState(0);
  const swipeHandlers = useSwipeable({
    onSwipedDown: debounce(
      ({ velocity }) => {
        setDeltaY(0);
      },
      500,
      { leading: true }
    ),
    onSwiping: ({ deltaY }) => {
      setDeltaY(deltaY);
    },
  });

  const handleTouchStart = (e) => {
    const firstTouchEvent = e.touches[0];
    const location = {
      x: firstTouchEvent.clientX,
      y: firstTouchEvent.clientY,
    };
    setTouchStartLocation(location);
  };
  const handleTouchEnd = (e) => {
    const firstTouchEvent = e.changedTouches[0];
    const location = {
      x: firstTouchEvent.clientX, //get the location of the end of the touch
      y: firstTouchEvent.clientY,
    };
    const differences = {
      x: location.x - touchStartLocation.x, //find the difference from the start to the end touch
      y: location.y - touchStartLocation.y,
    };
    if (differences.y > threshold && location.y > 0) {
      onClose();
    }
  };

  const getTransforms = (): React.CSSProperties | undefined => {
    if (currentDeltaY >= 0) {
      return undefined;
    } else if (currentDeltaY < -250) {
      return {
        transform: `translate3d(0, ${currentDeltaY * -1}px, 0)`,
        transition: "none",
      };
    } else return undefined;
  };
  // Layout
  return (
    <>
      <Transition
        appear={true}
        in={isVisible}
        timeout={{ appear: 0, enter: 0, exit: duration }}
        unmountOnExit={unmountOnExit}
        mountOnEnter={mountOnEnter}
      >
        {(state) => (
          <>
            <div
              onClick={onClose}
              className={classNames.backdrop}
              style={BackdropStyles[state]}
            />
            <div
              className={classNames.drawer}
              style={{
                ...TransitionStyles[state],
                ...getTransforms(),
              }}
            >
              <div
                {...swipeHandlers}
                className={classNames.handleWrapper}
              ></div>
              <div
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                className={classNames.contentWrapper}
              >
                {children}
              </div>
            </div>
          </>
        )}
      </Transition>
    </>
  );
};

export default SlideUpTransition;
