import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { VENUE } from "../constants/URL.const";
import { useAuth0 } from "../yoello-lib/modules/auth/Auth0";
import useGenerateNavPath from "./useGenerateNavPath";

/**Hook for navigating back to the venue if someone logs out */
export function useNavigateLoggedUser() {
  const { isAuthenticated } = useAuth0()!;
  const { generateNavPath } = useGenerateNavPath();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let redirectUrl = params.get("returnPath")
    ? decodeURI(params.get("returnPath"))
    : null;

  if (!redirectUrl) {
    redirectUrl = generateNavPath(VENUE);
  }

  useEffect(() => {
    if (redirectUrl) {
      history.replace(redirectUrl, location.state);
    }
    //eslint-disable-next-line
  }, [isAuthenticated]);
}
