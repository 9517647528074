import React from "react";
import T from "i18n-react";
import Styles from "./notFound.module.scss";
import Button from "../Widgets/Button";
import PageComponent from "../Page/PageComponent";
import { ReactComponent as HamburgerMenuSVG } from "../../imges/HamburgerMenu.svg";
import { ReactComponent as YoelloIconSVG } from "../../imges/yoelloIcon_new.svg";
import { ReactComponent as YoelloTextSVG } from "../../imges/yoelloText.svg";
import { ReactComponent as NotFoundSVG } from "../../imges/404.svg";
const NotFoundComponent = (props) => {
  return (
    <div className={Styles.notFoundPageContainer}>
      <header>
        <Button
          isHeaderBtn
          className={Styles.sidemenuToggleBtn}
          dataComponentName={"HamburgerMenu"}
          Icon={<HamburgerMenuSVG />}
          onClick={props.openSideDrawer}
        />
      </header>
      <div className={Styles.yoelloIcon}>
        <YoelloIconSVG />
      </div>
      <div className={Styles.yoelloLogo}>
        <YoelloTextSVG />
      </div>
      <div className={Styles.notFoundSvg}>
        <NotFoundSVG />
      </div>
      <div className={Styles.descriptions}>
        <pre>
          {T.translate(`Looks like we're 
                  missing something :(`)}
        </pre>
        <p>{T.translate(`If you think this is an issue with Yoello`)} </p>
        <strong>{T.translate(` Please contact us below:`)} </strong>
      </div>
      <div className={Styles.contactUsBtncontiner}>
        <a
          target="_blank"
          href="https://www.yoello.com/contact-us"
          rel="noopener noreferrer"
        >
          <Button
            className={Styles.contactUs}
            title="ContactUs"
            iconClassName={"consumer-right-arrow"}
          />
        </a>
      </div>
    </div>
  );
};

export default PageComponent(NotFoundComponent);
