import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { ORDERS } from "../../constants/URL.const";

/**Gets the starting route to determine type of navigation method */
export function useOrdersNavigation() {
  const location = useLocation();
  const history = useHistory();
  const Venue = useSelector((state: any) => state.Venue);
  const isStartRoute = useMemo(() => {
    if (location.pathname === ORDERS) {
      return true;
    }
    return false;
    //eslint-disable-next-line
  }, []);

  function navigateBack() {
    if (location.pathname.includes(`review`)) {
      const orderID = location.pathname.split("/")[2];
      history.replace(`${ORDERS}/${orderID}`);
      return;
    }
    if (isStartRoute) {
      history.goBack();
      return;
    }
    if (location.pathname === ORDERS) {
      history.push(`/v/${Venue.venue_nickname}`);
    } else if (location.pathname.includes(`${ORDERS}/`)) {
      history.push(ORDERS);
    } else {
      history.goBack();
    }
    // }
  }
  return { navigateBack, isStartRoute };
}
