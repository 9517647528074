import React from "react";
import T from "i18n-react";
import Styles from "./styles.module.scss";
import Button from "../Button";
import { connect } from "react-redux";
import { IConfirmOrderProps } from "./types.d";
import { SelectedOrderType } from "../../Payment/types.d";
import { ReactComponent as ThumbsUpSVG } from "../../../imges/ThumbsUp.svg";
import { _ChangeTable } from "../../../redux/actions/index";
import { useAuth0 } from "../../../yoello-lib/modules/auth/Auth0";
import IConsumerUser from "../../../yoello-lib/modules/auth/IConsumerUser";
import { ReactComponent as ClockIcon } from "../../../imges/ClockIcon.svg";
import { ReactComponent as MouseIcon } from "../../../imges/BlueMouseIcon.svg";
import { ReactComponent as StarIcon } from "../../../imges/icon-star.svg";

import { AlertStatusCard } from "../../../yoello-lib/modules/components/UI/Alerts/AlertStatusCard";
import { ORDERS } from "../../../constants/URL.const";
import { OrderReviewButton } from "../../Orders/OrderDetails/OrderReviewButton";
import { useSelector } from "react-redux";

function ConfirmOrder(props: IConfirmOrderProps) {
  const VenueReviewsEnabled = useSelector(
    (state: any) => state.Venue?.reviews_enabled
  );
  const { user } = useAuth0()!;

  function setOrderText() {
    switch (props.selectedOrderType) {
      case SelectedOrderType.CLICK:
        return "Payment.OrderCollectText";
      case SelectedOrderType.DELIVERY:
        return "Payment.OrderDeliveryText";
      case SelectedOrderType.TABLE:
        return `Payment.OrderTableText`;
      case SelectedOrderType.COLLECTION_POINT:
        return `Payment.CollectionPointText`;
    }
  }

  function getOrderFeedback() {
    switch (props.selectedOrderType) {
      case SelectedOrderType.CLICK:
        return <span>{props.TimeSlot}</span>;
      case SelectedOrderType.DELIVERY:
        return (
          <span>
            {props.TimeSlot}
            <br />
            {props.deliveryAddress?.address} {props.deliveryAddress?.city}{" "}
            {props.deliveryAddress?.postcode}
          </span>
        );
      case SelectedOrderType.TABLE:
        return (
          <span>
            {props.tableArea},<b>{props.Table}</b>
          </span>
        );
      case SelectedOrderType.COLLECTION_POINT:
        return (
          <span>
            <ClockIcon />{" "}
            <b>
              {props.prepTime} {T.translate("Misc.minutes")}
            </b>
          </span>
        );
      default:
        return null;
    }
  }

  function goToOrders() {
    props.CloseDrawer();
    props.history.push(ORDERS);
  }

  function userName() {
    const userProfile = user as IConsumerUser;
    if (userProfile !== undefined) {
      return userProfile.first_name;
    }
    return "";
  }
  return (
    <div className={Styles.ConfirmPayment}>
      <div
        style={{
          position: "absolute",
          top: "15px",
          right: "15px",
        }}
      >
        <Button
          onClick={props.CloseDrawer}
          dataComponentName="ExitButton"
          iconClassName={"consumer-close"}
          style={{
            position: "unset",
            color: "#AAB6D3",
            fontSize: "1.5rem",
          }}
          isHeaderBtn
        />
      </div>

      <div className={Styles.lineBar} />
      <div className={Styles.ConfirmOrderContainer}>
        <div
          style={{
            padding: "0 0.9rem",
          }}
        >
          <T.p
            className={Styles.ThankYouText}
            text={{ key: `Payment.ThankYou`, name: userName() }}
          />
          <div
            style={{
              margin: "1rem 0",
            }}
          >
            <ThumbsUpSVG width="32px" height="32px" />
          </div>

          <p className={Styles.OrderCompletedText}>
            {T.translate("Payment.YourOrderHasBeen")}
            <br />
            <strong>{T.translate("Payment.SuccessfullyCompleted")}</strong>
          </p>
        </div>

        <div
          style={{
            marginTop: "0.625rem",
          }}
        >
          <div className={Styles.OrderInfoBox}>
            <div className={Styles.OrderIdDiv}>
              <T.p text={{ key: `Payment.OrderId` }} />
              <p>
                <strong data-component-name="orderId">{props.orderId}</strong>
              </p>
            </div>
            {((props.selectedOrderType === SelectedOrderType.COLLECTION_POINT &&
              props.prepTime) ||
              props.selectedOrderType !==
                SelectedOrderType.COLLECTION_POINT) && (
              <div>
                <p className={Styles.OrderSentText}>
                  {T.translate(setOrderText())}
                </p>
                <p className={Styles.OrderSentInfoText}>{getOrderFeedback()}</p>
              </div>
            )}
          </div>
          {props.selectedOrderType === SelectedOrderType.COLLECTION_POINT && (
            <div className={Styles.infoBox}>
              <AlertStatusCard
                alertTitle={<b>{T.translate("Payment.OrderInfoTitle")}</b>}
                alertMessage={<p>{T.translate("Payment.OrderInfoText")}</p>}
                alertProps={{
                  color: "info",
                  icon: <MouseIcon />,
                }}
              />
            </div>
          )}
          <div
            style={{
              marginTop: "1.25rem",
            }}
            className={Styles.ViewOrderHistoryDiv}
          >
            <p>{T.translate("Payment.ViewOrderHistory")}</p>
            <Button
              title="OrderHistory"
              iconClassName="consumer-right-arrow"
              onClick={goToOrders}
              isYoelloBtn
            />
          </div>
          {VenueReviewsEnabled && (
            <div className={Styles.ReviewSectionDiv}>
              <div>
                <StarIcon width="1rem" height="1rem" />
                <p>{T.translate("Payment.RateYourExperience")}</p>
              </div>

              <OrderReviewButton
                buttonText={T.translate("Payment.LeaveReview") as string}
                className={Styles.ReviewButton}
                orderID={props.history?.location?.state?.orderLongID}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps, {
  _ChangeTable,
})(ConfirmOrder);
