import React from "react";

import PaymentDrawer from "../../PaymentDrawer";
import ConfirmPaymentComponent from "./ConfirmPaymentComponent";
import useConfirmPay360Order from "./useConfirmPay360Order";

const Pay360PaymentComponent = ({ requireCVV }) => {
  const {
    confirmPay360Order,
    onVerificationComplete,
    threedsUrl,
    threedsType,
    threedsTransactionId,
    setCVV,
  } = useConfirmPay360Order();

  return (
    <PaymentDrawer
      digitalWalletComponent={null}
      confirmPaymentComponent={
        <ConfirmPaymentComponent
          requireCVV={requireCVV}
          confirmPayment={confirmPay360Order}
          setCVV={setCVV}
          onVerificationComplete={onVerificationComplete}
          threedsUrl={threedsUrl}
          transactionId={threedsTransactionId}
          threedsType={threedsType}
        />
      }
    />
  );
};

export default Pay360PaymentComponent;
