import React, { useRef } from "react";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import Styles from "./orders.module.scss";
import T from "i18n-react";
import { OrderListContainer } from "./OrderListContainer";
import { OrderDetailsContainer } from "./OrdersDetailsContainer";
import { BY_ID, LOGIN } from "../../constants/URL.const";
import { OrderHeader } from "./OrderHeader";
import { useOrdersNavigation } from "./useOrdersNavigation";
import WithConsumerAuth from "../Auth/WithConsumerAuth";
import { useSnackbarMessages } from "../../yoello-lib/modules/components/snackbar/SnackbarContext";
// import { OrderReviewContainer } from "./OrderReviewContainer";
// import {REVIEW} from '../../constants/URL.const'
export function OrdersRoute({ isAuthenticated }: any) {
  const history = useHistory();
  const location = useLocation();
  let { path } = useRouteMatch();
  const { navigateBack, isStartRoute } = useOrdersNavigation();
  const { sendSnackbarMessage } = useSnackbarMessages();

  const headerRef = useRef();

  if (!isAuthenticated) {
    sendSnackbarMessage(
      T.translate("OrderHistory.QRCode.loginWarning").toString(),
      "warning"
    );
    history.push(LOGIN, {
      from: location.pathname,
      to: location.pathname,
    });
  }

  return (
    <div
      className={Styles.settingsPageContainer}
      id={"settingsPageContainerId"}
    >
      <div ref={headerRef}>
        <OrderHeader navigateBack={navigateBack} />
      </div>
      <Switch>
        <Route exact path={path}>
          <OrderListContainer headerRef={headerRef} />
        </Route>
        {/* <Route exact path={`${path}${BY_ID}${REVIEW}`}>
          <OrderReviewContainer />
        </Route> */}
        <Route path={`${path}${BY_ID}`}>
          <OrderDetailsContainer isStartRoute={isStartRoute} />
        </Route>
      </Switch>
    </div>
  );
}

export default WithConsumerAuth(OrdersRoute);
