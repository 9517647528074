import React, { useState } from "react";

import { useStripe, useElements } from "@stripe/react-stripe-js";
import T from "i18n-react";

import { setIsCardDetailsDrawerOpen,setIsPaymentDrawerOpen } from "../../reducer/PaymentActions";
import Drawer from "../../../Drawer";

import { useSnackbarMessages } from "../../../../yoello-lib/modules/components/snackbar/SnackbarContext";
import CardDetailsForm from "./CardDetailsForm";

interface ICardDetailsComponent {
  isOpen: boolean;
  dispatch: React.Dispatch<any>;
  paymentDetails: any;
  updatePaymentDetails: (
    paymentMethod: any,
    details: any,
    saveCard: boolean,
    userHasPaymentDetails: boolean
  ) => void;
}

const CardDetailsComponent = (props: ICardDetailsComponent) => {
  const stripe = useStripe();
  const elements = useElements();
  const [fullName, setFullName] = useState<string>("");
  const [postcode, setPostcode] = useState<string>("");
  const [rememberCard, setRememberCard] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState(false);
  const { sendSnackbarMessage } = useSnackbarMessages()!;
  const rememberCardOnChange = (e) => {
    setRememberCard(e.target.checked);
  };
  const submitCardDetails = async () => {
    setIsSaving(true);
    if (!stripe || !elements) {
      sendSnackbarMessage(
        T.translate("Payment.Errors.Unexpected").toString(),
        "error"
      );
      setIsSaving(false);
      return;
    }
    const cardElement = elements.getElement("cardNumber");
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: fullName,
        address: {
          postal_code: postcode,
        },
      },
    });
    if (error) {
      if (!error.param) {
        sendSnackbarMessage(error.message, "error");
      } else {
        if (error.param === "billing_details[name]") {
          const message = T.translate("Forms.ValidationErrors.Name");
          sendSnackbarMessage(message.toString(), "error");
        } else {
          const message = T.translate("Forms.ValidationErrors.Postcode");
          sendSnackbarMessage(message.toString(), "error");
        }
      }
    } else {
      const details = {
        payment_method: paymentMethod.id,
        card_type: paymentMethod.card.brand,
        masked_card_number: paymentMethod.card.last4,
        created_on: paymentMethod.created,
        name_on_card: paymentMethod.billing_details.name,
      };
      props.updatePaymentDetails(
        paymentMethod,
        details,
        rememberCard,
        !!props.paymentDetails
      );
      closeDrawer();
    }
    setIsSaving(false);
  };
  const closeDrawer = () => {
    props.dispatch(setIsCardDetailsDrawerOpen(false));
    props.dispatch(setIsPaymentDrawerOpen(true));
  };
  return (
    <Drawer
      instructions={["Payment.EnterYour", "Forms.CardDetails"]}
      isOpen={props.isOpen}
      closeDrawer={closeDrawer}
    >
      <CardDetailsForm
        fullName={fullName}
        postcode={postcode}
        setFullName={setFullName}
        setPostcode={setPostcode}
        submitCardDetails={submitCardDetails}
        isSaving={isSaving}
        rememberCardOnChange={rememberCardOnChange}
        rememberCard={rememberCard}
      />
    </Drawer>
  );
};

export default CardDetailsComponent;
