import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  PaymentRequestButtonElement,
  useStripe,
} from "@stripe/react-stripe-js";
import { usePaymentState } from "../PaymentStore";
import { getPlacedOrderPaymentIntent } from "../../../Api.js";
import { useCurrencyState } from "../../../yoello-lib/modules/hooks/useCurrencyState";
import T from "i18n-react";
import { roundPriceOption } from "../../../utils/pricingCalculator";
import Styles from "./styles.module.scss";
import { PaymentMethods } from "../types.d";
import { setSelectedPaymentMethod } from "../reducer/PaymentActions";
import { useDispatch } from "react-redux";
import { _updateCartID } from "../../../redux/actions";

/**Stripe pay now button for google/android/apple pay */
function StripePayNowButton({
  totalCost,
  checkPaymentProceed,
  createOrder,
  errorMessenger,
  successfulTransaction,
}) {
  const { retrieveToken, selectedPaymentMethod, dispatch } = usePaymentState();
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const currency = useCurrencyState();
  const isDisabled =
    selectedPaymentMethod &&
    selectedPaymentMethod !== PaymentMethods.GOOGLE_PAY;
  const rdxDispatch = useDispatch();
  useEffect(() => {
    if (stripe && paymentRequest === null) {
      const pRequest = stripe.paymentRequest({
        country: "GB",
        currency: currency.iso_code.toLowerCase(),
        total: {
          label: "Yoello Pay",
          amount: roundPriceOption(totalCost),
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });
      pRequest.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pRequest);
        }
      });
      pRequest.on("paymentmethod", async (ev) => {
        /**If the table is set but no table set, throw error */
        const canProceed = await checkPaymentProceed();
        if (canProceed) {
          const order = await createOrder(true).catch((e) => {
            ev.complete("fail");
            dispatch(setSelectedPaymentMethod(null));
            return;
          });
          const orderData = order?.data?.data;
          /**Check if the order data is returned */
          if (orderData === undefined) {
            errorMessenger(
              T.translate("Payment.Errors.SorryPaymentFailed") as string
            );
            dispatch(setSelectedPaymentMethod(null));
            ev.complete("fail");
            return;
          }
          const userToken = await retrieveToken();
          const orderIntent = await getPlacedOrderPaymentIntent(
            userToken,
            orderData.order_id
          );

          const clientSecret = orderIntent.data.data.payment_intent_key;
          const orderId = orderData.order_reference_number;
          const orderLongId = orderData.order_id;
          if (clientSecret === undefined || orderId === undefined) {
            errorMessenger(
              T.translate("Payment.Errors.SorryPaymentFailed") as string
            );
            dispatch(setSelectedPaymentMethod(null));
            ev.complete("fail");
            return;
          }

          // Confirm the PaymentIntent without handling potential next actions (yet).
          const { error: confirmError } = await stripe.confirmCardPayment(
            clientSecret,
            { payment_method: ev.paymentMethod.id },
            { handleActions: false }
          );
          try {
            if (confirmError) {
              // Report to the browser that the payment failed, prompting it to
              // re-show the payment interface, or show an error message and close
              // the payment interface.
              dispatch(setSelectedPaymentMethod(null));
              throw new Error("Failed payment");
            } else {
              // Report to the browser that the confirmation was successful, prompting
              // it to close the browser payment method collection interface.
              ev.complete("success");
              //calls our api to check if the order is confirmed
              //if an error then the order was not successful
              successfulTransaction(orderId, orderLongId);
              console.log("====================================");
              console.log("The payment has succeeded.");
              console.log("====================================");
              dispatch(setSelectedPaymentMethod(null));
            }
          } catch (error) {
            dispatch(setSelectedPaymentMethod(null));
            console.log("====================================");
            console.log(
              "The payment failed -- ask your customer for a new payment method.",
              error
            );
            console.log("====================================");
            ev.complete("fail");
            // The payment failed -- ask your customer for a new payment method.
            errorMessenger(error?.response?.data?.data?.errors[0] || undefined);
          }
        } else {
          dispatch(setSelectedPaymentMethod(null));
          ev.complete("fail");
        }
      });
    }
    // eslint-disable-next-line
  }, [stripe]);

  if (paymentRequest) {
    return (
      <div
        style={{
          marginTop: "2.9375rem",
          marginBottom: "2.5625rem",
        }}
        className={isDisabled && Styles.Disable}
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
            rdxDispatch(_updateCartID(uuidv4()));
            dispatch(setSelectedPaymentMethod(PaymentMethods.GOOGLE_PAY));
          }}
        >
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default StripePayNowButton;
