import React, { useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useDispatch } from "react-redux";

import { PaymentContext } from "../../PaymentStore";
import StripePaymentComponent from "./PaymentComponent";
import StripeCardDetailsComponent from "./CardDetailsComponent";

import useCompleteOrder from "../../PaymentHooks/useCompleteOrder";
import usePaymentDetails from "../../PaymentHooks/usePaymentDetails";
import useCanPaymentBeProcessed from "../../PaymentHooks/useCanPaymentBeProcessed";
import useOnSuccessfulOrder from "../../PaymentHooks/useOnSuccessfulOrder";
import { roundPriceOption } from "../../../../utils/pricingCalculator";

import { useSnackbarMessages } from "../../../../yoello-lib/modules/components/snackbar/SnackbarContext";

export interface IStripePaymentProcessor {}

const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_STRIPE!);

const StripePaymentProcessor = () => {
  const {
    isCardDetailsDrawerOpen,
    totalPrice,
    cartItemTaxAmount,
    serviceTaxAmount,
    paymentDetails,
    dispatch,
  } = useContext(PaymentContext);
  const { canPaymentBeProcessed } = useCanPaymentBeProcessed();
  const { updateStripeDetails } = usePaymentDetails();
  const { completeOrder } = useCompleteOrder();
  const { sendSnackbarMessage } = useSnackbarMessages();
  const { onSuccessfulOrder } = useOnSuccessfulOrder();
  const dispatcher = useDispatch();

  return (
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [
          { cssSrc: "https://fonts.googleapis.com/css?family=Open+Sans" },
        ],
      }}
    >
      <StripeCardDetailsComponent
        isOpen={isCardDetailsDrawerOpen}
        paymentDetails={paymentDetails}
        dispatch={dispatch}
        updatePaymentDetails={updateStripeDetails}
      />
      <StripePaymentComponent
        paymentDetails={paymentDetails}
        dispatch={dispatch}
        dispatcher={dispatcher}
        paymentFunction={completeOrder}
        totalPrice={roundPriceOption(totalPrice)}
        checkPaymentProceed={canPaymentBeProcessed}
        errorMessenger={(message: string) =>
          sendSnackbarMessage(message, "error")
        }
        successfulTransaction={onSuccessfulOrder}
        cartItemTaxAmount={cartItemTaxAmount}
        serviceTaxAmount={serviceTaxAmount}
      />
    </Elements>
  );
};

export default StripePaymentProcessor;
