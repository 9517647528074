import React from "react";
import { Redirect, useHistory } from "react-router-dom";
import { ORDERS, ROOT } from "../../constants/URL.const";
import APIExtensions from "../../yoello-lib/modules/API/APIDefs";
import useAPI from "../../yoello-lib/modules/API/useAPI";
import { useAuth0 } from "../../yoello-lib/modules/auth/Auth0";
import OrderHistoryListViewSkeleton from "../Widgets/Loading/OrderHistoryListView";
import OrderList from "./OrderList";

export function OrderListContainer({ headerRef }) {
  const { retrieveToken } = useAuth0()!;
  const { isLoading, apiData, isError } = useAPI<any[]>(
    APIExtensions.consumerOrders,
    retrieveToken,
    {}
  );
  const history = useHistory();

  if (isError) {
    return <Redirect to={ROOT} />;
  }

  if (isLoading) {
    return <OrderHistoryListViewSkeleton />;
  }

  function onSelect(id: string) {
    history.push(`${ORDERS}/${id}`);
  }

  return (
    <OrderList
      headerRef={headerRef}
      orderHistoryItems={apiData}
      onSelectedOrderItem={onSelect}
    />
  );
}
