import React from "react";

const FacebookIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 9.65999C0 6.24305 0 4.53458 0.675519 3.23415C1.24477 2.13829 2.13829 1.24477 3.23415 0.675519C4.53458 0 6.24306 0 9.66 0H16.3399C19.7569 0 21.4653 0 22.7658 0.675519C23.8616 1.24477 24.7551 2.13829 25.3244 3.23415C25.9999 4.53458 25.9999 6.24306 25.9999 9.66V16.3399C25.9999 19.7569 25.9999 21.4653 25.3244 22.7658C24.7551 23.8616 23.8616 24.7551 22.7658 25.3244C21.4653 25.9999 19.7569 25.9999 16.3399 25.9999H9.65999C6.24305 25.9999 4.53458 25.9999 3.23415 25.3244C2.13829 24.7551 1.24477 23.8616 0.675519 22.7658C0 21.4653 0 19.7569 0 16.3399V9.65999Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M14.6669 25.9992V16.3093H17.8154L18.287 12.5165H14.6669V10.1009C14.6669 9.0053 14.9601 8.25878 16.4742 8.25878H18.3932V4.87567C18.0593 4.8296 16.9138 4.72656 15.581 4.72656C12.7981 4.72656 10.8935 6.48837 10.8935 9.72524V12.5165H7.75684V16.3093H10.8935V25.9992H14.6669Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="13"
          y1="0"
          x2="13"
          y2="25.9999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1AACFF" />
          <stop offset="1" stopColor="#0163E0" />
          <stop offset="1" stopColor="#0064E4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FacebookIcon;
