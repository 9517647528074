import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useSquareVenue = () => {
  const venue = useSelector((state: any) => state.Venue);
  const [isSquareVenue, setIsSquareVenue] = useState<boolean>();

  useEffect(() => {
    setIsSquareVenue(venue.payment_processor === "SQUARE");
  }, [venue]);

  return {
    isSquareVenue,
  };
};

export default useSquareVenue;
