import React, { ComponentType, useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import T from "i18n-react";

import Styles from "./styles.module.scss";
import { useSelector } from "react-redux";

import PageComponent from "../Page/PageComponent";
import { LOGIN } from "./../../constants/URL.const";
import {
  setDiscountsTotalValue,
  setIsPaymentReady,
  setTotalPriceWithoutTips,
} from "./reducer/PaymentActions";
import { PaymentContext } from "./PaymentStore";
import OrderTypesComponent from "./OrderTypesComponent/OrderTypesComponent";
import MessageComponent from "./MessageInput/MessageComponent";
import TipComponent from "./Tips/TipComponent";
import DiscountsComponent, {
  DiscountType,
} from "./Dsicounts/DiscountsComponent";
import PricingComponent from "./Pricing/PricingComponent";
import NavigationHeader from "../NavigationHeader/NavigationHeader";
import PaymentViewDrawers from "./PaymentViewDrawers";
import PaymentFooter from "./PaymentFooter";

import useSquareVenue from "../../customHooks/Square/useSquareVenue";
import useSquareUser from "../../customHooks/Square/useSquareUser";
import PostCodeAlert from "../Widgets/PostCodeChecker/PostCodeAlert/PostCodeAlert";
import { PaymentStages } from "./types.d";
import { IPageComponentProps } from "../Page/types.d";

import { useVenueOpen } from "../../customHooks/useVenueOpen";
import usePaymentView from "./PaymentHooks/usePaymentView";
import usePaymentDetails from "./PaymentHooks/usePaymentDetails";
import useFeesAndTaxes from "./PaymentHooks/useFeesAndTaxes";
import { checkTimeslotAgaintsMenuTimings } from "../../utils/checkTimeslotAgaintsMenuTimings";

import { changeDeliveryDetails } from "./reducer/PaymentActions";
import useDelivery from "./PaymentHooks/useDelivery";
import { SelectedOrderType } from "./types.d";

interface IPaymentContainerProps extends IPageComponentProps {}

/**
 * Payment container handles all the different state changes of creating an order
 * @param param0
 */
function PaymentContainer({
  isAuthenticated,
  openSideDrawer,
  User,
}: IPaymentContainerProps) {
  const {
    dispatch,
    paymentStages,
    subTotalCost,
    totalPrice,
    transactionFee,
    tipTotal,
    postcodeCheckStatus,
    discounts,
    selectedTime,
    selectedOrderType,
  } = useContext(PaymentContext);

  const { venue, backFunction } = usePaymentView();
  const { getPaymentDetails } = usePaymentDetails();
  const { handleSquareUser } = useSquareUser();
  const { isSquareVenue } = useSquareVenue();
  const history = useHistory();
  const { setFeesAndTaxes } = useFeesAndTaxes();
  const isVenueOpen = useVenueOpen(false);
  const Venue = useSelector((state: any) => state.Venue);
  const cartItems = useSelector((state: any) => state.Cart);
  const AvailableMenus = useSelector((state: any) => state.AvailableMenus);
  const [menuTimingAlerts, setMenuTimingAlerts] = useState<IAvailableMenus[]>(
    []
  );
  const { checkPostCodeRadius } = useDelivery(isAuthenticated);

  useEffect(() => {
    setMenuTimingAlerts(
      checkTimeslotAgaintsMenuTimings(selectedTime, cartItems, AvailableMenus)
    );
    // eslint-disable-next-line
  }, [selectedTime]);
  useEffect(() => {
    /**If the user is not authenticated, or does not exist, make them go to the login */
    // @ts-ignore
    // eslint-disable-next-line
    if (!isAuthenticated || !User || User.sub) {
      return history.push(LOGIN);
    }

    // make sure if have a copy of the users address in state if it already existis in local
    const address = JSON.parse(window.localStorage.getItem("BillingAddress"));
    if (
      address &&
      selectedTime &&
      selectedOrderType === SelectedOrderType.DELIVERY
    ) {
      dispatch(changeDeliveryDetails(address));
      checkPostCodeRadius();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (subTotalCost > 0) {
      let totalDiscountAmount = 0;
      if (discounts.type === DiscountType.ABSOLUTE) {
        totalDiscountAmount = discounts.value;
      } else if (discounts.type === DiscountType.PERCENTAGE) {
        totalDiscountAmount = (discounts.value * subTotalCost) / 100;
      }
      dispatch(setDiscountsTotalValue(totalDiscountAmount));
    }
    //eslint-disable-next-line
  }, [discounts]);

  useEffect(() => {
    if (subTotalCost > 0) {
      dispatch(setTotalPriceWithoutTips(subTotalCost));
    }
    //eslint-disable-next-line
  }, [subTotalCost]);

  useEffect(() => {
    if (isAuthenticated) {
      getPaymentDetails();
    }
    //eslint-disable-next-line
  }, [isAuthenticated]);

  useEffect(() => {
    setFeesAndTaxes(totalPrice, transactionFee, tipTotal, dispatch);
    if (isAuthenticated && isSquareVenue) {
      handleSquareUser();
    } else {
      dispatch(setIsPaymentReady(true));
    }
    //eslint-disable-next-line
  }, [isAuthenticated, isSquareVenue]);

  useEffect(() => {
    setFeesAndTaxes(totalPrice, transactionFee, tipTotal, dispatch);
    //eslint-disable-next-line
  }, [venue, totalPrice, tipTotal, discounts.value]);
  const renderPageComponents = () => {
    if (paymentStages === PaymentStages.PAYMENT) {
      return (
        <div className={Styles.ChooseMethod}>
          <OrderTypesComponent isVenueOpen={isVenueOpen} />
          <PostCodeAlert
            postcodeCheckStatus={postcodeCheckStatus}
            menuTimingAlerts={menuTimingAlerts}
          />
          <MessageComponent />
          {!isSquareVenue && <DiscountsComponent subTotalCost={subTotalCost} />}
          {Venue.tipping_enabled ? <TipComponent /> : ""}
          <PricingComponent />
        </div>
      );
    }
  };
  return (
    <>
      <NavigationHeader
        openSideDrawer={openSideDrawer}
        title={T.translate("Payment.Checkout") as string}
        displayVenueImage={false}
        backURL={backFunction}
        className={Styles.paymentNavigationHeader}
      />
      <div className={Styles.Payment}>
        {renderPageComponents()}
        <PaymentViewDrawers isAuthenticated={isAuthenticated} />
        <PaymentFooter />
      </div>
    </>
  );
}
export default PageComponent(PaymentContainer as ComponentType);
