import React from "react";
import OrderTypeHeader from "../../../Widgets/OrderTypeHeader/OrderTypeHeader";
import { YellowColorCodeForOrderTypeSvgs } from "../OrderTypesComponent";
import { ReactComponent as CollectionBagSVG } from "../../../../imges/CollectionBag.svg";
import { useSelector } from "react-redux";
import { AlertStatusCard } from "../../../../yoello-lib/modules/components/UI/Alerts/AlertStatusCard";
import T from "i18n-react";
import { ReactComponent as BlueLocation } from "../../../../imges/BlueLocation.svg";

export function Pickup() {
  const Venue = useSelector((state: any) => state.Venue);
  return (
    <div>
      <OrderTypeHeader
        title="Payment.pickupNow"
        custom={Venue.collection_point_pickup_lable}
        svgImage={<CollectionBagSVG fill={YellowColorCodeForOrderTypeSvgs} />}
        selected={true}
      />
      <AlertStatusCard
        alertTitle={<b>{T.translate("OrderHistory.collectionPoint")}</b>}
        alertMessage={T.translate("Payment.CollectionPointInfo")}
        alertProps={{
          color: "info",
          icon: <BlueLocation />,
        }}
      />
    </div>
  );
}
