import React, { useMemo } from "react";
import { useFormState } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ICommonFormProps from "./interfaces/ICommonFormProps";

const knownErrors = ["required", "pattern", "minLength", "maxLength", "min", "max"];

/**Form error handler */
export function FormErrors(props: ICommonFormProps) {
    const { control, keyName } = props;
    const { t } = useTranslation();
    const { errors } = useFormState({
        control
    })
    const errorObj = errors[keyName];

    const label = useMemo(() => {
        if (!errorObj) return null;

        if (errorObj.message) {
            if (typeof errorObj.message === "string") {
                return t(errorObj.message.toString(), { keyName: props.label });
            }
            return errorObj?.message;
        }
        let message: string = "defaulterror";
        if (errorObj.type && knownErrors.indexOf(errorObj.type) !== -1) {
            message = errorObj.type as string;
        }
        return `${props.label} ${t('errors.formerrors.' + message)}`;
        // eslint-disable-next-line
    }, [errorObj, errorObj?.type, errorObj?.message, props.label]);

    if (!errorObj) {
        return null;
    }

    return <div className="formErrors" data-component-name={`errors-${props.keyName}`} style={{ color: "red" }}>
        {label}
    </div>
}