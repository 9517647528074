import { useEffect, useState } from "react";

interface IWindowHeightProps {
  /**false if you don't want to update the screen size */
  isResizeable: boolean;
}

/**Hook for getting the windows height */
export function useWindowHeight({ isResizeable }: IWindowHeightProps) {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    if (isResizeable) {
      window.addEventListener("resize", windowEventListener);
    }
    return () => {
      if (isResizeable) {
        window.removeEventListener("resize", windowEventListener);
      }
    };
    // eslint-disable-next-line
  }, []);

  function windowEventListener() {
    setWindowHeight(window.innerHeight);
  }

  return windowHeight;
}
