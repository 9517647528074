export const onKeyboardPressHandler = (event) => {
  console.log(event.currentTarget);
  if (event.key === "Enter") {
    blurKeyBoard(event.currentTarget);
  }

  // event
};

export const blurKeyBoard = (document) => {
  if (document && document.blur && typeof document.blur === "function") {
    console.log("Attempting to blur");
    document.blur();
  }
};
