
import React, { createContext, useContext, useState, FunctionComponent, useEffect } from "react";
import auth0 from "auth0-js";
import WebAuth0, { ConnectionType } from "./WebAuth0";
import history from './history';
import { ERROR_URL } from "./URL.const";
import { getCurrentEpochTime } from "./epochTime";
import auth0config from './Auth0.config.json';
import { useTranslation } from "react-i18next";
import IConsumerUser from './IConsumerUser';

export interface IAuth0Context {
    /**Login function */
    login: (username: string, password: string, redirectPath: string) => void;
    /**Reset Password function */
    resetPassword: (email: string) => void;
    /**Function for retrieving token */
    retrieveToken: () => Promise<string | undefined>;
    /**Register function */
    register: (username: string, password: string) => Promise<string>;
    /**Is user authenticated */
    isAuthenticated: boolean;
    /** Is auth0 still loading */
    isLoading: boolean;
    /**Auth0 error messages */
    auth0ErrorMessage: string | undefined;
    /**Function to logout */
    logout: () => void;
    /**Auth0 user profile */
    user: User;
    /**Token Object */
    token: ITokenObject | undefined;
    /**Used as part of the auth flow to check if the user is logged in */
    retrieveUser: (pathname?: string) => Promise<void>;
    /**Function which starts the authorise process with a local storage token */
    oldTokenAuthorise: (pathname?: string) => Promise<void>;
    /**Function for starting the passwordless login flow */
    passwordlessLogin: (value: string, connectionType?: ConnectionType) => Promise<any>;
    /**Function for verifiy the one time code sent via passwordless */
    passwordlessVerifiySMS: (value: string, oneTimeCode: string, connectionType?: ConnectionType, redirectUri?: string) => Promise<any>;
    /**Function to set the user object */
    setUser: (userObj: User) => void;
    /**Sets the redirect url for navigating back to the last page */
    setRedirectURLs: (url: string) => void;
    /*Logs user in using social logins */
    loginWithSocial: (connection: string, scope: string, redirectUri?: string, prompt?: string) => void;
    /*Gets user info that's stored in auth0 */
    getAuthUser: (accessToken: string) => Promise<auth0.Auth0UserProfile>;
    /**the url to redirect with */
    redirectURL: string | null;
    /**Set up the authed user */
    setUpAuthedUser: any;
    isValidToken: () => boolean;
    isRetrievingUser: boolean;
    /**Login url prop for passing throughout app */
    loginURL: string;
    parseRoutes?: (route: string) => string;
}
export type User = auth0.Auth0UserProfile | IConsumerUser;
export interface IAuth0Config {
    /**Auth0 domain */
    domain: string;
    /**Auth0 client ID */
    clientId: string;
    /**API audience */
    audience?: string;

}

export interface IAuth0Options {
    /**Config file for auth0 */
    config: auth0.AuthOptions;
    /** Keeps the token in storage so when you refresh or go back you log in */
    isRefreshable?: boolean;
    /**local storage key, defaults to `user_token_${window.location.host}` */
    tokenKey?: string;
    /**This is where you want to refresh your token would usually be the url you login from */
    loginURL: string;
    /**Parses the routes as part of the redirect hooks */
    parseRoutes?: (path: string) => string;
}

/**Creates the Auth0 context */
export const Auth0Context = createContext<IAuth0Context | undefined>(undefined);

/**Hook for using the Auth0 */
export const useAuth0 = () => useContext(Auth0Context);

interface ITokenObject {
    /**Epoch time it expires */
    expires: number;
    /**Access token */
    token: string;
}


/**
 * Auth0 provider this handles the users authentication, register a user
 * and token retrieval 
 */
export const Auth0Provider: FunctionComponent<IAuth0Options> = ({ children, config, isRefreshable, loginURL, parseRoutes, tokenKey = `user_token_${window.location.host}` }) => {
    const { t } = useTranslation();
    const [auth0WebAuth] = useState<WebAuth0>(new WebAuth0(config));
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState<User | undefined>();
    const [isLoading, setIsLoading] = useState(true);
    const [isRetrievingUser, setIsRetrievingUser] = useState(false);
    const [token, setToken] = useState<ITokenObject | undefined>();
    const [auth0ErrorMessage, setAuth0ErrorMessage] = useState<string | undefined>();
    const [redirectURL, setRedirectURL] = useState<string | null>(sessionStorage.getItem('redirectURL'));

    useEffect(() => {
        if (auth0ErrorMessage) {
            setAuth0ErrorMessage(undefined);
        }
        // eslint-disable-next-line
    }, []);
    /**
     * Registers the user and creates a user object and sets up the token
     * returns the users auth0 id
     */
    const register = async (email: string, password: string) => {
        if (auth0WebAuth && !isAuthenticated) {
            try {
                const registerObj = await auth0WebAuth.register(email, password);
                const user = await auth0WebAuth.decodeAccessTokenToUser(registerObj.accessToken);
                await setTokenObject({ accessToken: registerObj.accessToken, expiresIn: registerObj.expiresIn });
                setUser(user);
                setIsAuthenticated(true);
                return user.sub;
            } catch (error: any) {
                if (error?.code) {
                    setAuth0ErrorMessage(t('errors.auth0.regwithcode'));
                } else {
                    setAuth0ErrorMessage(t('errors.auth0.regwithoutcode'));
                }
                throw error;
            }

        } else {
            throw new Error(t('errors.auth0.regcurrentstate'));
        }
    };
    const retrieveUser = async (pathname?: string) => {
        setIsRetrievingUser(true);
        if (isLoading === false) {
            setIsLoading(true);
        }
        /**
         * Checks if the window has an access token, then parses URL hash
         * to create userobject and token object
         */
        if (window.location.hash.includes("access_token=")) {
            try {
                const decodedHash = await auth0WebAuth.parseURLHash();
                if (decodedHash.accessToken) {
                    await setTokenObject(decodedHash);
                    await setUserViaToken(decodedHash.accessToken);
                } else {
                    throw new Error(t('errors.auth0.decodetoken'));
                }
            } catch (error: any) {
                errorHandler(error);
            } finally {
                setIsLoading(false);
                setIsRetrievingUser(false);
            }
        } else {
            /**Refreshable retrieves the saved token in storage and retries logging the user in */
            if (isRefreshable === true && isAuthenticated === false) {
                await oldTokenAuthorise(pathname);
            } else {
                if (isAuthenticated === true) {
                    setIsRetrievingUser(false);
                    return;
                } else {
                    setIsRetrievingUser(false);
                    throw new Error("User not logged in");
                }
            }
        }
        setIsRetrievingUser(false);
    };
    /**Checks for old token, if an old token is found will try relogging */
    async function oldTokenAuthorise(pathname?: string) {
        const tokenObj = getTokenFromLocalStorage(tokenKey);
        if (tokenObj !== undefined) {
            if (!checkIfExpired(tokenObj.expires)) {
                setIsAuthenticated(true);
                setUserViaToken(tokenObj.token);
                setToken(tokenObj);
                return;
            }
            const redirectURL = window.location.origin + (pathname || window.location.pathname);
            const sessionObj = await auth0WebAuth.checkSession(redirectURL);
            if (sessionObj?.expiresIn! > 0) {
                setIsAuthenticated(true);
                await setUserViaToken(sessionObj?.accessToken!);
                setToken(sessionObj.idTokenPayload);
                setTokenObject(sessionObj);
            } else {
                auth0WebAuth.authorise({
                    nonce: sessionObj.idTokenPayload.nonce!, prompt: "none",
                    redirectUri: redirectURL
                });
            }
        } else {
            throw new Error("no token");
        }
    }
    /**retrieves the user object via the access token */
    async function setUserViaToken(accessToken: string) {
        try {
            const user = await auth0WebAuth.decodeAccessTokenToUser(accessToken);
            setUser(user);
            setIsAuthenticated(true);
        } catch (error) {
            auth0WebAuth.authorise({
                prompt: "none",
                redirectUri: window.location.origin + loginURL
            });
        }
    }
    /**Sets the token and expiry with a decoded hash */
    const setTokenObject = (decodedHash: auth0.Auth0DecodedHash) => {
        return new Promise<void>((resolve, reject) => {
            if (decodedHash.accessToken && decodedHash.expiresIn) {
                const expires = getCurrentEpochTime() + decodedHash.expiresIn;
                const tokenObject = {
                    expires: expires,
                    token: decodedHash.accessToken
                };
                if (isRefreshable === true) {
                    saveTokenToLocalStorage(tokenObject, tokenKey);
                }
                setToken(tokenObject);
                resolve();
            } else {
                reject(new Error(t('errors.auth0.tokenobj')));
            }
        });
    };
    /**Silently retrieves the token and renews if expired */
    const retrieveToken = async () => {
        if (token) {
            if (checkIfExpired(token.expires)) {
                const sessionObj = await auth0WebAuth.checkSession(window.location.origin + loginURL);
                setTokenObject(sessionObj);
                return `${sessionObj.accessToken}`;
            } else {
                return `${token.token}`;
            }
        } else {
            const token = getTokenFromLocalStorage(tokenKey);
            if (token) {
                if (checkIfExpired(token.expires)) {
                    const sessionObj = await auth0WebAuth.checkSession(window.location.origin + loginURL);
                    setTokenObject(sessionObj);
                    return `${sessionObj.accessToken}`;
                } else {
                    return `${token.token}`;
                }
            }
        }
    };
    /**Login using the a username and password */
    const login = async (username: string, password: string, redirectPath: string) => {
        if (auth0WebAuth) {
            try {
                setIsLoading(true);
                await auth0WebAuth.login({
                    username, password,
                    realm: "Username-Password-Authentication",
                    redirectUri: window.location.origin + redirectPath
                });
            } catch (error: any) {
                let errMsg;
                if (error.description) {
                    errMsg = error.description;
                } else if (error.message) {
                    errMsg = error.message;
                }
                setAuth0ErrorMessage(errMsg);
                setIsLoading(false);
            }
        }
    };
    /**Reset password using the an email address */
    const resetPassword = async (email: string) => {
        if (auth0WebAuth) {
            try {
                setIsLoading(true);
                await auth0WebAuth.resetPassword({
                    email,
                    connection: "Username-Password-Authentication",
                })
                setIsLoading(false);
            } catch (error: any) {
                let errMsg;
                if (error.description) {
                    errMsg = error.description;
                } else if (error.message) {
                    errMsg = error.message;
                }
                setAuth0ErrorMessage(errMsg);
                setIsLoading(false);
            }
        }
    };
    const isValidToken = () => {
        const tokenObj = getTokenFromLocalStorage(tokenKey);
        if (isRefreshable && tokenObj && tokenObj.token) {
            return true;
        }
        return false;
    }
    /**Starts the passwordless login process */
    const passwordlessLogin = async (phoneNumber: string, connectionType?: ConnectionType) => {
        if (auth0WebAuth) {
            const passwordless = await auth0WebAuth.passwordlessStart(phoneNumber, connectionType);
            return passwordless;
        }
    }
    /**Verifiys the one time code sent via passwordless start */
    const passwordlessVerifiySMS = async (phoneNumber: string, oneTimeCode: string, connectionType?: ConnectionType, redirectUri?: string) => {
        if (auth0WebAuth) {
            const passwordlessVerifiy = await auth0WebAuth.passwordlessSMSVerify(phoneNumber, oneTimeCode, connectionType, redirectUri);
            return passwordlessVerifiy;
        }
    }
    const logout = () => {
        setIsAuthenticated(false);
        setUser(undefined);
        setToken(undefined);
        removeTokenFromLocalStorage(tokenKey);
        auth0WebAuth.logout();
    };
    const setRedirectURLs = (url: string) => {
        sessionStorage.setItem('redirectURL', url);
        setRedirectURL(redirectURL);
    }
    const setUpAuthedUser = async (userData: IConsumerUser, tokenObj?: ITokenObject) => {
        if (token === undefined && tokenObj === undefined) {
            console.error("Warning no token and no token obj")
            return;
        }
        setIsAuthenticated(true);
        setIsLoading(false);
        if (tokenObj !== undefined) {
            setToken(tokenObj);
        }
        setUser(userData);
    }

    const loginWithSocial = (connection: string, scope: string, redirectUri?: string, prompt?: string) => {
        auth0WebAuth.authorise({ connection, scope, redirectUri, prompt });
    }

    const getAuthUser = async (accessToken: string) => {
        const user = await auth0WebAuth.decodeAccessTokenToUser(accessToken);
        return user;
    }

    return <Auth0Context.Provider
        value={{
            retrieveToken,
            register,
            login,
            resetPassword,
            isAuthenticated,
            isRetrievingUser,
            isLoading,
            auth0ErrorMessage,
            logout,
            user: user!,
            token: token,
            retrieveUser,
            getAuthUser,
            oldTokenAuthorise,
            passwordlessLogin,
            passwordlessVerifiySMS,
            setUser,
            setRedirectURLs,
            redirectURL,
            setUpAuthedUser,
            isValidToken,
            loginWithSocial,
            loginURL,
            parseRoutes
        }}>
        {children}
    </Auth0Context.Provider>;
};

/**Removes the token from storage */
function removeTokenFromLocalStorage(tokenKey: string) {
    localStorage.removeItem(tokenKey);
}

/**Saves the toke object to storage */
function saveTokenToLocalStorage(tokenObject: ITokenObject, tokenKey: string) {
    if (tokenObject !== undefined) {
        const stringObject = JSON.stringify(tokenObject);
        localStorage.setItem(tokenKey, stringObject);
    }
}
/**Retreives token from local storage */
function getTokenFromLocalStorage(tokenKey: string): ITokenObject | undefined {
    const tokenObj = localStorage.getItem(tokenKey);
    if (tokenObj === null) {
        return undefined;
    } else {
        return JSON.parse(tokenObj);
    }
}


/**Basic error handler which redirects to the error page */
const errorHandler = (error: Error) => {
    const errorState = {
        disabled: true, error
    };
    history.push(ERROR_URL, errorState);
};

/**Checks if the token has expired or if its close to expiry */
export const checkIfExpired = (expires: number) => {
    const currentEpochTime = getCurrentEpochTime();
    if (currentEpochTime >= expires) {
        return true;
    } else if ((expires - currentEpochTime) < auth0config.secondsTillRewenal) {
        return true;
    } else {
        return false;
    }
};