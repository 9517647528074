import React, { useEffect, useState } from "react";
import { Collapse } from "antd";
import Styles from "./tradingTimes.module.scss";
import { ItradingTimesProps } from "./types.d";
import T from "i18n-react";
import moment from "moment";

const { Panel } = Collapse;

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const TradingTimes = ({
  trading_times,
  selectedLanguage,
}: ItradingTimesProps) => {
  const [activeDay] = useState(moment().format("dddd").toLowerCase());
  const [tradingTimesElements, setTradingTimesElements] = useState({});

  useEffect(() => {
    /**Prebuild the list of elements into an object */
    const newTradingElements = {};
    if (trading_times) {
      console.log("trading_times", trading_times);
      Object.keys(trading_times).forEach((key) => {
        newTradingElements[key] = _renderEachWorkTime(key);
      });
    }

    setTradingTimesElements(newTradingElements);
    //eslint-disable-next-line
  }, [selectedLanguage]);

  const _renderEachWorkTime = (item: string) => {
    const times = trading_times[item];
    const isOpen =
      times.length > 0 && times.some((item: any) => item.isDisplayed);

    return isOpen ? (
      trading_times[item].map((time: ITradingTimesEntity, index: number) => {
        if (time.isDisplayed) {
          return (
            <div key={Math.random()}>
              <span>{time.openTime}-</span>
              <span>{time.closeTime}</span>
            </div>
          );
        } else {
          return null;
        }
      })
    ) : (
      <T.p key={"closedVenue"} text={{ key: `Venue.VenueClosed` }} />
    );
  };
  function renderWorkTimes(day: string) {
    return (
      <div
        className={`${Styles.worksDays} ${
          activeDay === day.toLowerCase() ? Styles.active : null
        }`}
        id={activeDay === day.toLowerCase() ? "ActiveTradingDay" : null}
      >
        <div>{T.translate(`Days.${day}`)}</div>
        <div>
          {tradingTimesElements[day.toLocaleLowerCase()]
            ? tradingTimesElements[day.toLocaleLowerCase()]
            : ""}
        </div>
      </div>
    );
  }
  return (
    <div className={Styles.tradingTimesContainer}>
      <Collapse>
        <Panel
          header={
            <span data-component-name={"tradingTimesCollapse"}>
              {T.translate("Venue.OpeningTime")}
            </span>
          }
          key={"1"}
        >
          <ul>
            {days.map((day) => (
              <li key={day}>{renderWorkTimes(day)}</li>
            ))}
          </ul>
        </Panel>
      </Collapse>
    </div>
  );
};

export default TradingTimes;
