import React, { useState, useMemo } from "react";
import { VerificationMethod } from "../../constants/verificationmethod";
import DynamicForm from "../../yoello-lib/modules/dynamicForms/DynamicForms";

import T from "i18n-react";
import MainTheme from "../../yoello-lib/modules/themes/MainTheme";
import ThemeWrapper from "../../yoello-lib/modules/themes/ThemeWrapper";

import EColourPanel from "../../yoello-lib/modules/dynamicForms/interfaces/EColourPanel";

import Drawer from "../Drawer";
import { makeStyles } from "@material-ui/core";
import { IUpdateNotificationSettings } from "./useAccountView";

import { NotificationForm } from "./Notification.form";

const useStyles = makeStyles({
  formWrapper: {
    padding: "0  1.5625rem  1.5625rem 1.5625rem",
  },
  emailWrapper: {
    "&>label": {
      fontSize: "1rem",
      lineHeight: "1.625rem",
      display: "flex",
      alignItems: "center",
      color: "#212121",
    },
  },
  button: {
    background: "#ffdd02",
    borderRadius: "0.625rem",
    height: "3.125rem",
    textTransform: "none",
    width: "100%",
    fontWeight: 600,
    fontSize: "0.9375rem",
    lineHeight: "1.25rem",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#212121",
    "&:active": {
      background: "#ffdd02",
    },
  },
  leftButton: {
    display: "none",
  },
  rightButton: {
    marginTop: "1.875rem",
  },
});

export interface INotificationsDrawer {
  selection: VerificationMethod;
  isOpen: boolean;
  hasEmail: boolean;
  onClose: () => void;
  onSubmit: (data: IUpdateNotificationSettings) => Promise<void>;
}

const NotificationsDrawer = ({
  selection,
  isOpen,
  hasEmail,
  onClose,
  onSubmit,
}: INotificationsDrawer) => {
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>(selection);

  const formRows = useMemo(() => {
    const rows = NotificationForm(selection, setSelectedOption, classes);
    if (selectedOption === "sms" || (selection === "sms" && !selectedOption)) {
      rows.splice(rows.length - 1);
    }
    if (selectedOption === "email" && hasEmail) {
      rows.splice(rows.length - 1);
    }
    return rows;
    //eslint-disable-next-line
  }, [selectedOption]);

  const handleSubmit = ({
    notification_method,
    contact_email,
  }: {
    notification_method: string;
    contact_email?: string;
  }) => {
    setIsSubmitting(true);
    onSubmit({
      notificationMethod: notification_method,
      email: contact_email,
    }).finally(() => {
      setIsSubmitting(false);
    });
  };

  function onDrawerClose() {
    onClose();
    // should be set to undefiend after animation completing
    setTimeout(() => {
      setSelectedOption(undefined);
    }, 100);
  }

  return (
    <Drawer
      instructions={["", T.translate("Account.ReceiveVia").toString()]}
      closeDrawer={onDrawerClose}
      isOpen={isOpen}
    >
      <div className={classes.formWrapper}>
        <ThemeWrapper theme={MainTheme}>
          <DynamicForm
            formData={{ notification_method: selection }}
            formRows={formRows}
            formKeyName="profile-builder"
            onHandleSubmit={handleSubmit}
            formButtons={{
              rightButton: {
                type: "submit",
                label: isSubmitting
                  ? T.translate("Account.Submitting").toString()
                  : T.translate("Account.Submit").toString(),
                color: EColourPanel.PRIMARY,
                className: classes.button,
                disabled: isSubmitting,
              },
              leftButtonCustomClassName: classes.leftButton,
              rightButtonCustomClassName: classes.rightButton,
            }}
          />
        </ThemeWrapper>
      </div>
    </Drawer>
  );
};

export default NotificationsDrawer;
