import React from "react";
import { getDateWithMonthName, getTime } from "../../ConvertDate";
import OrderStatus from "../OrderStatus";
import { ReactComponent as RightArrowSVG } from "../../../imges/RightArrow.svg";
import { priceToPounds } from "../../../utils/pricingCalculator";
import { IOrderHistoryView } from "./types.d";
import Styles from "./orderHistoryCard.module.scss";

function OrderHistoryCard({ info, onClick }: IOrderHistoryView) {
  return (
    <div
      onClick={() => onClick()}
      data-component-name="OrderHistoryCard"
      className={Styles.orderHistoryCardContainer}
    >
      <div className={Styles.orderDetails}>
        <div>
          <p className={Styles.orderId}>{info.hash_id}</p>
          <p className={Styles.orderDateText}>
            {getDateWithMonthName(info.created_on)},{getTime(info.created_on)}
          </p>
        </div>
        <div>
          <p className={Styles.price}>
            {info.currency_symbol}
            {priceToPounds(info.order_total)}
          </p>
        </div>
      </div>
      <div className={Styles.orderVanueAndStatus}>
        <div>
          {info.url_logo && <img src={info.url_logo} alt={info.venue_name} />}
          <p className={Styles.placeName}>{info.venue_name}</p>
        </div>
        <div>
          <div>
            {info.order_status && <OrderStatus status={info.order_status} />}
            <span>
              <RightArrowSVG />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderHistoryCard;
