import React from "react";
import Drawer from "../../../../Drawer";
import { usePaymentState } from "../../../PaymentStore";
import PaymentProcessingComponent from "../../PaymentProcessingComponent";

const OrderProcessing = () => {
  const { isPaymentProcessingDrawerOpen } = usePaymentState();
  return (
    <Drawer isOpen={isPaymentProcessingDrawerOpen} closeDrawer={() => {}}>
      <PaymentProcessingComponent />
    </Drawer>
  );
};

export default OrderProcessing;
