import Styles from "./filteringPage.module.scss";
import { FiltersType } from "../types.d";
import Button from "../../Widgets/Button";
import T from "i18n-react";
import React, { useEffect, useState } from "react";
import { Checkbox } from "antd";

type FiltersArrayType = {
  title: string;
  value: boolean;
  key: string;
};

interface IFilteringPageProps {
  Filters: FiltersType;
  onChange: (toBeChanged: FiltersType) => void;
  onCloseDrawer: () => void;
}

function FilteringPage({
  Filters,
  onChange,
  onCloseDrawer,
}: IFilteringPageProps) {
  const [FiltersArray, setFiltersArray] = useState<FiltersArrayType[]>([]);
  const [FiltersObj, setFiltersObj] = useState<FiltersType>(Filters);

  useEffect(() => {
    setFiltersObj(Filters);
    fetchFilters();
    //eslint-disable-next-line
  }, [Filters]);

  function fetchFilters() {
    let tempFiltersArray = [
      {
        title: T.translate("Venue.TableService") as string,
        value: FiltersObj.TableService,
        key: "TableService",
      },
      {
        title: T.translate("Venue.Delivery") as string,
        value: FiltersObj.Delivery,
        key: "Delivery",
      },
      {
        title: T.translate("Venue.Collection") as string,
        value: FiltersObj.Collection,
        key: "Collection",
      },
      {
        title: T.translate("Venue.pickupNow") as string,
        value: FiltersObj.PickupNow,
        key: "PickupNow",
      },
    ];
    setFiltersArray(tempFiltersArray);
  }

  function onFilterChange(toBeChanged) {
    let Filters: FiltersType;
    Filters = FiltersObj;
    Filters[toBeChanged] = !FiltersObj[toBeChanged];
    setFiltersObj(Filters);
    fetchFilters();
    onChange(Filters);
  }
  return (
    <div id="FilteringPage" className={Styles.FilteringPage}>
      <div className={Styles.lineBar}>
        {" "}
        <span></span>{" "}
      </div>
      <div className={Styles.HeaderDiv}>
        <div className="Flex1">
          <Button
            iconClassName="consumer-left-arrow"
            onClick={onCloseDrawer}
            dataComponentName={"BackButton"}
            style={{ color: "#AAB6D3", fontSize: "1.5rem", position: "unset" }}
            isHeaderBtn
          />
        </div>
        <div className="Flex4">
          <p className={Styles.HeaderTitle}>
            {T.translate("Group.FilterByOrderType")}
          </p>
        </div>
        <div className="Flex1" />
      </div>
      <div
        className={Styles.FiltersListDiv}
        data-component-name={"filteringPageContainer"}
      >
        {FiltersArray.map((filter, i) => {
          return (
            <div
              className={Styles.FilterDiv}
              onClick={() => onFilterChange(filter.key)}
              key={filter.key}
            >
              <p>{filter.title}</p>
              <div data-component-name={filter.title}>
                <Checkbox checked={filter.value} />
              </div>
            </div>
          );
        })}
      </div>
      <div className={Styles.ConfirmButtonDiv}>
        <Button
          onClick={onCloseDrawer}
          isYoelloBtn
          title="Confirm"
          style={{
            width: "90%",
          }}
        />
      </div>
    </div>
  );
}

export default FilteringPage;
