import React from "react";
import ProductDetailsDisplay from "./utils/ProductDetailsDisplay";
import { setPrice } from "./state/ProductDetails.action";
import ProductTags from "./Tags/ProductTags";

interface IProductDetailsProps {
  isShownTags: boolean;
  menuItem: IMenuItem;
  selectedPriceName: string | null;
  dispatch: any;
}

export function ProductDetails({
  isShownTags,
  menuItem,
  selectedPriceName,
  dispatch,
}: IProductDetailsProps) {
  function onClickPriceOptions(item: string, data: IPricingOptions) {
    dispatch(setPrice(item, data.option_price));
  }
  if (isShownTags) {
    return (
      <ProductTags
        menuItemName={menuItem.menu_item_name_public}
        menuItemTags={menuItem.menu_item_tags}
      />
    );
  } else {
    return (
      <ProductDetailsDisplay
        productDetails={menuItem}
        selectedPrice={selectedPriceName}
        onClickPricingOption={onClickPriceOptions}
      />
    );
  }
}
