import React, { useState } from "react";
import { LockOutlined } from "@material-ui/icons";
import { ReactComponent as RightChevron } from "../../../imges/RightChevron.svg";
import { v4 as uuidv4 } from "uuid";
import Button from "../Button";
import CardDots from "./CardDots";
import { ReactComponent as VisaSVG } from "../../../imges/Visa.svg";
import { ReactComponent as MasterCardSVG } from "../../../imges/MasterCard.svg";

import T from "i18n-react";
import Style from "./ConfirmPayment.module.scss";
import { usePaymentState } from "../../Payment/PaymentStore";
import { setSelectedPaymentMethod } from "../../Payment/reducer/PaymentActions";
import { PaymentMethods } from "../../Payment/types.d";
import { useDispatch } from "react-redux";
import { _updateCartID } from "../../../redux/actions";
export interface IConfirmationButtons {
  paymentDetails: any;
  isLoading: boolean;
  changeCard: () => void;
  processPayment: () => void;
  requireCVV?: boolean;
  updateCVV?: (cvv: string) => void;
  isDisabled: boolean;
}

const ConfirmationButtons = (props: IConfirmationButtons) => {
  const [cvcValue, setCvcValue] = useState<string>("");
  const { dispatch } = usePaymentState();
  const rdxDispatch = useDispatch();
  function cardDisplayType() {
    const cardType = props.paymentDetails.card_type;
    if (cardType === "visa") {
      return <VisaSVG />;
    } else if (
      cardType === "mastercard" ||
      cardType === "MC_DEBIT" ||
      cardType === "MC_CREDIT"
    ) {
      return <MasterCardSVG />;
    } else {
      return T.translate("Payment.YourSavedCard");
    }
  }
  return (
    <div className={Style.ConfirmPayments}>
      <div className={Style.CardDetailsWrapper}>
        <div className={Style.CardDetails}>
          <div className={Style.InputWrapper}>
            <div className={Style.IconWrapper}>
              <span
                className={"consumer-credit"}
                style={{
                  fontSize: "1.5rem",
                  marginRight: "5px",
                }}
              />
            </div>
            <div>
              {props.updateCVV && props.requireCVV && (
                <label className={Style.CVVLabel}>
                  {T.translate("Forms.CardNumber")}
                </label>
              )}
              <div className={Style.CardNumber}>
                <CardDots />
                <CardDots />
                <CardDots />
                <p>
                  {props.paymentDetails.masked_card_number.substr(
                    props.paymentDetails.masked_card_number.length - 4
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Button
            title={"ChangeCardDetails"}
            className={Style.changeCardButton}
            onClick={props.changeCard}
            dataComponentName={"ChangeCardDetails"}
            disabled={props.isDisabled}
          />
        </div>
      </div>

      {props.updateCVV && props.requireCVV && (
        <div className={Style.CVVWrapper}>
          <div className={Style.InputWrapper}>
            <div className={Style.IconWrapper}>
              <LockOutlined opacity={0.6} />
            </div>

            <div>
              <label className={Style.CVVLabel}>
                {T.translate("Forms.Cvc")}
              </label>
              <input
                placeholder={T.translate("Payment.EnterCvc").toString()}
                className={Style.CVVInput}
                type="number"
                min="100"
                max="9999"
                value={cvcValue}
                onChange={(e) => {
                  if (e.target.value.length < 5) {
                    setCvcValue(e.target.value);
                    props.updateCVV(e.currentTarget.value);
                  }
                }}
              ></input>
            </div>
          </div>
        </div>
      )}
      <div className={Style.SubmitCardDetails}>
        <Button
          isLoading={props.isLoading}
          disabled={props.isDisabled}
          className={Style.SubmitCardDetailsButton}
          dataComponentName="PayWithButton"
          Icon={<RightChevron />}
          embeddedComponent={
            <p>
              {T.translate("Payment.PayWith")} {cardDisplayType()}
            </p>
          }
          onClick={
            props.isLoading
              ? () => {}
              : () => {
                  dispatch(setSelectedPaymentMethod(PaymentMethods.CARD));
                  rdxDispatch(_updateCartID(uuidv4()));
                  props.processPayment();
                }
          }
        />
      </div>
    </div>
  );
};

export default ConfirmationButtons;
