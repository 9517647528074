import React from "react";
import ContentLoader from "react-content-loader";
import Styles from "./orderDetailsSkeleton.module.scss";

export const OrderDetailsSkeleton = () => {
  return (
    <div className={Styles.orderHistoryViewContainer}>
      <div className={Styles.orderDetails}>
        <div className={Styles.orderRefAndStatus}>
          <div>
            <ContentLoader>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
          <div>
            <ContentLoader>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
        </div>
        <div className={Styles.dateAndTime}>
          <div>
            <ContentLoader>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
          <div>
            <ContentLoader>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
        </div>

        <div className={Styles.venueDetails}>
          <div>
            <ContentLoader>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
        </div>

        <div className={Styles.deliveryDetails}>
          <div>
            <ContentLoader>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
        </div>

        <div className={Styles.timeSlotDetails}>
          <div>
            <ContentLoader>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
        </div>
      </div>
      <div className={Styles.paymentDetails}>
        <div className={Styles.totalPrice}>
          <div>
            <ContentLoader>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
          <div>
            <ContentLoader>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
        </div>
        <div className={Styles.priceDetails}>
          <div>
            <ContentLoader>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
          <div>
            <ContentLoader>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
        </div>
        <div className={Styles.priceDetails}>
          <div>
            <ContentLoader>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
          <div>
            <ContentLoader>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
        </div>
        <div className={Styles.priceDetails}>
          <div>
            <ContentLoader>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
          <div>
            <ContentLoader>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
        </div>
        <div className={Styles.priceDetails}>
          <div>
            <ContentLoader>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
          <div>
            <ContentLoader>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
        </div>

        <div className={Styles.paymentCardDetails}>
          <div>
            <ContentLoader>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
          <div>
            <ContentLoader>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
        </div>
      </div>
      <div className={Styles.productInfo}>
        <div>
          <ContentLoader>
            <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
          </ContentLoader>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsSkeleton;
