import { handleFilterCount } from "../../Utils/Filter";
import { IGroupPageState } from "../types.d";
import { SET_FILTERS } from "./group.actions";

export function GroupReducer(state: IGroupPageState, action: any) {
  switch (action.type) {
    case SET_FILTERS:
      const count = handleFilterCount(action.payload);
      return {
        ...state,
        filters: action.payload,
        filtersCount: count,
      };
  }
  return state;
}
