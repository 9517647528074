import auth0 from 'auth0-js';
import removeHash from '../URL/removeHash';

export type ConnectionType = "sms" | "email";

/**A promise based interpretation of the auth0 web library */
export default class WebAuth0 {
    private WebAuth0: auth0.WebAuth;
    private clientId: string;
    private domain: string;
    constructor(config: auth0.AuthOptions) {
        this.domain = config.domain;
        this.clientId = config.clientID;
        this.WebAuth0 = this.createWebAuth(config);
    }
    /**Creates the auth0 object */
    private createWebAuth = (config: auth0.AuthOptions) => {
        return new auth0.WebAuth({
            ...config,
            domain: config.domain,
            clientID: config.clientID,
            responseType: "token id_token",
            audience: config.audience,
        });
    };

    /**Registers via user-password auth */
    register = (email: string, password: string): Promise<IRegisterPayload> => {
        return new Promise((resolve, reject) => {
            this.WebAuth0.signupAndAuthorize({
                connection: 'Username-Password-Authentication',
                email,
                password,
                scope: "openid profile email",
            }, (error, result) => {
                if (error) {
                    return reject(error);
                }
                return resolve(result);
            });
        });
    };
    /** Authorises a user account/ logs in*/
    authorise = (options?: auth0.AuthorizeOptions) => {
        this.WebAuth0.authorize({ ...options, domain: this.domain });
    };
    /**username/password login flow */
    login = (loginOptions: auth0.CrossOriginLoginOptions) => {
        return new Promise((resolve, reject) => {
            this.WebAuth0.login(loginOptions,
                (error, result) => {
                    if (error) {
                        return reject(error);
                    }
                    return resolve(result);
                });
        });
    };
    /**Reset password flow */
    resetPassword = (ChangePasswordOptions: auth0.ChangePasswordOptions) => {
        return new Promise((resolve, reject) => {
            this.WebAuth0.changePassword(ChangePasswordOptions,
                (error, result) => {
                    if (error) {
                        return reject(error);
                    }
                    return resolve(result);
                });
        });
    };
    /**Logs out to the origin page */
    logout = () => {
        return this.WebAuth0.logout({
            returnTo: window.location.origin,
            clientID: this.clientId
        });
    };
    /**Checks session and returns a new hash */
    checkSession = (redirectURL?: string): Promise<auth0.Auth0DecodedHash> => {
        return new Promise((resolve, reject) => {
            this.WebAuth0.checkSession({ redirectUri: redirectURL || window.location.href },
                (error, result) => {
                    if (error) {
                        console.error("checkSessionError", JSON.stringify(error));
                        return reject(error);
                    }
                    return resolve(result);
                });
        });
    };
    /**Parses the url hash */
    parseURLHash = (): Promise<auth0.Auth0DecodedHash> => {
        return new Promise((resolve, reject) => {
            this.WebAuth0.parseHash({ hash: window.location.hash },
                (error, result) => {
                    removeHash();
                    if (error) {
                        return reject(error);
                    }
                    if (result === null) {
                        return reject("decoded hash result is null");
                    }
                    return resolve(result);
                });
        });
    };
    /**Returns epoch time of expiry */
    getTokenExpiry = (idTokenPayload: IIdTokenPayload): number => {
        if (idTokenPayload.exp) {
            return idTokenPayload.exp;
        } else {
            throw new Error("Could not get token expiry");
        }
    };
    /**Decodeds url hash access token to a auth0 profile */
    decodeAccessTokenToUser = (token: string): Promise<auth0.Auth0UserProfile> => {
        return new Promise((resolve, reject) => {
            this.WebAuth0.client.userInfo(token,
                (error, user) => {
                    if (error) {
                        return reject(error);
                    }
                    return resolve(user);
                });
        });
    };
    /**starts the passwordless flow using the phone number */
    passwordlessStart = (value: string, connectionType?: ConnectionType) => {
        const obj: Partial<auth0.PasswordlessVerifyOptions> = {};
        if (connectionType && connectionType === 'email') {
            obj["email"] = value
        } else {
            obj['phoneNumber'] = value;
        }
        return new Promise((resolve, reject) => {
            this.WebAuth0.passwordlessStart({
                connection: connectionType || 'sms',
                send: 'code',
                ...obj
            }, (error, result) => {
                if (error) {
                    return reject(error);
                } else {
                    resolve(result);
                }
            })
        })
    }
    /**Verifiys a passwordless sms  */
    passwordlessSMSVerify = (value: string, oneTimeCode: string, connectionType?: ConnectionType, redirectUri?: string) => {
        const obj: Partial<auth0.PasswordlessVerifyOptions> = {};
        if (connectionType && connectionType === 'email') {
            obj["email"] = value
        } else {
            obj['phoneNumber'] = value;
        }
        return new Promise((resolve, reject) => {
            this.WebAuth0.passwordlessVerify({
                connection: connectionType || 'sms',
                verificationCode: oneTimeCode,
                redirectUri,
                ...obj
            }, (error, result) => {
                if (error) {
                    return reject(error);
                } else {
                    return resolve(result);
                }
            })
        })
    }
}

interface IIdTokenPayload {
    /** user hash _p9nurhpseHERakjbi....*/
    at_hash: string;
    /** auth0 client id */
    aud: string;
    /**epoch time of expiry */
    exp: number;
    /** epoch time of creation*/
    iat: number; //
    /** https://yoello-dev.eu.auth0.com/*/
    iss: string; //
    /** cerpSrLKFXj24DktUinuuOR01Ku9.5MV*/
    nonce: string;
    /**auth0 user id */
    sub: string;
}

interface IRegisterPayload {
    /**access token */
    accessToken: string;
    /**Expircy epoch time */
    expiresIn: number;
    /**ID token */
    idToken: string;
    /**User scopes */
    scope: string;
    /**Token type */
    tokenType: string;
}