import React from "react";
import { useSelector } from "react-redux";
import { ReactComponent as FoodPlateSVG } from "../../../imges/FoodPlate.svg";
import { ReactComponent as BicycleSVG } from "../../../imges/Bicycle.svg";
import { ReactComponent as BagSVG } from "../../../imges/Bag_new.svg";
import Styles from "./orderDetails.module.scss";

import T from "i18n-react";

interface IOrderTypesProps {
  orderItem: IOrders;
}

export function OrderTypes({ orderItem }: IOrderTypesProps) {
  const Venue = useSelector((state: any) => state.Venue);
  const { Icon, orderType } = renderItems(orderItem, Venue);

  return (
    <div className={Styles.OrderTypes}>
      <div>
        {Icon} <p>{orderType}</p>
      </div>
    </div>
  );
}

function renderItems(orderItem: IOrders, Venue: any) {
  const {
    placed_order_order_type,
    placed_order_frozen_order_type_label,
  } = orderItem;

  let Icon = null;
  let orderType = null;
  switch (placed_order_order_type) {
    case "TABLE_DELIVERY":
      Icon = <FoodPlateSVG fill={"#FFDD02"} />;
      orderType = placed_order_frozen_order_type_label
        ? placed_order_frozen_order_type_label
        : (T.translate("Venue.TableService") as string);
      break;
    case "HOME_DELIVERY":
      Icon = <BicycleSVG fill={"#FFDD02"} />;
      orderType = placed_order_frozen_order_type_label
        ? placed_order_frozen_order_type_label
        : (T.translate("Venue.Delivery") as string);
      break;
    case "COLLECTION_POINT_PICKUP":
      Icon = <BagSVG fill={"#FFDD02"} />;
      orderType = placed_order_frozen_order_type_label
        ? placed_order_frozen_order_type_label
        : (T.translate("Venue.pickupNow") as string);
      break;
    case "COLLECTION":
      Icon = <BagSVG fill={"#FFDD02"} />;
      orderType = placed_order_frozen_order_type_label
        ? placed_order_frozen_order_type_label
        : (T.translate("Venue.Collection") as string);
      break;
  }
  return {
    Icon,
    orderType,
  };
}
