import React, { useEffect, useState } from "react";
import { Button, makeStyles } from "@material-ui/core";
import T from "i18n-react";

import MainTheme from "../../../yoello-lib/modules/themes/MainTheme";
import ThemeWrapper from "../../../yoello-lib/modules/themes/ThemeWrapper";

import { ReactComponent as EmailErrorSVG } from "../../../imges/searchError.svg";
import useLoginView from "../LoginView/useLoginView";
import { VerificationMethod } from "../../../constants/verificationmethod";
import { useHistory } from "react-router-dom";
import { LOGINV2 } from "../../../constants/URL.const";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
  },
  imageWrapper: {
    padding: "3rem",
    boxSizing: "border-box",
  },
  content: {
    padding: "0 2rem",
  },
  contentHeader: {
    fontSize: "1.4rem",
    fontWeight: 600,
    marginBottom: "1rem",
  },
  contentBody: {
    fontSize: "1rem",
  },
  button: {
    width: "100%",
    marginBottom: "1rem",
    textTransform: "none",
    fontWeight: 600,
    padding: "0.7rem",
    borderRadius: "10px",
  },
  buttonSecondary: {
    backgroundColor: "#F9F9F9",
    fontWeight: 500,
  },
});

const EmailErrorView = () => {
  const classes = useStyles();
  const history = useHistory();

  const { signinViaPasswordless } = useLoginView();
  const [username, setUsername] = useState();

  const onSendNewLinkClick = () => {
    if (!username) {
      goToLogin();
    }
    signinViaPasswordless(VerificationMethod.EMAIL, username);
  };

  const goToLogin = () => {
    history.push(LOGINV2);
  };

  useEffect(() => {
    if (localStorage.login_username) {
      setUsername(localStorage.login_username);
    }
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.imageWrapper}>
        <EmailErrorSVG />
      </div>
      <div className={classes.content}>
        <h4 className={classes.contentHeader}>
          {T.translate("Login.emailError.wentWrong")}
        </h4>
        <p className={classes.contentBody}>
          {T.translate("Login.emailError.tryRequesting")}
        </p>
        <ThemeWrapper theme={MainTheme}>
          {username && (
            <Button
              className={classes.button}
              onClick={onSendNewLinkClick}
              variant="contained"
              color="primary"
              disableElevation
            >
              {T.translate("Login.emailError.sendNew")}
            </Button>
          )}
          <Button
            className={`${classes.button} ${
              username ? classes.buttonSecondary : ""
            }`}
            onClick={goToLogin}
            variant="contained"
            color={username ? "default" : "primary"}
            disableElevation
          >
            {T.translate(
              username
                ? "Login.emailError.signIn"
                : "Login.emailError.backToLogin"
            )}
          </Button>
        </ThemeWrapper>
      </div>
    </div>
  );
};

export default EmailErrorView;
