import { useEffect, useState } from "react";
import T from "i18n-react";
import {
  TextInput,
  ToggleInput,
  InfoCheckInput,
  PhoneInput,
} from "../../Inputs";
import type { InputRow } from "../../Inputs";
import { isValidPhoneNumber } from "libphonenumber-js";
import useGetLoginParams from "../useGetLoginParams";
import FixedContinue from "./FixedContinue";

import { useAuth0 } from "../../../yoello-lib/modules/auth/Auth0";
import IDynamicFormRow from "../../../yoello-lib/modules/dynamicForms/interfaces/IDynamicFormRow";

const defaultFormElement: Partial<IDynamicFormRow> = {
  inputProperties: {
    variant: "outlined",
  },
  gridColumnOptions: { formCol: 12 },
  registerOptions: {
    required: true,
  },
};

const parseUserToForm = (user: auth0.Auth0UserProfile) => {
  if (!user) return {};
  return {
    first_name: user.given_name,
    last_name: user.family_name,
    contact_email: user.email,
  };
};

const generateFormRows = (method: string) => {
  const inputRows: InputRow[] = [
    {
      keyName: "first_name",
      Component: TextInput,
      registerOptions: {
        validate: (data: any) =>
          data && data.trim().match(/^[a-z0-9,.'-_\s]+$/i)
            ? true
            : T.translate("ProfileBuilder.errors.first_name"),
      },
    },
    {
      keyName: "last_name",
      Component: TextInput,
      registerOptions: {
        validate: (data: any) =>
          data && data.trim().match(/^[a-z0-9,.'-_\s]+$/i)
            ? true
            : T.translate("ProfileBuilder.errors.last_name"),
      },
    },
    {
      keyName: "contact_email",
      Component: TextInput,
      registerOptions: {
        validate: (data: any) =>
          data && data.trim().match(/^[^\s@]+@[^\s@.]+\.[^\s@]+$/)
            ? true
            : T.translate("ProfileBuilder.errors.contact_email"),
      },
    },
    {
      keyName: "contact_phone",
      Component: PhoneInput,
      gridColumnOptions: { formCol: 12 },
      registerOptions: {
        validate: (data: any) => {
          if (data) {
            return isValidPhoneNumber(data)
              ? true
              : T.translate("ProfileBuilder.errors.contact_phone");
          } else return T.translate("ProfileBuilder.errors.contact_phone");
        },
      },
    },
    // {
    //   keyName: "dob",
    //   Component: TextInput,
    //   inputOptions: {
    //     type: "date",
    //   },
    //   registerOptions: {
    //     validate: (data: any) => {
    //       const today = new Date();
    //       const inputDate = new Date(data);
    //       return today.getTime() > inputDate.getTime()
    //         ? true
    //         : T.translate("ProfileBuilder.errors.dob");
    //     },
    //   },
    // },
    {
      keyName: "notification_method",
      Component: ToggleInput,
      inputOptions: {
        // default: method ? method : "email",
        options: [
          { value: "email", name: T.translate("ProfileBuilder.email") },
          { value: "sms", name: T.translate("ProfileBuilder.sms") },
        ],
      },
    },
    {
      keyName: "opt_out",
      Component: InfoCheckInput,
      inputOptions: {
        message: T.translate("ProfileBuilder.opt_out"),
        defaultChecked: false,
      },
      registerOptions: {
        required: false,
      },
    },
    {
      keyName: "profile_submit",
      Component: FixedContinue,
    },
  ];

  return inputRows.map((row: InputRow) => {
    return {
      ...defaultFormElement,
      ...row,
      label: T.translate(`ProfileBuilder.${row.keyName}`),
      Component: row.Component,
    } as IDynamicFormRow;
  });
};

const useGenerateProfileForm = () => {
  const { method, username, isLoading: isFetchingParams } = useGetLoginParams();
  const { user, isAuthenticated, isRetrievingUser } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [defaultData, setDefaultData] = useState({});
  const [formRows, setFormRows] = useState<IDynamicFormRow[]>([]);
  useEffect(() => {
    setIsLoading(true);
    const auth0Data = parseUserToForm(user as auth0.Auth0UserProfile);
    if (isAuthenticated && !isRetrievingUser && !isFetchingParams) {
      const userData =
        method === "sms"
          ? { contact_phone: username }
          : method === "email"
          ? { contact_email: username }
          : {};
      setDefaultData({
        ...auth0Data,
        ...userData,
        notification_method: method ?? "email",
      });
      setFormRows(generateFormRows(method));
      setIsLoading(!isAuthenticated && isRetrievingUser);
    }
  }, [
    method,
    username,
    user,
    isAuthenticated,
    isRetrievingUser,
    isFetchingParams,
  ]);
  return {
    formRows,
    defaultData,
    isLoading,
  };
};

export default useGenerateProfileForm;
