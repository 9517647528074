import T from "i18n-react";
import React from "react";
import "./ChooseOrderTypeButton.css";
import { IChooseOrderTypeButton } from "./types.d";
import Button from "../Button";
import { convertToUTCTime } from "../../../yoello-lib/modules/utils/TimeConversion";

function ChooseOrderTypeButton(props: IChooseOrderTypeButton) {
  return (
    <div
      data-component-name={props.dataComponentName}
      className={`OrderTypeButtonsDiv ${
        props.selected ? "OrderTypeButtonsDivSelected" : ""
      }`}
      onClick={props.onClick}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "50px",
        }}
      >
        {props.selected ? (
          <div data-component-name="EtaText">
            {props.ETA ? (
              <p className="EtaText">
                {convertToUTCTime(props.ETA?.venue_time)}
              </p>
            ) : null}
            {props.selectedTable !== null &&
            props.selectedTable !== undefined ? (
              <p className="EtaText">
                {props.selectedArea ? `${props.selectedArea} ,` : "Table "}{" "}
                {props.selectedTable}
              </p>
            ) : null}
            {props.DeliveryTime ? (
              <p className="EtaText">
                {convertToUTCTime(props.DeliveryTime?.venue_time)}
              </p>
            ) : null}
          </div>
        ) : (
          <div className="Flex AlignCenter JustCenter w-100">
            <div className="Flex11">
              <T.p
                className="OrderTypeButtonsText"
                text={{ key: `Payment.${props.title}` }}
              />
            </div>

            <div className="Flex1 Flex AlignCenter">
              <span
                className={"consumer-right-arrow"}
                style={{
                  fontSize: "1.5rem",
                  marginRight: "4px",
                }}
              />
            </div>
          </div>
        )}

        {props.selected ? (
          <div data-component-name="ChangeButton">
            <Button isYoelloBtn className={"ChangeButton"} title="Change" />
          </div>
        ) : null}
      </div>
      {props.DeliveryAddress && props.DeliveryTime ? (
        <div className="mr-3">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "0",
              marginBottom: "0.7rem",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "left",
              }}
            >
              <span
                className="consumer-pin"
                style={{
                  fontSize: "1.5rem",
                  marginLeft: "-5px",
                }}
              />
              <p className="SelectedAddressText">
                {props.DeliveryAddress?.address} {props.DeliveryAddress?.city}{" "}
                {props.DeliveryAddress?.postcode}
              </p>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default ChooseOrderTypeButton;
