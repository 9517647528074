import React from "react";
import Styles from "../styles.module.scss";
import { VenueRippe } from "./VenueRipple";
import T from "i18n-react";

interface IVenueContentProps {
  Venue: IVenue;
}

export function VenueContent({ Venue }: IVenueContentProps) {
  return (
    <>
      <div className={Styles.topShadow}></div>
      <div className={Styles.venueNameAndStatus}>
        <p className={Styles.VenueNameText} data-component-name="venue-name">
          {Venue.venue_name}
        </p>
        <VenueRippe Venue={Venue} />
      </div>
      <div data-component-name="venue-address" className={Styles.addressName}>
        <span className="consumer-pin-fill"></span>
        <p>{Venue.location_name}</p>
      </div>
      <div
        data-component-name="venue-description"
        className={Styles.description}
      >
        <span>{T.translate("Venue.Description")}</span>
        <p>{Venue.venue_description}</p>
      </div>
    </>
  );
}
