import React from "react";
import T from "i18n-react";
import Button from "../Widgets/Button";
import Styles from "./DrawerHeader.module.scss";

interface IDrawerHeader {
  onClose: () => void;
  title?: string;
  instructions?: string[]; //First item is regular, second is bold.
  color?: string;
  instructionClassName?: string;
}

const DrawerHeader = (props: IDrawerHeader) => {
  const color = props.color || "#AAB6D3";
  return (
    <div className={Styles.DrawerHeader}>
      <div className={Styles.LineBar}>
        {" "}
        <span></span>{" "}
      </div>
      {props.title && (
        <div style={{ color }} className={Styles.Title}>
          {props.title}
        </div>
      )}
      {props.instructions && (
        <p className={props.instructionClassName ?? ""}>
          <span>{T.translate(props.instructions[0])}</span>
          <span className={Styles.Bold}>
            {T.translate(props.instructions[1])}:
          </span>
        </p>
      )}
      <div className={Styles.ExitButton}>
        <Button
          onClick={props.onClose}
          dataComponentName="ExitButton"
          iconClassName={"consumer-close"}
          style={{
            position: "unset",
            color: `${color}`,
            fontSize: "1.5rem",
          }}
          isHeaderBtn
        />
      </div>
    </div>
  );
};

export default DrawerHeader;
