import React from "react";
import Button from "../Widgets/Button";

interface IBackButton {
  onClick: () => void;
}

export function BackButton({ onClick }: IBackButton) {
  return (
    <Button
      iconClassName="consumer-left-arrow"
      onClick={onClick}
      dataComponentName={"BackButton"}
      style={{ color: "#000000", fontSize: "1.5rem", position: "unset" }}
      isHeaderBtn
    />
  );
}
