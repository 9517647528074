import React from "react";
import ContentLoader from "react-content-loader";
import Styles from "./venueLoading.module.scss";

interface ILoadingProps {
  isCardiffCastle: boolean;
}
const venueLandingPageLoader = ({ isCardiffCastle }: ILoadingProps) => (
  <div id="venue_container" className={Styles.venueContainer}>
    <div className={Styles.venueImage}>
      <div className={Styles.remote}>
        <ContentLoader>
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
        </ContentLoader>
      </div>

      <div className={Styles.logo}>
        <ContentLoader>
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
        </ContentLoader>
      </div>
    </div>

    <div className={Styles.venueDetails}>
      <div className={Styles.lineBar}>
        {" "}
        <span></span>{" "}
      </div>
      <div className={Styles.share}>
        <ContentLoader>
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
        </ContentLoader>
      </div>
      <div className={Styles.venueContent}>
        <div className={Styles.venueNameAndStatus}>
          <ContentLoader>
            <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
          </ContentLoader>
        </div>
        <div className={Styles.addressName}>
          <ContentLoader>
            <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
          </ContentLoader>
        </div>
        <div className={Styles.description}>
          <ContentLoader>
            <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
          </ContentLoader>
        </div>
        {!isCardiffCastle && (
          <div className={Styles.orderTypes}>
            <ContentLoader>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
        )}
        <div className={Styles.AlcoholTextDiv}>
          <ContentLoader>
            <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
          </ContentLoader>
        </div>
        <div className={Styles.AlcoholTextDiv}>
          <ContentLoader>
            <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
          </ContentLoader>
        </div>
        <div className={Styles.venueTradingTimes}>
          <ContentLoader>
            <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
          </ContentLoader>
        </div>
        <div className={Styles.shareButtons}>
          <ContentLoader>
            <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
          </ContentLoader>
          <ContentLoader>
            <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
          </ContentLoader>
          <ContentLoader>
            <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
          </ContentLoader>
          <ContentLoader>
            <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
          </ContentLoader>
        </div>
      </div>
    </div>
  </div>
);

export default venueLandingPageLoader;
