import React, { useEffect, useContext } from "react";
import {
  changeOrderType,
  changeTableNumber,
  setIsTableServiceDrawerOpen,
} from "../../reducer/PaymentActions";
import { ReactComponent as FoodPlateSVG } from "../../../../imges/FoodPlate.svg";
import ChooseOrderTypeButton from "../../../Widgets/ChooseOrderTypeButton";
import OrderTypeHeader from "../../../Widgets/OrderTypeHeader/OrderTypeHeader";
import { YellowColorCodeForOrderTypeSvgs } from "../OrderTypesComponent";
import { SelectedOrderType } from "../../types.d";
import { useSelector } from "react-redux";
import { PaymentContext } from "../../PaymentStore";
import { shouldResetOrderType } from "../../../Utils/ChekTime";

export function DineInOrderType() {
  const Venue = useSelector((state: any) => state.Venue);
  const OrderType = useSelector((state: any) => state.OrderType);
  const { dispatch, selectedOrderType, selectedTable, areaName } = useContext(
    PaymentContext
  );
  useEffect(() => {
    if (!OrderType || shouldResetOrderType(OrderType?.settingTime)) {
      dispatch(changeTableNumber(null, null));
      setTimeout(() => {
        dispatch(changeOrderType(SelectedOrderType.TABLE));
        dispatch(setIsTableServiceDrawerOpen(true));
      }, 100);
    } else if (OrderType) {
      const { selectedTable, areaName } = OrderType.orderType;
      dispatch(changeTableNumber(selectedTable, areaName));
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <OrderTypeHeader
        title="Payment.TableService"
        custom={Venue.dine_in_label}
        svgImage={<FoodPlateSVG fill={YellowColorCodeForOrderTypeSvgs} />}
        selected={true}
      />
      <ChooseOrderTypeButton
        key={"table"}
        title="SelectTable"
        ETA={null}
        onClick={() => {
          dispatch(changeOrderType(SelectedOrderType.TABLE));
          dispatch(setIsTableServiceDrawerOpen(true));
        }}
        selected={
          selectedOrderType === SelectedOrderType.TABLE &&
          selectedTable !== null
        }
        selectedTable={
          selectedTable !== null &&
          selectedOrderType === SelectedOrderType.TABLE
            ? selectedTable
            : null
        }
        selectedArea={
          areaName && selectedOrderType === SelectedOrderType.TABLE
            ? areaName
            : null
        }
        dataComponentName="TableDelivery"
      />
    </div>
  );
}
