import React from "react";
import { ReactComponent as RefundSVG } from "../../../imges/refundSvg.svg";
import Styles from "./refundedItemHeader.module.scss";
import T from "i18n-react";
interface IRefundedItemHeader {
  item: IOrders;
}
enum RefundResons {
  STOCK = "out_of_stock",
  OTHER = "OTHER",
  DISSATISFACTION = "DISSATISFACTION",
}
export function RefundedItemHeader({ item }: IRefundedItemHeader) {
  return (
    <div className={Styles.RefundedReason}>
      <RefundSVG />
      <div>
        <p>
          {T.translate("OrderHistory.RefundedItemsCount", {
            count: getRefundedItemsCount(item),
          })}
        </p>
        <p>
          {T.translate("OrderHistory.Reason")} {renderRefundReason(item)}
        </p>
      </div>
    </div>
  );
}
function renderRefundReason(item: IOrders) {
  switch (item.placed_order_refund_reason) {
    case RefundResons.STOCK:
      return T.translate("OrderHistory.STOCK");
    case RefundResons.DISSATISFACTION:
      return T.translate("OrderHistory.CUSTOMERDISSATISFACTION");
    default:
      return T.translate("OrderHistory.OTHER");
  }
}
function getRefundedItemsCount(item: IOrders) {
  return item.placed_order_items.reduce(function (acc, obj) {
    return acc + obj.main_in_order_item_refund_quantity;
  }, 0);
}
