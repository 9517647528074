import React from "react";
import ContentLoader from "react-content-loader";
import Styles from "./groupPageSkeleton.module.scss";
const GroupPageSkeleton = () => {
  return (
    <div className={Styles.groupPageContainer}>
      <div className={Styles.header}>
        {/* <ContentLoader backgroundColor={"#212121"}>
          <rect x="0" y="0" rx="5" ry="5" />
        </ContentLoader> */}
        <div className={Styles.SideMenuDrawerBtn}>
          <ContentLoader backgroundColor={"rgba(255, 255, 255, 0.15)"}>
            <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
          </ContentLoader>
        </div>
        <div className={Styles.NHSDrawerBtn}>
          <ContentLoader backgroundColor={"rgba(255, 255, 255, 0.15)"}>
            <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
          </ContentLoader>
        </div>
        <div className={Styles.groupDetails}>
          <div className={Styles.groupLogo}>
            <div>
              <ContentLoader backgroundColor={"rgba(255, 255, 255, 0.15)"}>
                <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
              </ContentLoader>
            </div>
          </div>
          <div>
            <div className={Styles.groupName}>
              <p>
                <ContentLoader backgroundColor={"rgba(255, 255, 255, 0.15)"}>
                  <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
                </ContentLoader>
              </p>
            </div>
            <div className={Styles.groupAddressAndShare}>
              <p>
                <ContentLoader backgroundColor={"rgba(255, 255, 255, 0.15)"}>
                  <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
                </ContentLoader>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={Styles.content}>
        <div className={Styles.lineBar}>
          <span />
        </div>
        <div className={Styles.shadowTop}></div>
        <div className={Styles.venuesList}>
          <div>
            <ContentLoader backgroundColor={"#F9F9F9"}>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
          <div>
            <ContentLoader backgroundColor={"#F9F9F9"}>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
          <div>
            <ContentLoader backgroundColor={"#F9F9F9"}>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
          <div>
            <ContentLoader backgroundColor={"#F9F9F9"}>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
          <div>
            <ContentLoader backgroundColor={"#F9F9F9"}>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
          <div>
            <ContentLoader backgroundColor={"#F9F9F9"}>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
          <div>
            <ContentLoader backgroundColor={"#F9F9F9"}>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
          <div>
            <ContentLoader backgroundColor={"#F9F9F9"}>
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
            </ContentLoader>
          </div>
        </div>
        <div className={Styles.shadowBottom}></div>
      </div>
    </div>
  );
};

export default GroupPageSkeleton;
