import React from "react";
import Styles from "./styles.module.scss";
import Button from "../Button";

interface IDrawerHeaderProps {
  title: string;
  closeDrawer: () => void;
}

function DrawerHeader({ title, closeDrawer }: IDrawerHeaderProps) {
  return (
    <div className={Styles.DrawerHeader}>
      <div className={Styles.lineBar}>
        {" "}
        <span></span>{" "}
      </div>

      <div className={Styles.DeliveryHeader}>
        <div className="Flex1" />
        <p>{title}</p>
        <div className="Flex1">
          <Button
            onClick={closeDrawer}
            dataComponentName="ExitButton"
            iconClassName={"consumer-close"}
            style={{
              position: "unset",
              color: "#AAB6D3",
              fontSize: "1.5rem",
            }}
            isHeaderBtn
          />
        </div>
      </div>
      <div className={Styles.divider}></div>
    </div>
  );
}

export default DrawerHeader;
