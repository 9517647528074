import React, { useEffect, useState } from "react";
import { makeStyles, SwipeableDrawer } from "@material-ui/core";

const useStyles = makeStyles({
  Drawer: {
    boxSizing: "border-box",
    padding: "1rem",
    backgroundColor: "#FFFFFF",
    paddingBottom: "5rem",
  },
  DrawerOverlay: {
    zIndex: 999,
    backgroundColor: "#000",
    opacity: 0,
    position: "fixed",
    left: "0",
    top: "0",
    height: "100vh",
    width: "100vw",
    transition: "350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    visibility: "hidden",
    "&.open": {
      opacity: 0.5,
      visibility: "visible",
    },
  },
});

const SquareDrawerWrapper = ({ isOpen, closeDrawer, children }) => {
  const classes = useStyles();
  const [drawerState, setDrawerState] = useState(isOpen ? "open" : "closed");

  useEffect(() => {
    setDrawerState("open");
    return () => {
      setDrawerState("closed");
    };
  }, []);

  useEffect(() => {
    setDrawerState(isOpen ? "open" : "closed");
  }, [isOpen]);

  return (
    <>
      <div
        onClick={closeDrawer}
        className={`${classes.DrawerOverlay} ${drawerState}`}
      ></div>
      <SwipeableDrawer
        anchor={"bottom"}
        open={isOpen}
        onClose={closeDrawer}
        onOpen={() => null}
        variant={"persistent"}
        disableSwipeToOpen={true}
        disableDiscovery={true}
        transitionDuration={350}
        id="CardDetailsDrawer"
        style={{ borderRadius: "16px" }}
        PaperProps={{ square: false, style: { borderRadius: "24px 24px 0 0" } }}
        classes={{ paper: classes.Drawer }}
      >
        {children}
      </SwipeableDrawer>
    </>
  );
};

export default SquareDrawerWrapper;
