import { useEffect } from "react";
import { useAuth0 } from "../yoello-lib/modules/auth/Auth0";

/**Hook for when the user is logged in */
export function useCustomerLogged() {
  const { isAuthenticated } = useAuth0()!;

  useEffect(() => {
    if (isAuthenticated) sessionStorage.setItem("logged", "true");
  }, [isAuthenticated]);
}
