import React, { useEffect, useState, useContext } from "react";
import T from "i18n-react";
import { useSelector } from "react-redux";
import { PaymentContext } from "../PaymentStore";
import TipCards from "../../Widgets/TipCards/TipCards";
import Styles from "../styles.module.scss";
import TipInput from "./TipInput";
import { setTipPrice } from "../reducer/PaymentActions";
import { priceToPounds } from "../../../utils/pricingCalculator";
import { addTaxes } from "../../../yoello-lib/modules/utils/TaxController";
import { getVenueTippingOptions } from "../../../Api.js";
import { useAuth0 } from "../../../yoello-lib/modules/auth/Auth0";
import { ReactComponent as ClearIcon } from "../../../imges/icon-clear.svg";

export enum TipType {
  ROUNDUP = "ROUNDUP",
  PERCENTAGE = "PERCENTAGE",
  CUSTOM = "ABSOLUTE",
  ZERO = "ZERO",
}

declare type TippingOptionsDataType = {
  value: number;
  venue_id: string;
  serial_id: number;
  deleted: boolean;
  metadata: boolean | null;
  created_on: string;
  updated_on: string;
  id: string;
  tip_type: TipType;
  enabled: true;
  sort_position: number;
};
const TipComponent = () => {
  const {
    dispatch,
    totalPrice,
    tipTotal,
    cartItemTaxAmount,
    serviceTaxAmount,
    deliveryTaxAmount,
    totalPriceOverride,
  } = useContext(PaymentContext);
  const [tipType, setTipType] = useState<TipType>(TipType.ZERO);
  const [tipPercantage, setTipPercantage] = useState<number>(0);
  const [customValue, setCustomValue] = useState<number>();
  const [tippingOptions, setTippingOptions] = useState<
    Array<TippingOptionsDataType>
  >([]);
  const Venue = useSelector((state: any) => state.Venue);

  const { retrieveToken } = useAuth0()!;

  useEffect(() => {
    if (Venue.tipping_enabled) {
      retrieveTippingOptions();
    }
    // eslint-disable-next-line
  }, [Venue]);

  useEffect(() => {
    dispatch(setTipPrice(getTips()));
    // eslint-disable-next-line
  }, [totalPrice, tipType, customValue, tipPercantage]);

  async function retrieveTippingOptions() {
    const token = await retrieveToken();
    const tippingOptionsData = await getVenueTippingOptions(
      token,
      Venue.venue_nickname
    );
    // we are not sorting this on back-end and we always need RoundUp and Custom to be at first
    // first sorting based on sort position and then moving RoundUp and Custom to begining if they exist
    let tippingOptionsArray: Array<TippingOptionsDataType> =
      tippingOptionsData.data.data;
    tippingOptionsArray.sort((a, b) =>
      a.sort_position > b.sort_position ? 1 : -1
    );

    tippingOptionsArray.sort((a, b) =>
      a.tip_type === TipType.CUSTOM && a.enabled
        ? -1
        : b.tip_type === TipType.CUSTOM && b.enabled
        ? 1
        : 0
    );

    tippingOptionsArray.sort((a, b) =>
      a.tip_type === TipType.ROUNDUP && a.enabled
        ? -1
        : b.tip_type === TipType.ROUNDUP && b.enabled
        ? 1
        : 0
    );

    setTippingOptions(tippingOptionsArray);
  }

  function getTips() {
    const totalTax = addTaxes(
      cartItemTaxAmount,
      serviceTaxAmount,
      deliveryTaxAmount
    );
    let price;
    switch (tipType) {
      case TipType.ZERO:
        return 0;
      case TipType.ROUNDUP:
        const roundUpPrice = totalPriceOverride
          ? totalPriceOverride
          : totalPrice + totalTax - tipTotal;
        price = priceToPounds(roundUpPrice);
        //@ts-ignore
        return (Math.ceil(price) - price) * 100;
      case TipType.PERCENTAGE:
        price = totalPriceOverride
          ? totalPriceOverride
          : totalPrice + totalTax - tipTotal;
        price = (price * tipPercantage) / 100;
        return price;
      case TipType.CUSTOM:
        if (customValue) {
          return customValue * 100;
        } else {
          return 0;
        }
      default:
        return 0;
    }
  }

  function handleTippingOptionClick(
    clickedTipType: TipType,
    clickedTipPercantage?: number
  ) {
    setTipType(clickedTipType);
    if (clickedTipPercantage) {
      setTipPercantage(clickedTipPercantage);
    }
  }

  function renderTippingOptions() {
    let tippingOptionsArray = [];
    tippingOptions.forEach((item, i) => {
      if (item.enabled) {
        switch (item.tip_type) {
          case TipType.ROUNDUP:
            tippingOptionsArray.push(
              <TipCards
                selected={tipType === TipType.ROUNDUP}
                title="Roundup"
                onClick={() => {
                  handleTippingOptionClick(TipType.ROUNDUP);
                }}
                tipType={TipType.ROUNDUP}
              />
            );
            break;
          case TipType.CUSTOM:
            tippingOptionsArray.push(
              tipType === TipType.CUSTOM ? (
                <div
                  data-component-name="custom-tip"
                  className={Styles.TipCardInputDiv}
                  style={{
                    position: "relative",
                  }}
                  onClick={() => {
                    handleTippingOptionClick(TipType.CUSTOM);
                  }}
                >
                  <TipInput
                    customTipValue={customValue}
                    onChangeCustomTip={setCustomValue}
                  />
                </div>
              ) : (
                <TipCards
                  tipType={TipType.CUSTOM}
                  title="Custom"
                  onClick={() => {
                    handleTippingOptionClick(TipType.CUSTOM);
                  }}
                />
              )
            );
            break;

          case TipType.PERCENTAGE:
            if (item?.value !== undefined || item?.value !== null) {
              tippingOptionsArray.push(
                <TipCards
                  selected={
                    tipType === TipType.PERCENTAGE &&
                    tipPercantage === item.value
                  }
                  title={item.value.toString()}
                  onClick={() => {
                    handleTippingOptionClick(TipType.PERCENTAGE, item.value);
                  }}
                  tipType={TipType.PERCENTAGE}
                />
              );
            }
            break;
          default:
            break;
        }
      }
    });
    return tippingOptionsArray;
  }

  function clearTippingOption() {
    setTipType(TipType.ZERO);
    setTipPercantage(0);
    setCustomValue(0);
  }

  return tippingOptions ? (
    <div className={Styles.TipsSectionDiv}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
          marginBottom: "1rem",
        }}
      >
        <T.p
          className={Styles.TipStaffText}
          text={{ key: `ShopCart.TipStaffText` }}
        />
        {tipType !== TipType.ZERO ? (
          <div
            className={Styles.TipClearButtonDiv}
            onClick={clearTippingOption}
            data-component-name="clear-tipping"
          >
            <ClearIcon width="12px" height="12px" />
            <T.p text={{ key: `Payment.Clear` }} />
          </div>
        ) : (
          ""
        )}
      </div>

      <div
        className="Flex AlignCenter"
        style={{
          justifyContent: "space-around",
        }}
      >
        {renderTippingOptions()}
      </div>
    </div>
  ) : null;
};
export default React.memo(TipComponent);
