/**Renders the image, if fails then image isn't an image */
export function renderImage(url: string) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    /**If the img can be loaded its real */
    img.addEventListener("load", () => {
      resolve(img);
    });

    /**if the img errors then reject */
    img.addEventListener("error", () => reject());
    img.src = url;
  });
}
