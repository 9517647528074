import { IAddCartItem } from "../redux/Reducers/Cart";

export function abvCartCheck(
  cartItems: IAddCartItem[],
  menuItems: any[],
  limit: number = 4
) {
  /**find all items with abv*/
  let abvCount = 0;
  for (const items of cartItems) {
    /**Does the item contain drink and abv */
    if (
      items.menuItem.menu_item_category_name === "DRINK" &&
      isAvb(items.menuItem.menu_item_abv)
    ) {
      abvCount += items.itemCount;
    }
    /**Check for extras */
    if (items.extras && typeof items.extras === "object") {
      for (const keys in items.extras) {
        const extraArray = items.extras[keys];
        if (extraArray.length > 0) {
          for (const extraItem of extraArray) {
            const id = extraItem.id;

            if (!menuItems || !Array.isArray(menuItems)) break;
            /**Get the menu item */
            const menuItemExtra = menuItems.find(
              (item) => item.menu_item_id === id
            );

            if (
              menuItemExtra.menu_item_category_name === "DRINK" &&
              parseInt(menuItemExtra.menu_item_abv.toString()) > 0
            ) {
              abvCount += items.itemCount * extraItem.count;
            }
          }
        }
      }
    }
  }
  /**Count the total amount of abv items */
  if (abvCount > limit) {
    return false;
  }
  return true;
}

/**Checks if item has abv */
function isAvb(abv: unknown) {
  if (!abv) {
    return false;
  }
  if (typeof abv === "string") {
    abv = parseInt(abv);
  }

  return abv > 0;
}
