import { useSelector } from "react-redux";
import { addTaxes } from "../../../yoello-lib/modules/utils/TaxController";
import { IOrderBody, IDeliveryAddress } from "../../../typings/IPayment";
import {
  SelectedOrderType,
  BackendTimeSlots,
  IDiscountStoreData,
} from "../types.d";
import { CARDIFF_CASTLE } from "../../../constants";
import { roundPriceOption } from "../../../utils/pricingCalculator";
import { IAddCartItem } from "../../../redux/Reducers/Cart";
import { itemTotalCount } from "../../../utils/cartController";
import { DiscountType } from "../Dsicounts/DiscountsComponent";

const useOrderBody = () => {
  const venue = useSelector((state: any) => state.Venue);
  const group = useSelector((state: any) => state.Group);
  const cartID = useSelector((state: any) => state.CartID);
  const cart = useSelector((state: any) => state.Cart);

  const generateOrderBody = (
    selectedTime: BackendTimeSlots,
    isUsingPaymentRequest: boolean,
    cartItemTaxAmount: number,
    serviceTaxAmount: number,
    deliveryTaxAmount: number,
    message: string,
    selectedOrderType: any,
    deliveryPrice: number,
    tipTotal: number,
    areaName: string,
    selectedTable: string,
    totalPrice: number,
    deliveryAddress: IDeliveryAddress,
    timeSlotsData: BackendTimeSlots[],
    discounts?: IDiscountStoreData
  ) => {
    let selectedTimeSlot = timeSlotsData.find((item: BackendTimeSlots) => {
      return item?.venue_time === selectedTime?.venue_time;
    });
    let UTCtimeStamp = selectedTimeSlot?.time_utc;
    let VenueTimeStamp = selectedTimeSlot?.venue_time;
    // if (timeStamp !== null) {
    // const split = timeStamp.split(":");
    // const dt = new Date();

    // timeStamp = new Date(
    //   dt.getFullYear(),
    //   dt.getMonth(),
    //   dt.getDate(),
    //   parseInt(split[0]),
    //   parseInt(split[1])
    // );

    const taxTotal = addTaxes(
      cartItemTaxAmount,
      serviceTaxAmount,
      deliveryTaxAmount
    );

    const orderBody: IOrderBody = {
      venue_id: venue.id,
      intent: isUsingPaymentRequest,
      comment: message,
      delivery_charge:
        selectedOrderType === SelectedOrderType.DELIVERY ||
        (group?.group_service_charge_enabled &&
          group?.group_nickname === CARDIFF_CASTLE)
          ? deliveryPrice
          : null,
      gratuity: roundPriceOption(tipTotal),
      delivery_table:
        selectedOrderType === SelectedOrderType.TABLE ? selectedTable : null,
      total_price: roundPriceOption(totalPrice + taxTotal),
      table_area:
        selectedOrderType === SelectedOrderType.TABLE ? areaName : null,
      order_type: selectedOrderType,
      takeaway_timeslot:
        selectedOrderType === SelectedOrderType.TABLE ? null : UTCtimeStamp,
      venue_takeaway_timeslot: VenueTimeStamp,
      delivery_address: deliveryAddress,
      menu_items: [],
      group_id: undefined,
      cart_id: cartID,
    };
    if (group?.group_id && group?.group_nickname === CARDIFF_CASTLE) {
      orderBody.group_id = group?.group_id;
    }
    const menuItems = cart.map((cartItem: IAddCartItem) => {
      let extras: { extra_id: string; extra_pricing_option_key: string }[] = [];
      if (cartItem.extras) {
        for (const extraKey in cartItem.extras) {
          const extraArray = cartItem.extras[extraKey];
          extraArray.forEach((extra) => {
            extras.push({
              extra_id: extra.id,
              extra_pricing_option_key: extra.priceName,
              // @ts-ignore
              quantity: extra.count
                ? extra.count * cartItem.itemCount
                : cartItem.itemCount,
            });
          });
        }
      }
      const { menuItem, measure, itemCount, preferences } = cartItem;
      return {
        menu_item_id: menuItem.menu_item_id,
        quantity: itemTotalCount(menuItem, measure, itemCount),
        pricing_option_key: measure,
        extras: extras,
        modifiers: [],
        preferences: preferences
          ? Object.keys(preferences).map((preference) => {
              return preferences[preference];
            })
          : [],
      };
    });
    orderBody.menu_items = menuItems;
    if (!discounts?.error && discounts?.value) {
      orderBody.discount = {
        id: discounts.id,
        type: discounts.absoluteValue ? DiscountType.ABSOLUTE : discounts.type,
        value: discounts.absoluteValue ?? discounts.value,
        code: discounts.code,
        metadata: null,
      };
    }
    return orderBody;
  };

  return {
    generateOrderBody,
  };
};

export default useOrderBody;
