import React, { useEffect } from "react";
import { useDrawer } from "../../Drawer/DrawerContext";
import Button from "../../Widgets/Button";
import DifficultTimes from "../../Widgets/DifficultTimes/DifficultTimes";
import Styles from "../groupPage.module.scss";

export function GroupTrackTrace({ group }) {
  const { openDrawer } = useDrawer()!;
  useEffect(() => {
    const setTrack = () => {
      const url = group?.group_test_and_trace_url;
      const groupName = group?.group_name_internal;

      if (url && url.length > 0) {
        const sessionName = `${groupName}-test&trace`;
        if (window.sessionStorage.getItem(sessionName) === null) {
          openTrackTrace(openDrawer, url);
          window.sessionStorage.setItem(sessionName, "true");
        }
      }
    };
    setTrack();
    //eslint-disable-next-line
  }, [group]);
  return group?.group_test_and_trace_url ? (
    <Button
      isHeaderBtn
      className={Styles.NHSDrawerBtn}
      title={"TrackAndTrace"}
      onClick={() => openTrackTrace(openDrawer, group.group_test_and_trace_url)}
    />
  ) : null;
}

function openTrackTrace(openDrawer, trackURL: string) {
  if (openDrawer) {
    openDrawer(
      DifficultTimes,
      {
        trackAndTraceURL: trackURL,
      },
      "bottom",
      "DifficultTimesDrawer"
    );
  }
}
