import React, { useContext } from "react";
import T from "i18n-react";
import { PaymentContext } from "./PaymentStore";

import useCanPaymentBeProcessed from "./PaymentHooks/useCanPaymentBeProcessed";

import Styles from "./styles.module.scss";
import { PaymentStages } from "./types.d";
import Button from "../Widgets/Button";
import { postcodeCheckStatusType } from "../Widgets/PostCodeChecker/PostCodeChecker";
import {
  setIsPaymentDrawerOpen,
  setSelectedPaymentMethod,
} from "./reducer/PaymentActions";
import useFormatCurrency from "../../yoello-lib/modules/localisation/useCurrency";
import { addTaxes } from "../../yoello-lib/modules/utils/TaxController";

const PaymentFooter = () => {
  const {
    selectedOrderType,
    isLoading,
    paymentStages,
    dispatch,
    postcodeCheckStatus,
    totalPrice,
    totalPriceOverride,
    tipTotal,
    serviceTaxAmount,
    cartItemTaxAmount,
    deliveryTaxAmount,
    isPaymentReady,
    selectedPaymentMethod,
  } = useContext(PaymentContext);
  const { canPaymentBeProcessed } = useCanPaymentBeProcessed();
  const { formatCurrency } = useFormatCurrency();
  const totalTax = addTaxes(
    cartItemTaxAmount,
    serviceTaxAmount,
    deliveryTaxAmount
  );
  const total = totalPrice + totalTax;
  const formattedPrice = totalPriceOverride
    ? formatCurrency(totalPriceOverride + tipTotal)
    : formatCurrency(total);

  const handleOnClick = async () => {
    if (selectedPaymentMethod) {
      dispatch(setSelectedPaymentMethod(null));
    }
    const paymentCanBeProcessed = await canPaymentBeProcessed();
    if (paymentCanBeProcessed && !isLoading) {
      dispatch(setIsPaymentDrawerOpen(true));
    }
  };
  return (
    <div className={Styles.BottomBar}>
      {selectedOrderType === null && (
        <T.p
          className={Styles.OrderTypeNotSelectedText}
          text={{ key: `Payment.OrderTypeNotSelected` }}
        />
      )}

      {paymentStages === PaymentStages.PAYMENT && selectedOrderType !== null && (
        <>
          <div className={Styles.PaymentFooterTotalPrice}>
            <p>{T.translate("Payment.TotalPay")}</p>
            <p>{formattedPrice}</p>
          </div>
          <div className="Flex2">
            <Button
              className={Styles.ProceedPaymentText}
              isYoelloBtn
              title={"ProceedPayment"}
              disabled={
                postcodeCheckStatus ===
                  postcodeCheckStatusType.OutOfDeliveryRadius ||
                isLoading ||
                !isPaymentReady
              }
              dataComponentName={!isLoading && "PaymentButton"}
              onClick={handleOnClick}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentFooter;
