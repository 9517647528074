import { useContext } from "react";
import T from "i18n-react";
import { useSelector, useDispatch } from "react-redux";

import { PaymentContext } from "../PaymentStore";

import { CARDIFF_CASTLE } from "../../../constants";
import { checkAbvContainsFood } from "../../../utils/checkAbvContainsFood";
import { SelectedOrderType } from "../types.d";

import { useSnackbarMessages } from "../../../yoello-lib/modules/components/snackbar/SnackbarContext";
import useFormatCurrency from "../../../yoello-lib/modules/localisation/useCurrency";
import { getStripeMinimumOrderValue } from "../../../utils/getStripeMinimumOrderValue";
import { checkTimeslotAgaintsMenuTimings } from "../../../utils/checkTimeslotAgaintsMenuTimings";
import useAPI from "../../../yoello-lib/modules/API/useAPI";
import { useAuth0 } from "../../../yoello-lib/modules/auth/Auth0";
import APIExtensions from "../../../yoello-lib/modules/API/APIDefs";
import { HTTPMethods } from "../../../yoello-lib/modules/API/API";
import { setSelectedMenuType } from "../../../yoello-lib/modules/redux/actions/consumer.actions";
import { useCheckIfOrderTypeSelected } from "./useCheckIfOrderTypeSelected";

const useCanPaymentBeProcessed = () => {
  const dispatch = useDispatch();
  const group = useSelector((state: any) => state.Group);
  const Venue = useSelector((state: any) => state.Venue);
  const cart = useSelector((state: any) => state.Cart);
  const cartItems = useSelector((state: any) => state.Cart);
  const AvailableMenus = useSelector((state: any) => state.AvailableMenus);
  const orderType = useSelector((state: any) => state.SelectedMenuType);
  const { retrieveToken } = useAuth0!();
  const { callAPI } = useAPI(APIExtensions.consumerVenue, retrieveToken, {
    query: Venue.venue_nickname,
  });

  const {
    selectedTime,
    selectedOrderType,
    selectedTable,
    totalPrice,
    deliveryAddress,
    subTotalCost,
    totalDiscounts,
  } = useContext(PaymentContext);

  const { sendSnackbarMessage } = useSnackbarMessages()!;
  const { renderOrderTypeDrawer } = useCheckIfOrderTypeSelected();
  const { formatCurrency } = useFormatCurrency();
  const canPaymentBeProcessed = async () => {
    renderOrderTypeDrawer();
    const updatedVenue = await callAPI(HTTPMethods.GET);
    const minPriceMap = {
      COLLECTION: "min_collection_price",
      HOME_DELIVERY: "min_delivery_price",
      TABLE_DELIVERY: "min_dine_in_price",
      COLLECTION_POINT_PICKUP: "min_pickup_now_price",
    };
    const orderTypeEnabled = {
      TABLE_DELIVERY: "table_enabled",
      HOME_DELIVERY: "delivery_enabled",
      COLLECTION: "pickup_enabled",
      COLLECTION_POINT_PICKUP: "pickup_now_enabled",
    };

    const stripeMinimumOrderValue = getStripeMinimumOrderValue(
      Venue.currency_iso
    );
    let errMsg = T.translate("Payment.Errors.PaymentCouldNotProceed");

    if (selectedOrderType === null) {
      errMsg = T.translate("Payment.Errors.SelectOrderType");
    } else if (!updatedVenue?.[orderTypeEnabled[orderType]]) {
      errMsg = T.translate("Payment.Errors.SelectOrderTypeNotAvailable");
      dispatch(setSelectedMenuType(null));
    } else if (
      totalPrice < stripeMinimumOrderValue ||
      totalDiscounts > subTotalCost
    ) {
      errMsg = T.translate("Payment.Errors.BelowMinimum", {
        price: `${stripeMinimumOrderValue}${
          Venue.currency_iso === "GBP" ? "p" : "c"
        }`,
      });
    } else if (totalPrice < updatedVenue[minPriceMap[selectedOrderType]]) {
      errMsg = T.translate("ShopCart.Errors.CartLessThan", {
        price: formatCurrency(updatedVenue[minPriceMap[selectedOrderType]]),
      }) as string;
    } else if (
      selectedOrderType === SelectedOrderType.TABLE &&
      selectedTable === null
    ) {
      errMsg = T.translate("Payment.Errors.WithoutVenueArea");
    } else if (
      selectedOrderType === SelectedOrderType.CLICK &&
      selectedTime === null
    ) {
      errMsg = T.translate("Payment.Errors.WithoutCollectionTime");
    } else if (
      selectedOrderType === SelectedOrderType.DELIVERY &&
      (selectedTime === null ||
        deliveryAddress.full_name.length === 0 ||
        deliveryAddress.address.length === 0 ||
        deliveryAddress.postcode.length === 0)
    ) {
      errMsg = T.translate("Payment.Errors.WithoutDeliveryInfo");
    } else if (
      group?.group_abv_food_threshold &&
      group?.group_nickname === CARDIFF_CASTLE
    ) {
      if (!checkAbvContainsFood(cart, group?.group_abv_food_threshold)) {
        const price = formatCurrency(group?.group_abv_food_threshold);
        errMsg = T.translate("Payment.Errors.CartMustContainMin", {
          price: price,
        });
        // NEEDS currency localization
      } else {
        return true;
      }
    } else if (
      checkTimeslotAgaintsMenuTimings(selectedTime, cartItems, AvailableMenus)
        .length
    ) {
      errMsg = T.translate("Payment.Errors.TimeSlotInvalidForMenu");
    } else {
      return true;
    }
    sendSnackbarMessage(errMsg as string, "error");
    return false;
  };

  return {
    canPaymentBeProcessed,
  };
};

export default useCanPaymentBeProcessed;
