import React, { useContext } from "react";

import { PaymentContext } from "../PaymentStore";

import { onKeyboardPressHandler } from "../../../utils/keyboard";
import { setMessage } from "../reducer/PaymentActions";
import Styles from "../styles.module.scss";
import T from "i18n-react";

const MessageComponent = () => {
  const { dispatch } = useContext(PaymentContext);
  function onChange(event) {
    const { value } = event.target;
    dispatch(setMessage(value));
    // .replace(/[:\\//'#<>]/g, "")
  }
  return (
    <div className={Styles.TipsSectionDiv}>
      {/* <p className={Styles.TipStaffText}>{T.translate("Forms.LeaveMessage")}</p> */}
      <div className={Styles.MessageInputDiv}>
        <input
          type="text"
          maxLength={255}
          onKeyPress={onKeyboardPressHandler}
          className={Styles.TipCardInput}
          style={{
            width: "100%",
          }}
          placeholder={T.translate("Forms.WriteMessage") as string}
          onChange={onChange}
          data-component-name="MessageInput"
        />
      </div>
    </div>
  );
};

export default MessageComponent;
