import { useSelector } from "react-redux";
import omitBy from "lodash/omitBy";
import isEmpty from "lodash/isEmpty";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

const useGenerateNavPath = () => {
  const venue = useSelector((state: any) => state.Venue);
  const group = useSelector((state: any) => state.Group);
  const location = useLocation<locationState>();

  const [fromPath, setFromPath] = useState("");
  const [toPath, setToPath] = useState("");

  const generateNavPath = (path: string, queryParams?: any) => {
    const queryObj = queryParams || {};
    queryObj["from_path"] = fromPath;
    queryObj["to_path"] = toPath;

    if (!path) {
      return null;
    }
    let updatedPath = path
      .replace(
        "v/:id/",
        venue?.venue_nickname ? `v/${venue.venue_nickname}/` : ""
      )
      .replace(
        "g/:group/",
        group?.group_nickname ? `g/${group.group_nickname}/` : ""
      );

    let params = queryParams
      ? new URLSearchParams(omitBy(queryObj, isEmpty)).toString()
      : "";
    return !params ? updatedPath : `${updatedPath}?${params}`;
  };

  const compareNavPath = (currentPath: string, targetPath: string) => {
    let alteredPath = generateNavPath(targetPath);
    return currentPath === alteredPath;
  };

  useEffect(() => {
    setFromPath(location?.state?.from);
    setToPath(location?.state?.to);
  }, [location]);

  return {
    generateNavPath,
    compareNavPath,
  };
};

export default useGenerateNavPath;
