import React, { useContext, useEffect } from "react";
import { PaymentContext } from "../../PaymentStore";

import SquarePaymentContainer from "./SquarePaymentContainer";
import { useLoadPaymentForm } from "./CardDetails/useLoadPaymentForm";

import { setIsUsingPaymentRequest } from "../../reducer/PaymentActions";

export interface ISquarePaymentProcessor {}

const SquarePaymentProcessor = () => {
  const { dispatch } = useContext(PaymentContext);
  const { hasLoaded, config } = useLoadPaymentForm();

  useEffect(() => {
    dispatch(setIsUsingPaymentRequest(true));
    //eslint-disable-next-line
  }, []);

  if (!hasLoaded) return null;
  return (
    <>
      <SquarePaymentContainer
        paymentForm={
          //@ts-ignore
          window.SqPaymentForm
        }
        config={config}
      />
    </>
  );
};

export default SquarePaymentProcessor;
