import React from "react";
import { useSelector } from "react-redux";
import { APP_LANGUAGES } from "../../../constants";
import Styles from "./languagePicker.module.scss";
import { ILanguagePickerProps } from "./types.d";

function LanguagePicker({ openDrawer, showFull }: ILanguagePickerProps) {
  const lang = useSelector((state: any) => state.localization.language);
  function renderLanguageFlag() {
    if (lang.length > 0) {
      const { Flag } = APP_LANGUAGES[lang];
      return <Flag />;
    } else {
      const { Flag } = APP_LANGUAGES["EN"];
      return <Flag />;
    }
  }
  function renderLanguageKey() {
    if (lang.length > 0) {
      const { shortName, fullName } = APP_LANGUAGES[lang];
      return showFull ? fullName : shortName;
    } else {
      const { shortName, fullName } = APP_LANGUAGES["EN"];
      return showFull ? fullName : shortName;
    }
  }

  return (
    <div className={Styles.selectLanguage} onClick={openDrawer}>
      <div>
        {renderLanguageFlag()}
        <p>{renderLanguageKey()}</p>
        <span className="consumer-bottom-arrow"></span>
      </div>
    </div>
  );
}

export default LanguagePicker;
