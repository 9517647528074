import React from "react";
import { useMenu } from "../MenuContext";
import Styles from "./category.module.scss";

interface IProps {
  description: string;
  position: number;
}

export function CategoryDescription({ description, position }: IProps) {
  const { elementRefs } = useMenu();

  return description && description.length > 0 ? (
    <div
      className={`${Styles.subCategoryDescription}`}
      ref={(ref) => (elementRefs.current["category"][position] = ref)}
    >
      {description}
    </div>
  ) : null;
}
