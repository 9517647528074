import React, { useContext } from "react";
import { PaymentContext } from "../../../PaymentStore";

import ConfirmationButtons from "../../../../Widgets/ConfirmPayment/ConfirmationButtons";
import {
  setIsCardDetailsDrawerOpen,
  setIsPaymentDrawerOpen,
} from "../../../reducer/PaymentActions";
import useConfirmSquareOrder from "./useConfirmSquareOrder";
import { PaymentMethods } from "../../../types.d";

const ConfirmPaymentComponent = ({ processPayment }) => {
  const {
    confirmSquareOrderWithVerification,
    paymentDetails,
  } = useConfirmSquareOrder(processPayment);
  const { isLoading, dispatch, selectedPaymentMethod } = useContext(
    PaymentContext
  );

  return (
    <ConfirmationButtons
      isLoading={isLoading}
      isDisabled={
        selectedPaymentMethod && selectedPaymentMethod !== PaymentMethods.CARD
      }
      paymentDetails={paymentDetails}
      processPayment={confirmSquareOrderWithVerification}
      changeCard={() => {
        dispatch(setIsPaymentDrawerOpen(false));
        dispatch(setIsCardDetailsDrawerOpen(true));
      }}
    />
  );
};

export default ConfirmPaymentComponent;
