import React from "react";
import { OrderReference } from "./OrderReference";
import Styles from "./orderDetails.module.scss";
import { PaymentDetails } from "./PaymentDetails";
import { ItemSummary } from "./ItemSummary";
import { Route, useRouteMatch } from "react-router-dom";
import { QR_CODE } from "../../../constants/URL.const";
import { OrderQRCodeContainer } from "./QRCode/OrderQRCodeContainer";

interface IOrderDetailsProps {
  orderItem: IOrders;
  isStartRoute: boolean;
}

export function OrderDetails(props: IOrderDetailsProps) {
  let { path } = useRouteMatch();

  return (
    <>
      <div className={Styles.Container}>
        <OrderReference {...props} />
        <PaymentDetails {...props} />
        <ItemSummary {...props} />
      </div>
      <Route exact path={`${path}${QR_CODE}`}>
        <OrderQRCodeContainer {...props} />
      </Route>
    </>
  );
}
