import React from "react";
import Styles from "./styles.module.scss";
import T from "i18n-react";
import Button from "../Button";
import { ReactComponent as TimeCircleSVG } from "../../../imges/icon-time-circle.svg";

interface IDifferentTimezoneWarningProps {
  closeDrawer: () => void;
}
function DifferentTimezoneWarning({
  closeDrawer,
}: IDifferentTimezoneWarningProps) {
  return (
    <div
      data-component-name={"DifferentTimezoneWarning"}
      className={Styles.WarningComponent}
    >
      <header>
        <div className={Styles.WarningComponentHeaderDiv}>
          <TimeCircleSVG
            data-component-name={"timezone-close"}
            width="18px"
            height="18px"
          />
          <p>{T.translate("Venue.DifferentTimezone")}</p>
        </div>
        <Button
          onClick={closeDrawer}
          dataComponentName="ExitButton"
          iconClassName={"consumer-close"}
          style={{
            position: "unset",
            color: "#AAB6D3",
            fontSize: "1.5rem",
          }}
          isHeaderBtn
        />
      </header>

      <div>
        <p className={Styles.DescriptionText}>
          {T.translate("Venue.TimezoneWarningPart1")}{" "}
          <strong>{T.translate("Venue.differentTimezone")}</strong>{" "}
          {T.translate("Venue.TimezoneWarningPart2")}
        </p>
      </div>
    </div>
  );
}

export default DifferentTimezoneWarning;
