import React, { useContext } from "react";
import { PaymentContext } from "../../../PaymentStore";
import SquareDrawerWrapper from "../SquareDrawerWrapper";
import PaymentProcessingComponent from "../../PaymentProcessingComponent";
import { setSelectedPaymentMethod } from "../../../reducer/PaymentActions";

const PaymentProcessingContainer = () => {
  const { isPaymentProcessingDrawerOpen, dispatch } = useContext(
    PaymentContext
  );
  return (
    <SquareDrawerWrapper
      isOpen={isPaymentProcessingDrawerOpen}
      closeDrawer={() => {
        dispatch(setSelectedPaymentMethod(null));
      }}
    >
      <PaymentProcessingComponent />
    </SquareDrawerWrapper>
  );
};

export default PaymentProcessingContainer;
