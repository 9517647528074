


export const SET_FEES_MIGRATION = "SET_FEES_MIGRATION";

export function setFeesMigration(isMigrated: boolean) {
    return {
        type: SET_FEES_MIGRATION,
        payload: isMigrated
    }
}

export const SET_ITEMS_MIGRATION = "SET_ITEMS_MIGRATION";

export function setItemsMigration(isMigrated: boolean) {
    return {
        type: SET_ITEMS_MIGRATION,
        payload: isMigrated
    }
}