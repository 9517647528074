import React from "react";
import { generateExtraPricingOptionName } from "../../../utils/generateExtraPricingOptionName";
import ChoiceBox from "../ChoiceBox/ChoiceBox";
import EmptyMinExtras from "./EmptyMinExtras";

interface Props {
  selectedExtras: any;
  extrasData: IMenuExtra;
  menuItemExtras: any;
  Venue: any;
  searchText?: string;
  DisplayeExtrasAllergens(menuItem: any): void;
  handleExtraChange: (
    menuItem: any,
    pricingOptionName: string,
    price: number,
    increaseCountBoolean?: boolean
  ) => void;
}

const Extras = ({
  selectedExtras,
  extrasData,
  menuItemExtras,
  Venue,
  searchText,
  DisplayeExtrasAllergens,
  handleExtraChange,
}: Props) => {
  const { extras, ids } = selectedExtras;
  const hasMinQuantity = extrasData.menu_item_extra_group_quantity_min >= 0;
  const hasStock = !!extrasData.menu_item_extra_group_item_id_list.length;

  // Display an unavailable extras warning when extras have a min quantity and no stock
  if (hasMinQuantity && !hasStock) return <EmptyMinExtras />;

  // filter out any extras that don't meet the search criteria
  const filteredExtras = extrasData?.menu_item_extra_group_item_id_list;
  // ?.menu_item_extra_group_item_id_list.filter(
  //   (extra: any) =>
  //     menuItemExtras[extra]?.menu_item_extra_group_name_public
  //       .toLocaleLowerCase()
  //       .includes(searchText.toLocaleLowerCase())
  // );

  // build than render the vaild extras
  const renderExtras = () =>
    filteredExtras.map((extra: any) => {
      const menuItem = menuItemExtras[extra];

      return Object.keys(menuItem?.extra_pricing_options_extras).map((po) => {
        const priceOption: IPricingOptions =
          menuItem?.extra_pricing_options_extras[po];
        const index = ids.indexOf(extra);
        const pricingOptionName = generateExtraPricingOptionName(
          menuItem?.extra_name_public,
          priceOption?.option_name ?? ""
        );

        return (
          <ChoiceBox
            key={`${menuItem?.extra_name_public}-${po}`}
            onClick={() =>
              handleExtraChange(menuItem, po, priceOption.option_price)
            }
            choiceBoxName={pricingOptionName}
            isSelected={
              index > -1 && extras[index].priceName === po ? true : false
            }
            choiceBoxPricingOption={priceOption}
            menuItem={menuItem}
            setDisplayeExtrasAllergens={DisplayeExtrasAllergens}
            changeCount={(increaseCountBoolean) => {
              handleExtraChange(
                menuItem,
                po,
                priceOption.option_price,
                increaseCountBoolean
              );
            }}
            extraCount={extras[index]?.count}
            Square={Venue.payment_processor === "SQUARE"}
          />
        );
      });
    });

  return <>{renderExtras()}</>;
};

export default Extras;
