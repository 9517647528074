import T from "i18n-react";

export const API_ERRORS = "ApiErrors.";

interface IErrorCodes {
  error_code?: string;
  message?: string;
  [key: string]: any;
}

/**Function that returns API error codes as strings */
export function APIError(errorCodes: IErrorCodes[]): string {
  if (Array.isArray(errorCodes)) {
    return errorCodes
      .map((eC) => {
        if (typeof eC === "string") {
          return T.translate(eC);
        }
        return T.translate(API_ERRORS + (eC.error_code || eC.message), {
          ...eC,
        });
      })
      .join(" , ");
  } else if (typeof errorCodes === "string") {
    return T.translate(API_ERRORS + errorCodes).toString();
  } else if (typeof errorCodes === "object") {
    const ec = errorCodes as IErrorCodes;
    return T.translate(API_ERRORS + ec.error_code, {
      ...ec,
    }).toString();
  } else {
    return T.translate(API_ERRORS + "default").toString();
  }
}
