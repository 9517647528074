export const ROOT = "/";

export const PAYMENT = "/payment";
export const OPENBANKING = "/ob";
export const PAYMENT_VERIFICATION = "/payment/post-verification";
export const VENUE_MENU = "/v/:id/menu";
export const VENUE = "/v/:id/";
export const GROUP_VENUE_MENU = "/g/:group/:id/menu";
export const GROUP = "/g/:group";
export const GROUP_VENUE = "/g/:group/:id";
export const NOT_FOUND = "/not-found";
export const PAY360_VERIFICATION = "/payment/pay360/post-verification";
export const ACCOUNT = "/account";

export const LOGIN_REDIRECT = "/login/redirect";
export const LOGIN_ERROR = "/login/error";

export const LOGIN = "/login";
export const LOGINV2 = "/v/:id/login";

export const LOGIN_START = "/login/start";
export const LOGINV2_START = "/v/:id/login/start";

export const LOGIN_VERIFY = "/login/verify";
export const LOGINV2_VERIFY = "/v/:id/login/verify";

export const LOGIN_EMAIL_SENT = "/login/sent";
export const LOGINV2_EMAIL_SENT = "/v/:id/login/sent";

export const LOGIN_EMAIL_ERROR = "/login/email/error";
export const LOGINV2_EMAIL_ERROR = "/v/:id/login/email/error";

export const LOGIN_PROFILE = "/login/create-profile";
export const LOGINV2_PROFILE = "/v/:id/login/create-profile";

export const LOGIN_MANUAL = "/login/manual";
export const LOGIN_MANUALV2 = "/v/:id/login/manual";

export const ORDERS = "/o";
export const REVIEW = "/review";
export const BY_ID = "/:id";
export const QR_CODE = "/qr-code";
export const CONFIRM = "/confirm";
export const CART = "/cart";
