import React, { useContext } from "react";
import Styles from "../styles.module.scss";
import { PaymentContext } from "../PaymentStore";
export function OnApplyMessageComponent() {
  const { discounts } = useContext(PaymentContext);
  return (
    <div className={Styles.discountsCodeApplyMessage}>
      {discounts.message && discounts.error && (
        <p
          data-component-name="DiscountsErrorText"
          className={Styles.errorMessage}
        >
          {discounts.message}
        </p>
      )}
    </div>
  );
}
