import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { SelectedOrderType } from "../Payment/types.d";
import { MenuTimelines } from "../TopMenu/MenuTimelines/MenuTimelines";
import { useMenu } from "./MenuContext";

export function MenuTimes() {
  const {
    getCurrentMenu,
    state: { menuPosition, menuKeys },
  } = useMenu();
  const AvailableMenus = useSelector((state: any) => state.AvailableMenus);
  const SelectedMenuType = useSelector((state: any) => state.SelectedMenuType);

  /**Only returns if menu timings */
  const currentMenu = useMemo(() => {
    const menu = getCurrentMenu();

    if (!menu) return null;
    if (!menu.timings) return null;
    return menu;
    // eslint-disable-next-line
  }, [menuPosition, menuKeys]);

  return currentMenu &&
    AvailableMenus.length > 0 &&
    (SelectedMenuType === SelectedOrderType.CLICK ||
      SelectedMenuType === SelectedOrderType.DELIVERY) ? (
    <MenuTimelines Menus={currentMenu} />
  ) : null;
}
