import { ITask } from "../../interfaces/ITask";

export const OUTSTANDING_BODY = "OUTSTANDING_BODY";

interface IOutstandingBody {
    tasks: ITask[],
    venueId: string
}

export function setOutstandingBody(outstanding: IOutstandingBody) {
    return {
        type: OUTSTANDING_BODY,
        payload: outstanding
    };
}