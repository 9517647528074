import React from "react";
import Styles from "./orderDetails.module.scss";
import { currencyFormat } from "./PaymentDetails";
import T from "i18n-react";
interface IPaymentDiscountProps {
  orderItem: IOrders;
}
export function PaymentDiscount({ orderItem }: IPaymentDiscountProps) {
  const {
    placed_order_discount_frozen,
    placed_order_discount_code,
    placed_order_discount_value,
  } = orderItem;

  if (placed_order_discount_frozen === null) {
    return null;
  }
  function renderItem() {
    if (placed_order_discount_frozen.type === "PERCENTAGE") {
      return `-${placed_order_discount_frozen.value}%`;
    }
    return `-${currencyFormat(orderItem, placed_order_discount_frozen.value)}`;
  }
  return (
    <div className={Styles.PaymentDiscount}>
      <div>
        <div>{T.translate("OrderHistory.Discount")}</div>
        <div>
          {placed_order_discount_code} ({renderItem()})
        </div>
      </div>
      <p>-{currencyFormat(orderItem, placed_order_discount_value)}</p>
    </div>
  );
}
