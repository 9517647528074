import Modal from "react-modal";
import React, { ReactNode, useEffect, useState } from "react";
import { IModelState } from "./types.d";
// import '../Home/home.css'
import history from "../../utils/history";

const customStyles = {
  content: {
    top: "45%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    overflow: "visible",
    marginTop: "30px",
    marginBottom: "30px",
    transform: "translate(-50%, -50%)",
    width: "calc(100% - 15px)",
    height: "80%",
    borderRadius: "25px",
    zIndex: "999",
    background: "white",
    border: "1px solid #DAEFFF",
    transition: "0.2s height ease-in",
    padding: "0",
    boxShadow: "0px 4px 50px rgba(0, 0, 0, 0.5)",
    maxWidth: "768px",
  },
} as any;

interface IModalParentProps extends IModelState {
  hideModal: () => void;
}
function ModalParent({
  Component,
  props,
  isOpen,
  hideModal,
}: IModalParentProps) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={hideModal}
      style={customStyles}
      closeTimeoutMS={100}
      ariaHideApp={false}
      id="ParentModal"
    >
      {Component !== null && Component !== undefined && (
        <UnmountModalWrapper hideModal={hideModal}>
          <Component {...props} handleCloseModal={hideModal} />
        </UnmountModalWrapper>
      )}
    </Modal>
  );
}

interface IUnmount {
  children: ReactNode;
  hideModal: () => void;
}

function UnmountModalWrapper({ children, hideModal }: IUnmount) {
  const [windowlocal] = useState(window.location.pathname);
  useEffect(() => {
    /**Handles the back navigation */
    history.push(windowlocal, { noLoading: true });
    window.addEventListener("popstate", (event) => {
      hideModal();
    });
    return () => {
      window.removeEventListener("popstate", () => null);
    };
    // eslint-disable-next-line
  }, []);
  return <> {children} </>;
}
export default ModalParent;
