import React, { ComponentType } from 'react';
import { SnackbarContext } from "./SnackbarContext";

/**HoC for handling the snackbar context when a component already is using a context provider
 * use this ONLY on class components. We have the hook for functional components which is the superiour way 
 * But legacy an all that
 */
 const withSnackbarWrapper = (Component:ComponentType) => (
    (props:any) => (
        <SnackbarContext.Consumer>
            {snackbar => <Component {...props} snackbarContext={snackbar}/>}
        </SnackbarContext.Consumer>
    )
);

export default withSnackbarWrapper;