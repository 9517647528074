import React from "react";
import { Checkbox } from "antd";
import T from "i18n-react";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import Button from "../../../Widgets/Button";

import Styles from "./CardDetails.module.scss";

interface ICardDetailsForm {
  fullName: string;
  postcode: string;
  setFullName: React.Dispatch<React.SetStateAction<string>>;
  setPostcode: React.Dispatch<React.SetStateAction<string>>;
  rememberCardOnChange: (e: any) => void;
  submitCardDetails: () => void;
  rememberCard: boolean;
  isSaving: boolean;
}

const StripeStyles = {
  base: {
    fontFamily: "Open Sans",
    color: "#212121",
    "input::placeholder": { color: "#9E9E9E" },
  },
};

const CardDetailsForm = (props: ICardDetailsForm) => {
  const handleSubmit = () => {
    props.submitCardDetails();
  };
  return (
    <div className={Styles.CardDetails}>
      <form>
        <div className={Styles.InputWrapper}>
          <CardNumberElement
            className={Styles.CardNumber}
            options={{
              placeholder: T.translate("Forms.CardNumber").toString(),
              style: { ...StripeStyles },
            }}
          />
        </div>
        <div className={Styles.InputWrapper}>
          <CardExpiryElement
            className={`${Styles.CardExpiry}`}
            options={{
              placeholder: "MM/YY",
              style: { ...StripeStyles },
            }}
          />
        </div>
        <div className={Styles.InputWrapper}>
          <CardCvcElement
            className={Styles.CardCvc}
            options={{
              placeholder: T.translate("Forms.Cvc").toString(),
              style: { ...StripeStyles },
            }}
          />
        </div>
        <div className={Styles.InputWrapper}>
          <input
            data-component-name="InputFullName"
            type="text"
            className={`${Styles.InputField} ${Styles.FullName}`}
            name="full_name"
            placeholder={T.translate("Forms.FullName") as string}
            value={props.fullName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              props.setFullName(e.currentTarget.value)
            }
            required
          />
        </div>
        <div className={Styles.InputWrapper}>
          <input
            type="text"
            className={`${Styles.InputField} ${Styles.Postcode}`}
            name="postcode"
            placeholder={T.translate("Forms.PostCode") as string}
            value={props.postcode}
            data-component-name="InputPostCode"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              props.setPostcode(e.currentTarget.value)
            }
            required
          />
        </div>
        <div
          className={Styles.checkboxWrapper}
          data-component-name="RememberMyCard"
        >
          <Checkbox
            onChange={props.rememberCardOnChange}
            checked={props.rememberCard}
          >
            {T.translate("Payment.RememberMyCardDetails")}
          </Checkbox>
        </div>
      </form>
      <Button
        isLoading={props.isSaving}
        title={"UseCardDetails"}
        isYoelloBtn
        onClick={handleSubmit}
        dataComponentName={"SaveCardDetailsButton"}
      />
    </div>
  );
};

export default CardDetailsForm;
