import { SwipeableDrawer } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ComponentType } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { updateUserLanguagePreference } from "../../utils/updateUserLanguagePreference";
import { useAuth0 } from "../../yoello-lib/modules/auth/Auth0";
// import { LOGIN } from "../../constants/URL.const";
// import { _ChangeRedirect } from "../../redux/actions";
// import { getRedirectPlaceURL } from "../../utils/redirectHistory";
import withSnackbarWrapper from "../../yoello-lib/modules/components/snackbar/SnackbarWrapper";
import { setLanguage } from "../../yoello-lib/modules/redux/actions/localization";
import WithConsumerAuth from "../Auth/WithConsumerAuth";
import { useDrawer } from "../Drawer/DrawerContext";
import { SideMenu } from "../SideMenu/SideMenu";
import ShareBox from "../Widgets/ShareBox";

//@ts-ignorezd
const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

function PageComponent(Component: ComponentType<any>, group = false) {
  function WrappedComponent(props: any) {
    const location = useLocation();
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [isShareDrawerOpen, setOpenShareDrawer] = useState<boolean>(false);
    const { openDrawer, closeDrawer } = useDrawer()!;
    const dispatch = useDispatch();
    const { user, retrieveToken, setUser } = useAuth0()!;
    useEffect(() => {
      //@ts-ignore
      setIsOpenDrawer(location.state?.menuOpen);
      /**Handles the redirect each time a new page is loaded */
      // if (!window.location.pathname.includes(LOGIN)) {
      //   dispatch(_ChangeRedirect(getRedirectPlaceURL()));
      // }
      // if (sessionStorage.getItem("track_and_trace") === null && !group) {
      //     /**this is a hack and I AM NOT PROUD 1 BIT */
      //     if (sessionStorage.getItem("GroupPageVisited") === null
      //         && sessionStorage.getItem("GroupPageVisited-Goodsheds") === null) {
      //         setIsTrackAndTraceOpen(true);
      //     }
      // }
      // eslint-disable-next-line
    }, [location]);
    const sidebarProps = {
      closeSideDrawer: () => setIsOpenDrawer(false),
      openDrawer,
      closeDrawer,
      dispatchLanguage: (key) => {
        dispatch(setLanguage(key));
        updateUserLanguagePreference(
          key.toLowerCase(),
          user,
          retrieveToken,
          setUser
        );
      },
    };
    return (
      <>
        <Component
          {...props}
          apiConfig={props.apiConfig}
          openDrawer={openDrawer}
          closeDrawer={closeDrawer}
          openSideDrawer={() => setIsOpenDrawer(true)}
          openShareDrawer={() => setOpenShareDrawer(true)}
        />
        <SwipeableDrawer
          anchor={"left"}
          open={isOpenDrawer}
          onClose={() => setIsOpenDrawer(false)}
          onOpen={() => setIsOpenDrawer(true)}
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
          // transitionDuration={0}
          transitionDuration={350}
          id="SIDEMENUDRAWER"
        >
          <SideMenu {...sidebarProps} />
        </SwipeableDrawer>
        <SwipeableDrawer
          anchor={"bottom"}
          open={isShareDrawerOpen}
          onClose={() => setOpenShareDrawer(false)}
          onOpen={() => setOpenShareDrawer(true)}
          disableBackdropTransition={!iOS}
          disableDiscovery={true}
          id="SHAREDRAWER"
          transitionDuration={350}
          disableSwipeToOpen={true}
        >
          <div
            style={{
              width: "100%",
              height: "14.1875rem",
            }}
          >
            <ShareBox title={group ? "ShareThisGroup" : "WithFriends"} />
          </div>
        </SwipeableDrawer>
      </>
    );
  }
  return WithConsumerAuth(withSnackbarWrapper(WrappedComponent));
}

export default PageComponent;
