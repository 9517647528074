import React from "react";
import { ReactComponent as SupportSVG } from "../../imges/Heart.svg";
import { ReactComponent as AccountSVG } from "../../imges/Account.svg";
import { ReactComponent as BlogSVG } from "../../imges/Document.svg";
import { ReactComponent as BusinessSVG } from "../../imges/Work.svg";
import { ReactComponent as LegalsSVG } from "../../imges/Password.svg";
import { ReactComponent as OrderHistorySVG } from "../../imges/Bag.svg";
import { ReactComponent as PrivacySVG } from "../../imges/ShieldDone.svg";

export const sharedListItems = [
  {
    name: "Support",
    icon: <SupportSVG width="	1.1875rem" height="1.125rem" />,
  },
  {
    name: "Blog",
    icon: <BlogSVG width="1rem" height="1.125rem" />,
    url: "https://www.yoello.com/blog",
  },
  {
    name: "Referbusiness",
    icon: <BusinessSVG width="1rem" height="1.125rem" />,
    url: "https://www.yoello.com/recommend-business",
  },
  {
    name: "Legals",
    icon: <LegalsSVG width="1rem" height="1.125rem" />,
    url: "https://www.yoello.com/terms-conditions",
  },
  {
    name: "Privacy",
    icon: <PrivacySVG width="1rem" height="1.125rem" />,
    url: "https://www.yoello.com/privacy-policy",
  },
];

export const loggedInListItems = [
  {
    name: "Orderhistory",
    icon: <OrderHistorySVG width="1rem" height="1.125rem" />,
  },
  {
    name: "MyAccount",
    icon: <AccountSVG width="1rem" height="1.125rem" />,
  },
  ...sharedListItems,
];
