import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import Tooltip from "../Tooltip/Tooltip";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      marginLeft: "0.5rem",
      width: "18px",
      height: "18px",

      "&>*": {
        width: "100%",
        height: "100%",
      },
    },
  })
);
interface IProps {
  title: string;
  tooltip?: string;
  icon?: JSX.Element;
}

const Label = (props: IProps) => {
  const { icon, title, tooltip } = props;
  const classes = useStyles();

  return (
    <div style={{ display: "flex" }}>
      <span style={{ color: "#757575" }}>{title}</span>

      {tooltip && (
        <Tooltip title={tooltip}>
          <span className={classes.icon}>{icon}</span>
        </Tooltip>
      )}
    </div>
  );
};

export default Label;
