import SocialButton from "./SocialButton";
import React from "react";
import FacebookIcon from "./Icons/FacebookIcon";
import T from "i18n-react";

const FacebookButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <SocialButton
      onClick={onClick}
      text={T.translate("Login.Texts.facebook").toString()}
    >
      <FacebookIcon />
    </SocialButton>
  );
};

export default FacebookButton;
