import React, { useContext } from "react";
import { SwipeableDrawer } from "@material-ui/core";

import { PaymentContext } from "../PaymentStore";
import { useSelector } from "react-redux";
import UnmountDrawerWrapper from "../../Drawer/UnmountDrawerWrapper";
import SelectTable from "../../Widgets/SelectTable/SelectTable";
import {
  changeTableNumber,
  setIsTableServiceDrawerOpen,
} from "../reducer/PaymentActions";

/**Drawer for handling the selection of a table */
const TableComponent = () => {
  const {
    areaName,
    selectedTable,
    isTableServiceDrawerOpen,
    dispatch,
  } = useContext(PaymentContext);
  const LoginRedirectToMainPage = useSelector(
    (state: any) => state.LoginRedirectToMainPage
  );
  const props = {
    GroupsTable: LoginRedirectToMainPage === "PaymentA",
    handleTableSelect: (table: string, group: string) => {
      dispatch(changeTableNumber(table, group));
    },
    selectedTable: selectedTable,
    areaName: areaName,
    closeDrawer: onClose,
  };
  function onClose() {
    dispatch(setIsTableServiceDrawerOpen(false));
  }
  return (
    <SwipeableDrawer
      anchor={"bottom"}
      open={isTableServiceDrawerOpen}
      onOpen={() => null}
      onClose={onClose}
      transitionDuration={350}
      disableSwipeToOpen={true}
      disableDiscovery={true}
      id="deliveryTimeDrower"
    >
      <UnmountDrawerWrapper closeDrawer={onClose}>
        <SelectTable {...props} />
      </UnmountDrawerWrapper>
    </SwipeableDrawer>
  );
};

export default TableComponent;
