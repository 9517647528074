import React from "react";
import T from "i18n-react";
import { makeStyles } from "@material-ui/core";
import { ReactComponent as EmptyListIcon } from "../../imges/empty_list_icon.svg";
const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    borderTop: "1px solid #E8EAF4",
    "& p": {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "1.125rem",
      lineHeight: "1.75rem",
      textAlign: "center",
      color: "#616161",
      width: "11.9375rem",
    },
  },
});
export function EmptyList() {
  const classes = useStyles();
  return (
    <div className={classes.root} data-component-name="emptyList">
      <EmptyListIcon />
      <p>{T.translate("Menu.emptyList")}</p>
    </div>
  );
}
