import { getPaymentDetails } from "../../../Api.js";

const useStripe = () => {
  const getCard = async (token: string) => {
    if (token) {
      try {
        const response = await getPaymentDetails(token);
        return response.data.data;
      } catch (err) {
        return null;
      }
    }
  };

  return {
    getCard,
  };
};

export default useStripe;
