import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const stage = process.env.REACT_APP_STAGE;
const cardLockUrl = `https://secure${
  stage !== "prod" ? ".mite" : ""
}.pay360.com/cardlock/scripts/cardLock.js`;

const usePay360Form = (preSubmitCallback) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const Venue = useSelector((state: any) => state.Venue);
  useEffect(() => {
    let pay360Script = document.createElement("script");
    pay360Script.src = cardLockUrl;
    pay360Script.type = "text/javascript";
    pay360Script.async = false;
    pay360Script.onload = () => {
      setHasLoaded(true);
    };
    document.getElementsByTagName("head")[0].appendChild(pay360Script);
  }, []);

  const createCardLock = () => {
    if (hasLoaded) {
      //@ts-ignore
      new window.CardLock({
        publishableId: Venue.publishable_id,
        formElementId: "pay360-form",
        panElementId: "pay360-form-cardnumber",
        cvvElementId: "pay360-form-cvv",
        tokenElementId: "pay360-form-token",
        merchantAction: "/payment",
        preSubmitCallback: preSubmitCallback,
      });
    } else {
      console.log("Card lock is loading");
    }
  };

  return { hasLoaded, createCardLock };
};

export default usePay360Form;
