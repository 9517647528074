import React from "react";
import T from "i18n-react";
import Styles from "./styles.module.scss";

interface IVenueOrderTypeHeaderProps {
  title: string;
  custom?: string;
  svgImage: JSX.Element;
  selected?: boolean;
}

function VenueOrderTypeHeader({
  title,
  svgImage,
  selected,
  custom,
}: IVenueOrderTypeHeaderProps) {
  return (
    <div
      data-component-name="VenuePageOrderTypeCollapse"
      className={Styles.VenueOrderTypeHeaderTitleDiv}
    >
      <div>
        {svgImage}
        {!custom && <T.p text={{ key: title }} />}
        {custom && <p>{custom}</p>}
      </div>
      <span className="consumer-right-arrow"></span>
    </div>
  );
}

export default VenueOrderTypeHeader;
