import axios, { CancelTokenSource } from 'axios';
// import SessionClient from '../auth/SessionClient';
import { handleAxiosError } from './APIError';

/**Axios breadcrumb inteceptor */
axios.interceptors.response.use(function (res) {
    return res;
}, handleAxiosError);

export enum HTTPMethods {
    POST = "POST",
    GET = "GET",
    PUT = "PUT",
    DELETE = "DELETE",
    HEAD = "HEAD",
    OPTIONS = "OPTIONS",
    PATCH = "PATCH",
    LINK = "LINK",
    UNLINK = "UNLINK"
}

export interface IQueryParams {
    [keyValue: string]: any;
}

export interface APIOptions<TBody> {
    query?: string;
    body?: TBody;
    params?: IQueryParams;
    headers?: IQueryParams;
    /**a query to go inbetween baseURL and the rest of the url */
    preQuery?: string;
    overrideMethod?: HTTPMethods;
}

interface IResponse<T> {
    data: T;
    count?: number;
}
/**
 * Encapsulates behaviour for communicating with the api endpoint
 */
export default class API {
    private baseURL: string;
    private cancelToken?: CancelTokenSource;
    private tokenFunction?: Function;
    constructor(tokenFunction?: Function, baseUrl?: string) {
        this.baseURL = baseUrl || `https://mdapi.yllo.uk/`;
        if (tokenFunction) {
            this.tokenFunction = tokenFunction;
        }
    }
    private constructURL<TBody>(apiExtension: string, options: APIOptions<TBody>) {
        let url = "";
        if (options.preQuery) {
            url += options.preQuery + '/';
        }
        url += apiExtension;
        if (options.query) {
            if (url.endsWith('/')) {
                url += options.query;
            } else {
                url += '/' + options.query;
            }
        }
        return url;
    }
    /**
     * Post an HTTP request and get back the response body */
    public async makeHTTPRequest<TBody, TResponse>(apiExtension: string,
        method: HTTPMethods, options: APIOptions<TBody>, disabledFields?: string[], baseURL?: string)
        : Promise<IResponse<TResponse>> {
        const url = this.constructURL(apiExtension, options);

        this.setCancelToken();
        let headers;
        /**if we want to override the headers */
        if (options.headers) {
            headers = options.headers;
        } else {
            headers = await this.createHeaders();
        }
        const body = this.stripDisabledFields(options.body, disabledFields);

        const base = baseURL ? baseURL : this.baseURL;

        const response = await axios({
            method: options?.overrideMethod ?? method,
            url: base + url,
            data: body,
            cancelToken: this.cancelToken?.token || undefined,
            params: options.params,
            headers
        });
        return { data: response.data.data, count: response.headers['x-total-count'] };
    };
    /**If the api has disabled fields, will delete those from the body */
    private stripDisabledFields(body?: Record<string, any>, disabledFields?: string[]) {
        if (!body) {
            return undefined;
        }
        if (disabledFields) {
            for (const field of disabledFields) {
                if (body[field] !== undefined) {
                    delete body[field];
                }
            }
        }
        return body;
    }

    private setCancelToken() {
        this.cancelToken = axios.CancelToken.source();
    }
    public resetTokenFunction(tokenFunction: Function) {
        this.tokenFunction = tokenFunction;
    }
    cancelCurrentRequest() {
        if (this.cancelToken) {
            this.cancelToken.cancel("Http Call Cancelled");
            this.cancelToken = undefined;
        }
    };

    private async createHeaders(): Promise<{ [key: string]: string; } | null> {
        if (this.tokenFunction) {
            const token = await this.tokenFunction();
            return {
                "Authorization": `Bearer ${token}`,
                // "yoello-session-id": SessionClient.sessionId
            };
        }
        return null;
    };
}