import React, { useEffect, useState } from "react";
import T from "i18n-react";
import { IButtonProps } from "./types.d";
import Styles from "./styles.module.scss";
import Lottie from "react-lottie";
import animationData from "../../../lottie/loading1.json";

export const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const Buttons = ({
  id,
  isHeaderBtn,
  title,
  titleVariables = {},
  disabled,
  onClick,
  className,
  Icon,
  iconClassName,
  style,
  embeddedComponent,
  isLoading = false,
  dataComponentName,
  selectedLanguage,
  isYoelloBtn,
}: IButtonProps) => {
  const [language, setLanguage] = useState("");
  useEffect(() => {
    setLanguage(selectedLanguage);
    //eslint-disable-next-line
  }, [selectedLanguage]);

  return (
    <div
      style={style}
      id={id}
      className={`${className} ${Styles.Clickable} ${
        disabled ? Styles.disabled : undefined
      } ${isHeaderBtn ? Styles.headerBtn : undefined} ${language}
      ${isYoelloBtn ? Styles.YoelloButton : undefined}`}
      onClick={onClick}
      data-component-name={dataComponentName}
    >
      {isLoading ? (
        <Lottie
          options={defaultOptions}
          isStopped={false}
          isPaused={false}
          isClickToPauseDisabled={true}
          //@ts-ignore
          margin="9px auto 0 auto"
        />
      ) : (
        <>
          {embeddedComponent && embeddedComponent}
          {title && (
            <T.p text={{ key: `buttons.${title}`, ...titleVariables }} />
          )}
          {Icon && Icon}
          {iconClassName && <span className={iconClassName}></span>}
        </>
      )}
    </div>
  );
};

export default Buttons;
