import React, { useState } from "react";
import { useEffect } from "react";
import { renderImage } from "../../../utils/imageController";
import Styles from "../groupPage.module.scss";

export function GroupLogo({ group }) {
  const [renderImg, setRenderImg] = useState(
    <Image
      groupLogo={"group_logo"}
      groupName={"group_name_public"}
      isImageRender={false}
    />
  );
  useEffect(() => {
    if (group) {
      const { group_name_public, group_logo } = group;
      renderImage(group_logo).then(() => {
        setRenderImg(
          <Image
            groupLogo={group_logo}
            groupName={group_name_public}
            isImageRender={true}
          />
        );
      });
    }
  }, [group]);
  return renderImg;
}

function Image({ groupName, groupLogo, isImageRender }) {
  return (
    <div className={Styles.groupLogo}>
      {isImageRender && <img alt={groupName} src={groupLogo} />}
    </div>
  );
}
