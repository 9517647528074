import React, { useEffect } from "react";
import { useDrawer } from "../Drawer/DrawerContext";
import Styles from "../GroupPage/groupPage.module.scss";
import T from "i18n-react";
import { ReactComponent as FilterSVG } from "../../imges/Filter.svg";
import FilteringPage from "../GroupPage/FilteringPage/FilteringPage";

interface IFilterProps {
  setFilters: (filter) => void;
  filterId?: string;
  filters: Record<string, boolean>;
  filtersCount: number;
}

export function FilterComponent({
  setFilters,
  filters,
  filtersCount,
  filterId = "Filters",
}: IFilterProps) {
  const { openDrawer, closeDrawer } = useDrawer()!;
  useEffect(() => {
    const filters = sessionStorage.getItem(filterId);
    if (filters) {
      setFilters(JSON.parse(filters));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={`${Styles.OrderTypeFilterDiv} 
      ${filtersCount > 0 ? Styles.OrderTypeFilterDivSelected : ""}`}
      data-component-name={"orderTypesFilterButton"}
      onClick={() =>
        handleFilters(openDrawer, closeDrawer, setFilters, filters, filterId)
      }
    >
      <FilterSVG fill={filtersCount > 0 ? "#FFDD02" : ""} />
      <T.p text={{ key: `Payment.OrderType` }} />
      {filtersCount > 0 && (
        <div className={Styles.FiltersCountDiv}>{filtersCount}</div>
      )}
    </div>
  );
}
export function handleFilterCount(filterState) {
  let count = 0;
  for (const key in filterState) {
    if (filterState[key]) {
      count++;
    }
  }
  return count;
}

export function handleFilters(
  openDrawer,
  closeDrawer,
  setFilters,
  filters,
  filterId
) {
  if (openDrawer) {
    openDrawer(
      FilteringPage,
      {
        Filters: filters,
        onChange: (Filters) => {
          sessionStorage.setItem(filterId, JSON.stringify(Filters));
          setFilters(Filters);
        },
        onCloseDrawer: closeDrawer,
      },
      "bottom",
      "GroupPageFilterDrawer"
    );
  }
}
