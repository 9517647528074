import React from "react";

import TableComponent from "./Table/TableComponent";
import DeliveryComponent from "./Delivery/DeliveryComponent";
import TimeSelectionComponent from "./TimeSelection/TimeSelectionComponent";
import PaymentProcessorContainer from "./PaymentDrawer/PaymentProcessorContainer";

const PaymentViewDrawers = ({ isAuthenticated }) => {
  return (
    <>
      <TimeSelectionComponent />
      <TableComponent />
      <PaymentProcessorContainer />
      <DeliveryComponent isAuthenticated={isAuthenticated} />
    </>
  );
};

export default PaymentViewDrawers;
