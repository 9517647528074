import React from "react";
import { useSelector } from "react-redux";
import Button from "../Widgets/Button";
import { ReactComponent as HamburgerMenuSVG } from "../../imges/HamburgerMenu.svg";
import { ReactComponent as PlaceholderSVG } from "../../imges/placeholder.svg";
import Styles from "./styles.module.scss";
import { BackButton } from "./BackButton";

interface INavigationHeaderProps {
  demo?: boolean;
  backURL: () => void;
  openSideDrawer: Function;
  displayVenueImage?: boolean;
  className?: string;
  title?: string;
}

const NavigationHeader = React.forwardRef<
  HTMLDivElement,
  INavigationHeaderProps
>(
  (
    {
      demo = false,
      backURL,
      openSideDrawer,
      displayVenueImage = true,
      className = "",
      title,
    },
    ref
  ) => {
    const Venue = useSelector((state: any) => state.Venue);

    return (
      <div className={`${Styles.NavigationHeader} ${className}`} ref={ref}>
        <div>{!demo ? <BackButton onClick={() => backURL()} /> : null}</div>
        <div className={Styles.headerConteiner}>
          {displayVenueImage === true &&
            (Venue.url_logo ? (
              <img src={Venue.url_logo} alt="" />
            ) : (
              <PlaceholderSVG />
            ))}
          {displayVenueImage === false && title && (
            <span className={Styles.title}>{title}</span>
          )}
        </div>
        <div>
          {!demo ? (
            <Button
              Icon={<HamburgerMenuSVG />}
              dataComponentName={"HamburgerMenu"}
              style={{ background: "unset", position: "unset" }}
              //@ts-ignore
              onClick={openSideDrawer}
              isHeaderBtn
            />
          ) : null}
        </div>
      </div>
    );
  }
);

export default NavigationHeader;
