import React from "react";
import { usePaymentState } from "../../../PaymentStore";
import PaymentDrawer from "../../PaymentDrawer";
import ConfirmPaymentComponent from "./ConfirmPaymentComponent";
import DigitalWalletComponent from "./DigitalWalletComponent";

const SquarePaymentComponent = ({ processPayment }) => {
  const { selectedPaymentMethod } = usePaymentState();
  return (
    <PaymentDrawer
      isPersistent
      digitalWalletComponent={
        <DigitalWalletComponent method={selectedPaymentMethod} />
      }
      confirmPaymentComponent={
        <ConfirmPaymentComponent processPayment={processPayment} />
      }
    />
  );
};

export default SquarePaymentComponent;
