import React from "react";
import Styles from "./selectLanguage.module.scss";
import T from "i18n-react";
import { ISelectLanguageProps } from "./types";
import Button from "../Button";

const SelectLanguage = ({
  languages,
  onCloseDrawer,
  onSelectedLanguageChange,
}: ISelectLanguageProps) => {
  function renderLanguages() {
    const template = [];
    for (let key in languages) {
      const { Flag, fullName } = languages[key];
      template.push(
        <li key={key} onClick={() => onSelectedLanguageChange(key)}>
          <div>
            <Flag />
            <span>{fullName}</span>
          </div>
          <span className="consumer-right-arrow"></span>
        </li>
      );
    }
    return template;
  }
  return (
    <div
      className={Styles.selectLanguageContainer}
      data-component-name={"languagePickerDrawer"}
    >
      <div className={Styles.lineBar}>
        <span />
      </div>
      <header>
        <div>
          <p>{T.translate("languagePicker.title")}</p>
        </div>
        <div>
          <Button
            onClick={onCloseDrawer}
            dataComponentName="ExitButton"
            iconClassName={"consumer-close"}
            style={{
              position: "unset",
              color: "#AAB6D3",
              fontSize: "1.5rem",
            }}
            isHeaderBtn
          />
        </div>
      </header>
      <ul>{renderLanguages()}</ul>
    </div>
  );
};

export default SelectLanguage;
