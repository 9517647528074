import img_0 from "./images/img_0.png";
import img_1 from "./images/img_1.png";
import img_2 from "./images/img_2.png";
const data = {
  v: "5.5.3",
  fr: 29.9700012207031,
  ip: 0,
  op: 210.000008553475,
  w: 194,
  h: 193,
  nm: "QR Scanning",
  ddd: 0,
  assets: [
    {
      id: "image_0",
      w: 148,
      h: 5,
      u: "",
      p: img_0,
      e: 0,
    },
    {
      id: "image_1",
      w: 171,
      h: 171,
      u: "",
      p: img_1,
      e: 0,
    },
    {
      id: "image_2",
      w: 117,
      h: 118,
      u: "",
      p: img_2,
      e: 0,
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 2,
      nm: "handler",
      refId: "image_0",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833],
                y: [1],
              },
              o: {
                x: [0.167],
                y: [0],
              },
              t: 31.379,
              s: [0],
            },
            {
              i: {
                x: [0.833],
                y: [1],
              },
              o: {
                x: [0.167],
                y: [0],
              },
              t: 36.621,
              s: [100],
            },
            {
              i: {
                x: [0.833],
                y: [1],
              },
              o: {
                x: [0.167],
                y: [0],
              },
              t: 156.353,
              s: [100],
            },
            {
              t: 177.000007209358,
              s: [0],
            },
          ],
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 33.126,
              s: [98.385, 26.144, 0],
              to: [0, 22.833, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.833,
                y: 1,
              },
              o: {
                x: 0.167,
                y: 0,
              },
              t: 59.346,
              s: [98.385, 163.144, 0],
              to: [0, 0, 0],
              ti: [0, 22.833, 0],
            },
            {
              i: {
                x: 0.833,
                y: 0.833,
              },
              o: {
                x: 0.333,
                y: 0.333,
              },
              t: 85.565,
              s: [98.385, 26.144, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.667,
                y: 1,
              },
              o: {
                x: 0.333,
                y: 0,
              },
              t: 112,
              s: [98.385, 26.144, 0],
              to: [0, 22.833, 0],
              ti: [0, 0, 0],
            },
            {
              i: {
                x: 0.833,
                y: 1,
              },
              o: {
                x: 0.167,
                y: 0,
              },
              t: 138.221,
              s: [98.385, 163.144, 0],
              to: [0, 0, 0],
              ti: [0, 22.833, 0],
            },
            {
              t: 166.825006794922,
              s: [98.385, 26.144, 0],
            },
          ],
          ix: 2,
        },
        a: {
          a: 0,
          k: [73.635, 2.375, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
        },
      },
      ao: 0,
      ip: 0,
      op: 210.000008553475,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "Shape Layer 1",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 52,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [98.25, 33, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [0.75, -23.683, 0],
          ix: 1,
        },
        s: {
          a: 1,
          k: [
            {
              i: {
                x: [0.648, 0.648, 0.648],
                y: [1, 0.383, 1],
              },
              o: {
                x: [0.303, 0.303, 0.303],
                y: [0, 0, 0],
              },
              t: 36.621,
              s: [100, 0.799, 100],
            },
            {
              i: {
                x: [0.673, 0.673, 0.673],
                y: [1, 0.666, 1],
              },
              o: {
                x: [0.334, 0.334, 0.334],
                y: [0, 0.375, 0],
              },
              t: 40.118,
              s: [100, 13.868, 100],
            },
            {
              i: {
                x: [0.812, 0.812, 0.812],
                y: [1, 1, 1],
              },
              o: {
                x: [0.427, 0.427, 0.427],
                y: [0, 0.691, 0],
              },
              t: 48.859,
              s: [100, 65.014, 100],
            },
            {
              i: {
                x: [0.578, 0.578, 0.578],
                y: [1, 0.508, 1],
              },
              o: {
                x: [0.182, 0.182, 0.182],
                y: [0, 0, 0],
              },
              t: 59.346,
              s: [100, 103.799, 100],
            },
            {
              i: {
                x: [0.668, 0.668, 0.668],
                y: [1, 0.611, 1],
              },
              o: {
                x: [0.331, 0.331, 0.331],
                y: [0, 0.338, 0],
              },
              t: 73.331,
              s: [100, 44.453, 100],
            },
            {
              i: {
                x: [0.671, 0.671, 0.671],
                y: [1, 0.738, 1],
              },
              o: {
                x: [0.337, 0.337, 0.337],
                y: [0, 0.527, 0],
              },
              t: 78.006,
              s: [100, 21.792, 100],
            },
            {
              i: {
                x: [0.671, 0.671, 0.671],
                y: [1, 1, 1],
              },
              o: {
                x: [0.346, 0.346, 0.346],
                y: [0, -0.999, 0],
              },
              t: 84,
              s: [100, 0, 100],
            },
            {
              i: {
                x: [0.671, 0.671, 0.671],
                y: [1, 1, 1],
              },
              o: {
                x: [0.346, 0.346, 0.346],
                y: [0, 0, 0],
              },
              t: 85,
              s: [100, 0, 100],
            },
            {
              i: {
                x: [0.666, 0.666, 0.666],
                y: [1, 0.071, 1],
              },
              o: {
                x: [0.346, 0.346, 0.346],
                y: [0, 0, 0],
              },
              t: 112,
              s: [100, 0, 100],
            },
            {
              i: {
                x: [0.668, 0.668, 0.668],
                y: [1, 0.586, 1],
              },
              o: {
                x: [0.334, 0.334, 0.334],
                y: [0, 0.299, 0],
              },
              t: 118.393,
              s: [100, 10.941, 100],
            },
            {
              i: {
                x: [0.671, 0.671, 0.671],
                y: [1, 0.679, 1],
              },
              o: {
                x: [0.337, 0.337, 0.337],
                y: [0, 0.364, 0],
              },
              t: 122.319,
              s: [100, 31.825, 100],
            },
            {
              i: {
                x: [0.812, 0.812, 0.812],
                y: [1, 1, 1],
              },
              o: {
                x: [0.427, 0.427, 0.427],
                y: [0, 0.475, 0],
              },
              t: 127.733,
              s: [100, 65.014, 100],
            },
            {
              i: {
                x: [0.812, 0.812, 0.812],
                y: [1, 1, 1],
              },
              o: {
                x: [0.182, 0.182, 0.182],
                y: [0, 0, 0],
              },
              t: 132.791,
              s: [100, 92.216, 100],
            },
            {
              i: {
                x: [0.812, 0.812, 0.812],
                y: [1, 1, 1],
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0, 0, 0],
              },
              t: 136.718,
              s: [100, 103.799, 100],
            },
            {
              i: {
                x: [0.583, 0.583, 0.583],
                y: [1, 0.517, 1],
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0, 0, 0],
              },
              t: 138.028,
              s: [100, 103.799, 100],
            },
            {
              i: {
                x: [0.703, 0.703, 0.703],
                y: [1, 0.76, 1],
              },
              o: {
                x: [0.351, 0.351, 0.351],
                y: [0, 0.346, 0],
              },
              t: 152.523,
              s: [100, 49.303, 100],
            },
            {
              i: {
                x: [0.794, 0.794, 0.794],
                y: [1, 1, 1],
              },
              o: {
                x: [0.436, 0.436, 0.436],
                y: [0, 0.78, 0],
              },
              t: 161.104,
              s: [100, 11.405, 100],
            },
            {
              t: 166.825006794922,
              s: [100, 0, 100],
            },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ty: "rc",
              d: 1,
              s: {
                a: 0,
                k: [132, 125.5],
                ix: 2,
              },
              p: {
                a: 0,
                k: [0, 0],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 4,
              },
              nm: "Rectangle Path 1",
              mn: "ADBE Vector Shape - Rect",
              hd: false,
            },
            {
              ty: "gf",
              o: {
                a: 0,
                k: 100,
                ix: 10,
              },
              r: 1,
              bm: 0,
              g: {
                p: 3,
                k: {
                  a: 0,
                  k: [
                    0,
                    1,
                    0.867,
                    0.008,
                    0.5,
                    1,
                    0.867,
                    0.008,
                    1,
                    1,
                    0.867,
                    0.008,
                    0,
                    0,
                    0.5,
                    0.5,
                    1,
                    1,
                  ],
                  ix: 9,
                },
              },
              s: {
                a: 0,
                k: [-0.5, -62.35],
                ix: 5,
              },
              e: {
                a: 0,
                k: [-0.25, 63.075],
                ix: 6,
              },
              t: 1,
              nm: "Gradient Fill 1",
              mn: "ADBE Vector Graphic - G-Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 0,
                k: [0.5, 38.75],
                ix: 2,
              },
              a: {
                a: 0,
                k: [0, 0],
                ix: 1,
              },
              s: {
                a: 0,
                k: [100, 100],
                ix: 3,
              },
              r: {
                a: 0,
                k: 0,
                ix: 6,
              },
              o: {
                a: 0,
                k: 100,
                ix: 7,
              },
              sk: {
                a: 0,
                k: 0,
                ix: 4,
              },
              sa: {
                a: 0,
                k: 0,
                ix: 5,
              },
              nm: "Transform",
            },
          ],
          nm: "Rectangle 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 210.000008553475,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: "frame",
      refId: "image_1",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: {
                x: [0.486],
                y: [1],
              },
              o: {
                x: [0.429],
                y: [0],
              },
              t: 2,
              s: [0],
            },
            {
              i: {
                x: [0.774],
                y: [13.631],
              },
              o: {
                x: [0.162],
                y: [0],
              },
              t: 13.941,
              s: [100],
            },
            {
              i: {
                x: [0.833],
                y: [1],
              },
              o: {
                x: [0.167],
                y: [0],
              },
              t: 115.4,
              s: [100],
            },
            {
              i: {
                x: [0.833],
                y: [1],
              },
              o: {
                x: [0.167],
                y: [0],
              },
              t: 156.353,
              s: [100],
            },
            {
              t: 177.000007209358,
              s: [0],
            },
          ],
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [97, 95.518, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [85.25, 85.25, 0],
          ix: 1,
        },
        s: {
          a: 1,
          k: [
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [1, 1, 1],
              },
              o: {
                x: [0.782, 0.782, 0.333],
                y: [0, 0, 0],
              },
              t: 2,
              s: [110, 110, 100],
            },
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [1, 1, 1],
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0, 0, 0],
              },
              t: 13.941,
              s: [93, 93, 100],
            },
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [1, 1, 1],
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0, 0, 0],
              },
              t: 25.88,
              s: [100, 100, 100],
            },
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [1, 1, 1],
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0, 0, 0],
              },
              t: 115.4,
              s: [100, 100, 100],
            },
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [1, 1, 1],
              },
              o: {
                x: [0.782, 0.782, 0.333],
                y: [0, 0, 0],
              },
              t: 121.926,
              s: [98, 98, 100],
            },
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [1, 1, 1],
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0, 0, 0],
              },
              t: 130.633,
              s: [100, 100, 100],
            },
            {
              i: {
                x: [0.833, 0.833, 0.833],
                y: [1, 1, 1],
              },
              o: {
                x: [0.167, 0.167, 0.167],
                y: [0, 0, 0],
              },
              t: 156.353,
              s: [100, 100, 100],
            },
            {
              t: 177.000007209358,
              s: [110, 110, 100],
            },
          ],
          ix: 6,
        },
      },
      ao: 0,
      ip: 0,
      op: 210.000008553475,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: "QR",
      refId: "image_2",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [98.075, 95.519, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [58.325, 58.688, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
        },
      },
      ao: 0,
      ef: [
        {
          ty: 29,
          nm: "Gaussian Blur",
          np: 6,
          mn: "ADBE Gaussian Blur 2",
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 0,
              nm: "Blurriness",
              mn: "ADBE Gaussian Blur 2-0001",
              ix: 1,
              v: {
                a: 1,
                k: [
                  {
                    i: {
                      x: [0.833],
                      y: [1],
                    },
                    o: {
                      x: [0.333],
                      y: [0],
                    },
                    t: 3.493,
                    s: [0],
                  },
                  {
                    i: {
                      x: [0.833],
                      y: [1],
                    },
                    o: {
                      x: [0.167],
                      y: [0],
                    },
                    t: 16.925,
                    s: [10],
                  },
                  {
                    i: {
                      x: [0.833],
                      y: [1],
                    },
                    o: {
                      x: [0.167],
                      y: [0],
                    },
                    t: 27.373,
                    s: [0],
                  },
                  {
                    i: {
                      x: [0.833],
                      y: [1],
                    },
                    o: {
                      x: [0.333],
                      y: [0],
                    },
                    t: 111.048,
                    s: [0],
                  },
                  {
                    i: {
                      x: [0.833],
                      y: [1],
                    },
                    o: {
                      x: [0.167],
                      y: [0],
                    },
                    t: 128.456,
                    s: [8],
                  },
                  {
                    t: 139.336255675282,
                    s: [0],
                  },
                ],
                ix: 1,
              },
            },
            {
              ty: 7,
              nm: "Blur Dimensions",
              mn: "ADBE Gaussian Blur 2-0002",
              ix: 2,
              v: {
                a: 0,
                k: 1,
                ix: 2,
              },
            },
            {
              ty: 7,
              nm: "Repeat Edge Pixels",
              mn: "ADBE Gaussian Blur 2-0003",
              ix: 3,
              v: {
                a: 0,
                k: 0,
                ix: 3,
              },
            },
            {
              ty: 7,
              nm: "GPU Rendering",
              mn: "ADBE Force CPU GPU",
              ix: 4,
              v: {
                a: 0,
                k: 1,
                ix: 4,
              },
            },
          ],
        },
      ],
      ip: 0,
      op: 210.000008553475,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 1,
      nm: "White Solid 3",
      sr: 1,
      ks: {
        o: {
          a: 0,
          k: 100,
          ix: 11,
        },
        r: {
          a: 0,
          k: 0,
          ix: 10,
        },
        p: {
          a: 0,
          k: [97, 96.5, 0],
          ix: 2,
        },
        a: {
          a: 0,
          k: [97, 96.5, 0],
          ix: 1,
        },
        s: {
          a: 0,
          k: [100, 100, 100],
          ix: 6,
        },
      },
      ao: 0,
      sw: 194,
      sh: 193,
      sc: "#ffffff",
      ip: 0,
      op: 210.000008553475,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};

export default data;
