import Styles from "./leavingWarning.module.scss";
import { ReactComponent as LeavingWarningSVG } from "../../../imges/leavingWarning.svg";
import Button from "../../Widgets/Button";
import T from "i18n-react";
interface LeavingWarningProps {
  venueName: string;
  isOpen: boolean;
  onCancel: any;
  onContinue: any;
}

const LeavingWarning = (props: LeavingWarningProps) => {
  return (
    <div className={`${Styles.leavingWarningContainer}`}>
      <div
        className={`${Styles.leavingWarningDrawer} ${
          props.isOpen ? Styles.openWarning : Styles.closeCart
        } `}
      >
        <div className={Styles.header}>
          <div className={Styles.lineBar} />
        </div>
        <div className={Styles.warningDetails}>
          <div>
            <Button
              iconClassName="consumer-close"
              isHeaderBtn
              className={Styles.closeDrawer}
              onClick={() => props.onCancel()}
            />
          </div>
          <div className={Styles.icon}>
            <LeavingWarningSVG />
          </div>
          <p> {T.translate("LeavingWarning.Question")}</p>
          <p>
            {T.translate("LeavingWarning.Description", {
              venueName: <b>{props.venueName}</b>,
            })}
          </p>
          <div className={`${Styles.buttonContainer}`}>
            <button onClick={props.onCancel} className={`${Styles.cancel}`}>
              {T.translate("buttons.Cancel")}
            </button>
            <button
              onClick={() => props.onContinue()}
              className={`${Styles.continue}`}
            >
              {T.translate("buttons.Continue")}
            </button>
          </div>
        </div>
      </div>
      <div
        id="backgroundMaskId"
        className={`${Styles.drawerMask} ${
          props.isOpen && Styles.maskBackground
        }`}
        onClick={props.onCancel}
      ></div>
    </div>
  );
};
export default LeavingWarning;
