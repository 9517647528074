/**Checks if its an object, if so returns true
 * @param object the object you want to check
 * @param isEmpty defaults to false, but also checks if the object is empty
 */
export function isObject<T>(object: T, isEmpty = false) {
  if (!object) {
    return false;
  }
  if (typeof object === "object") {
    if (isEmpty) {
      if (Object.keys(object).length === 0) {
        return false;
      }
    }
    return true;
  } else {
    return false;
  }
}
