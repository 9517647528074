import React, { useEffect, useState } from "react";
// import TextField from '@material-ui/core/TextField';
import T from "i18n-react";
import "./AddressForm.css";
import { ReactComponent as AlertErrorIcon } from "../../../imges/AlertErrorIcon.svg";
import { AlertStatusCard } from "../../../yoello-lib/modules/components/UI/Alerts/AlertStatusCard";
import { getDeliveryAddress } from "../../../utils/getdeliveryAddress";
interface IAddress {
  full_name: string;
  address: string;
  city: string;
  postcode: string;
}
export function AddressForm(props: any) {
  const [state, setState] = useState<IAddress>({
    full_name: "",
    address: "",
    city: "",
    postcode: "",
  });
  useEffect(() => {
    const address = getDeliveryAddress();
    if (address) {
      setState(address);
      props.handleDeliveryInfoChange(address);
    }
    // eslint-disable-next-line
  }, []);

  function handleInputChange(e: any) {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name !== "full_name") {
      props.setPostCodeChanged(true);
    }
    props.handleDeliveryInfoChange({
      ...state,
      [name]: value,
    });
  }
  return (
    <div
      className="AddressForm"
      style={{
        height: props.noFullName ? "auto" : "",
      }}
    >
      {props.noFullName ? null : (
        <div
          className="SignUpInputDivs"
          style={{
            marginTop: "10px",
          }}
        >
          <input
            type="text"
            className="SignUpInput"
            placeholder={T.translate("Forms.FullName") as string}
            value={state.full_name}
            name="full_name"
            onChange={handleInputChange}
          />
        </div>
      )}

      <div
        className="SignUpInputDivs"
        style={{
          marginTop: "10px",
        }}
      >
        <input
          type="text"
          className="SignUpInput"
          placeholder={T.translate("Forms.StreetAddress") as string}
          value={state.address}
          name="address"
          onChange={handleInputChange}
        />
      </div>

      <div
        className="SignUpInputDivs"
        style={{
          marginTop: "10px",
        }}
      >
        <input
          type="text"
          className="SignUpInput"
          placeholder={T.translate("Forms.City") as string}
          value={state.city}
          name="city"
          onChange={handleInputChange}
        />
      </div>

      <div
        className="SignUpInputDivs"
        style={{
          marginTop: "10px",
        }}
      >
        <input
          type="text"
          className="SignUpInput"
          placeholder={T.translate("Forms.PostCode") as string}
          value={state.postcode}
          name="postcode"
          onChange={handleInputChange}
          onBlur={props.onPostcodeBlur}
        />
      </div>
      <div className="mt-4">
        {props.postCodeInValid ? (
          <AlertStatusCard
            alertProps={{
              color: "error",
              icon: <AlertErrorIcon />,
              style: {
                color: "#F65164",
                borderRadius: "0.75rem",
                fontWeight: 600,
              },
            }}
            alertTitle={T.translate("Forms.InvalidPostcode")}
            alertMessage={T.translate("Forms.EnterValidPostCode")}
          />
        ) : null}
      </div>
    </div>
  );
}

export default AddressForm;
