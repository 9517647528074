import React from "react";
import Styles from "./ConfirmPayment.module.scss";
function CardDots() {
  return (
    <div className={Styles.Lines}>
      <span className={Styles.Dots}></span>
      <span className={Styles.Dots}></span>
      <span className={Styles.Dots}></span>
      <span className={Styles.Dots}></span>
    </div>
  );
}

export default CardDots;
