import React, { useState, useEffect } from "react";
import ProductsList from "../../ProductsList/ProductList";
import Loader from "../../Widgets/Loading/Loading";
import { useSelector } from "react-redux";

interface ISwipeItemsProps {
  data: any;
  windowHeight?: number;
  padding?: number;
  position?: number;
}

const SwipeItems = ({
  data,
  windowHeight,
  padding,
  position,
}: ISwipeItemsProps) => {
  const [newData, setNewData] = useState<any[]>([]);
  const venue = useSelector((state: any) => state.Venue);
  useEffect(() => {
    if (data) {
      if (!venue.menu_grid_view) {
        // data.sort((a, b) => a.menu_item_enabled ? -1 : 1)
        setNewData(data);
      } else {
        let newArr = [];
        let tmp = [...data];
        while (tmp.length) newArr.push(tmp.splice(0, 3));
        // newArr.sort((a, b) => a.menu_item_enabled ? -1 : 1)

        setNewData(newArr);
        newArr = [];
      }
    }
  }, [data, venue]);
  return (
    <div style={{ backgroundColor: "white" }}>
      {data !== undefined ? (
        <ProductsList
          listView={true}
          menuItemList={newData}
          windowHeight={windowHeight}
          padding={padding}
          position={position}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default React.memo(SwipeItems);
