import React from "react";
import Button from "../../../Widgets/Button";
import { OrderQRCode } from "./OrderQRCode";
import Styles from "./ordersQRCode.module.scss";
import { ReactComponent as CompleteQRCode } from "../../../../imges/CompleteQRCode.svg";
import { OrderTitle } from "../OrderTitle";
import { Ring } from "./Ring";
import T from "i18n-react";

interface IQRCodeDialogProps {
  orderItem: IOrders;
  closeDrawer: () => void;
}

export function QRCodeDialog({ orderItem, closeDrawer }: IQRCodeDialogProps) {
  return (
    <div className={Styles.qrCodeContainer}>
      <div className={Styles.lineBar} />
      <div className={Styles.TopBar}>
        <div>
          <CompleteQRCode />
          <T.p text={{ key: "OrderHistory.QRCode.header" }} />
        </div>
        <Button
          onClick={closeDrawer}
          dataComponentName="ExitButton"
          iconClassName={"consumer-close"}
          className={Styles.CloseButton}
        />
      </div>

      <div className={Styles.Content}>
        {orderItem.placed_order_status !== "FINISHED" ? (
          <div className={Styles.TopText}>
            <T.p
              className={Styles.QRSubtitle}
              text={{
                key: "OrderHistory.QRCode.title",
              }}
            />
            <p className={Styles.CollectionPoint}>
              <b>{orderItem.collection_point_name}</b>
            </p>
          </div>
        ) : (
          <T.p
            className={Styles.InfoText}
            text={{ key: "OrderHistory.QRCode.info" }}
          />
        )}
        <div className={Styles.QRCode}>
          <Ring />
          <OrderQRCode id={orderItem.placed_order_id} />
        </div>
        <div className={Styles.TextContent}>
          <OrderTitle
            titleKey="OrderHistory.OrderId"
            valueText={orderItem.placed_order_ref}
            isLarge={true}
            valueComponentName="OrderDetailsOrderID"
          />
        </div>
      </div>
    </div>
  );
}
