import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import Axios from "axios";
import T from "i18n-react";
import { usePaymentState } from "../../../PaymentStore";

import { _updateCartID } from "../../../../../redux/actions/index";
import {
  setIsPaymentProcessingDrawerOpen,
  setIsUsingPaymentRequest,
  setSelectedPaymentMethod,
  setIsPaymentDrawerOpen,
} from "../../../reducer/PaymentActions";
import { useSnackbarMessages } from "../../../../../yoello-lib/modules/components/snackbar/SnackbarContext";
import { generateSquareEndpoint } from "../../../../../customHooks/Square/generateEndpoints";
import useCompleteOrder from "../../../PaymentHooks/useCompleteOrder";
import useOnSuccessfulOrder from "../../../PaymentHooks/useOnSuccessfulOrder";
import { useCheckCartItems } from "../../../../../customHooks/useCheckCartItems";

import { useCurrencyState } from "../../../../../yoello-lib/modules/hooks/useCurrencyState";
import { useSentry } from "../../../../../customHooks/useSentry";

const useConfirmSquareOrder = (processPayment?: Function) => {
  const { completeOrder } = useCompleteOrder();
  const { sendSnackbarMessage } = useSnackbarMessages();
  const { checkCart } = useCheckCartItems();
  const { iso_code } = useCurrencyState();
  const rdxDispatch = useDispatch();
  const {
    isLoading,
    dispatch,
    paymentDetails,
    totalPrice,
    totalPriceOverride,
    tipTotal,
    retrieveToken,
  } = usePaymentState();
  const { onSuccessfulOrder } = useOnSuccessfulOrder();
  const { captureException } = useSentry();
  const Venue = useSelector((state: any) => state.Venue);

  const verificationDetails = {
    intent: "CHARGE",
    amount: (((totalPriceOverride || totalPrice) + tipTotal) / 100)
      .toFixed(2)
      .toString(),
    currencyCode: iso_code.toUpperCase(),
    billingContact: {},
  };

  const onOrderFailure = () => {
    dispatch(setIsPaymentProcessingDrawerOpen(false));    
    dispatch(setIsPaymentDrawerOpen(true));
    dispatch(setSelectedPaymentMethod(null));
    rdxDispatch(_updateCartID(uuidv4()));
  };

  const confirmSquareOrderWithVerification = () => {
    dispatch(setIsPaymentDrawerOpen(false));
    dispatch(setIsPaymentProcessingDrawerOpen(true));
    if (processPayment) {
      processPayment(
        paymentDetails.card_nonce,
        verificationDetails,
        confirmSquareOrder
      );
    }
  };

  const confirmSquareOrder = async (
    err: any,
    verificationResult?: any,
    card_nonce?: string
  ) => {
    let order;
    const userToken = await retrieveToken();
    //Check if cart is still valid
    const cartSuccessful = await checkCart();
    if (!cartSuccessful) return;
    //Error if Square verify failed.
    if (err || !userToken) {
      sendSnackbarMessage(
        T.translate("Payment.Errors.ConfirmOrderVerifyFailed").toString(),
        "error"
      );
      onOrderFailure();
      return;
    }
    try {
      //Try to place order in Yoello
      order = await completeOrder(true);
      if (!order) {
        throw new Error("Yoello order couldn't be created.");
      }
    } catch (error) {
      sendSnackbarMessage(
        T.translate("Payment.Errors.CompleteOrderFailed").toString(),
        "error"
      );
      captureException(error, { feature: "square-complete-order" });
      onOrderFailure();
      return;
    }
    try {
      //Try to place order in Square
      await Axios.post(
        generateSquareEndpoint("orders"),
        {
          venue_id: Venue.id,
          order_reference_number: order.data.data.order_reference_number,
        },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      );
    } catch (error) {
      sendSnackbarMessage(
        T.translate("Payment.Errors.OrderCreationFailed").toString(),
        "error"
      );
      captureException(error, { feature: "square-complete-order" });
      onOrderFailure();
      return;
    }

    try {
      //Try to create card payment
      await createCardPayment(
        card_nonce || paymentDetails.card_nonce,
        order.data.data.order_reference_number,
        verificationResult?.token
      );
    } catch (error) {
      captureException(error, { feature: "square-payment" });
      if (error.response?.data?.data?.square_errors?.length > 0) {
        error.response.data.data.square_errors.forEach((message: string) => {
          sendSnackbarMessage(
            T.translate(`Payment.Errors.${message}`).toString(),
            "error"
          );
        });
      } else {
        sendSnackbarMessage(
          T.translate("Payment.Errors.PaymentAuthenticationError").toString(),
          "error"
        );
      }
      onOrderFailure();
      return;
    }
    //
    onSuccessfulOrder(
      order.data.data.order_reference_number,
      order.data.data.order_id
    );
  };

  const createCardPayment = (
    nonce: string,
    orderId: string,
    verificationToken?: string
  ) => {
    return retrieveToken().then((userToken) => {
      return Axios.post(
        generateSquareEndpoint(`orders/${orderId}/payments`),
        {
          card_nonce: nonce,
          verification_token: verificationToken,
        },
        {
          headers: { Authorization: `Bearer ${userToken}` },
          params: { venue_id: Venue.id },
        }
      );
    });
  };

  useEffect(() => {
    dispatch(setIsUsingPaymentRequest(true));
    //eslint-disable-next-line
  }, []);

  return {
    confirmSquareOrderWithVerification,
    confirmSquareOrder,
    isLoading,
    paymentDetails,
  };
};

export default useConfirmSquareOrder;
