import React from "react";
interface IErrorBoundaryState {
  hasError: boolean;
}
interface IErrorBoundaryProps {
  children: React.ReactNode;
  fullBackFunction: (component: React.ReactNode) => React.ReactNode;
  captureException: (error: Error) => void;
}
class ErrorBoundary extends React.Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.props.captureException(error);

    this.setState({ hasError: true });
    setTimeout(() => {
      if (document.getElementById("BodyDivId")) {
        document.getElementById("BodyDivId").style.filter = "none";
      }
    }, 100);
  }

  render() {
    if (this.state.hasError) {
      return this.props.fullBackFunction;
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
