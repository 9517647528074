import React from "react";
import { Divider, makeStyles } from "@material-ui/core";
import T from "i18n-react";
import { Link } from "react-router-dom";
import Styles from "../styles.module.scss";
import SocialLoginButtons from "./SocialLoginButtons";
import PageLoading from "../../Page/PageLoading";
import { useHistory } from "react-router";
import useGenerateNavPath from "../../../customHooks/useGenerateNavPath";
import { LOGIN_MANUALV2 } from "../../../constants/URL.const";

const useStyles = makeStyles({
  root: {},
  title: {
    fontSize: "1.375rem",
    fontWeight: 600,
  },
  description: {},
  form: {},
  dividerWrapper: {
    padding: "2rem 0",
    "& > span": {
      position: "absolute",
      left: "50%",
      transform: "translate(-50%, -50%)",
      padding: "0 1rem",
      backgroundColor: "#FFFFFF",
      color: "#737FA4",
    },
  },
  manualSignIn: {
    display: "flex",
    justifyContent: "center",
  },
  select: {
    width: "100%",
    marginBottom: "1rem",
    border: "1px solid #F9F9F9",
    backgroundColor: "#F9F9F9",
    borderRadius: "6px",
    padding: "0.875rem",
    "&.Mui-active": {
      backgroundColor: "#FFFFFF",
      border: "1px solid #FFDD02",
    },
    "& option": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "8vw",
    },
  },
  input: {
    border: "none",
    outline: "unset",
    width: "100%",
    marginBottom: "1rem",
    position: "relative",
    "& .MuiInput-root": {
      border: "1px solid #F9F9F9",
      backgroundColor: "#F9F9F9",
      transition: "0.25s ease-in-out",
      borderRadius: "6px",
      width: "100%",
      display: "block",
      boxSizing: "border-box",
      "&.Mui-error": {
        border: "1px solid #F44336",
      },
      "&.Mui-focused": {
        backgroundColor: "#FFFFFF",
        border: "1px solid #FFDD02",
      },
      "& input": {
        padding: "1.6rem",
        boxSizing: "border-box",
      },
    },
  },
});

const LoginView = ({ isLoading }: { isLoading: boolean }) => {
  const history = useHistory<{ title: string }>();
  const classes = useStyles();
  const { generateNavPath } = useGenerateNavPath();

  if (isLoading) return <PageLoading />;

  return (
    <div className={Styles.Login}>
      <h2 className={classes.title}>
        {T.translate(
          history?.location?.state?.title ?? "Login.Texts.letsCompleteOrder"
        )}
      </h2>
      <p className={classes.description}>
        <span>{T.translate("Login.Texts.fewDetails")}</span>
      </p>

      <SocialLoginButtons />

      <div className={classes.dividerWrapper}>
        <Divider />
        <span>OR</span>
      </div>

      <div className={classes.manualSignIn}>
        <Link
          to={{
            pathname: generateNavPath(LOGIN_MANUALV2),
            state: history.location?.state,
          }}
        >
          {T.translate("Login.Texts.signInManually")}
        </Link>
      </div>
    </div>
  );
};

export default LoginView;
