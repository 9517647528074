import React, { useEffect, useState } from "react";
import Styles from "../styles.module.scss";
import T from "i18n-react";
import { getPageTitle } from "./getPageTitle";

interface IPageTitleProps {
  position: number;
  menuItem: IMenuItem;
  isShownTags: boolean;
  extras: any;
}

function PageTitle({
  position,
  isShownTags,
  menuItem,
  extras,
}: IPageTitleProps) {
  const [pageTitles, setPageTitles] = useState([]);
  useEffect(() => {
    setPageTitles(getPageTitle(menuItem, isShownTags, extras));
    //eslint-disable-next-line
  }, []);

  return (
    <p
      className={`${Styles.PageTitle} 
      ${position === 0 && Styles.productDetailsPageTitle}`}
    >
      {T.translate(pageTitles[position])}
    </p>
  );
}

export default PageTitle;
