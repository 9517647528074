import React, { useEffect, useState } from "react";
import Styles from "../styles.module.scss";
import Button from "../../Widgets/Button";
import { ReactComponent as HamburgerMenuSVG } from "../../../imges/HamburgerMenu.svg";
import { ReactComponent as BackArrowSVG } from "../../../imges/ArrowLeft.svg";
import { useDispatch, useSelector } from "react-redux";
import LanguagePicker from "../../Widgets/LanguagePicker";
import { useDrawer } from "../../Drawer/DrawerContext";
import { APP_LANGUAGES } from "../../../constants";
import { setLanguage } from "../../../yoello-lib/modules/redux/actions/localization";
import SelectLanguage from "../../Widgets/SelectLanguage";
import { Link } from "react-router-dom";
import { updateUserLanguagePreference } from "../../../utils/updateUserLanguagePreference";
import { useAuth0 } from "../../../yoello-lib/modules/auth/Auth0";

interface IVenueTopBannerProps {
  Venue: IVenue;
  openSideDrawer: () => void;
  area: boolean;
}

export function VenueTopBanner({
  Venue,
  openSideDrawer,
  area,
}: IVenueTopBannerProps) {
  const Group = useSelector((state: any) => state.Group);
  const [isRenderedBack, setIsRenderedBack] = useState(false);
  const { openDrawer, closeDrawer } = useDrawer()!;
  const { user, retrieveToken, setUser } = useAuth0()!;
  const dispatch = useDispatch();
  useEffect(() => {
    if (area) {
      return setIsRenderedBack(true);
    } else {
      if (Group) {
        if (Group.group_id === Venue.venue_group_id) {
          return setIsRenderedBack(true);
        }
      }
    }
    if (isRenderedBack) {
      setIsRenderedBack(false);
    }
    // eslint-disable-next-line
  }, [Venue, Group, area]);

  function openLanguageDrawer(openDrawer, closeDrawer, dispatch) {
    if (openDrawer) {
      openDrawer(
        SelectLanguage,
        {
          languages: APP_LANGUAGES,
          onCloseDrawer: closeDrawer,
          onSelectedLanguageChange: (key: string) => {
            dispatch(setLanguage(key));
            updateUserLanguagePreference(
              key.toLowerCase(),
              user,
              retrieveToken,
              setUser
            );
            closeDrawer();
          },
        },
        "bottom",
        "SelectLanguageDrawer"
      );
    }
  }
  return (
    <div
      data-component-name="venue-top-banner"
      className={Styles.venueImage}
      style={{
        backgroundImage: `url('${
          Venue?.url_banner ? Venue?.url_banner : "/imags/header_default.png"
        }')`,
      }}
    >
      <Button
        isHeaderBtn
        className={Styles.venueSidemenuToggleBtn}
        onClick={openSideDrawer}
        Icon={<HamburgerMenuSVG />}
        dataComponentName="HamburgerMenu"
      />
      <div
        className={Styles.languageWrapper}
        data-component-name={"languagePicker"}
      >
        <LanguagePicker
          openDrawer={() =>
            openLanguageDrawer(openDrawer, closeDrawer, dispatch)
          }
        />
      </div>
      {isRenderedBack && (
        <Link to={`/g/${Group.group_nickname}`}>
          <Button
            isHeaderBtn
            className={Styles.venueBackBtn}
            Icon={<BackArrowSVG />}
          />
        </Link>
      )}
    </div>
  );
}
