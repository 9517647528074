import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const setPath = (
  prefix: string,
  key: string,
  defaultValue: string,
  setStateCall: Function,
  location: any
) => {
  const locationState = location?.state as locationState;
  if (locationState && locationState[key]) {
    setStateCall(locationState[key]);
    localStorage.setItem(`${prefix}_${key}`, locationState[key]);
  } else {
    setStateCall(localStorage.getItem(`${prefix}_${key}`) || defaultValue);
  }
};

const useGetToAndFromPaths = (
  prefix: string,
  defaultTo: string,
  defaultFrom: string
) => {
  const [toPath, setToPath] = useState("");
  const [fromPath, setFromPath] = useState("");
  const [isLoadingPaths, setIsLoadingPaths] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setIsLoadingPaths(true);
    setPath(prefix, "to", defaultTo, setToPath, location);
    setPath(prefix, "from", defaultFrom, setFromPath, location);
    setIsLoadingPaths(false);
  }, [location, location.state, prefix, defaultTo, defaultFrom]);

  return {
    toPath,
    fromPath,
    isLoadingPaths,
  };
};

export default useGetToAndFromPaths;
