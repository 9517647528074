import React from "react";
import { MenuContents } from "./MenuContents";
import { MenuProvider } from "./MenuContext";
import Styles from "./styles.module.scss";
import smoothScroll from "smoothscroll-polyfill";
import "./home.css";
import { MenuNavigation } from "./UI/MenuNavigation";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { ConfirmOrderContainer } from "./ConfirmOrderContainer";
import { CART, CONFIRM } from "../../constants/URL.const";
import ShoppingCart from "../ShopList/ShoppingCartList";
import { CartButtons } from "./UI/CartButtons";
// import TopMenuV2 from "../TopMenu/TopMenuV2";

export function MenuContainer({ openSideDrawer, area }) {
  let { path } = useRouteMatch();
  smoothScroll.polyfill();

  return (
    <MenuProvider>
      <div className={Styles.MenuContainer}>
        <MenuNavigation openSideDrawer={openSideDrawer} />
        <MenuContents />
        {/* <TopMenuV2 /> */}
        <div className={Styles.FixedCheckout}>
          <CartButtons checkoutBtnLabel={"ViewCart"} />
        </div>
      </div>
      <Switch>
        <Route exact path={`${path}${CONFIRM}`}>
          <ConfirmOrderContainer />
        </Route>
        <Route exact path={`${path}${CART}`}>
          <ShoppingCart area={area} />
        </Route>
      </Switch>
    </MenuProvider>
  );
}
