import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import {
  getConsumerPreferences,
  ConsumerUpdatePreferences,
  ConsumerUpdate,
  getConsumer,
} from "../../Api.js";
import T from "i18n-react";
import { useAuth0 } from "../../yoello-lib/modules/auth/Auth0";
import IConsumerUser from "../../yoello-lib/modules/auth/IConsumerUser";
import { useSnackbarMessages } from "../../yoello-lib/modules/components/snackbar/SnackbarContext";
import { VENUE } from "../../constants/URL.const";

interface IAccountView {
  isAuthenticated: boolean;
  User: IConsumerUser;
}
export interface IUpdateNotificationSettings {
  email?: string;
  notificationMethod: string;
}
export type PreferencesDTO = {
  marketing_email: boolean;
  marketing_sms: boolean;
  notify_by_email: boolean;
  notify_by_sms: boolean;
};

const useAccountView = ({ isAuthenticated, User }: IAccountView) => {
  const location = useLocation();
  const history = useHistory();
  const { getAuthUser, logout, retrieveToken } = useAuth0();
  const [authUser, setAuthUser] = useState<auth0.Auth0UserProfile>();
  const [updateUser, setUpdateUserInfo] = useState<boolean>(false);
  const [consumer, setUpdatedConsumer] = useState<IConsumerUser | undefined>(
    undefined
  );
  const [preferences, setPreferences] = useState<PreferencesDTO>();
  const [isNotificationDrawerOpen, setIsNotificationDrawerOpen] = useState(
    false
  );
  const { sendSnackbarMessage } = useSnackbarMessages();
  const [isLoading, setIsLoading] = useState(true);

  const closePage = () => {
    //@ts-ignore
    history.push(location?.state?.from || VENUE, {
      from: location.pathname,
      menuOpen: true,
    });
  };

  const openNotificationDrawer = () => {
    setIsNotificationDrawerOpen(true);
  };

  const closeNotificationDrawer = () => {
    setIsNotificationDrawerOpen(false);
    setUpdateUserInfo(false);
  };

  const updateNotificationSettings = async ({
    notificationMethod,
    email,
  }: IUpdateNotificationSettings) => {
    const updatedPreferences = {
      ...preferences,
      notify_by_sms: notificationMethod === "sms",
      notify_by_email: notificationMethod === "email",
    };

    const token = await retrieveToken();
    if (email) {
      try {
        await ConsumerUpdate({ contact_email: email }, token);
        setUpdateUserInfo(true);
      } catch (e) {
        sendSnackbarMessage(
          T.translate("Login.Errors.CouldntUpdateUser").toString(),
          "error"
        );
      }
    }
    try {
      await ConsumerUpdatePreferences(updatedPreferences, token);
      setPreferences(updatedPreferences);
      closeNotificationDrawer();
    } catch (e) {
      sendSnackbarMessage(
        T.translate("Account.Errors.CouldntUpdatePreferences").toString(),
        "error"
      );
    }
  };

  useEffect(() => {
    if (isAuthenticated && User) {
      retrieveToken()
        .then((token) => {
          getConsumerPreferences(token)
            .then((response) => {
              setPreferences(response.data.data);
              getAuthUser(token)
                .then((user) => {
                  setAuthUser(user);
                })
                .catch(() => {
                  sendSnackbarMessage(
                    T.translate("Account.Errors.NoAuthUser").toString(),
                    "error"
                  );
                  closePage();
                });
            })
            .catch(() => {
              sendSnackbarMessage(
                T.translate("Account.Errors.NoPreferences").toString(),
                "error"
              );
              closePage();
            })
            .finally(() => {
              setIsLoading(false);
            });
        })
        .catch(() => {
          sendSnackbarMessage(
            T.translate("Account.Errors.NoToken").toString(),
            "error"
          );
          logout();
        });
    }
    //eslint-disable-next-line
  }, [isAuthenticated, User]);

  useEffect(() => {
    if (!isAuthenticated) {
      logout();
    }
    //eslint-disable-next-line
  }, [isAuthenticated]);
  useEffect(() => {
    if (updateUser) {
      retrieveToken().then((token) => {
        getConsumer(token)
          .then((response) => {
            setUpdatedConsumer(response.data.data);
          })
          .catch(() => {
            sendSnackbarMessage(
              T.translate("Account.Errors.NoAuthUser").toString(),
              "error"
            );
            closePage();
          });
      });
    }
    //eslint-disable-next-line
  }, [updateUser]);

  return {
    isLoading,
    isNotificationDrawerOpen,
    authUser,
    preferences,
    logout,
    closePage,
    openNotificationDrawer,
    closeNotificationDrawer,
    updateNotificationSettings,
    consumer,
  };
};

export default useAccountView;
