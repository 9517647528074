import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { usePaymentState } from "../PaymentStore";
import { setTotalPriceOverride } from "../reducer/PaymentActions";

import useCurrency from "../../../yoello-lib/modules/localisation/useCurrency";

import BillLoading from "./BillLoading";
import BillList from "./BillList";
import { IBillItem } from "./BillItem";

import { TotalPriceWidget } from "./TotalPriceWidget";

import useSquareCalculateOrder from "../../../customHooks/Square/useSquareCalculateOrder";
import Styles from "../styles.module.scss";

const SquarePricing = () => {
  const { tipTotal, totalPrice, dispatch } = usePaymentState();
  const Venue = useSelector((state: any) => state.Venue);
  const { isCalculatingOrder } = useSquareCalculateOrder(calculateComplete);
  const [billData, setBillData] = useState(null);
  const [billItems, setBillItems] = useState<IBillItem[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { formatCurrency } = useCurrency();
  const totalPriceProps = {
    total: { ...billItems[billItems.length - 1] },
    onClick: () => setIsOpen(!isOpen),
    isOpen: isOpen,
  };
  const generateBillItems = () => {
    if (!billData) return [];
    setBillItems([
      {
        id: "item-price",
        titleKey: "Payment.ItemsPrice",
        className: Styles.BillSummarySectionDivs,
        value: formatCurrency(billData.sub_total),
      },
      {
        id: "discount",
        titleKey: "Payment.Discount",
        className: Styles.Discount,
        value: `-${formatCurrency(billData.discount_total)}`,
        hidden: billData.discount_total === 0,
      },
      {
        id: "tip-total",
        titleKey: "Payment.TipPrice",
        className: Styles.BillSummarySectionDivs,
        value: formatCurrency(tipTotal),
        hidden: !Venue?.tipping_enabled,
      },
      {
        id: "delivery",
        titleKey: "Payment.Delivery",
        className: Styles.BillSummarySectionDivs,
        value: formatCurrency(billData.delivery_charge_total),
        hidden: billData.delivery_charge_total === 0,
      },
      {
        id: "service-charge",
        titleKey: "Payment.ServiceCharge",
        className: Styles.BillSummarySectionDivs,
        value: formatCurrency(billData.service_total),
      },
      {
        id: "taxes",
        titleKey: "Payment.Taxes",
        className: Styles.BillSummarySectionDivs,
        value: formatCurrency(billData.tax_total),
      },
      {
        id: "total",
        titleKey: "Payment.TotalPay",
        className: Styles.TotalPriceDiv,
        value: formatCurrency(billData.order_total + tipTotal),
      },
    ]);
  };
  function calculateComplete(response) {
    if (!response) return;
    if (response.error) {
      //@TODO handle errors
    } else if (response.order) {
      setBillData(response.order);
      dispatch(setTotalPriceOverride(response.order.order_total));
    }
  }

  useEffect(() => {
    generateBillItems();
    //eslint-disable-next-line
  }, [billData, tipTotal, totalPrice]);

  return isCalculatingOrder ? (
    <BillLoading />
  ) : (
    <div className={Styles.PricingWrapper}>
      <TotalPriceWidget {...totalPriceProps} />
      <BillList items={billItems} isOpen={isOpen} />
    </div>
  );
};

export default SquarePricing;
