import { useEffect, useState } from "react";
import history from "../../utils/history";

interface IUnmountDrawerWrapperProps {
  children: JSX.Element;
  closeDrawer: Function;
}

/**Wrapper to stop the back button navigating  */
function UnmountDrawerWrapper({
  children,
  closeDrawer,
}: IUnmountDrawerWrapperProps) {
  const [windowlocal] = useState(window.location.pathname);

  useEffect(() => {
    /**Handles the back navigation */
    history.push(windowlocal, { noLoading: true });
    window.addEventListener("popstate", (event) => {
      closeDrawer();
    });
    return () => {
      window.removeEventListener("popstate", () => null);
    };
    // eslint-disable-next-line
  }, []);
  return children;
}

export default UnmountDrawerWrapper;
