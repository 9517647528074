import React from "react";
import T from "i18n-react";
import { makeStyles } from "@material-ui/core";

interface Props {
  min: number;
  max: number;
  internalName: string;
}

const useStyles = makeStyles({
  root: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "0.6875rem",
    color: "#757575",
    // padding: "0.5rem 0.8rem",
    marginLeft: "0.9375rem",
    marginBottom: "0.4375rem",
    padding: "0 1.5rem",
  },
});

const ExtrasQuantityTitle = ({ min, max, internalName }: Props) => {
  const classes = useStyles();

  if (!min && !max) {
    return null;
  } else if (max === 0 || !max) {
    return (
      <p className={classes.root}  id={internalName}>
        {T.translate("Menu.MinText")}{" "}
        <strong data-component-name={"extraMin"}>{min}</strong>{" "}
        {min > 1 ? T.translate("Menu.Items") : T.translate("Menu.Item")}:
      </p>
    );
  } else if (min === 0 || !min) {
    return (
      <p className={classes.root}>
        {T.translate("Menu.MaxText")} <strong>{max}</strong>{" "}
        {max > 1 ? T.translate("Menu.Items") : T.translate("Menu.Item")}:
      </p>
    );
  } else if (min && max) {
    return (
      <p className={classes.root} id={internalName}>
        {T.translate("Menu.MinMaxTextPartOne")}{" "}
        <strong data-component-name={"extraMin"}>{min}</strong>
        {min > 1 ? T.translate("Menu.Items") : T.translate("Menu.Item")}
        {T.translate("Menu.MinMaxTextPartTwo")} <strong>{max}</strong>
        {max > 1 ? T.translate("Menu.Items") : T.translate("Menu.Item")}:
      </p>
    );
  } else return null;
};

export default ExtrasQuantityTitle;
