import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import T from "i18n-react";

import {
  LOGINV2_VERIFY,
  // LOGINV2_EMAIL_SENT,
} from "../../../constants/URL.const";
import useGenerateNavPath from "../../../customHooks/useGenerateNavPath";
import { useAuth0 } from "../../../yoello-lib/modules/auth/Auth0";
import { useSnackbarMessages } from "../../../yoello-lib/modules/components/snackbar/SnackbarContext";
import { VerificationMethod } from "../../../constants/verificationmethod";
import {
  countryPhoneRegex,
  formatPhoneNumber,
} from "../../../utils/PhoneNumberFormatting";
import { LoginStorage } from "../../../constants/storageVars.const";
import { diallingCodes } from "../../../constants/countryCodes.const";

const checkVerificationMethod = (internationalCode: string, value: string) => {
  const safeValue = value || "";
  const smsRegex =
    countryPhoneRegex[internationalCode] || countryPhoneRegex["GENERIC"];
  return safeValue.match(smsRegex)
    ? VerificationMethod.SMS
    : safeValue.trim().match(/^[^\s@]+@[^\s@.]+\.[^\s@]+$/)
    ? VerificationMethod.EMAIL
    : VerificationMethod.INVALID;
};

const useLoginView = () => {
  const history = useHistory();
  const location = useLocation<locationState>();
  const { passwordlessLogin, isRetrievingUser } = useAuth0()!;
  const { generateNavPath } = useGenerateNavPath();
  const { sendSnackbarMessage } = useSnackbarMessages();
  const [isLoading, setIsLoading] = useState(false);
  const [internationalCode, setInternationalCode] = useState<string>("+44");
  const [verificationMethod, setVerificationMethod] = useState<
    VerificationMethod
  >(VerificationMethod.INVALID);
  const [countryCode, setCountryCode] = useState<string>("GB");

  const [username, setUsername] = useState<string>();

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cleanedValue = e.currentTarget.value.replace(/\s/g, "");
    setUsername(cleanedValue);
    setVerificationMethod(
      checkVerificationMethod(internationalCode, cleanedValue)
    );
  };

  const clearUsername = () => {
    setUsername(undefined);
  };

  const updateInternationalCode = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const countryCode = e.target.value;
    setCountryCode(countryCode);
    const dialingCode = diallingCodes.find((item) => item.code === countryCode)
      .dial_code;
    localStorage.setItem("internationalCode", dialingCode);
    localStorage.setItem("countryCode", countryCode);
    setInternationalCode(dialingCode);
    setVerificationMethod(checkVerificationMethod(dialingCode, username));
  };

  const signinViaPasswordless = async (
    verificationMethod: VerificationMethod,
    username: string
  ) => {
    const isEmail = verificationMethod === VerificationMethod.EMAIL;

    switch (verificationMethod) {
      case VerificationMethod.SMS:
        username = formatPhoneNumber(internationalCode, username).trim();
        break;
      case VerificationMethod.EMAIL:
        username = username.toLowerCase().trim();
        break;
      case VerificationMethod.INVALID:
        return null;
    }

    setIsLoading(true);

    try {
      await passwordlessLogin(username, verificationMethod);
    } catch (e) {
      sendSnackbarMessage(
        T.translate(
          !isEmail
            ? "Login.Errors.FailedPasswordlessSms"
            : "Login.Errors.FailedPasswordlessEmail"
        ).toString(),
        "error"
      );
      return null;
    } finally {
      setIsLoading(false);
    }

    let path = generateNavPath(LOGINV2_VERIFY, {
      method: verificationMethod,
      username: username,
    });

    if (isEmail) {
      if (location?.state?.to) {
        localStorage.setItem(LoginStorage.LOCATION_TO, location?.state?.to);
      }
      if (location?.state?.from) {
        localStorage.setItem(LoginStorage.LOCATION_FROM, location?.state?.from);
      }

      localStorage.setItem(LoginStorage.USERNAME, username);
    }
    history.push(path);
  };

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    let loginUsername = username;
    signinViaPasswordless(verificationMethod, loginUsername);
  };

  useEffect(() => {
    const storedCode = localStorage.getItem("internationalCode");
    const storedCountryCode = localStorage.getItem("countryCode");
    if (storedCode) {
      setInternationalCode(storedCode);
      setCountryCode(
        storedCountryCode ??
          diallingCodes.find((item) => item.dial_code === storedCode).code
      );
    }
  }, []);

  return {
    onInputChange,
    onSubmit,
    signinViaPasswordless,
    verificationMethod,
    internationalCode,
    updateInternationalCode,
    isRetrievingUser,
    isLoadingLogin: isLoading,
    countryCode,
    username,
    clearUsername,
  };
};

export default useLoginView;
