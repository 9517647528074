import React from "react";
import { useSelector } from "react-redux";
import Styles from "../styles.module.scss";
import T from "i18n-react";
import useCurrency from "../../../yoello-lib/modules/localisation/useCurrency";

interface IVenueThresholdProps {
  area: boolean;
}

export function VenueThreshold({ area }: IVenueThresholdProps) {
  const Group = useSelector((state: any) => state.Group);

  return Group && Group?.group_abv_food_threshold && area ? (
    <div className={Styles.AlcoholTextDiv}>
      <div>
        <span className="consumer-info-circle"></span>{" "}
        <span>{T.translate("Venue.Alcohol")}</span>
      </div>
      <p className={Styles.AlcoholText}>
        <AlcoholWarningText
          threshold={this.props.Group?.group_abv_food_threshold}
        />
      </p>
    </div>
  ) : null;
}

const AlcoholWarningText = (threshold) => {
  const { formatCurrency } = useCurrency();
  const formattedThreshold = formatCurrency(threshold.threshold);
  return (
    <p className="ExtraInfoText">
      <strong>{T.translate("Venue.Alcohol")}:</strong>{" "}
      {T.translate("Venue.AlcoholWarningText", {
        amount: formattedThreshold,
      })}
    </p>
  );
};
