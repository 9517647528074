import React from "react";
import { useSelector } from "react-redux";
import { useGetLastOrder } from "../../../customHooks/useGetLastOrder";
import Button from "../../Widgets/Button";
import Styles from "../../ShopList/shopList.module.scss";
import { useHistory } from "react-router-dom";
import { CART } from "../../../constants/URL.const";
import { useMenu } from "../MenuContext";

interface IProps {
  checkoutBtnLabel: string;
}

export function CartButtons({ checkoutBtnLabel }: IProps) {
  const cartItems = useSelector((state: any) => state.Cart);
  const { lastOrder, addOrderToCart } = useGetLastOrder();
  const history = useHistory();
  const {
    menuItemState,
    state: { cartCount, cartAmount },
    formatCurrency,
  } = useMenu();

  function onClick() {
    const path = history.location.pathname.split("/");
    const lastPath = path[path.length - 1];
    if (cartItems.length > 0 && lastPath !== "cart") {
      goCart();
    }
  }

  function goCart() {
    history.push(history.location.pathname + CART);
  }

  if (!menuItemState) return null;

  return lastOrder && cartItems.length === 0 ? (
    <Button
      title={"RepeatOrder"}
      iconClassName={"consumer-buy"}
      dataComponentName="RepeatOrderButton"
      onClick={(e) => {
        const isAllItems = addOrderToCart();
        if (isAllItems) {
          goCart();
        }
      }}
      className={Styles.repeatBtn}
    />
  ) : (
    <div className={Styles.ActiveShoppingBtn}>
      {cartCount !== 0 && (
        <span className={Styles.CartCountIcon}>{cartCount}</span>
      )}
      <Button
        title={checkoutBtnLabel}
        className={
          cartItems.length > 0 ? Styles.checkoutBtn : Styles.emptyCheckoutBtn
        }
        iconClassName={"consumer-buy"}
        dataComponentName="CheckoutButton"
        titleVariables={{
          cartAmount: cartAmount && formatCurrency(cartAmount),
        }}
        onClick={onClick}
      />
    </div>
  );
}
