import React from "react";
import { useMenu } from "../MenuContext";
import { setMenuTab } from "../state/Menu.Actions";

export function SearchTab() {
  const {
    dispatch,
    state: { menuPosition },
  } = useMenu();

  function onSelectSearch() {
    dispatch(setMenuTab(-1));
  }

  return (
    <div
      className={`Flex AlignCenter JustCenter ${
        menuPosition === -1 ? "actived" : ""
      }`}
      onClick={onSelectSearch}
      data-component-name="search_trigger"
    >
      <span className="MenuSearchIcon consumer-search" />
    </div>
  );
}
