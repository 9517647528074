import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useGetPaymentProcessor = () => {
  const venue = useSelector((state: any) => state.Venue);
  const [paymentProcessor, setPaymentProcessor] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (venue) {
      setIsLoading(false);
      setPaymentProcessor(venue.payment_processor);
    }
  }, [venue]);

  return {
    isLoading,
    paymentProcessor,
  };
};

export default useGetPaymentProcessor;
