import axios from "./api/index";
// import SessionClient from './yoello-lib/modules/auth/SessionClient';
// const URL = "https://4fml0pfy6f.execute-api.eu-west-1.amazonaws.com/dev/"
// const URL = "https://toberql1k8.execute-api.eu-west-1.amazonaws.com/dev/"
// let backendHost;
export const hostname = window && window.location && window.location.hostname;
// if (process.env.REACT_APP_STAGE === 'dev') {
//     backendHost = "dev-pay";
// } else if (process.env.REACT_APP_STAGE === 'stage') {
//     backendHost = "staging-pay";
// } else if (process.env.REACT_APP_STAGE === 'prod') {
//     backendHost = "pay";
// } else if (hostname === "localhost") {
//     backendHost = "dev-pay";
// }

// export const URL = "https://capi.yllo.uk/" + backendHost + "/";
// export const URL = `https://${backendHost}.execute-api.eu-west-1.amazonaws.com/${endpointEnd}/`;

// export const Auth0_domain = domain;
// export const Auth0_client_id = client_id;
// export const Auth0_audience = audience;

function createHeaders(token) {
  return {
    "Content-type": "application/x-www-form-urlencoded",
    Authorization: token ? "Bearer " + token : undefined,
    // "yoello-session-id": SessionClient.sessionId
  };
}

export const GetVenues = (offset, limit) => {
  return axios({
    method: "GET",
    url:
      "venues" +
      (offset !== null ? "?offset=" + offset : "") +
      (limit ? "&limit=" + limit : ""),
    headers: createHeaders(),
  });
};

export const GetGroupVenues = (groupName) => {
  return axios({
    method: "GET",
    url: "venue-groups/" + groupName,
    headers: createHeaders(),
  });
};

export const GetVenueInfo = (id) => {
  return axios({
    method: "GET",
    url: "venues/" + id,
    headers: createHeaders(),
  });
};

export const GetMenuItems = (venue_id, group_id, orderType) => {
  return axios({
    method: "GET",
    url:
      "menu-items/" +
      venue_id +
      "?" +
      `${orderType ? "order_type=" + orderType + "&" : ""}` +
      `${group_id ? "group_id=" + group_id + "&" : ""}`,
    headers: createHeaders(),
  });
};
export const GetMenuItemsSubcategories = (venue_id) => {
  return axios({
    method: "GET",
    url: "menu-item-subcategories/" + venue_id,
    headers: createHeaders(),
  });
};

export const LoginSendSms = (data) => {
  return axios({
    method: "post",
    url: "https://yoello-dev.eu.auth0.com/passwordless/start",
    data: data,
    headers: createHeaders(),
  });
};

export const LoginConfirmSmsCode = (data) => {
  return axios({
    method: "post",
    url: "https://yoello-dev.eu.auth0.com/oauth/token",
    data: data,
    headers: createHeaders(),
  });
};

export const ConsumerCreate = (data, token) => {
  return axios({
    method: "post",
    url: "consumer",
    data: data,
    headers: createHeaders(token),
  });
};

export const ConsumerUpdate = (data, token) => {
  return axios({
    method: "put",
    url: "consumer",
    data: data,
    headers: createHeaders(token),
  });
};

export const getConsumer = (token) => {
  return axios({
    method: "get",
    url: "consumer",
    headers: createHeaders(token),
  });
};

export const CreatePaymentDetails = (data, token, updatePaymentDetails) => {
  // venue id is being sent inside data
  return axios({
    method: updatePaymentDetails ? "put" : "post",
    url: "payment-details",
    data: data,
    headers: createHeaders(token),
  });
};

export const getPaymentDetails = (token) => {
  return axios({
    method: "get",
    url: "payment-details",
    headers: createHeaders(token),
  });
};
export const CreateOrder = (data, token, venueId) => {
  return axios({
    method: "post",
    url: "place-order",
    data: data,
    headers: createHeaders(token),
  });
};

export const getOrderHistoryList = (token) => {
  return axios({
    method: "get",
    url: "orders",
    headers: createHeaders(token),
  });
};

export const getOrderHistoryView = (token, id) => {
  return axios({
    method: "get",
    url: "orders/" + id,
    headers: createHeaders(token),
  });
};

export const deleteUser = (token) => {
  return axios({
    method: "delete",
    url: "data-cleanup",
    headers: createHeaders(token),
  });
};

export const getGoogleMapDistance = (VenueAdress, CustomerAddress) => {
  return axios({
    method: "get",
    url: `https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&origins=${VenueAdress}&destinations=${CustomerAddress}&key=AIzaSyAVh852Ll0UcYgz9eIH7s35FQje47qj48U`,
    headers: {
      "Content-type": "application/x-www-form-urlencoded",
    },
  });
};

export const getConsumerPreferences = (token) => {
  return axios({
    method: "get",
    url: "consumer_preferences",
    headers: createHeaders(token),
  });
};

export const ConsumerUpdatePreferences = (data, token) => {
  return axios({
    method: "PATCH",
    url: "consumer_preferences",
    data: data,
    headers: createHeaders(token),
  });
};

export const getVenueTimeSlotsList = (token, venue_nickname, order_type) => {
  return axios({
    method: "get",
    url: "venues/" + venue_nickname + "/time_slots?order_type=" + order_type,
    headers: createHeaders(token),
  });
};

export const getPlacedOrderPaymentIntent = (token, placed_order_id) => {
  return axios({
    method: "get",
    url: "place-order-payment-intent/" + placed_order_id,
    headers: createHeaders(token),
  });
};
export const getDiscountsCodeInfo = (token, data) => {
  return axios({
    method: "post",
    url: "/discounts/validate",
    data: JSON.stringify(data),
    headers: {
      "Content-type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + token,
    },
  });
};

export const getVenueTippingOptions = (token, venue_nickname) => {
  return axios({
    method: "get",
    url: "venues/" + venue_nickname + "/tipping_options",
    headers: createHeaders(token),
  });
};
