import React from "react";
import ShareWidget from "../ShareWidget/ShareWidget";
import Styles from "./shareBox.module.scss";
interface IShareBox {
  title: string;
}
const ShareBox = ({ title }: IShareBox) => {
  const shareWidgetProps = {
    title: title,
    shareOnVenueAndGroupPage: true,
  };
  return (
    <div className={Styles.shareBoxContainer}>
      <div className={Styles.lineBar}>
        <span></span>
      </div>
      <div className={Styles.ShareWrapper}>
        <ShareWidget {...shareWidgetProps} />
      </div>
    </div>
  );
};

export default ShareBox;
