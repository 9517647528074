import { useEffect, useContext } from "react";
import { useSelector } from "react-redux";

import { getVenueTimeSlotsList } from "../../../Api.js";

import { PaymentContext } from "../PaymentStore";
import { SelectedOrderType } from "../types.d";
import { CARDIFF_CASTLE } from "../../../constants";
import {
  setDeliveryPrice,
  addDeliveryTimes,
  setPostcodeCheckStatus,
  setDeliveryInclusiveTax,
  setDeliveryTax,
  setIsLoading,
} from "../reducer/PaymentActions";
import { checkPostCodeInDeliveryRadius } from "../../../utils/checkPostCodeInDeliveryRadius";
import { postcodeCheckStatusType } from "../../Widgets/PostCodeChecker/PostCodeChecker";
import { ITaxType } from "../../../yoello-lib/modules/interfaces/ITaxes";
import {
  calculateAdditiveTax,
  calculateInclusiveTax,
} from "../../../yoello-lib/modules/utils/TaxController";
import { useAuth0 } from "../../../yoello-lib/modules/auth/Auth0";

const useDelivery = (isAuthenticated) => {
  const { selectedOrderType, deliveryAddress, dispatch } = useContext(
    PaymentContext
  );
  const venue = useSelector((state: any) => state.Venue);
  const group = useSelector((state: any) => state.Group);

  const { retrieveToken } = useAuth0()!;

  useEffect(() => {
    setPriceForDelivery();
    setDeliveryTimes();
    // eslint-disable-next-line
  }, [venue, group, selectedOrderType, deliveryAddress, dispatch]);

  async function setDeliveryTimes() {
    if (
      venue &&
      selectedOrderType !== SelectedOrderType.TABLE &&
      isAuthenticated
    ) {
      try {
        if (!selectedOrderType) return;
        const token = await retrieveToken();
        const TimeSlotsData = await getVenueTimeSlotsList(
          token,
          venue.venue_nickname,
          selectedOrderType
        );
        let TimeSlotsList = TimeSlotsData.data.data.time_slots;
        dispatch(addDeliveryTimes(TimeSlotsList));
        // **** OLD WAY OF DOING IT FRONT_END ONLY ****
        // const day = moment().format("dddd").toLowerCase();
        // if ("trading_times" in Venue) {
        //   const tradingTimes = Venue ?.trading_times[day] || undefined;
        //   if (tradingTimes) {
        //     console.log("dispatching");
        //     console.log("====================================");
        //     console.log(
        //       generateTimeIntervalsTradingTimes(
        //         tradingTimes,
        //         SelectedMenuType === SelectedOrderType.DELIVERY
        //           ? Venue.delivery_max_lead_time
        //           : Venue.food_prep_time
        //       )
        //     );
        //   }
        // }
      } catch (error) {}
    }
  }

  const setPriceForDelivery = () => {
    if (
      (group?.group_service_charge_enabled &&
        group?.group_nickname === CARDIFF_CASTLE) ||
      selectedOrderType === SelectedOrderType.DELIVERY
    ) {
      let deliveryCharge = 0;
      let taxType;
      let taxPercentage = [];
      if (
        venue?.venue_fees_and_taxes &&
        venue?.venue_fees_and_taxes["DELIVERY"]
      ) {
        const delivery = venue?.venue_fees_and_taxes["DELIVERY"];
        deliveryCharge = delivery.fee_amount;
        for (const taxItems of delivery.taxes) {
          taxType = taxItems.tax_inclusion_type;
          taxPercentage.push(taxItems.tax_percentage);
        }
      } else if (venue?.delivery_charge) {
        deliveryCharge = venue.delivery_charge;
      }
      if (taxType === ITaxType.ADDITIVE) {
        const deliveryTax = taxPercentage.reduce((prevValue, nextItem) => {
          const calc = calculateAdditiveTax(nextItem, deliveryCharge);
          return prevValue + calc;
        }, 0);
        dispatch(setDeliveryTax(deliveryTax));
      } else {
        const deliveryTax = taxPercentage.reduce((prevValue, nextItem) => {
          const calc = calculateInclusiveTax(deliveryCharge, nextItem);
          return prevValue + calc;
        }, 0);
        dispatch(setDeliveryInclusiveTax(deliveryTax));
      }
      dispatch(setDeliveryPrice(deliveryCharge));
    }
  };

  const checkPostCodeRadius = async () => {
    // TO DO: move this logic into a reuseable component
    console.log("New Delivery :====================================");
    console.log(deliveryAddress);
    console.log("====================================");
    const userDeliveryAddress = `${venue.address_country}, ${deliveryAddress.address}, ${deliveryAddress.city}, ${deliveryAddress.postcode}`;
    if (venue.delivery_radius_km) {
      dispatch(setIsLoading(true));
      await checkPostCodeInDeliveryRadius(
        venue,
        userDeliveryAddress,
        venue.delivery_radius_km,
        (InRadius: any) => {
          if (InRadius) {
            dispatch(
              setPostcodeCheckStatus(postcodeCheckStatusType.InDeliveryRadius)
            );
          } else {
            dispatch(
              setPostcodeCheckStatus(
                postcodeCheckStatusType.OutOfDeliveryRadius
              )
            );
          }
          dispatch(setIsLoading(false));
        }
      );
    }
  };

  return {
    setPriceForDelivery,
    setDeliveryTimes,
    checkPostCodeRadius,
  };
};

export default useDelivery;
