import { useSelector } from "react-redux";
import { SelectedOrderType } from "../Components/Payment/types.d";

/** Venue label hash */
export function useVenueLabels() {
  const Venue = useSelector((state: any) => state.Venue);

  return {
    [SelectedOrderType.CLICK]: Venue.collection_label || "Venue.Collection",
    [SelectedOrderType.COLLECTION_POINT]:
      Venue.collection_point_pickup_lable || "Venue.pickupNow",
    [SelectedOrderType.DELIVERY]: Venue.delivery_label || "Venue.Delivery",
    [SelectedOrderType.TABLE]: Venue.dine_in_label || "Venue.TableService",
  };
}
