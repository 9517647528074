import React from "react";
import T from "i18n-react";
import { IBillItem } from "./BillItem";
import { ReactComponent as ArrowSVG } from "../../../imges/Arrow-Down.svg";
import Styles from "../styles.module.scss";

interface ITotalPriceWidget {
  total: IBillItem;
  onClick: () => void;
  isOpen: boolean;
}

export function TotalPriceWidget({
  total,
  onClick,
  isOpen,
}: ITotalPriceWidget) {
  return (
    <div className={Styles.TotalPriceWrapper} onClick={onClick}>
      <T.p text={{ key: total.titleKey }} />
      <div className={isOpen && Styles.rotateSVG}>
        <p data-component-name={total.id}>{total.value}</p>
        <ArrowSVG />
      </div>
    </div>
  );
}
