

/**Helper function to return back a locale
 * defaults to en-GB
 */
export function getBrowserLocales() {
    let locale = 'en-GB';
    if (navigator.languages) {
        const getFirstLang = navigator?.languages[0];
        if (getFirstLang && getFirstLang.length > 2) {
            locale = getFirstLang;
        }
    } else if (navigator.language) {
        const lang = navigator.language;
        if (lang.length > 2) {
            locale = lang;
        }
    }
    return locale;
}