import * as types from "../actions/ActionTypes";

/**Reducer used to handle the menu item list creation */
const MenuItemReducer = (state = {}, action) => {
  switch (action.type) {
    case types.setMenuItems:
      // if this is false it means there is another api that going to be called for Availabel Menus
      // in here just storing the required data from first API so it can be used once the second API hits this
      if (action.data.menusList === false) {
        return {
          apiData: action.data.menuItems ?? state.apiData,
          categories: action.data.categories ?? state.categories,
        };
      }
      const MenusData = Array.isArray(action.data.menusList)
        ? arrayToObject(action.data.menusList)
        : action.data.menusList;

      const mappedObj = mapCategoriesMenuItemData(
        action.data.categories ?? state.categories,
        action.data.menuItems ?? state.apiData,
        MenusData
      );
      return {
        ...mappedObj,
        apiData: action.data.menuItems ?? state.apiData,
        categories: action.data.categories ?? state.categories,
      };
    case types.resetMenuItems:
      return {};
    default:
      return { ...state };
  }
};
export default MenuItemReducer;

/**Maps the categories and sub categories kinda into a tree */
function mapCategoriesMenuItemData(categoryData, menuItems, menusList) {
  const catObj = arrayToObject(categoryData);
  const object = {};
  const rawMenuItems = [];
  const newSortedObject = {};
  if (menusList) {
    if (categoryData !== undefined && menuItems !== undefined) {
      /**
       * Loop through every item if they aren't an extra
       * check if the Menu is undefined then check if the Menu subcateogry is undefined
       * if so create the object then push the menu items else just push the item
       */
      for (const menuItem of menuItems) {
        /**Item is an extra so we don't want in the list */

        if (menuItem.menu_item_sold_as_extra_only === false) {
          menuItem.menu_ids.forEach((item) => {
            const categoryName = menusList[item]?.name_public;
            if (categoryName === undefined) {
              return;
            }
            const subCatName = menuItem.menu_item_subcategory_name;
            const subCatId = menuItem.menu_item_subcategory_id;
            if (object[categoryName] === undefined) {
              object[categoryName] = {
                sort_position: menusList[item].sort_position,
              };
              if (
                menusList[item]?.timings &&
                menusList[item]?.timings_enabled
              ) {
                object[categoryName].timings = menusList[item]?.timings;
              }
            }
            if (object[categoryName][subCatId] === undefined) {
              object[categoryName][subCatId] = {
                subcatName: subCatName,
                subcatPosition: catObj[subCatId].sort_position,
                menuItems: [],
                subcatDesc: catObj[subCatId].subcategory_desc,
              };
            }
            object[categoryName][subCatId].menuItems.push(menuItem);
          });
          rawMenuItems.push(menuItem);
        }
      }
      let list = object;
      const keysSorted = Object.keys(list).sort(function (a, b) {
        return list[a].sort_position - list[b].sort_position;
      });

      keysSorted.forEach((item) => {
        delete object[item].sort_position;
        newSortedObject[item] = object[item];
      });
    }
  } else {
    if (categoryData !== undefined && menuItems !== undefined) {
      /**
       * Loop through every item if they aren't an extra
       * check if the category is undefined then check if the catgory sub cateogry is undefined
       * if so create the object then push the menu items else just push the item
       */
      for (const menuItem of menuItems) {
        /**Item is an extra so we don't want in the list */
        if (menuItem.menu_item_sold_as_extra_only === false) {
          const categoryName = menuItem.menu_item_category_name;
          const subCatName = menuItem.menu_item_subcategory_name;
          const subCatId = menuItem.menu_item_subcategory_id;
          if (object[categoryName] === undefined) {
            object[categoryName] = {};
          }
          if (object[categoryName][subCatId] === undefined) {
            object[categoryName][subCatId] = {
              subcatName: subCatName,
              subcatPosition: catObj[subCatId].sort_position,
              menuItems: [],
              subcatDesc: catObj[subCatId].subcategory_desc,
            };
          }
          object[categoryName][subCatId].menuItems.push(menuItem);
          rawMenuItems.push(menuItem);
        }
      }
      const keysSorted = Object.keys(object).sort(function (a, b) {
        return a === "DRINK" ? -1 : b === "DRINK" ? 1 : 0;
      });

      keysSorted.forEach((item) => {
        newSortedObject[item] = object[item];
      });
    }
  }

  const sortedObject = {};
  let index1 = 0;
  /**Sorts the sub categories */
  Object.keys(newSortedObject).forEach((treeKeys) => {
    sortedObject[treeKeys] = { startIndex: index1, subCats: {} };
    if (newSortedObject[treeKeys]?.timings) {
      sortedObject[treeKeys].timings = newSortedObject[treeKeys]?.timings;
      delete newSortedObject[treeKeys].timings;
    }
    const subCats = newSortedObject[treeKeys];
    Object.keys(subCats)
      .sort((a, b) => {
        const aObj = subCats[a].subcatPosition;
        const bObj = subCats[b].subcatPosition;
        if (aObj < bObj) {
          return -1;
        }
        if (aObj > bObj) {
          return 1;
        }
        return 0;
      })
      .forEach((key) => {
        sortedObject[treeKeys] = {
          ...sortedObject[treeKeys],
          subCats: {
            ...sortedObject[treeKeys].subCats,
            [key]: { ...newSortedObject[treeKeys][key], index: index1 },
          },
        };
        index1++;
      });
    sortedObject[treeKeys] = {
      ...sortedObject[treeKeys],
      endIndex: index1 - 1,
    };
  });
  return {
    tree: sortedObject,
    raw: rawMenuItems,
  };
}

const arrayToObject = (array) => {
  return array.reduce((item, newItem) => {
    return {
      ...item,
      [newItem.id]: newItem,
    };
  }, {});
};
