import React, { useMemo } from "react";
import { useMenu } from "../MenuContext";
import { setMenuTab } from "../state/Menu.Actions";
import { SearchTab } from "./SearchTab";
import { Tabs } from "antd";
import { useTabScroll } from "./useTabScroll";
import Styles from "../styles.module.scss";
import { BackButton } from "../../NavigationHeader/BackButton";
const { TabPane } = Tabs;

export function TopTabs() {
  const {
    dispatch,
    state: { menuPosition, isScrolled },
    menuItemState,
    goBack,
  } = useMenu();
  const ref = useTabScroll(menuPosition, []);

  const tabs = useMemo(() => {
    const tabs = [];
    if (!menuItemState) return tabs;
    const keys = Object.keys(menuItemState);
    for (let i = 0; i < keys.length; i++) {
      tabs.push(
        <TabPane
          tab={
            <span
              data-component-name="CategoryTab"
              data-category-tab={keys[i]}
              className="tabs"
              ref={(el) => (ref.current[i] = el)}
            >
              {keys[i].toUpperCase()}
            </span>
          }
          key={i.toString()}
        />
      );
    }
    return tabs;
    //eslint-disable-next-line
  }, [menuItemState]);

  function onChangeTab(activeKey: string) {
    dispatch(setMenuTab(parseInt(activeKey)));
  }

  function generateStyles() {
    if (tabs.length <= 1) {
      /**Forces one element into the middle */
      const style = {
        position: "absolute",
        zIndex: "-1",
      };
      return style;
    }
    return {};
  }
  return (
    <div className={Styles.TopTabs}>
      {isScrolled && <BackButton onClick={goBack} />}
      <SearchTab />
      <Tabs
        className="topMenuTabs"
        activeKey={`${menuPosition}`}
        onChange={onChangeTab}
        style={{
          ...generateStyles(),
        }}
      >
        {tabs}
      </Tabs>
    </div>
  );
}
