export const checkAbvContainsFood = (shoppingCart, limit) => {
  // check if the shopping cart contains a product with ABV above 0
  const hasAbvProduct = shoppingCart.find((product) => {
    return (
      product.menuItem.menu_item_category_name === "DRINK" &&
      parseInt(product.menuItem.menu_item_abv) > 0
    );
  });

  if (hasAbvProduct) {
    let FoodPrice = 0;
    // check if the cart has a food item and add their prices to FoodPrice
    shoppingCart.forEach((product) => {
      if (product.menuItem.menu_item_category_name === "FOOD") {
        FoodPrice = FoodPrice + product.finalPrice;
      }
    });

    if (parseInt(FoodPrice) < limit) {
      // if prices are under the specific amount then return false
      return false;
    }
  }
  return true;
};
