import React from "react";
import { useAuth0 } from "../../yoello-lib/modules/auth/Auth0";
import IConsumerUser from "../../yoello-lib/modules/auth/IConsumerUser";
import { loggedInListItems, sharedListItems } from "./SideMenuLists";
import Styles from "./styles.module.scss";
import { ReactComponent as LogoutSVG } from "../../imges/Logout.svg";
import T from "i18n-react";
import { SideMenuEntity } from "./SideMenuEntity";
import { useHistory, useLocation } from "react-router-dom";
import { LOGIN, ORDERS, ACCOUNT, LOGINV2 } from "../../constants/URL.const";
import Button from "../Widgets/Button";
import { useDrawer } from "../Drawer/DrawerContext";
import Support from "../Widgets/Support";
import { useSnackbarMessages } from "../../yoello-lib/modules/components/snackbar/SnackbarContext";
import useGenerateNavPath from "../../customHooks/useGenerateNavPath";

export function SideMenuContent({ openSettingsPage, closeSideDrawer }) {
  const { isAuthenticated, user, logout } = useAuth0()!;
  const { openDrawer, closeDrawer } = useDrawer();
  const { sendSnackbarMessage } = useSnackbarMessages()!;
  const { generateNavPath } = useGenerateNavPath();
  const history = useHistory();
  const location = useLocation();

  const onClickFuncs = {
    Support: openSupportDrawer,
    MyAccount: openAccountPage,
    Orderhistory: openHistoryPage,
  };

  function renderUserFirstName() {
    const userObj = user as IConsumerUser;
    if (userObj) {
      return userObj.first_name;
    } else return "User";
  }

  function openSupportDrawer() {
    openDrawer(
      Support as React.ReactNode,
      {
        onCloseDrawer: closeDrawer,
      },
      "bottom",
      "SupportDrawer"
    );
  }

  function openAccountPage() {
    closeSideDrawer();
    const userObj = user as IConsumerUser;
    if (!userObj?.user_details_set) {
      sendSnackbarMessage(
        T.translate("Login.Errors.accountNotExist").toString(),
        "error"
      );
      return;
    }
    history.push(ACCOUNT, {
      from: location.pathname,
    });
  }

  function openHistoryPage() {
    closeSideDrawer();
    history.push(ORDERS);
  }

  function renderContent() {
    if (isAuthenticated) {
      return (
        <div>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "stretch",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: "1.6rem",
              }}
            >
              <div>
                <T.p
                  className={Styles.UserName}
                  text={{ key: `SideMenu.Hello` }}
                />
                <p className={Styles.UserName}>
                  <strong>{renderUserFirstName()}</strong>
                </p>
              </div>
              <div
                className="Flex AlignCenter mt-auto"
                data-component-name={"SignOutButton"}
              >
                <LogoutSVG width="18px" height="18px" />
                <T.p
                  onClick={() => logout()}
                  className={Styles.SignOut}
                  text={{ key: `SideMenu.SignOut` }}
                />
              </div>
            </div>

            {loggedInListItems.map((item: any, i) => {
              if (item?.url) {
                return (
                  <a
                    key={i}
                    rel="noopener noreferrer"
                    href={item.url}
                    target="_blank"
                  >
                    {" "}
                    <SideMenuEntity
                      key={i.toString()}
                      keyString={i.toString()}
                      {...item}
                    />
                  </a>
                );
              } else {
                return (
                  <SideMenuEntity
                    key={i.toString()}
                    keyString={i.toString()}
                    {...item}
                    onClick={onClickFuncs[item.name]}
                  />
                );
              }
            })}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div>
            <T.p
              className={Styles.ScanTitleText}
              text={{ key: `SideMenu.ScanOrderPay` }}
            />
            <T.p
              className={Styles.SignInText}
              text={{ key: `SideMenu.SignInText` }}
            />
          </div>
          <div>
            <Button
              className={Styles.SignInButton}
              dataComponentName="SignInButton"
              isYoelloBtn
              onClick={() => {
                if (location.pathname.includes(LOGIN)) {
                  closeSideDrawer();
                  return;
                }
                history.push(
                  generateNavPath(LOGINV2, {
                    //quick fix for caching issue
                    v: "v2",
                  }),
                  {
                    from: location.pathname,
                    to: location.pathname,
                    title: "Login.Texts.lets",
                  }
                );
              }}
              title="SignIn"
            />
          </div>
          <div>
            {sharedListItems.map((item, i) => {
              if (item.url) {
                return (
                  <a
                    key={i}
                    rel="noopener noreferrer"
                    href={item.url}
                    target="_blank"
                  >
                    {" "}
                    <SideMenuEntity keyString={i.toString()} {...item} />
                  </a>
                );
              } else {
                return (
                  <SideMenuEntity
                    keyString={i.toString()}
                    {...item}
                    onClick={onClickFuncs[item.name]}
                  />
                );
              }
            })}
          </div>
        </div>
      );
    }
  }

  return <div className={Styles.Content}>{renderContent()}</div>;
}
