import { useAuth0 } from "../yoello-lib/modules/auth/Auth0";
import IConsumerUser from "../yoello-lib/modules/auth/IConsumerUser";
import * as Sentry from "@sentry/browser";
import SessionClient from "../yoello-lib/modules/auth/SessionClient";
import { useSelector } from "react-redux";

export function useSentry() {
  const { user } = useAuth0()!;
  const venue = useSelector((state: any) => state.Veneu);

  /**Capture sentry exception pass through error object & optional tags arrays */
  function captureException(error: Error, tags?: Record<string, string>) {
    const loggedUser = user as IConsumerUser;
    Sentry.captureException(error, (scope) => {
      if (loggedUser) {
        scope.setUser({
          username: loggedUser.contact_phone || loggedUser.contact_email,
          email: loggedUser.contact_email,
        });
      }
      /**Add custom tags */
      if (tags) {
        for (const key in tags) {
          scope.setTag(key, tags[key]);
        }
      }
      if (venue) {
        scope.setTag("venue-id", venue.id);
        scope.setTag("venue-nickname", venue.venue_nickname);
      }
      if (SessionClient) {
        scope.setTag("session-id", SessionClient.sessionId);
      }
      return scope;
    });
  }
  return {
    captureException,
  };
}
