import React from "react";
import ContentLoader from "react-content-loader";
import Styles from "./orderHistoryListViewSkeleton.module.scss";

export const OrderHistoryListViewSkeleton = () => {
  return (
    <div className={Styles.orderList}>
      <div>
        <ContentLoader>
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
        </ContentLoader>
      </div>
      <div>
        <ContentLoader>
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
        </ContentLoader>
      </div>
      <div>
        <ContentLoader>
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
        </ContentLoader>
      </div>
      <div>
        <ContentLoader>
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
        </ContentLoader>
      </div>
      <div>
        <ContentLoader>
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
        </ContentLoader>
      </div>
      <div>
        <ContentLoader>
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
        </ContentLoader>
      </div>
      <div>
        <ContentLoader>
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
        </ContentLoader>
      </div>
    </div>
  );
};

export default OrderHistoryListViewSkeleton;
