import React from "react";
import { makeStyles, FormControlLabel, Checkbox } from "@material-ui/core";

import T from "i18n-react";

import EditButton from "../../../../ProductDetails/EditButton/EditButton";

import {
  LocationOnOutlined,
  CreditCardOutlined,
  CalendarTodayOutlined,
  LockOutlined,
  PersonOutline,
} from "@material-ui/icons";

import Drawer from "../../../../Drawer";

import DetailCard from "./DetailCard";
import { IBillingAddress } from "./useBillingAddressValidation";

interface IConfirmDetails {
  isSubmitting: boolean;
  isCardDetailsDrawerOpen: boolean;
  isConfirmDetailsOpen: boolean;
  cardNumber: string;
  cleanCardNumber: string;
  expiry: string;
  cleanExpiry: string;
  cvv: string;
  cleanCvv: string;
  fullName: string;
  postCode: string;
  billingAddress: IBillingAddress;
  setSaveCard: (value: boolean) => void;
  closeDrawer: () => void;
  openCardDetailsDrawer: () => void;
  hiddenRef: any;
}

const useStyles = makeStyles({
  Wrapper: {
    padding: "0 2rem 2rem",
  },
  Card: {
    padding: "1rem",
    marginBottom: "1rem",
    border: "1px solid #C1CAE155",
    borderRadius: "1rem",
    position: "relative",
  },
  EditWrapper: {
    position: "absolute",
    top: "0",
    right: "0",
    padding: "1rem",
  },
  SubmitButton: {
    background: "#ffdd02",
    borderRadius: "0.625rem",
    height: "3.125rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0.5625rem 1.125rem",
    cursor: "pointer",
    width: "100%",
    border: "none",
    outline: "unset",
    fontWeight: 600,
    fontSize: "1rem",
    fontFamily:
      '"Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Avenir", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Pacifico", sans-serif',
    "&.disabled": {
      cursor: "not-allowed",
      background: "transparent !important",
      border: "1px solid #9e9e9e !important",
      pointerEvents: "none",
      "& > p": {
        color: "#9e9e9e !important",
      },
      "& > span": {
        color: "#9e9e9e !important",
      },
    },
  },
});

const ConfirmDetails = ({
  isSubmitting,
  isCardDetailsDrawerOpen,
  isConfirmDetailsOpen,
  cardNumber,
  cleanCardNumber,
  expiry,
  cleanExpiry,
  cvv,
  cleanCvv,
  fullName,
  postCode,
  billingAddress,
  setSaveCard,
  closeDrawer,
  openCardDetailsDrawer,
  hiddenRef,
}: IConfirmDetails) => {
  const classes = useStyles();

  return (
    <Drawer
      isPersistent
      isOpen={isCardDetailsDrawerOpen && isConfirmDetailsOpen}
      closeDrawer={closeDrawer}
      title="Card Details"
      instructions={["Confirm Your ", "Details"]}
    >
      {isCardDetailsDrawerOpen && (
        <div className={classes.Wrapper}>
          <div className={classes.Card}>
            <div className={classes.EditWrapper}>
              <EditButton onClick={openCardDetailsDrawer} />
            </div>
            <DetailCard
              icon={<PersonOutline />}
              title={T.translate("Forms.FullName").toString()}
              displayValue={fullName}
            />
            <DetailCard
              icon={<CreditCardOutlined />}
              title={T.translate("Forms.CardNumber").toString()}
              displayValue={
                cardNumber
                // For starring out card number // `${cardNumber.substr(0, cardNumber.length - 4).replace(/(\S)/g, "*")} ${cardNumber.substr(cardNumber.length - 4, cardNumber.length)}`
              }
            />
            <DetailCard
              icon={<CalendarTodayOutlined />}
              title={"MM/YY"}
              displayValue={expiry}
            />
            <DetailCard
              icon={<LockOutlined />}
              title={T.translate("Forms.Cvc").toString()}
              displayValue={cvv}
            />
          </div>
          <div className={classes.Card}>
            <div className={classes.EditWrapper}>
              <EditButton onClick={closeDrawer} />
            </div>
            <DetailCard
              icon={<LocationOnOutlined />}
              title={T.translate("Forms.BillingAddress").toString()}
              displayValue={`
                                ${billingAddress.line1}, 
                                ${billingAddress.city}, 
                                ${billingAddress.country_code}, 
                                ${postCode}  
                            `}
            />
          </div>
          <FormControlLabel
            style={{ padding: "1rem" }}
            control={
              <Checkbox
                onChange={(e) => setSaveCard(e.target.checked)}
                name="save-card"
              />
            }
            label={T.translate("Payment.RememberMyCardDetails")}
          />
          {/* 
                        Pay360 requires a traditional form, which it hijacks the submit for,
                        then populates the token input - which we can extract to make a payment.
                        All fields are hidden except the submit button.
                    */}
          <form
            id="pay360-form"
            action="https://secure.pay360.com/cardlock/fallback"
          >
            <input id="pay360-form-expiry" type="hidden" value={cleanExpiry} />
            <input
              id="pay360-form-cardnumber"
              type="hidden"
              value={cleanCardNumber}
            />
            <input id="pay360-form-cvv" type="hidden" value={cleanCvv} />
            <input type="hidden" ref={hiddenRef} id="pay360-form-token"></input>
            <input
              disabled={isSubmitting}
              className={`${classes.SubmitButton} ${
                isSubmitting ? "disabled" : ""
              }`}
              type="submit"
              name="submit"
              value={T.translate(
                !isSubmitting ? "buttons.UseCardDetails" : "buttons.Submitting"
              ).toString()}
            />
          </form>
        </div>
      )}
    </Drawer>
  );
};

export default ConfirmDetails;
