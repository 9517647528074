import React from "react";
// import T from "i18n-react";
import Styles from "./styles.module.scss";
import { connect, useDispatch } from "react-redux";
import { IVenueOrderTypesProps } from "./types.d";
import {
  _SetSelectedMenuType,
  _resetCart,
  _SetSelectedOrderType,
} from "../../../redux/actions/index";
import { SelectedOrderType } from "../../Payment/types.d";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import OrderType from "./OrderType/OrderType";

import useCurrency from "../../../yoello-lib/modules/localisation/useCurrency";
import { ReactComponent as CollectionBagSVG } from "../../../imges/CollectionBag.svg";
import { ReactComponent as BicycleSVG } from "../../../imges/Bicycle.svg";
import { ReactComponent as FoodPlateSVG } from "../../../imges/FoodPlate.svg";
import VenueOrderTypeHeader from "./VenueOrderTypeHeader/VenueOrderTypeHeader";
import T from "i18n-react";
import { useDrawer } from "../../Drawer/DrawerContext";
import PostCodeChecker from "../PostCodeChecker/PostCodeChecker";

export enum OrderTypeTextsENUM {
  PreparationTime = "Venue.FoodPrepTime",
  DeliveryFee = "Payment.DeliveryFee",
  DeliveryTime = "Payment.DeliveryTimeTitle",
  MinOrderValue = "Payment.MinOrder",
}

function VenueOrderTypes(props: IVenueOrderTypesProps) {
  const { formatCurrency } = useCurrency();
  const history = useHistory();
  const SelectedMenuType = useSelector((state: any) => state.SelectedMenuType);
  const Venue = useSelector((state: any) => state.Venue);
  const formattedDeliveryFee = formatCurrency(props.DeliveyFee);
  const formattedMinOrderValue = (item) => {
    return item ? formatCurrency(item) : null;
  };
  const dispatch = useDispatch();
  const { openDrawer, closeDrawer } = useDrawer()!;

  const OrderTypeTexts = {
    TABLE: [
      {
        key: OrderTypeTextsENUM.PreparationTime,
        value: `${props.PrepTime}`,
      },
      {
        key: OrderTypeTextsENUM.MinOrderValue,
        value: formattedMinOrderValue(Venue.min_dine_in_price),
      },
    ],
    CLICK: [
      {
        key: OrderTypeTextsENUM.PreparationTime,
        value: `${props.PrepTime}`,
      },
      {
        key: OrderTypeTextsENUM.MinOrderValue,
        value: formattedMinOrderValue(Venue.min_collection_price),
      },
    ],
    PICKUPNOW: [
      {
        key: OrderTypeTextsENUM.PreparationTime,
        value: `${props.PrepTime}`,
      },
      {
        key: OrderTypeTextsENUM.MinOrderValue,
        value: formattedMinOrderValue(Venue.min_pickup_now_price),
      },
    ],
    DELIVERY: [
      {
        key: OrderTypeTextsENUM.DeliveryTime,
        value: `${props.DeliveyTime}`,
      },
      {
        key: OrderTypeTextsENUM.DeliveryFee,
        value:
          props.DeliveyFee > 0
            ? formattedDeliveryFee.toString()
            : (T.translate("Misc.free") as string),
      },
      {
        key: OrderTypeTextsENUM.MinOrderValue,
        value: formattedMinOrderValue(Venue.min_delivery_price),
      },
    ],
  };

  const handleViewMenuPage = (SelectedOrder: SelectedOrderType) => {
    if (
      SelectedOrder === SelectedOrderType.DELIVERY &&
      Venue.delivery_radius_km
    ) {
      openDrawer(
        PostCodeChecker as any,
        {
          closeDrawer: closeDrawer,
          goToMenuPage: () => goToMenuPage(SelectedOrder),
        },
        "bottom",
        "PostCodeChecker"
      );
    } else {
      goToMenuPage(SelectedOrder);
    }
  };

  const goToMenuPage = (SelectedOrder: SelectedOrderType) => {
    if (SelectedOrder !== SelectedMenuType) {
      props._resetCart();
    }
    dispatch(_SetSelectedOrderType(null));
    props._SetSelectedMenuType(SelectedOrder);
    history.push(props.MenuPageLink);
  };

  const renderOrderTypes = () => {
    let OrderTypes = [];
    if (props.TableServiceEnable) {
      OrderTypes.push(
        <OrderType
          infoArray={OrderTypeTexts.TABLE}
          handleButtonClick={() => handleViewMenuPage(SelectedOrderType.TABLE)}
          HeaderComponent={
            <VenueOrderTypeHeader
              svgImage={<FoodPlateSVG fill={"transparent"} />}
              title={Venue.dine_in_label || "Venue.TableService"}
              custom={Venue.dine_in_label}
            />
          }
        />
      );
    }
    if (props.CollectionEnable) {
      OrderTypes.push(
        <OrderType
          infoArray={OrderTypeTexts.CLICK}
          handleButtonClick={() => handleViewMenuPage(SelectedOrderType.CLICK)}
          HeaderComponent={
            <VenueOrderTypeHeader
              svgImage={<CollectionBagSVG fill={"transparent"} />}
              title={Venue.collection_label || "Venue.Collection"}
              custom={Venue.collection_label}
            />
          }
        />
      );
    } else if (Venue.pickup_now_enabled) {
      OrderTypes.push(
        <OrderType
          infoArray={OrderTypeTexts.PICKUPNOW}
          handleButtonClick={() =>
            handleViewMenuPage(SelectedOrderType.COLLECTION_POINT)
          }
          HeaderComponent={
            <VenueOrderTypeHeader
              svgImage={<CollectionBagSVG fill={"transparent"} />}
              title={Venue.collection_point_pickup_lable || "Venue.pickupNow"}
              custom={Venue.collection_point_pickup_lable}
            />
          }
        />
      );
    }

    if (props.DeliveryEnable) {
      OrderTypes.push(
        <OrderType
          infoArray={OrderTypeTexts.DELIVERY}
          handleButtonClick={() =>
            handleViewMenuPage(SelectedOrderType.DELIVERY)
          }
          HeaderComponent={
            <VenueOrderTypeHeader
              svgImage={<BicycleSVG fill={"transparent"} />}
              title={Venue.delivery_label || "Venue.Delivery"}
              custom={Venue.delivery_label}
            />
          }
        />
      );
    }

    return OrderTypes;
  };
  const orderTypes = renderOrderTypes();
  return (
    <>
      {orderTypes.length > 0 && (
        <div className={Styles.description + " mb-1"}>
          <span>{T.translate("Venue.Ordering")}</span>
        </div>
      )}
      <div id="VenueOrderTypes" className={Styles.VenueOrderTypes}>
        {orderTypes}
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps, {
  _SetSelectedMenuType,
  _resetCart,
})(VenueOrderTypes);
