import { useEffect, useRef } from "react";
import scrollIntoView from "smooth-scroll-into-view-if-needed";

/**Handles the tabs scrolling into view */
export function useTabScroll(position: number, deps: any[] = []) {
  const ref = useRef([]);

  useEffect(() => {
    if (ref.current[position]) {
      scrollIntoView(ref.current[position], {
        inline: "center",
        behavior: "smooth",
        block: "nearest",
      });
    }
    // eslint-disable-next-line
  }, [position, ...deps]);

  return ref;
}
