export function getDeliveryAddress() {
  if (window.localStorage.getItem("BillingAddress")) {
    let address;
    if (typeof window.localStorage.getItem("BillingAddress") !== "object") {
      address = JSON.parse(window.localStorage.getItem("BillingAddress"));
    } else {
      address = window.localStorage.getItem("BillingAddress");
    }
    const deliveryAddress = {
      full_name: address?.full_name,
      address: address?.address,
      city: address?.city,
      postcode: address?.postcode,
    };
    return deliveryAddress;
  }
  return null;
}
