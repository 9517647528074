import React from "react";
import { QRCode } from "react-qrcode-logo";
import YoelloLogo from "../../../../imges/qrYoello.png";

export function OrderQRCode({ id }) {
  return (
    <div data-component-name={`QR-CODE-${id}`}>
      <QRCode
        value={id}
        logoImage={YoelloLogo}
        quietZone={0}
        size={250}
        bgColor="transparent"
        ecLevel="M"
        logoWidth={64}
        logoHeight={64}
        qrStyle="dots"
      />
    </div>
  );
}
