import React from "react";
import { Divider, makeStyles } from "@material-ui/core";
import { ChevronRight, ChevronLeft } from "@material-ui/icons";
import T from "i18n-react";
import { ReactComponent as SignoutSVG } from "../../imges/Signout.svg";
import ProfileImage from "../../imges/profile-placeholder.png";
import { ReactComponent as AccountSVG } from "../../imges/AccountDark.svg";
import { VerificationMethod } from "../../constants/verificationmethod";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",

    boxSizing: "border-box",
  },
  header: {
    padding: "1.5rem",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
  },
  headerTitle: {
    flex: "1 1 auto",
    textAlign: "center",
    fontSize: "1.5rem",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    "& > span": {
      padding: "0 0.5rem",
    },
  },
  headerButton: {
    flex: "1 1 4rem",
  },
  profile: {
    //   padding: "1rem",
    padding: "1.5rem",
    boxSizing: "border-box",
  },
  profileHeader: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    boxSizing: "border-box",
    paddingBottom: "1.5rem",
  },

  // profilePicture: (props: { imgUrl: string }) => ({
  //   backgroundImage: `url(${props.imgUrl})`,
  profilePicture: {
    backgroundImage: `url(${ProfileImage})`,
    backgroundSize: "3.75rem",
    borderRadius: "3.75rem",
    height: "3.75rem",
    width: "3.75rem",
    marginBottom: "1.25rem",
  },
  profileDetails: {
    padding: "1.25rem 0.25rem",
  },
  fullName: {
    fontSize: "1.42rem",
    fontWeight: 600,
  },
  detailItem: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "0.5rem",
  },
  itemName: {
    fontWeight: 500,
    fontSize: "0.9rem",
    textAlign: "left",
    flex: "3",
    color: "#455681",
    whiteSpace: "normal",
    paddingRight: "0.5rem",
  },
  itemValue: {
    fontSize: "0.9rem",
    textAlign: "right",
    flex: "1 1 auto",
    color: "#212121",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  notifications: {
    cursor: "pointer",
  },
  divider: {
    backgroundColor: "#E8EAF4",
  },
  signoutWrapper: {
    cursor: "pointer",
    padding: "1.25rem 0.75rem",
    display: "flex",
    alignItems: "center",
    "& > span": {
      paddingLeft: "0.75rem",
      fontSize: "0.9rem",
      color: "#455681",
    },
  },
});

export interface IAccountComponent {
  profilePicture: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  notificationsFrom: VerificationMethod;
  closePage: () => void;
  openNotificationDrawer: () => void;
  logout: () => void;
}

const AccountComponent = ({
  profilePicture,
  firstName,
  lastName,
  email,
  phone,
  notificationsFrom,
  closePage,
  openNotificationDrawer,
  logout,
}: IAccountComponent) => {
  const classes = useStyles({ imgUrl: profilePicture });

  const RenderDetail = ({ name, value }: { name: string; value: string }) => {
    return (
      <div className={classes.detailItem}>
        <div className={classes.itemName}>
          {T.translate(`Account.Details.${name}`)}
        </div>
        <div className={classes.itemValue}>{value}</div>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.headerButton} onClick={closePage}>
          <ChevronLeft fontSize="large" />
        </div>
        <div className={classes.headerTitle}>
          <span>
            <AccountSVG />
          </span>
          <span>{T.translate("SideMenu.MyAccount")}</span>
        </div>
        <div className={classes.headerButton} />
      </div>
      <Divider className={classes.divider} />
      <div className={classes.profile}>
        <div className={classes.profileHeader}>
          <div className={classes.profilePicture} />
          <div className={classes.fullName}>{`${firstName} ${lastName}`}</div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.profileDetails}>
          <RenderDetail name="FirstName" value={firstName} />
          <RenderDetail name="LastName" value={lastName} />
          <RenderDetail name="Email" value={email} />
          <RenderDetail name="Phone" value={phone} />
        </div>
        <Divider className={classes.divider} />
        <div className={classes.profileDetails}>
          <div
            className={`${classes.detailItem} ${classes.notifications}`}
            onClick={openNotificationDrawer}
          >
            <div className={classes.itemName}>
              {T.translate("Account.ReceiveVia")}
            </div>
            <div className={classes.itemValue}>
              {T.translate(`ProfileBuilder.${notificationsFrom}`)}
              <ChevronRight />
            </div>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.signoutWrapper}>
          <SignoutSVG />
          <span onClick={logout}>{T.translate("Account.SignOut")}</span>
        </div>
      </div>
    </div>
  );
};

export default AccountComponent;
