import { ReactComponent as GBFlagSVG } from "../imges/GB_Flag.svg";
import { ReactComponent as FRFlagSVG } from "../imges/FR_Flag.svg";
import { ReactComponent as ESFlagSVG } from "../imges/ES_Flag.svg";

export const iOS =
  process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
export const APP_LANGUAGES = {
  EN: {
    shortName: "En",
    fullName: "English",
    Flag: GBFlagSVG,
    ISO: "en",
  },
  FR: {
    shortName: "Fr",
    fullName: "French",
    Flag: FRFlagSVG,
    ISO: "fr",
  },
  ES: {
    shortName: "Es",
    fullName: "Spanish",
    Flag: ESFlagSVG,
    ISO: "es",
  },
};

export const CARDIFF_CASTLE = "cardiff-castle";
