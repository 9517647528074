import React from "react";
import T from "i18n-react";

export interface IBillItem {
  id: string;
  titleKey: string;
  value: string;
  className: string;
  hidden?: boolean;
  subTitle?: string;
}

const BillItem = (props: IBillItem) => {
  if (props.hidden) return null;
  return (
    <div className={props.className}>
      {props.subTitle ? (
        <div>
          <T.p text={{ key: props.titleKey }} />
          <p>{props.subTitle}</p>
        </div>
      ) : (
        <T.p text={{ key: props.titleKey }} />
      )}
      <p data-component-name={props.id}>{props.value}</p>
    </div>
  );
};

export default BillItem;
