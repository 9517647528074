import React, { createContext, useContext, useReducer } from "react";

import PaymentReducer from "./reducer/PaymentReducer";
import { IPageComponentProps } from "../Page/types.d";
import {
  IPaymentContainerState,
  IPaymentContext,
  PaymentStages,
} from "./types.d";
import WithConsumerAuth from "../Auth/WithConsumerAuth";
import { usePaymentProviders } from "./PaymentHooks/usePaymentProviders";

export const paymentContainerInitalState: IPaymentContainerState = {
  userHasPaymentDetails: false,
  isPaymentDrawerOpen: false,
  isCardDetailsDrawerOpen: false,
  isDeliveryDrawerOpen: false,
  isCollectionDrawerOpen: false,
  isPaymentProcessingDrawerOpen: false,
  isTableServiceDrawerOpen: false,
  deliveryTimes: [],
  selectedOrderType: null,
  paymentStages: PaymentStages.PAYMENT,
  selectedTable: null,
  areaName: null,
  deliveryAddress: {
    full_name: "",
    address: "",
    city: "",
    postcode: "",
  },
  disabledIds: [],
  selectedTime: null,
  isUpdatingPaymentDetails: false,
  subTotalCost: 0,
  tipTotal: 0,
  totalPrice: 0,
  transactionFee: 0,
  isLoading: false,
  message: "",
  deliveryPrice: 0,
  postcodeCheckStatus: null,
  cartItemTaxAmount: null,
  serviceTaxAmount: null,
  cartItemInclusiveTaxAmount: null,
  serviceTaxInclusiveTaxAmount: null,
  isUsingPaymentRequest: false,
  deliveryTaxAmount: null,
  deliveryInclusiveTaxAmount: null,
  paymentDetails: null,
  totalPriceOverride: null,
  discounts: {
    code: null,
    message: null,
    value: null,
    error: false,
  },
  totalDiscounts: null,
  isPaymentReady: false,
  paymentProviders: {},
  placedOrderID: null,
  selectedPaymentMethod: null,
};

export const PaymentContext = createContext<Partial<IPaymentContext>>(
  paymentContainerInitalState
);

export const usePaymentState = () => useContext(PaymentContext);

interface IPaymentStore extends IPageComponentProps {
  Component: any;
}

const PaymentStore = (props: IPaymentStore) => {
  const { Component } = props;
  //@ts-ignore
  const [state, dispatch] = useReducer(
    PaymentReducer,
    paymentContainerInitalState
  );

  usePaymentProviders(dispatch);
  return (
    <PaymentContext.Provider
      value={{
        ...state,
        dispatch,
        retrieveToken: props.retrieveToken,
        isAuthenticated: props.isAuthenticated,
      }}
    >
      <Component {...props} isAuthenticated={props.isAuthenticated} />
    </PaymentContext.Provider>
  );
};

//@ts-ignore
export default WithConsumerAuth(PaymentStore);
