import {
  isVenueOpen,
  ITradingTimesEntity,
  generateTimeIntervalsTradingTimes,
} from "./tradingTimesObject";
import moment from "moment-timezone";
import { SelectedOrderType } from "./venueOpen";

export function venuePreOrderAvailable(
  venueData: any,
  tradingTimes: Record<string, ITradingTimesEntity[]>,
  orderTypeSelectedFlag: boolean,
  orderType?: any
) {
  if (!tradingTimes) {
    return false;
  }
  const day = moment().format("dddd").toLowerCase();
  const todayTradingTimes = tradingTimes[day];

  const deliveryTimes = generateTimeIntervalsTradingTimes(
    todayTradingTimes,
    venueData.delivery_max_lead_time,
    venueData.time_zone_name
  );

  const collectionTimes = generateTimeIntervalsTradingTimes(
    todayTradingTimes,
    venueData.food_prep_time,
    venueData.time_zone_name
  );

  if (orderTypeSelectedFlag) {
    // if order type is selected then that means user is in menu page:
    // Check if the venue is closed
    // and whether delivery/collection times are available based on their choosen order type
    // and whether venue is offering preOrders or not
    // If all met then user can preOrder
    return (
      !isVenueOpen(tradingTimes, venueData.time_zone_name) &&
      ((orderType === SelectedOrderType.CLICK && collectionTimes.length > 0) ||
        (orderType === SelectedOrderType.DELIVERY &&
          deliveryTimes.length > 0)) &&
      venueData.preorders_enabled
    );
  } else {
    // if order type is not selected then user is on venue page
    // check if venue is closed
    // and if collection is enabled and has collection times or delivery is enabled and has delivery times
    // and venue is offering preOrders
    return (
      !isVenueOpen(tradingTimes, venueData.time_zone_name) &&
      ((venueData.pickup_enabled && collectionTimes.length > 0) ||
        (venueData.delivery_enabled && deliveryTimes.length > 0)) &&
      venueData.preorders_enabled
    );
  }
}

export function canVenuePreorder(
  venue: any,
  tradingTimes: any,
  deliveryTimes: any,
  collectionTimes: any,
  orderSelect: any
) {
  if (orderSelect === SelectedOrderType.TABLE) {
    return false;
  }
  if (orderSelect) {
    // if order type is selected then that means user is in menu page:
    // Check if the venue is closed
    // and whether delivery/collection times are available based on their choosen order type
    // and whether venue is offering preOrders or not
    // If all met then user can preOrder
    return (
      !isVenueOpen(tradingTimes, venue.time_zone_name) &&
      ((orderSelect === SelectedOrderType.CLICK &&
        collectionTimes.length > 0) ||
        (orderSelect === SelectedOrderType.DELIVERY &&
          deliveryTimes.length > 0)) &&
      venue.preorders_enabled
    );
  } else {
    // if order type is not selected then user is on venue page
    // check if venue is closed
    // and if collection is enabled and has collection times or delivery is enabled and has delivery times
    // and venue is offering preOrders
    return (
      !isVenueOpen(tradingTimes, venue.time_zone_name) &&
      ((venue.pickup_enabled && collectionTimes.length > 0) ||
        (venue.delivery_enabled && deliveryTimes.length > 0)) &&
      venue.preorders_enabled
    );
  }
}