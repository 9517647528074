import React from 'react';

import Lottie from 'react-lottie';
import animationData from './lottie/ErrorPage.json'
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

// const changeTheFilter = () => {
//     document.getElementById('BodyDivId').style.filter = 'none'

// }
function ErrorPage(props) {
    // changeTheFilter()
    return (
        <div style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'darkblue'
        }}>
            <Lottie options={defaultOptions}
                height={"unset"}
                width={"100%"}
                isStopped={false}
                isPaused={false}
                isClickToPauseDisabled={true}
            />
        </div>
    );
}

export default ErrorPage;