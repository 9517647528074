import React from "react";
import T from "i18n-react";
import { IVenueCard } from "./types.d";
import Styles from "./venueCard.module.scss";
import { ReactComponent as RightArrowSVG } from "../../../imges/RightArrow.svg";
import { isVenueOpen } from "../../../yoello-lib/modules/utils/tradingTimesObject";

function VenueCard({ venueInfo }: IVenueCard) {
  return (
    <div
      className={Styles.venueCardContainer}
      data-component-name="GroupPageVenueCard"
    >
      <div>
        {venueInfo.venue_url_logo && (
          <div className={Styles.venueImage}>
            <img
              width="100%"
              height="100%"
              src={venueInfo.venue_url_logo}
              alt={venueInfo.venue_nickname}
            />
          </div>
        )}

        <div className={Styles.venueDetails}>
          <p>{venueInfo.venue_name}</p>
          {isVenueOpen(
            venueInfo.trading_times,
            venueInfo?.venue_time_zone_name
          ) ? (
            <div className={Styles.venueOpenText}>
              <div className={Styles.openCircleRipple}></div>
              <p>{T.translate("Venue.OpenText")}</p>
            </div>
          ) : (
            <div className={Styles.venueCloseText}>
              <div className={Styles.closeCircleRipple}></div>
              <p>{T.translate("Venue.VenueClosed")}</p>
            </div>
          )}
        </div>
      </div>

      <div className={Styles.letsGo}>
        <RightArrowSVG />
      </div>
    </div>
  );
}

export default VenueCard;
