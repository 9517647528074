import React from "react";
import "../TopMenu/styles.css";
import { useMenu } from "./MenuContext";
import { MenuLists } from "./MenuLists";
import { MenuSearch } from "./MenuSearch";
import { MenuTimes } from "./MenuTimes";
import { SubTab } from "./Tabs/SubTab";
import { TopTabs } from "./Tabs/TopTab";
import { useScrolled } from "./hooks";
import Styles from "./styles.module.scss";
import MenuItemsPageSkeletonLoading from "../Widgets/Loading/MenuItemsPage";
import { EmptyList } from "../TopMenu/EmptyList";

export function MenuContents() {
  const {
    menuItemState,
    state: { menuPosition },
    elementRefs,
  } = useMenu();
  const { className } = useScrolled(Styles.isScrolled, Styles.isScrolledOut);

  return menuItemState ? (
    <div className={`${Styles.MenuContents} ${className}`}>
      {menuItemState && Object.keys(menuItemState).length > 0 ? (
        <>
          <div
            ref={(ref) => (elementRefs.current["topMenu"] = ref)}
            data-component-name="top_menu"
            className={"top_menu"}
          >
            <TopTabs />
            <MenuTimes />
            {menuPosition === -1 ? <MenuSearch /> : <SubTab />}
          </div>
          <MenuLists />
        </>
      ) : (
        <EmptyList />
      )}
    </div>
  ) : (
    <MenuItemsPageSkeletonLoading hideHeader={true} />
  );
}
