import * as types from "../actions/ActionTypes"

const Table = (state = null, action) => {
  switch (action.type) {
    case types.ChangeTable:

      state = action.data;
      return state;

    default:

      return state
  }
};
export default Table