import React from "react";
import PageLoading from "../../Page/PageLoading";

const PaymentProcessingComponent = () => {
  return (
    <div>
      <PageLoading />
    </div>
  );
};

export default PaymentProcessingComponent;
