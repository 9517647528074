import { generatePay360Endpoints } from "../../environments/services";
import { IAPIDefinitions } from "../types";


export enum APIPay360 {
    payrefunds = "pay360refunds"
}

function generatePay360URL() {
    const stage = process.env.REACT_APP_ENVIRONMENT;
    const overwriteHost = process.env.REACT_APP_SERVICE_ENDPOINT;
    const url = generatePay360Endpoints(stage, overwriteHost);
    return url + "/api/";
}


export const pay360Defs: IAPIDefinitions = {

};

pay360Defs[APIPay360.payrefunds] = {
    baseURL: generatePay360URL(),
    defaultURL: 'payments/refund',
    POST: {}
};