import React from "react";
import { useHistory } from "react-router-dom";
import Styles from "./orders.module.scss";
import Button from "../Widgets/Button";
import { ReactComponent as BagSVG } from "../../imges/Bag_new.svg";
import { ReactComponent as BackArrowSVG } from "../../imges/ArrowLeft.svg";
import T from "i18n-react";
import { ORDERS } from "../../constants/URL.const";

export function OrderHeader({ navigateBack }) {
  const history = useHistory();

  const getHeaderTitle = () => {
    if (history.location.pathname.includes("review")) {
      return "Payment.LeaveReview";
    } else if (history.location.pathname.includes(ORDERS + "/")) {
      return "OrderHistory.OrderDetails";
    } else {
      return "OrderHistory.OrderHistory";
    }
  };
  return (
    <div className={Styles.header}>
      <div className={Styles.pageTitle}>
        <BagSVG />
        <T.p className="HeaderTitle" text={getHeaderTitle()} />
      </div>
      <Button
        isHeaderBtn
        className={Styles.backBtn}
        Icon={<BackArrowSVG />}
        onClick={() => {
          navigateBack();
        }}
      />
    </div>
  );
}
