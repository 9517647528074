import "./DifficultTimes.css";
import React, { Component } from "react";
import Button from "../Button";
import SwipeableViews from "react-swipeable-views";
import T from "i18n-react";
import { ReactComponent as YoelloSVG } from "../../../imges/yoello-w2.svg";
import { ReactComponent as NHSSVG } from "../../../imges/NHS-w.svg";
class DifficultTimes extends Component {
  state = {
    index: 0,
    played1: false,
    played2: false,
  };
  render() {
    return (
      <div className="DifficultTimes">
        <div
          style={{
            textAlign: "center",
            height: "5px",
            display: "flex",
            justifyContent: "center",
            margin: "15px 0 10px 0",
          }}
        >
          <img src={require("../../../imges/Line.svg")} alt="" />
        </div>
        <div
          style={
            {
              // paddingTop: "20px"
            }
          }
        >
          <SwipeableViews
            index={this.state.index}
            disableLazyLoading={true}
            onChangeIndex={(e) => {
              if (e === 1) {
                this.setState({
                  index: e,
                  played1: true,
                });
              } else if (e === 2) {
                this.setState({
                  index: e,
                  played2: true,
                });
              } else {
                this.setState({
                  index: e,
                });
              }
            }}
            style={{
              height: "100%",
            }}
          >
            <div className="SlideDivs">
              <div className="TopBar">
                {/* <img
                  src={require("../../../imges/yoello-w2.svg")}
                  alt=""
                  height="50px"
                  width="85px"
                /> */}
                <YoelloSVG height={35} width={60} />
                <NHSSVG height={35} width={60} />
                {/* <img
                  height="35px"
                  width="85px"
                  alt=""
                  src={require("../../../imges/NHS-w.svg")}
                /> */}
              </div>
              <div
                style={{
                  padding: "5px",
                  textAlign: "center",
                  // padding: "20px 30px"
                }}
              >
                <T.p
                  className="COVIDTestTitle"
                  text={{ key: `DifficultTimes.COVIDTestTitle` }}
                />
                <T.p
                  className="COVIDTestDescription"
                  text={{ key: `DifficultTimes.COVIDTestDescription` }}
                />
                <T.p
                  className="COVIDTestDescription mt-2"
                  text={{ key: `DifficultTimes.COVIDTestRegisterText` }}
                />
              </div>
            </div>
            <div className="SlideDivs">
              <div>
                <img
                  alt="Support"
                  width="100%"
                  src={require("../../../imges/SupportOffice.svg")}
                />
              </div>
              <div>
                <T.p
                  className="COVIDTestTitle"
                  text={{ key: `DifficultTimes.SupportingLocalBusinesses` }}
                />
                <T.p
                  className="Discription"
                  text={{ key: `DifficultTimes.Description2` }}
                />
              </div>
            </div>
          </SwipeableViews>
          <div
            style={{
              position: "absolute",
              bottom: "0px",
              width: "calc(100%)",
            }}
          >
            <div>
              <a
                style={{
                  textDecoration: "none",
                  color: "inherit",
                }}
                rel="noopener noreferrer"
                target="_blank"
                href={this.props.trackAndTraceURL || ""}
              >
                <Button
                  title={"Register"}
                  isYoelloBtn
                  className="DifficultTimesBtn"
                  style={{
                    height: "2.5rem",
                    border: "none",
                    display: this.state.index !== 0 ? "none" : "",
                    width: "80%",
                    margin: "10px auto",
                    background: "#005EB8",
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: "800",
                    fontSize: "18px",
                    lineHeight: "27px",
                    textAlign: "center",
                  }}
                />
              </a>
              <Button
                dataComponentName="NhsSkipButton"
                isYoelloBtn
                onClick={() => {
                  let e = this.state.index + 1;
                  if (e === 2) {
                    this.props.handleCloseModal();
                  } else {
                    this.setState({
                      index: e,
                    });
                  }
                }}
                title={
                  this.state.index === 0
                    ? "Skip"
                    : this.state.index === 1
                    ? "GetStarted"
                    : "Next"
                }
                className="DifficultTimesBtn"
                style={{
                  height: "2.5rem",
                  border: "none",
                  width: "80%",
                  margin: "auto",
                  background: "#000000",
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: "800",
                  fontSize: "18px",
                  lineHeight: "27px",
                  textAlign: "center",
                }}
              />
            </div>
            <div
              style={{
                flex: "2",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0 10px",
                margin: "20px 0",
              }}
            >
              <div
                className={`BarDotsDiv ${
                  this.state.index === 0 ? "BarDotsDivSelected" : ""
                }`}
              ></div>
              <div
                className={`BarDotsDiv ${
                  this.state.index === 1 ? "BarDotsDivSelected" : ""
                }`}
              ></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default DifficultTimes;
