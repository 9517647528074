import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router";
import { NOT_FOUND, VENUE_MENU } from "../../constants/URL.const";
import APIExtensions from "../../yoello-lib/modules/API/APIDefs";
import { MenuContainer } from "../Menu/MenuContainer";
import PageContainer from "../Page/PageContainer";
import { VenueComponent } from "../Venue/VenueContainer";
import MenuItemsSkeleton from "../Widgets/Loading/MenuItemsPage";
import venueLandingPageLoader from "../Widgets/Loading/VenueLandigPageSkeleton";

export function VenueRouter(props) {
  let { path } = useRouteMatch();
  const Venue = useSelector((state: any) => state.Venue);
  const orderType = useSelector((state: any) => state.SelectedMenuType);
  const localization = useSelector((state: any) => state.localization);

  return (
    <Switch>
      <Route
        exact
        path={path}
        render={(props) => (
          <PageContainer
            {...props}
            apiConfig={{
              dispatcherParams: [
                Venue !== null &&
                Venue?.venue_nickname === props.match.params["id"]
                  ? true
                  : false,
                localization.language,
              ],
              query: props.match.params["id"],

              headers: {},
            }}
            APIExtension={APIExtensions.consumerVenue}
            ChildComponent={VenueComponent}
            LoadingComponent={venueLandingPageLoader}
            errorFunction={() =>
              props.history.push(NOT_FOUND, { message: "Venue not found" })
            }
          />
        )}
      />
      <Route
        path={VENUE_MENU}
        render={(props) => (
          <PageContainer
            {...props}
            area={false}
            apiConfig={{
              query: props.match.params["id"],
              headers: {},
              params: { order_type: orderType },
              dispatcherParams: [orderType, Venue],
            }}
            APIExtension={APIExtensions.consumerMenu}
            ChildComponent={MenuContainer}
            errorFunction={() =>
              props.history.push(NOT_FOUND, {
                message: "Venue not found",
              })
            }
            LoadingComponent={MenuItemsSkeleton}
          />
        )}
      />
    </Switch>
  );
}
