import { useEffect, useState } from "react";
import T from "i18n-react";
import { useDispatch } from "react-redux";
import { _updateCartID } from "../../../../../redux/actions";
import { v4 as uuidv4 } from "uuid";

const stage = process.env.REACT_APP_STAGE;
const appId =
  stage === "prod"
    ? "sq0idp-EYm1ewdua57GbToKLys75g"
    : "sandbox-sq0idb-H7O0i8uOgd4bPmVpSedJkg";

export const useLoadPaymentForm = () => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const rdxDispatch = useDispatch();
  const config = {
    applicationId: appId,
    inputClass: "sq-input",
    autoBuild: false,
    // applePay: {
    //   elementId: "sq-apple-pay",
    // },
    googlePay: {
      elementId: "sq-google-pay",
    },
    cardNumber: {
      elementId: "sq-card-number",
      placeholder: T.translate("Forms.CardNumber"),
    },
    cvv: {
      elementId: "sq-cvv",
      placeholder: T.translate("Forms.Cvc"),
    },
    expirationDate: {
      elementId: "sq-expiration-date",
      placeholder: T.translate("Forms.ExpirationDate"),
    },
    postalCode: {
      elementId: "sq-postal-code",
      placeholder: T.translate("Forms.PostCode"),
    },
    inputStyles: [
      {
        fontFamily: "Arial", //Square don't allow non-web fonts, so Open Sans currently isn't available
        backgroundColor: "transparent",
        fontSize: "14px", // Can't set font size to rem for Square so setting at 14px
        padding: "14px",
      },
    ],
    callbacks: {
      createPaymentRequest: () => {},
      methodsSupported: function (methods, unsupportedReason) {
        var googlePayBtn = document.getElementById("sq-google-pay");
        var applePayBtn = document.getElementById("sq-apple-pay");
        googlePayBtn?.addEventListener("click", async () => {
          rdxDispatch(_updateCartID(uuidv4()));
        });
        applePayBtn.style.display = "none";
        googlePayBtn.style.display = "none";
        if (methods.applePay === true) {
          applePayBtn.style.display = "none"; //Set to inline-block once verified
        } else if (methods.googlePay === true) {
          googlePayBtn.style.display = "inline-block";
        } else {
          console.log(unsupportedReason);
        }
      },
    },
  };
  useEffect(() => {
    let sqPaymentScript = document.createElement("script");
    sqPaymentScript.src = `https://js.squareup${
      stage === "prod" ? "" : "sandbox"
    }.com/v2/paymentform`;
    sqPaymentScript.type = "text/javascript";
    sqPaymentScript.async = false;
    sqPaymentScript.onload = () => {
      setHasLoaded(true);
    };
    document.getElementsByTagName("head")[0].appendChild(sqPaymentScript);
  }, []);

  return { hasLoaded, config };
};
